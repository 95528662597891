import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

const data1 = [
    {
      label: 'TRACKER NUMBER',
      field: 'tracker_no',
      sort: 'asc',
      width: 200,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: 'TRACKER STATUS',
      field: 'tracker_status',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'ASSOCIATED VEHICLE',
      field: 'associated',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'FRD / FIR / IDENTIFICATION NUMBER',
      field: 'frd_fir',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'DATE',
      field: 'date',
      sort: 'asc',
      width: 150,
    },    
  ] 

const ASSIGNED = 1
const AVAILABLE = 2

class TrackerList extends Component {
    counter = 0  
  constructor(props){
    super(props);
    this.state = {
      trackerList : '',
      datatable : [],          
      nextCount : 0, 
      totalCount : 0, 
      buttonFlag : true, 
      dataCollection : [], 
      chunckValue : '',
      searchText : '',
      totalEntries : '',        
      statusFilterValue : 0
    }
  }

  componentDidMount(){
    setTimeout(() => {
        var navBarHeight = $('.navBar').outerHeight();                
        $('.jsw_report_parent').css({
            paddingTop: navBarHeight + 50,        
        })        
    }, 300); 
    this.getTrackerData()  
  }

  emptyTable = () => {
    this.setState({
      datatable : []
    })
  }

  buttonStatus = () => {
    const {nextCount, totalCount} = this.state
    // console.log(nextCount,'.................',totalCount)
    if(nextCount === totalCount){
      // console.log('iffffffffff')      
      if(this.counter === 0){
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }else{
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    }else{
    //   console.log('elseeeeeeee', this.counter)
      if(this.counter === 0){
        // console.log('else 1111111111')
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')        
        $('#dt-next').addClass('enabled-btn')
      }else{
        // console.log('else 22222222')
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')        
      }
    }    
  }

  entriesData = () => {
    const {nextCount, totalCount, chunckValue} = this.state
    if(nextCount != totalCount){
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start+'-'+end
      this.setState({
        totalEntries : entries_text
      })
    }else{
      if(nextCount <= 10){
        if(nextCount == 0){ 
          this.setState({
            totalEntries : "0"
          })
        }else{
          var entries_text = '1'+'-'+totalCount
          this.setState({
            totalEntries : entries_text
          })
        }
      }else{
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if(module > 0){
          start = nextCount - module + 1
          end = nextCount
          entries_text = start+'-'+end
        }else{
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start+'-'+end
        }
        this.setState({
          totalEntries : entries_text
        })
      }
    }
  }

  trackerStatus = (status) => {
    const {t} = this.props
    if(status === AVAILABLE){
      return(
        <p className="cj_w_header" style={{color : "#28a745"}}>{t('ct.available')}</p>
      )
    }else{
      return(
        <p className="cj_w_header" style={{color : "#ed7d28"}}>{t('ct.assigned')}</p>
      )
    }
  }

  createTableData = () => {        
    const {trackerList,datatable} = this.state    
    var row = []  
    // console.log('lenght is11111111111', datatable)
    for (let i = 0; i < trackerList.length; i++) {
      const element = trackerList[i];
      var row_data = {tracker_no : element.serial_number, tracker_status : this.trackerStatus(element.tracker_status), associated : element.assigned_vehicle ? element.assigned_vehicle : "NA",
        frd_fir :element.ref_number ? element.ref_number : "NA", date : CommonFunction.tableDateFormate(element.tracker_map_date)}       
      datatable.push(row_data)                 
    }    
    this.buttonStatus() 
    this.entriesData()   
    this.forceUpdate()
    // console.log('data table data is2222222', datatable)
    // this.intervalData()
  }

  pageNextClick = () => {
    const {totalCount,nextCount,dataCollection,datatable} = this.state
    // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
    this.counter = this.counter + 1  
    for (let i = 0; i < datatable.length; i++) {
      const element = datatable[i];
      dataCollection.push(element)
    }      
    this.setState({
      datatable : []
    },() => {
      this.getTrackerData()
    })
  }

  pagePreviousClick = () => {
    const {dataCollection, chunckValue, nextCount} = this.state
    var pageSize = nextCount % chunckValue
    if(pageSize === 0){
      pageSize = chunckValue
    }
    // console.log('previous clicked', pageSize)    
    var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
    this.setState({
      datatable : last,
      nextCount : nextCount - pageSize
    },() => {      
      this.buttonStatus() 
      this.entriesData()     
    })
    this.counter = this.counter -1
  }

  optimizationSearch = (value) => {  
    
      this.counter = 0
      if(value.length >= 3){
        // console.log('serach.................')
        setTimeout(() => {
          this.setState({
            datatable : [],
            dataCollection : [],
            totalCount : '',
            chunckValue : '',
            nextCount : 0,
            searchText : value
          },() => {
            this.getTrackerData()          
          })
        }, 1000);     
      }else if(value.length === 0){
        setTimeout(() => {
          this.setState({
            datatable : [],
            dataCollection : [],
            totalCount : '',
            chunckValue : '',
            nextCount : 0,
            searchText : value
          },() => {
            this.getTrackerData()          
          })
        }, 1000);     
      }          
  }

  getTrackerData = () => {
    $('#processing').show()
    const data = {next_count : this.state.nextCount, status : this.state.statusFilterValue, start_date : "", end_date : "", search : this.state.searchText}
    // console.log('data is', data)
    ApiUrlCall.getTrackerDetails(JSON.stringify(data)).then(response => {
      $('#processing').hide()
      // console.log('response of tracker is', response)
      this.emptyTable()
      if(response.data.response_code === 200 && response.data.is_success){
          this.setState({
            trackerList : response.data.data.tracker_list,
            nextCount : response.data.data.next_count,
            totalCount : response.data.data.total_count,
            chunckValue : response.data.data.size
          },() => {
            this.createTableData()
          })
      }else{
        this.setState({
          openAlert : true,
          errorMessage : response.data.response_message
        })
      }

    }).catch(error => {
      $('#processing').hide()
      console.log("error while fetching tracker details", error)
    })
  }

  statusFilter = (e) => {
    var status = e.currentTarget.value
    // console.log('value is', status)
    this.setState({
      statusFilterValue : status,
      datatable : [],
      dataCollection : [],
      totalCount : 0,
      chunckValue : 0,
      nextCount : 0,
      searchText : '',
    },() => {
      this.getTrackerData()
    })
  }

  render(){
    const {t} = this.props
    return (
        <div>
            <Navbar/>
            <div className="jsw_report_parent">
                <div>
                    <select className="status-filter" onChange={this.statusFilter}>
                      <option value="0">{t('ct.all.text')}</option>
                      <option value="1">{t('dds.assigned')}</option>
                      <option value="2">{t('dds.available')}</option>
                    </select>
                    <MDBDataTable            
                        displayEntries={false}
                        paging={false}
                        ref={this.myref}
                        scrollX
                        entries={10}            
                        displayEntries={false}
                        striped
                        bordered
                        data={{columns : data1 , rows : this.state.datatable}}            
                        onSearch = {this.optimizationSearch}            
                    />
                    <Grid container style={{margin : "20px 0px"}}>
                        <Grid item xs>
                            <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                        </Grid>
                        <Grid item xs style={{textAlign : "right"}}>
                            <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>{t('aeds.previous')}</button>
                            <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>{t('aeds.next')}</button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={1800}
                onClose={(e, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    this.setState({ openAlert: false })
                }
                }
                open={this.state.openAlert}
                message={this.state.errorMessage}
            />
        </div>
    );
  }
}

export default withTranslation()(TrackerList);
