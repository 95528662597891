import React, { Component } from 'react';
//import $ from 'jquery';
import TableView from '../tableView/tableView';


class Vehicles extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
        // var navBarHeight = $('.navBar').outerHeight()
        // $('.vehicleRoute').css({
        //   paddingTop : navBarHeight+60,
        // })
  }

  render(){
    return (
      <div className="vehicleRoute">
        <TableView />
      </div>
    );
  }
}

export default Vehicles;
