import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import { BrowserRouter as Router, Link, } from "react-router-dom";
import '../vehicles/addvehicles.css'

class Addvihcles extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    componentDidMount(){
        var navBarHeight = $('.navBar').outerHeight();                    
        $('.create_new_vehicle').css({
          paddingTop : navBarHeight+60,
          paddingLeft : "100px",
          paddingRight : "100px"
        })             
    } 

    selectType = (e) => {
        $(e.currentTarget).addClass('selected_btn')
        $(e.currentTarget).removeClass('unseleted_btn')
        $(e.currentTarget).siblings().removeClass('selected_btn')
        $(e.currentTarget).siblings().addClass('unseleted_btn')
    }

    previousView = (e) => {
        if($('#enter_vehicle_details').is(':hidden')){
            $('#enter_vehicle_details').show()
            $('#enter_vehicle_document').hide() 
            $('#next_detail').show()
            $('#add_details').hide()
        }else{
            
        }
    }

    nextView =(e) => {
        $('#enter_vehicle_details').hide()
        $('#enter_vehicle_document').show()
        $(e.currentTarget).hide()
        $(e.currentTarget).siblings().show()
    }

    removeFile = (e) => {
        $(e.currentTarget).parent().hide()
        $(e.currentTarget).parent().siblings().first().show()        
    }

    fileUpload = (e) => {        
        e.currentTarget.nextSibling.click()        
    }

    fileAdded = (e) => {
        var uploadedFile = e.currentTarget.files[0].name
        $(e.currentTarget).prev().hide()
        $(e.currentTarget).next().show()
        $(e.currentTarget).next().find('label').empty()
        $(e.currentTarget).next().find('label').append(uploadedFile)
        // console.log('file added and the name is ')
    }

    render() {
       return (
           <div className="create_new_vehicle">
               <div>                                       
                   <h3><i class="fa fa-long-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Add a new vehicle</h3>
               </div>
               
               <div className="map_view_div">
                   <Grid container>
                        <Grid item xs>   
                            <p className="p_header p_margin0">basic vehicle details</p>                         
                        </Grid>
                        <Grid item xs>                            
                        </Grid>
                   </Grid>
               </div>
               
               <div style={{textAlign:"-webkit-right"}}>
                    <div className="prev_next_div">
                        <div>
                            <button className="btn previous_btn" onClick={this.previousView}>previous</button>
                        </div>
                        <div>
                            <button id="next_detail" className="btn orange-btn" style={{float:"none"}} onClick={this.nextView}>next</button>
                            <button id="add_details" className="btn orange-btn" style={{float:"none", display:"none"}} onClick={this.addVehicle}>Add vehicle</button>
                        </div>
                    </div>
               </div>

               <div className="map_view_div vehicle_enter_detail">
                   {/* vehicle details */}
                   <div id="enter_vehicle_details" style={{display:"block"}}>
                   <div>
                        <p className="p_header p_margin0">vehicle use</p>
                    </div>

                    <div className="vehile_uses">
                        <div onClick={this.selectType} className="selected_btn">
                            <p className="p_margin0">Dispatch</p>
                        </div>
                        <div onClick={this.selectType} className="unseleted_btn">
                            <p className="p_margin0">Infrastructure</p>
                        </div>
                        <div onClick={this.selectType} className="unseleted_btn">
                            <p className="p_margin0">Freight</p>
                        </div>
                    </div>

                    <div>
                        <Grid spacing={4} container>
                            <Grid item xs={4}>
                                <p>Make</p>
                                <input type="text" placeholder="Make" className="vd_input"></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p>Model</p>
                                <input type="text" placeholder="Model" className="vd_input"></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p>Variant</p>
                                <input type="text" placeholder="Variant" className="vd_input"></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p>Fuel Type</p>
                                <input type="text" placeholder="Fuel Type" className="vd_input"></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p>Fuel Capacity</p>
                                <input type="text" placeholder="Fuel Capacity" className="vd_input"></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p>Registration Number</p>
                                <input type="text" placeholder="Registration Number" className="vd_input"></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p>VIN</p>
                                <input type="text" placeholder="VIN" className="vd_input"></input>
                            </Grid>
                        </Grid>
                    </div>
               </div>
               {/* vehicle details end*/}

               {/* enter vehilce document */}
               <div id="enter_vehicle_document" style={{display:"none"}}>
                   <Grid container spacing={4}>
                        <Grid item xs={2}>
                            <p className="p_header p_margin0">Registration certificate</p>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="arrow_button upload_btn" onClick={this.fileUpload} style={{marginTop:"0px"}}>
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                            <input type="file" className="file_upload" onChange={this.fileAdded}></input>
                            <div className="show_file_name">
                                <label className="p_margin0">File name</label>
                                <div class="remove_file" onClick={this.removeFile}> <i class="fa fa-close" aria-hidden="true"></i></div>
                            </div>
                        </Grid>
                   </Grid>

                   <Grid spacing={4} container>
                        <Grid item xs={2}>
                            <p className="p_header p_margin0">insurance</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Name of insurer</p>
                            <input type="text" placeholder="Name of insurer" className="vd_input"></input>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Date of Expiry</p>
                            <input type="text" placeholder="Date of Expiry" className="vd_input"></input>
                        </Grid>
                        <Grid item xs={2}>                            
                            <div className="arrow_button upload_btn" onClick={this.fileUpload} style={{marginTop:"40px"}}>
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                            <input type="file" className="file_upload" onChange={this.fileAdded}></input>
                            <div className="show_file_name"  style={{marginTop:"33px"}}>
                                <label className="p_margin0">File name</label>
                                <div class="remove_file" onClick={this.removeFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                            </div>
                        </Grid>
                   </Grid>

                   <Grid spacing={4} container>
                        <Grid item xs={2}>
                            <p className="p_header p_margin0">permit</p>
                        </Grid>
                        <Grid item xs={4}>                            
                            <div className="arrow_button upload_btn" onClick={this.fileUpload} style={{marginTop:"0px"}}>
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                            <input type="file" className="file_upload" onChange={this.fileAdded}></input>
                            <div className="show_file_name">
                                <label className="p_margin0">File name</label>
                                <div class="remove_file" onClick={this.removeFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                            </div>
                        </Grid>
                   </Grid>

                   <Grid spacing={4} container>
                        <Grid item xs={2}>
                            <p className="p_header p_margin0">loan</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>EMI Amount</p>
                            <input type="text" placeholder="EMI Amount" className="vd_input"></input>
                        </Grid>
                        <Grid item xs={4}>
                            <p>End Date</p>
                            <input type="text" placeholder="End Date" className="vd_input"></input>
                        </Grid>
                        <Grid item xs={2}>                            
                            <div className="arrow_button upload_btn" onClick={this.fileUpload} style={{marginTop:"40px"}}>
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </div>
                            <input type="file" className="file_upload" onChange={this.fileAdded}></input>
                            <div className="show_file_name"  style={{marginTop:"33px"}}>
                                <label className="p_margin0">File name</label>
                                <div class="remove_file" onClick={this.removeFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                            </div>
                        </Grid>
                   </Grid>

                   {/* <Grid container>
                        <Grid item xs={2}>
                            <p className="p_header p_margin0">permits</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Name of insurer</p>
                            <input type="text" placeholder="Fuel Capacity" className="vd_input"></input>
                        </Grid>                        
                        <Grid item xs={2}>
                            upload button
                        </Grid>
                   </Grid> */}
               </div>
               {/* enter vehilce document end */}
               </div>
           </div>
       )
    }
}
                            
export default Addvihcles;