import React, { Component } from 'react';
import $ from 'jquery';
import Map from '../map/map'
import Grid from '@material-ui/core/Grid';
import './people_tracking.scss'
import { withTranslation } from 'react-i18next';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import constant from '../constant.json'

const L = window.L
const ONMOVE = 1
const INMEET = 2
const ALL = [ONMOVE, INMEET]
const MOVING_ICON = 'images/moving_person_icon.png'
const MEET_ICON = 'images/meeting_icon.png'
const MOVING_ICON_INACTIVE = 'images/moving_person_icon_inactive.png'
const MEET_ICON_INACTIVE = 'images/meeting_icon_inactive.png'

class PeopleTrackingMapView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boundFlag: true,
            movingCount: 0,
            meetingCount: 0,
            employeeData: [],
            marker: [],
            selectedOption: ALL,
            boundFlag: true,
            clickMessage: ''
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 2000);
        if (localStorage.getItem('default_view') == constant.people_tracking) {
            this.setIntervalLiveData()
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.interval)
    }

    setIntervalLiveData = () => {
        $('#processing').show()
        this.getLiveTracking()
        this.interval = setInterval(this.getLiveTracking, 500000)
    }

    getLiveTracking = () => {
        ApiUrlCall.ptLiveTrack().then(response => {
            $('#processing').hide()
            // console.log('live data', response)
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    employeeData: response.data.data.user_list
                }, () => {
                    if (this.state.employeeData.length > 0) {
                        this.stateCount(this.state.employeeData)
                        this.createMarker(this.state.selectedOption)
                        // this.boundMarkers()
                    }
                })
            } else if (response.data.response_code == 410 && !response.data.is_success) {
                if ($('#pplt_expire_dialog').is(':hidden')) {
                    $('#pplt_expire_dialog').slideDown()
                }
            } else {

            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching live tracking data', error)
        })
    }

    stateCount = (state_markers) => {
        var moving = 0
        var meet = 0
        if (state_markers) {
            for (let i = 0; i < state_markers.length; i++) {
                // var j = (() => {
                if (state_markers[i].tracking_state == ONMOVE) {
                    moving = moving + 1
                    // return 0
                } else if (state_markers[i].tracking_state == INMEET) {
                    meet = meet + 1
                    // return 1
                }
                // })()
            }
        }
        this.setState({
            movingCount: moving,
            meetingCount: meet,
        })
    }


    createMarker = (state) => {
        const { employeeData } = this.state
        const array = []
        const icon = [MOVING_ICON, MEET_ICON , MOVING_ICON_INACTIVE , MEET_ICON_INACTIVE ]
        
        if (employeeData.length > 0) {
            for (let i = 0; i < employeeData.length; i++) {
                
                if (state.includes(employeeData[i].tracking_state)) {
                    var j = (() => {
                        
                        if (employeeData[i].tracking_state == ONMOVE) {
                            if(employeeData[i].is_active){
                                return 0
                            }else{
                                return 2
                            }
                        } else if (employeeData[i].tracking_state == INMEET) {
                            if(employeeData[i].is_active){
                                return 1
                            }else{
                                return 3
                            }
                            
                        }

                    })()
                    
                    var last_reported_time = CommonFunction.tableDateFormate(employeeData[i].last_reported) + ", " + CommonFunction.timeFormate(employeeData[i].last_reported)
                    array.push(
                        {
                            position: [employeeData[i].lat, employeeData[i].lng],
                            // rotateMarker: true,
                            
                           infoWinData: { name: employeeData[i].name, location: employeeData[i].address , last_reported: last_reported_time },
                            peopleTracking: true,
                            icon: L.icon({
                                iconUrl: icon[j],
                                iconRetinaUrl: icon[j],
                                iconSize: [65, 65],
                                popupAnchor: [-3, -15]
                            }),
                            draggable: false,
                            title: employeeData[i].name,
                            onClick: () => { },
                            onDragend: () => { }
                        }
                    )
                }
                // else {          
                //   array.push({})
                // }
            }
        }
        this.setState({
            markers: array,
        }, () => {
            // this.boundMarkers()
        })
    }

    allEmployee = (e) => {
        $(e.currentTarget).addClass('selected_tab')
        $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
        this.setState({
            markers: [],
            selectedOption: ALL,
            boundFlag: true,
            clickMessage: ""
        }, () => {
            this.createMarker(ALL)
        })
    }

    movingEmployee = (e) => {
        if (this.state.movingCount > 0) {
            $(e.currentTarget).addClass('selected_tab')
            $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
            this.setState({
                markers: [],
                selectedOption: [ONMOVE],
                clickMessage: "",
                boundFlag: true,
            }, () => {
                this.createMarker([ONMOVE])
            })
        } else {
            this.setState({
                clickMessage: "Currently No Employee is Moving *"
            })
        }
    }

    meetingEmployee = (e) => {
        if (this.state.meetingCount > 0) {
            $(e.currentTarget).addClass('selected_tab')
            $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
            this.setState({
                markers: [],
                selectedOption: [INMEET],
                clickMessage: "",
                boundFlag: true,
            }, () => {
                this.createMarker([INMEET])
            })
        } else {
            this.setState({
                clickMessage: "Currently No Employee is in Meeting*"
            })
        }
    }

    zoomInMap = (e) => {
        $(e.currentTarget).parent().parent().parent().addClass('zoom_in_map')
        $(e.currentTarget).siblings().show()
        $(e.currentTarget).hide()
        $('body').css({
            pointerEvents: "none",
            overflow: "hidden"
        })
        window.dispatchEvent(new Event('resize'));
    }
    zoomOutMap = (e) => {
        $(e.currentTarget).parent().parent().parent().removeClass('zoom_in_map')
        $(e.currentTarget).siblings().show()
        $(e.currentTarget).hide()
        $('body').css({
            pointerEvents: "auto",
            overflow: "auto"
        })
        window.dispatchEvent(new Event('resize'));
    }

    AllUser = (e) => {
        $(e.currentTarget).addClass('selected_tab')
        $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    }
    onMoveUser = (e) => {
        $(e.currentTarget).addClass('selected_tab')
        $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    }
    inMeetingUser = (e) => {
        $(e.currentTarget).addClass('selected_tab')
        $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    }

    render() {
        const { t } = this.props
        const { employeeData, movingCount, meetingCount } = this.state
        return (
            <div className="cardView">
                <Grid container className="">
                    <Grid item xs={11} style={{ textAlign: "left" }}>
                        <label className="widget-header">{t('vls.map')}</label>&nbsp;
                        <span className="cj_w_header" style={{ color: "#dc3545", float: "right" }}>{this.state.clickMessage}</span>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                        <img src="images/expand.png" alt="icon" onClick={this.zoomInMap} style={{ cursor: "pointer" }}></img>
                        <img src="images/expand.png" alt="icon" onClick={this.zoomOutMap} style={{ display: "none", cursor: "pointer" }}></img>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs>
                        <div className="vehicle_state selected_tab" onClick={this.allEmployee}>
                            <p className="p_margin0">ALL&nbsp;<span>{CommonFunction.zeroAppend(employeeData.length)}</span></p>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div onClick={this.movingEmployee} className="vehicle_state">
                            <p className="p_margin0">On Move&nbsp;<span>{CommonFunction.zeroAppend(movingCount)}</span></p>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div onClick={this.meetingEmployee} className="vehicle_state">
                            <p className="p_margin0">In Meeting&nbsp;<span>{CommonFunction.zeroAppend(meetingCount)}</span></p>
                        </div>
                    </Grid>
                </Grid>
                <div className="p_map_view">
                    <Map
                        key={1}
                        height={"100%"}
                        markers={this.state.markers}
                        pauseBound={this.state.boundFlag}
                        cluster={true}
                        onZoom={() => {
                            this.setState({
                                boundFlag: false
                            })
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(PeopleTrackingMapView);
