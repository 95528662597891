import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'


class TableView extends Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }


  render () {
    return(
    <div className="tableOuterDiv">
    <div className="cardView tableParent">
      <Table responsive>
        <thead className="tableHeader">
          <tr>
            <th>#</th>
            <th>journey</th>
            <th>start date & time</th>
            <th>vehicle</th>
            <th>driver</th>            
          </tr>
        </thead>
        <tbody className="tableBody">
          <tr>
            <td><input type="checkbox"/></td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>
            <td>Table cell</td>            
          </tr>
        </tbody>
      </Table>
    </div>
    </div>
  )
  }
}

export default TableView;
