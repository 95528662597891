import React, { Component } from 'react';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
//import $ from 'jquery';
// import TableView from '../tableView/tableView';


class Migrate extends Component {
  constructor(props){
    super(props);
    this.state = {
        redirectToReferrer : false,
    }
  }

  componentWillMount(){
    localStorage.clear()
  }

  componentDidMount(){
      analytics.logEvent('page_view',{
        page_title : "LoginViaOldWeb"
      })
      analytics.logEvent('screen_view',{
        screen_name : "LoginViaOldWeb",        
      }) 
      Amplify.configure(awswebapp);
      Analytics.record({
        name: constant.awsName, 
        // Attribute values must be strings
        attributes: { screen_name: 'WEB_LOGIN_VIA_OLD_WEBAPP'}
      }); 
        // var navBarHeight = $('.navBar').outerHeight()
        // $('.vehicleRoute').css({
        //   paddingTop : navBarHeight+60,
        // })
        this.externalLogin()
        //console.log('>>>>>>>>>>>>>>>> external login')
  }

  componentWillUnmount(){
    window.location.reload(false)
  }

  externalLogin = () => {
    var user_id = localStorage.getItem('user_id')
    var token = localStorage.getItem('token')
    //console.log(user_id,'=====',token)
    if(user_id == null && token == null){
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const user_id = urlParams.get('user')
      const username = urlParams.get('customer_name')
      const user_name = urlParams.get('username')
      const group = urlParams.get('group')
      localStorage.setItem("token", token);
      localStorage.setItem("user_id", user_id)
      localStorage.setItem("username", username)
      localStorage.setItem('user_name', user_name)
      // localStorage.setItem('is_people_tracking', false)  
      localStorage.setItem('default_view',1)    
      localStorage.setItem('group', group)
      this.setState({
          redirectToReferrer : true,
      })  
      // window.location.reload(false)      
    }else{
        this.setState({
            redirectToReferrer : true,
        })
        // window.location.reload(false)
    }
  }

  render(){
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    // console.log('login component called', from,'..........', redirectToReferrer)
    if (redirectToReferrer === true) {      
        return <Redirect to={from} />              
    }
    return (
      <div className="migrate_parent">
          <p>Redirecting</p>
        {/* <TableView /> */}
      </div>
    );
  }
}

export default Migrate;
