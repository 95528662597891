import React, { Component } from 'react';
import $ from 'jquery';
import '../journey/journey_alert.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Grid from '@material-ui/core/Grid';
import Navbar from '../../Config/Navbar';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function';
import Calendar from 'react-calendar/dist/entry.nostyle'
import '../calendar/calendar.scss'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';

class JswNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: [],
            startDate: new Date(),
            endDate: new Date(),
            vehicleList : '',
            selectedVehicle : '',
            firstLoad : true,
            selectedFilter : 1,
            searchList : '',
            searchSelectedText : ''
        }
    }

    componentDidMount() {
        analytics.logEvent('screen_view',{
            screen_name : "JourneyAlert"
          }) 
          analytics.logEvent('page_view',{
            page_title : "JourneyAlert",        
          })
          Amplify.configure(awswebapp);
          Analytics.record({
            name: constant.awsName, 
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_JOURNEY_ALERT'}
          });
        var navBarHeight = $('.navBar').outerHeight();
        $('.journey_alert_parent').css({
            paddingTop: navBarHeight + 60,
            paddingLeft: "100px",
            paddingRight: "100px",
            paddingBottom: "100px",
        })
        this.getNotificationListData()
        this.getVehicleList()
    }

    getVehicleList = () => {
        var data = {next_count : 0 , search : ''}
        ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
            // console.log('vehicle list', response)
            if(response.data.response_code === 200 && response.data.is_success === true){
                this.setState({
                    vehicleList : response.data.data.vehicle_summary_list
                }, () => {
                    // console.log('vehicle list state set', this.state.vehicleList)
                })
            }
        }).catch(error => {
            console.log('error while fetching vehicle list', error)
        })
    }

    getNotificationData = () => {
        $('#processing').show()
        var end = this.state.endDate
        var start = this.state.startDate 
        if(this.state.firstLoad){
            start.setHours(0,0,0,0)     
        }  
        // let parameter = {start_date:  CommonFunction.ServerDateTime(start) ,end_date: CommonFunction.ServerDateTime(end) }
        let parameter = {serial_number : this.state.selectedVehicle, start_date: CommonFunction.ServerDateTime(start), end_date: CommonFunction.ServerDateTime(end) }        
        // console.log('date parameter is', parameter)
        ApiUrlCall.getNotification(JSON.stringify(parameter)).then(response => {
            if (response.data.response_code === 200 && response.data.is_success === true) {
                $('#processing').hide()
                // console.log('response notification is', response.data)
                this.setState({
                    notificationData: response.data.data.notifications,
                    firstLoad : false,
                }, () => {
                    $('#processing').hide()
                    //   this.renderNotification()              
                })
            }
            else if (response.data.response_code === 204 && response.data.is_success === true) {
                $('#processing').hide()
                this.setState({ noAlert: true, })
            }
            // console.log('notification response is', response)
        }).catch(error => {
            console.log('error while fetching nottification data', error)
        })
    }

    getNotificationPanelData = (arraylist) => {
        // const { notificationData } = this.state
        let notiArray = []
        // for (let i = 0; i < notificationData.length; i++) {
        const notification = arraylist
        // console.log('notification list is', notification)
        for (let j = 0; j < notification.length; j++) {
            const element = notification[j];
            let icon = "images/ALERT_TYPE_" + element.alertType + ".png"
            notiArray.push(
                <Grid item xs={6} container className="alert_container">
                    <Grid item xs={3}>
                        <div className="alert_icon_div">
                            <img src={icon} className="alert-icon"></img>
                        </div>
                    </Grid>
                    <Grid item xs className="noti-content">
                        <label><b>{element.vehicle_name}</b></label>
                        <p>{element.bodyText}</p>
                        <span>{CommonFunction.timeFormate(element.event_time)}</span>&nbsp;&nbsp;<span>|
                                </span>&nbsp;&nbsp;<span>{CommonFunction.dateFormate(element.event_time)}</span>
                    </Grid>
                </Grid>
            )
        }

        // }
        return notiArray
    }

    showStartDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }
    showEndDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }

    StartdateClicked = date => {
        var isoStartDate = date
        // console.log('props value', date.toISOString())
        this.setState({ startDate: isoStartDate })
        $('#start_date_cal').slideUp(200)
    }

    // this method is used to create end date into ISO and send it to server
    EnddateClicked = date => {
        var isoStartDate = date
        // console.log('props value', date.toISOString())
        this.setState({ endDate: isoStartDate })
        $('#end_date_cal').slideUp(200)
    }

    selectedFilterIs = (e) => {
        var selectedValue = e.currentTarget.value
        this.setState({
            selectedFilter : selectedValue
        }, () => {
            $('.search_list').prev().val("")
        })
    }

    searchFrdFir = (e) => {
        var value = e.currentTarget.value
        var data = {search_str : value.trim(), is_dashboard : 0}
        // console.log('data', data)
        if(value.length > 3){
            ApiUrlCall.searchVehicleNumber(JSON.stringify(data)).then(response => {
                // console.log('search dahboard hit', response)
                if(response.data.response_code === 200 && response.data.is_success){
                    this.setState({
                        searchList : response.data.data
                    })
                }
    
            }).catch(error => {
                console.log("error while searching vehicle details", error)
            })  
            $(e.currentTarget).next().slideDown("slow")
        }else{   
            this.setState({
                searchList : ''
            })         
            $(e.currentTarget).next().slideUp("slow") 
        }
    }

    searchVehicleNumber = (e) => {
        var value = e.currentTarget.value
        var data = {search_str : value.trim(), is_dashboard : 0}
        // console.log('data', data)
        if(value.length > 3){
            ApiUrlCall.searchVehicleNumber(JSON.stringify(data)).then(response => {
                // console.log('search dahboard hit', response)
                if(response.data.response_code === 200 && response.data.is_success){
                    this.setState({
                        searchList : response.data.data
                    })
                }
    
            }).catch(error => {
                console.log("error while searching vehicle details", error)
            })  
            $(e.currentTarget).next().slideDown("slow")
        }else{
            this.setState({
                searchList : ''
            })         
            $(e.currentTarget).next().slideUp("slow") 
        }
    }

    searchDataClick = (e) => {
        const {selectedFilter} = this.state
        var split_details = $(e.currentTarget).text().split("/")
        var unique_number = split_details[1]
        var vehicle_number = split_details[0]
        // console.log('1111111111111111111') 
        if(selectedFilter == 2){
            // console.log('2222222222')
            this.setState({
                searchSelectedText : unique_number,
                notificationData : '',
            },() => {    
                $('.search_list').prev().val(this.state.searchSelectedText)             
                this.getNotificationListData()
            })
        }else{
            // console.log('3333333333333')
            this.setState({
                searchSelectedText : vehicle_number,
                notificationData : '',
            },() => {
                $('.search_list').prev().val(this.state.searchSelectedText)
                this.getNotificationListData()
            })
        }
        $('.search_list').slideUp("slow")        
    }

    getNotificationListData = () => {
        $('#processing').show()
        const {selectedFilter,searchSelectedText} = this.state
        if(selectedFilter == 2){
            var data = {filter_type: selectedFilter , frd_number: searchSelectedText.trim() , registration_number : "", start_date : "", end_date : ""}
        }else if(selectedFilter == 3){
            var data = {filter_type: selectedFilter , frd_number: "" , registration_number : searchSelectedText.trim(), start_date : "", end_date : "" }
        }else{
            var data = {filter_type: selectedFilter , frd_number: "" , registration_number : "", start_date : CommonFunction.ServerDateTime(this.state.startDate.setHours(0,0,0,0)),
            end_date : CommonFunction.ServerDateTime(this.state.endDate.setHours(23,59,59,0))}
        }
        // console.log('data is', data)
        ApiUrlCall.jswNotificationList(JSON.stringify(data)).then(response => {
            // console.log('notification repsonse is', response)
            if(response.data.response_code === 200 && response.data.is_success){
                $('#processing').hide()
                this.setState({
                    notificationData: response.data.data.notifications,
                })
            }else{
                $('#processing').hide()
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching notification data', error)
        })
    }

    render() {
        const {t} = this.props
        const { notificationData, vehicleList, searchList } = this.state
        let icon = <img src="images/next-copy-2.png" width="35px"></img>
        var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);
        var maxDate = new Date()
        if (notificationData && notificationData.length > 0) {
            // console.log('notification data is', notificationData)
            var panel = notificationData.map(panel => {
                return (
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={icon}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="p_margin_bottom p_header">{CommonFunction.tableDateFormate(panel.date)}&nbsp;&nbsp;
                            ({panel.notification_list.length})</p>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="expand_panel">
                            <Grid container>
                                {this.getNotificationPanelData(panel.notification_list)}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
            })
        }        
        if(vehicleList){            
            var vehicle = vehicleList.map(vehicle =>{
                return(
                    <option key={vehicle.serial_number} id={vehicle.serial_number}>{vehicle.vehicle_name}</option>
                )
            })
        }

        if(searchList){      
            if(searchList.length > 0){
                var search_data_list = searchList.map(data => {
                  return(
                    <div>
                      <p id={data.serial_number} onClick={this.searchDataClick}>{data.vehicle_name} / {data.frd_fir_number}</p>
                      <hr/>
                    </div>
                  )
                })
              }else{
                var search_data_list = (() => {
                  return(
                    <div>
                      <p>No Data Available</p>
                    </div>
                  )
                })()
              } 
          }

        return (
            <div>
                <Navbar />
                <div className="journey_alert_parent">
                    <div>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>                                
                                <label style={{marginTop : "15px"}}>
                                    <img src="images/arrow11.png" alt="icon" style={{ marginRight: "15px", cursor: 'pointer' }}
                                        onClick={() => {
                                            this.props.history.push('/')
                                        }}></img>
                                    <span className="start_journey_loc">{t('jas.alerts')}</span>
                                </label>
                            </Grid>
                            <Grid item xs={2}>
                                <p>&nbsp;</p>                                
                                <select defaultValue={this.state.selectedFilter} className="status-filter"
                                 onChange={this.selectedFilterIs} style={{marginTop : "5px"}}>
                                    <option value="1">{t('jsw.noti.filter1')}</option>
                                    <option value="2">{t('jsw.noti.filter2')}</option>
                                    <option value="3">{t('jsw.noti.filter3')}</option>
                                </select>
                            </Grid>
                            <Grid item xs={4} style={{display : this.state.selectedFilter == 2 ? "" : "none", position : "relative"}}>
                                <p>&nbsp;</p> 
                                <input type="text" placeholder={t('jsw.search.frd')} className="journey_input"
                                onChange={this.searchFrdFir} onBlur={(e) => {
                                    $(e.currentTarget).next().slideUp("slow")
                                }}></input>
                                <div className="search_list" style={{position : "absolute", border : "1px solid #cccccc",zIndex : "99", width : "80%"}}>
                                        {search_data_list}
                                </div>
                            </Grid>
                            <Grid item xs={4} style={{display : this.state.selectedFilter == 3 ? "" : "none", position : "relative"}}>
                                <p>&nbsp;</p> 
                                <input type="text" placeholder={t('jsw.search.vehicle')} className="journey_input"
                                onChange={this.searchVehicleNumber} onBlur={(e) => {
                                    $(e.currentTarget).next().slideUp("slow")
                                }}></input>
                                <div className="search_list" style={{position : "absolute", border : "1px solid #cccccc",zIndex : "99", width : "80%"}}>
                                        {search_data_list}
                                </div>
                            </Grid>
                            <Grid item xs style={{display : this.state.selectedFilter == 1 ? "" : "none"}}>
                                <p className="cj_w_header">{t('ct.startdate')}</p>
                                <div className="input_div" onClick={this.showStartDate}>
                                    <input type="text" placeholder={t('ct.startdate')} id="start_date" value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input" disabled></input>
                                    <img src="images/fill-111.png"></img>
                                </div>
                                <div className="calendar_parent" id="start_date_cal">
                                    <Calendar
                                        selectRange={false}
                                        onChange={this.StartdateClicked}
                                        value={this.state.date}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs style={{display : this.state.selectedFilter == 1 ? "" : "none"}}>
                                <p className="cj_w_header">{t('ct.enddate')}</p>
                                <div className="input_div" onClick={this.showEndDate}>
                                    <input type="text" placeholder={t('ct.enddate')} id="end_date" value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input" disabled></input>
                                    <img src="images/fill-111.png"></img>
                                </div>
                                <div className="calendar_parent" id="end_date_cal">
                                    <Calendar
                                        selectRange={false}
                                        onChange={this.EnddateClicked}
                                        value={this.state.date}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs style={{display : this.state.selectedFilter == 1 ? "" : "none"}}>
                                <p>&nbsp;</p>
                                <button className="btn orange-btn btn-block" onClick={() => {
                                    this.getNotificationListData()
                                }} style={{marginTop : "0px"}}>{t('ct.generate')}</button>                                
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                        <Tabs>
                            <TabList>
                                {/* <Tab>All</Tab> */}
                                <Tab>{t('ct.notifications')}</Tab>
                                {/* <Tab>Actionables</Tab> */}
                            </TabList>

                            <TabPanel >
                                <div className="document_expand_parent" style={{display : this.state.notificationData.length > 0 ? "" : "none"}}>
                                    {panel}                                    
                                </div>
                                <div style={{display : this.state.notificationData.length == 0 ? "" : "none"}}>
                                    <p>No Data Available</p>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>

                </div>
            </div>
        );
    }
}

export default withTranslation()(JswNotification);
