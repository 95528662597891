import React, { Component, useEffect, useState} from 'react';
import $ from 'jquery';
import './dashboard.scss'
import Grid from '@material-ui/core/Grid';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom'
import DashboardContent from './dashboardContent'
import Journeys from '../journey/journey'
// import Drivers from '../drivers/drivers'
import Vehicles from '../vehicles/vehicles'
import Reports from '../reports/reports'
import Addvihcles from '../vehicles/Addvehicles';
// import Vehiclesdoc from '../vehicles/Vehiclesdoc';
// import Vehiclelist from '../vehicles/Vehiclelist';
// import Myvehicles from '../vehicles/Myvehicles';
// import Addmyvehicles from '../vehicles/Myvehicles';
import AddDriver from '../drivers/Adddriver';
// import Adddriverdoc from '../drivers/Adddriverdoc';
import JourneyOverview from '../journey/journey_overview';
// import JourneyDocument from '../journey/journey_document'
import JourneyAlert from '../journey/journey_alert';
import CreateJourney from '../journey/create_new_journey';
// import DriverListing from '../drivers/DriverListing';
import Login from '../../Login';
import Vehicle_report from '../reports/vehicle_report';
import Summery_report from '../reports/Summery_report';
// import Vehicle_Table from '../reports/Vehicle_Table';
import DashboardSetup from '../onboarding/onboarding'
import VehicleLists from '../vehicles/vehilcleList'
import VehiclesDetails from '../vehicles/addvehicledetail'
import DriverDetails from '../drivers/driverdetail'
import DriverLists from '../drivers/driverList'
import EditVehicle from '../vehicles/editvehicledetail'
import EditJourneyDetails from '../journey/editjourneydetails'
import PrivateRoute from '../../Config/Private'
import Migrate from './migrate'
//import ProtectedRoute from '../../Config/ProtectedRoute';
import CreateJourneyDetails from '../journey/creatjourneydetail';
import UserTab from '../inviteuser/tab_list'
import CreateUser from '../inviteuser/create_user'
import jwtDecode from 'jwt-decode';
import Setting from '../setting/setting'
import SettingForJSW from '../jsw/setting/setting1'
import JswComponent from '../jsw/jsw'
import JswReport from '../jsw/jsw_report'
import TrackerList from '../jsw/tracker_list'
import JswNotification from '../jsw/jsw_notification'
import VendorIntegration from '../setting/vendor_integration'
import TrackerManagement from '../setting/tracker_management'
import TrackerManagementReport from '../reports/tracker_management_report'
import PeopleVisitList from '../peopletracking/people_visit_list'
import PeopleReport from '../peopletracking/people_report'
import PeopleUserManagement from  '../peopletracking/people_user_management'
import SendInvite from '../peopletracking/send_invite'
import AddVisit from '../peopletracking/add_visit'
import PeopleTrackingRedirect from '../dashboard/people_tracking_redirect'
import constant from '../constant.json'
import Scheduler_report from '../reports/sheduler_report';
import delete_account from '../setting/delete_account';


const fakeAuthCentralState = {
  isAuthenticated: false,
  authenticate(callback) {
     this.isAuthenticated = true;
     setTimeout(callback, 300);
  },  
};
class MainRoute extends Component {
  constructor(props){
    super(props);
    this.myRef = React.createRef()
    this.state = {
      isNavBarHidden: false,
       loggedIn: false,
    }
  }

  componentDidMount(){
    var navBarHeight = $('.navBar').outerHeight()
    // console.log("height of navbar", navBarHeight);
  }

  render() {
    
    const Page404 = ({ location }) => (
      <div>
        <code>{location.pathname} <h2>404 Page not Found
          {console.log(location.path,"alfa.....")}
          </h2></code>
      </div>
   );
    // console.log('routers are called', this.props)
    return (
      <Router>
        <div>
          
           <Switch>
              <Route exact strict path="/login" component={Login} />
              {parseInt(localStorage.getItem("group")) !== constant.rent_user ? 
              <PrivateRoute exact strict path="/" component={DashboardContent} />  : 
              <PrivateRoute exact strict path="/" component={JswComponent} />   }             
          </Switch>
        
          <Route exact strict path="/migrate" component={Migrate} />
          <Route exact strict path="/people_tracking_new" component={PeopleTrackingRedirect} />
          <PrivateRoute exact strict path="/journey" component={Journeys} />
          {/* <PrivateRoute exact strict path="/myvehicles" component={Myvehicles} /> */}
          {/* <PrivateRoute exact strict path="/drivers" component={Drivers} /> */}
          <PrivateRoute exact strict path="/reports" component={Reports} />
          <PrivateRoute path="/addvehicles" component={Addvihcles} />
          {/* <PrivateRoute path="/addmyvehicles" component={Addmyvehicles} /> */}
          {/* <PrivateRoute path="/vehiclesdoc" component={Vehiclesdoc} /> */}
          {/* <PrivateRoute path="/vehiclelist" component={Vehiclelist} /> */}
          <PrivateRoute path="/add_driver" component={AddDriver} />
          {/* <PrivateRoute path="/addriverdoc" component={Adddriverdoc} /> */}
          <PrivateRoute path="/journeyOverview" component={JourneyOverview} />
          {/* <PrivateRoute path="/journey_document" component={JourneyDocument} /> */}
          <PrivateRoute path="/journey_alert" component={JourneyAlert} />
          <PrivateRoute path="/create_new_journey" component={CreateJourney} />
          {/* <PrivateRoute path="/driverlist" component={DriverListing} /> */}
          <PrivateRoute path="/vehicle-report" component={Vehicle_report} />
          <PrivateRoute path="/sumery-report" component={Summery_report} />
          {/* <PrivateRoute path="/vehicle-table" component={Vehicle_Table} /> */}
          <PrivateRoute path="/vehicle-report" component={Vehicle_report} />
          <PrivateRoute path="/dashboard_setup" component={DashboardSetup} />
          <PrivateRoute path="/list_vehicle" component={VehicleLists} />
          <PrivateRoute path="/vehicle_details" component={VehiclesDetails} />
          <PrivateRoute path="/driver_details" component={DriverDetails} />
          <PrivateRoute path="/user_list" component={UserTab} />
          <PrivateRoute path="/create_user" component={CreateUser} />
          <PrivateRoute path="/edit_vehicle_details" component={EditVehicle} />
          <PrivateRoute path="/edit_journey_details" component={EditJourneyDetails} />
          <PrivateRoute path="/enter_journey_details" component={CreateJourneyDetails} />
          <PrivateRoute path="/setting" component={Setting} />
          <PrivateRoute   path="/deleteaccount"  component={delete_account}/> 

          <PrivateRoute path="/setting_rental" component={SettingForJSW} />
          <PrivateRoute path="/live_dashboard" component={JswComponent} />
          <PrivateRoute path="/report_data" component={JswReport} />
          <PrivateRoute path="/tracker_list" component={TrackerList} />
          <PrivateRoute path="/notification" component={JswNotification} />
          <PrivateRoute path="/vendor_integration" component={VendorIntegration} />
          <PrivateRoute path="/tracker_management" component={TrackerManagement} />
          <PrivateRoute path="/tracker_management_report" component={TrackerManagementReport} />
          <PrivateRoute path="/p_user_management" component={PeopleUserManagement} />
          <PrivateRoute path="/p_visit_list" component={PeopleVisitList} />
          <PrivateRoute path="/p_report" component={PeopleReport} />
          <PrivateRoute path="/p_send_invite" component={SendInvite} />
          <PrivateRoute path="/p_add_visit" component={AddVisit} />
          <PrivateRoute path="/sheduler_report" component={Scheduler_report} />
        
        </div>
          {/* <Route component={Page404} />  */}
      </Router>
    );
  }
}

export default MainRoute;
