import React, { Component } from 'react';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import Calendar from 'react-calendar/dist/entry.nostyle'
import Grid from '@material-ui/core/Grid';
import CommonFunction from '../common_function'
import { MDBDataTable } from 'mdbreact';
import ApiUrlCall from '../API/apiurl';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Snackbar from '@material-ui/core/Snackbar';

const data1 = [
    {
        label: 'TRACKER NUMBER',
        field: 'serial_number',
        sort: 'asc',
        width: 200,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'TRACKER TYPE',
        field: 'type',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'VEHICLE MAPPED',
        field: 'mapped',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'VEHICLE REGISTRATION NUMBER',
        field: 'reg_no',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'TRACKER STATUS',
        field: 'status',
        sort: 'asc',
        width: 150,
    },
    // {
    //     label: 'MAPPING ID',
    //     field: 'mapping_id',
    //     sort: 'asc',
    //     width: 150,
    // },
    {
        label: 'MAPPING DATE & TIME',
        field: 'date',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'ACTION',
        field: 'action',
        sort: 'asc',
        width: 150,
    }
]

class TrackerManagement extends Component {
    counter = 0
    constructor(props) {
        super(props);
        this.state = {
            trackerListData: [],
            startDate: '',
            datatable: [],
            nextCount: 0,
            totalCount: '',
            dataCollection: [],
            chunckValue: '',
            searchText: '',
            totalEntries: '',
            selectFilter: 1,
            mapSerial_number: '',
            showValidDateCal: false,
            isValidChecked: true,
            validityDate: '',
            untrackData: [],
            selectVehicleId: '',
            addVehicleName: '',
            addVehiclRegNumber: '',
            openAlert: false,
            errorMessage: ''
        }
    }

    componentDidMount() {
        this.trackerList()
        this.getUntrackVehicleList()
    }

    getUntrackVehicleList = () => {
        ApiUrlCall.untrackVehicleList().then(response => {
            // console.log('response', response)
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    untrackData: response.data.data.vehicle_list
                })
            }
        }).catch(error => {
            console.log('error while fetching untrack list', error)
        })
    }

    emptyTable = () => {
        this.setState({
            datatable: []
        })
    }

    trackerList = () => {
        const { nextCount, searchText, startDate } = this.state
        if(startDate){
            var data = { next_count: nextCount, search: searchText, start_date : CommonFunction.ServerDateTime(startDate) }
        }else{
            var data = { next_count: nextCount, search: searchText, start_date : "" }
        }
        $('#processing').show()
        ApiUrlCall.trackerManagementList(JSON.stringify(data)).then(response => {
            //console.log('response is report', response)
            this.emptyTable()
            if (response.data.response_code === 200 && response.data.is_success) {
                $('#processing').hide()
                this.setState({
                    trackerListData: response.data.data.vehicle_summary_list,
                    nextCount: response.data.data.next_count,
                    totalCount: response.data.data.total_count,
                    chunckValue: response.data.data.size
                }, () => {
                    this.createTableData()
                })
            } else {
                $('#processing').hide()
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while getting fuel report', error)
        })
    }

    mapVehicle = (serial_number) => {        
        this.setState({
            mapSerial_number: serial_number,            
        },() => {            
            $('#map_vehicle_details').slideDown()
        })
    }

    // getFuelType = (type) => {
    //     const { t } = this.props
    //     // console.log(type, "fuel type is")
    //     var fuel_type = [t('ct.gas'), t('ct.diesel'), t('ct.petrol'), t('ct.cng'), t('ct.ethanol'), t('ct.bio')][type]
    //     return fuel_type
    // }

    vehicleMappedStatus = (value) => {
        //console.log('value', value)
        if (value == 3) {
            return "Unmapped"
        } else if (value == 2){
            return "Mapped"
        }
    }

    unmapVehicle = (serial_number,date_val,reg_number) => {
        $('#processing').show()
        var data = { serial_number: serial_number,mapped_date:date_val,reg_number:reg_number }
        ApiUrlCall.unmapTracker(JSON.stringify(data)).then(response => {
            $('#processing').hide()
             //console.log('rep', response)
            if (response.data.response_code == 200 && response.data.is_success) {                
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                }, () => {
                    this.counter = 0
                    this.trackerList()
                })
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while unmapping vehicle', error)
        })
    }

    actionVehicleState = (value, serial_number, is_removable,date_val,reg_number) => {
        if(is_removable){
            if (value) {
                return <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => this.unmapVehicle(serial_number,date_val,reg_number)}>Unmap</p>
            } else {
                return <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => this.mapVehicle(serial_number)}>Map</p>
            }
        }else{
            return ""
        }
    }

    createTableData = () => {
        const { trackerListData, datatable } = this.state
        var row = []
        // console.log('lenght is', vehicleData.length)
        for (let i = 0; i < trackerListData.length; i++) {
            const element = trackerListData[i];
            var row_data = {
                serial_number: element.serial_number,
                type: element.tracker_type,
                mapped: element.vehicle_name,
                reg_no: element.reg_number,
                status: this.vehicleMappedStatus(element.status),
                // mapping_id: element.mapping_id,
                date: CommonFunction.tableDateFormate(element.mapping_date)+", "+CommonFunction.timeFormate(element.mapping_date),
                action: this.actionVehicleState(element.is_mapped, element.serial_number,element.is_removable,element.mapping_date,element.reg_number)
            }
            datatable.push(row_data)
        }
        this.buttonStatus()
        this.entriesData()
        this.forceUpdate()
        // this.intervalData()
    }

    StartdateClicked = date => {
        this.setState({ startDate: date })
        $('#start_date_cal').slideUp(200)
    }

    showStartDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
        $('#end_date_cal').slideUp()
    }

    buttonStatus = () => {
        const { nextCount, totalCount } = this.state
        // console.log(nextCount,'.................',totalCount)
        if (nextCount === totalCount) {
            // console.log('iffffffffff')      
            if (this.counter === 0) {
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            } else {
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            }
        } else {
            // console.log('elseeeeeeee', this.counter)
            if (this.counter === 0) {
                // console.log('else 1111111111')
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('disable-btn')
                $('#dt-next').addClass('enabled-btn')
            } else {
                // console.log('else 22222222')
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').addClass('enabled-btn')
                $('#dt-next').removeClass('disable-btn')
            }
        }
    }

    pageNextClick = () => {
        const { totalCount, nextCount, dataCollection, datatable } = this.state
        // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
        this.counter = this.counter + 1
        for (let i = 0; i < datatable.length; i++) {
            const element = datatable[i];
            dataCollection.push(element)
        }
        this.setState({
            datatable: []
        }, () => {
            this.trackerList()
        })
    }

    pagePreviousClick = () => {
        const { dataCollection, chunckValue, nextCount } = this.state
        var pageSize = nextCount % chunckValue
        if (pageSize === 0) {
            pageSize = chunckValue
        }
        // console.log('previous clicked', pageSize)    
        var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
        this.setState({
            datatable: last,
            nextCount: nextCount - pageSize
        }, () => {
            this.buttonStatus()
            this.entriesData()
        })
        this.counter = this.counter - 1
    }

    optimizationSearch = (value) => {
        setTimeout(() => {
            this.counter = 0
            if (value.length >= 3) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.trackerList()
                })
            } else if (value.length === 0) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.trackerList()
                })
            }
        }, 1000);
    }

    entriesData = () => {
        const { nextCount, totalCount, chunckValue } = this.state
        if (nextCount != totalCount) {
            var start = nextCount - chunckValue + 1
            var end = nextCount
            var entries_text = start + '-' + end
            this.setState({
                totalEntries: entries_text
            })
        } else {
            if (nextCount <= 10) {
                if(nextCount == 0){ 
                    this.setState({
                      totalEntries : "0"
                    })
                  }else{
                    var entries_text = '1'+'-'+totalCount
                    this.setState({
                      totalEntries : entries_text
                    })
                  }
            } else {
                var module = nextCount % chunckValue
                var start
                var end
                var entries_text
                if (module > 0) {
                    start = nextCount - module + 1
                    end = nextCount
                    entries_text = start + '-' + end
                } else {
                    start = nextCount - chunckValue
                    end = nextCount
                    entries_text = start + '-' + end
                }
                this.setState({
                    totalEntries: entries_text
                })
            }
        }
    }

    // getFilterList = () => {
    //     console.log('get fileter list')
    // }

    cancelModal = () => {        
        this.setState({
            addVehiclRegNumber : '',
            addVehicleName : '',
            selectVehicleId : '',
            mapSerial_number : '',
            validityDate : '',   
            showValidDateCal: false,
            isValidChecked: true,         
        },() => {                                        
            $('#map_vehicle_details').fadeOut()   
            $('#is_temporary_check').prop('checked',true)                                         
            $('#select_vehicle_input').val("")
            $('#registration_number_input').val("")            
        })
    }

    showValidDate = (e) => {
        if (!e.currentTarget.checked) {
            this.setState({
                showValidDateCal: true,
                isValidChecked: false,
            })
        } else {
            this.setState({
                showValidDateCal: false,
                validityDate: '',
                isValidChecked: true,
            })
        }
    }

    modalValidDate = date => {
        this.setState({ validityDate: date })
        $('#modal_date').slideUp(200)
    }

    showModalDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }

    selectVehicle = (e) => {
        var id = $(e.currentTarget).prop('id')
        var name = $(e.currentTarget).text().split("/")
        // console.log('name',name)
        var req_no = $(e.currentTarget).next().text()
        this.setState({
            selectVehicleId: id,
            addVehicleName: name[0].trim(),
            addVehiclRegNumber : req_no
        }, () => {
            $('#untrack_list').slideUp()
            $('#select_vehicle_input').val(this.state.addVehicleName)
            $('#registration_number_input').val(this.state.addVehiclRegNumber)
        })
    }

    saveVehicleDetails = () => {
        const { addVehicleName, addVehiclRegNumber, mapSerial_number, selectVehicleId, validityDate, isValidChecked } = this.state
        var regex = new RegExp("^[A-Z|a-z]{2}\\s?[0-9]{1,2}\\s?[A-Z|a-z]{0,3}\\s?[0-9]{4}$")

        if (!addVehicleName.trim()) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Enter Vehicle Name"
            })
            return
        }


        if (!regex.test(addVehiclRegNumber)) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Enter Valid Registration Number"
            })
            return
        }
        if (isValidChecked) {
            var data = { id: selectVehicleId, expiry_date: '', name: addVehicleName, registration_number: addVehiclRegNumber, serial_number: mapSerial_number }
        } else {
            if (!validityDate) {
                this.setState({
                    openAlert: true,
                    errorMessage: "Kindly Select Validity Date"
                })
                return
            }
            var data = { id: selectVehicleId, expiry_date: CommonFunction.ServerDateTime(validityDate), name: addVehicleName, registration_number: addVehiclRegNumber, serial_number: mapSerial_number }
        }
        ApiUrlCall.mapTrackerToVehicle(JSON.stringify(data)).then(response => {
             //console.log('reponse map tracker', response)
            if(response.data.response_code == 200 && response.data.is_success){
                this.setState({
                    addVehiclRegNumber : '',
                    addVehicleName : '',
                    selectVehicleId : '',
                    mapSerial_number : '',
                    validityDate : '',
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    showValidDateCal: false,
                    isValidChecked: true,
                },() => {                                        
                    $('#map_vehicle_details').fadeOut()   
                    $('#is_temporary_check').prop('checked',true)                                         
                    $('#select_vehicle_input').val("")
                    $('#registration_number_input').val("")
                    this.counter = 0
                    this.trackerList()
                })
            }else{
                this.setState({
                    openAlert : true,
                    errorMessage : response.data.response_message
                })
            }
        }).catch(error => {
            console.log('error while mapping tracker', error)
        })
    }

    getFilterList = () => {
        this.setState({
            datatable: [],
            dataCollection: [],
            totalCount: '',
            chunckValue: '',
            nextCount: 0,
        },() => {
            this.counter = 0
            this.trackerList()
        })
    }

    render() {
        const { t } = this.props
        const { untrackData } = this.state
        if (untrackData.length > 0) {
            var list = untrackData.map(data => {
                return (
                <div>
                    <p id={data.id} onClick={this.selectVehicle} style={{cursor : "pointer"}}>{data.name}&nbsp;/&nbsp;{data.reg_number}</p>
                    <span style={{display : "none"}}>{data.reg_number}</span>
                </div>
                )
            })
        }
        return (
            <div className="vehicleRoute">
                <div className="grid-container-vehicle" style={{ width: "fit-content" }}>
                    <div className="grid-item-vehicle">
                        <p className="p_header" style={{ textAlign: "left" }}>Mapping Date</p>
                        <div className="input_div" onClick={this.showStartDate}>
                            <input type="text" placeholder="Select Date" id="start_date" disabled value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>
                            <img src="images/fill-111.png" className="vd_journey_icon"></img>
                        </div>
                        <div className="calendar_parent" id="start_date_cal">
                            <Calendar
                                selectRange={false}
                                onChange={this.StartdateClicked}
                                value={this.state.startDate}
                            />
                        </div>
                    </div>
                    {/* <div className="grid-item-vehicle" style={{ paddingLeft: "20px" }}>
                        <p className="p_header" style={{ textAlign: "left" }}>Tracker Status</p>
                        <select className="status-filter" style={{ position: "relative" }}
                            onChange={(e) => {
                                this.setState({
                                    selectFilter: e.currentTarget.value
                                })
                            }}>
                            <option value="1">All</option>
                            <option value="2">Mapped</option>
                            <option value="3">Unmapped</option>
                        </select>
                    </div> */}
                    <div className="grid-item-vehicle">
                        <p>&nbsp;</p>
                        <button className="btn orange-btn" onClick={this.getFilterList}>Go</button>
                    </div>
                </div>
                <div>
                    <MDBDataTable
                        displayEntries={false}
                        paging={false}
                        scrollX
                        entries={10}
                        displayEntries={false}
                        striped
                        bordered
                        data={{ columns: data1, rows: this.state.datatable }}
                        onSearch={this.optimizationSearch}
                    />
                    <Grid container style={{ margin: "20px 0px" }}>
                        <Grid item xs>
                            <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                            <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                            <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                        </Grid>
                    </Grid>
                </div>

                <div id="map_vehicle_details" className="p_modal_parent" style={{ display: "none", right: "0px" }}>
                    <div className="p_modal_boday">
                        <p className="p_header">Add Vehicle Details</p>
                        <hr />
                        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                            <Grid item xs>
                                <p className="cj_w_header">Vehicle Name&nbsp;*</p>
                            </Grid>
                            <Grid item xs style={{ position: "relative" }}>
                                <input type="text" placeholder="Enter Vehicle Name" className="journey_input" id="select_vehicle_input" defaultValue={this.state.addVehicleName}
                                    onChange={(e) => {                                        
                                        this.setState({
                                            addVehicleName: e.currentTarget.value
                                        })
                                    }} onFocus={() => {
                                        $('#untrack_list').slideDown()
                                    }} onBlur={() => {
                                        $('#untrack_list').slideUp()
                                    }} onKeyDown={() => {
                                        this.setState({
                                            selectVehicleId : ""
                                        })
                                    }}></input>
                                <div className="untrack_list" id="untrack_list">
                                    {list}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                            <Grid item xs>
                                <p className="cj_w_header">Vehicle Registration Number&nbsp;*</p>
                            </Grid>
                            <Grid item xs>
                                <input type="text" placeholder="Enter Registration Number" className="journey_input" defaultValue={this.state.addVehiclRegNumber}
                                    id="registration_number_input"
                                    onChange={(e) => {
                                        this.setState({
                                            addVehiclRegNumber: e.currentTarget.value
                                        })
                                    }}></input>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                            <Grid item xs>
                                <p className="cj_w_header">Mapped Tracker</p>
                            </Grid>
                            <Grid item xs>
                                <input type="text" placeholder="Mapped Tracker" className="journey_input" value={this.state.mapSerial_number} disabled></input>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                            <Grid item xs>
                                <p className="cj_w_header">Map Forever</p>
                            </Grid>
                            <Grid item xs>
                                <input type="checkbox" placeholder="Map Forever" id="is_temporary_check"
                                    defaultChecked="true" onChange={this.showValidDate}></input>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginBottom: "10px", display: this.state.showValidDateCal ? "" : "none" }}>
                            <Grid item xs>
                                <p className="cj_w_header">Vehicle details Valid&nbsp;*</p>
                            </Grid>
                            <Grid item xs style={{ position: "relative" }}>
                                <div className="input_div" onClick={this.showModalDate}>
                                    <input type="text" placeholder="Select Date" id="modal_date_input" disabled
                                        value={CommonFunction.dateFormate(this.state.validityDate)} className="journey_input"></input>
                                    <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                </div>
                                <div className="calendar_parent" id="modal_date" style={{ width: "100%" }}>
                                    <Calendar
                                        selectRange={false}
                                        onChange={this.modalValidDate}
                                        value={this.state.validityDate}
                                        minDate={new Date()}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={4}>
                            <Grid item xs>
                                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.saveVehicleDetails}>Save</button>
                            </Grid>
                            <Grid item xs>
                                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.cancelModal}>Cancel</button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1200}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(TrackerManagement);
