import React, { Component } from 'react';
import $ from 'jquery';
import './drivers.scss'
import Table from 'react-bootstrap/Table';
import Navbar from '../../Config/Navbar';
import Grid from '@material-ui/core/Grid';
import {   Link,BrowserRouter as Router } from 'react-router-dom'
import ApiUrlCall from '../API/apiurl'
import CommonFunction from '../common_function'
import Tooltip from '@material-ui/core/Tooltip';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

const data1 = [
  {
    label: 'DRIVER NAME',
    field: 'driver_name',
    sort: 'asc',
    width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'LAST JOURNEY START',
    field: 'start_location',
    sort: 'asc',
    width: 300,
  },
  {
    label: 'LAST JOURNEY END',
    field: 'end_location',
    sort: 'asc',
    width: 300,
  },
  {
    label: 'CONTACT',
    field: 'mobile_no',
    sort: 'asc',
    width: 150,
  },  
  {
    label: 'STATUS',
    field: 'status',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'ACTION',
    field: 'action',
    sort: 'asc',
    width: 150,
  }  
]  


class DriverLists extends Component {
  counter = 0
  constructor(props){
    super(props);
    this.state = {
      driverdata : [],
      datatable : [],          
      nextCount : 0, 
      totalCount : '', 
      buttonFlag : true, 
      dataCollection : [], 
      chunckValue : '',
      searchText : '',
      totalEntries : ''
    }
    this.driverDetailsData = this.DriverDetailsGet.bind(this)    
  }

  componentDidMount(){
    analytics.logEvent('page_view',{
      "page_title" : "DriverList"
    })
    analytics.logEvent('screen_view',{
      screen_name : "DriverList",      
    })
    analytics.logEvent('page_view',{
            "page_title" : "DriverDetail"
          })
      Amplify.configure(awswebapp);
      Analytics.record({
        name: constant.awsName, 
        // Attribute values must be strings
        attributes: { screen_name: 'WEB_DRIVER_LIST'}
      });  
      // var navBarHeight = $('.navBar').outerHeight();                  
      // $('.driver_list_parent').css({
      //   paddingTop : navBarHeight+60,
      //   paddingLeft : "100px",
      //   paddingRight : "100px"
      // })  
      this.driverDetailsData();         
  } 

  buttonStatus = () => {
    const {nextCount, totalCount} = this.state
    // console.log(nextCount,'.................',totalCount)
    if(nextCount === totalCount){
      // console.log('iffffffffff')      
      if(this.counter === 0){
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }else{
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    }else{
      // console.log('elseeeeeeee', this.counter)
      if(this.counter === 0){
        // console.log('else 1111111111')
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')        
        $('#dt-next').addClass('enabled-btn')
      }else{
        // console.log('else 22222222')
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')        
      }
    }    
  }

  entriesData = () => {
    const {nextCount, totalCount, chunckValue} = this.state
    if(nextCount != totalCount){
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start+'-'+end
      this.setState({
        totalEntries : entries_text
      })
    }else{
      if(nextCount <= 10){
        if(nextCount == 0){ 
          this.setState({
            totalEntries : "0"
          })
        }else{
          var entries_text = '1'+'-'+totalCount
          this.setState({
            totalEntries : entries_text
          })
        }
      }else{
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if(module > 0){
          start = nextCount - module + 1
          end = nextCount
          entries_text = start+'-'+end
        }else{
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start+'-'+end
        }
        this.setState({
          totalEntries : entries_text
        })
      }
    }
  }

  
  createTableData = () => {
    const {driverdata,datatable} = this.state        
    // console.log('lenght is', driverdata.length)
    for (let i = 0; i < driverdata.length; i++) {
      const element = driverdata[i];
      var row_data = {driver_name : element.driver_name, start_location : element.last_journey_start_loc,
      end_location : element.last_journey_end_loc, mobile_no : element.mobile_no, status : this.VehicleStatus(element.status),
      action : <p className="cj_w_header" style={{color:'#f4811f', cursor : "pointer"}} onClick={() => this.uniqueDriverId(element.id)}>Driver Details</p>} 
      datatable.push(row_data)                 
    }    
    this.buttonStatus() 
    this.entriesData()   
    this.forceUpdate()
    // this.intervalData()
  }

  emptyTable = () => {
    this.setState({
      datatable : []
    })
  }

  DriverDetailsGet(){
    $('#processing').show()
    var data = {next_count : this.state.nextCount , search : this.state.searchText , start_time:'',end_time:''} 
    ApiUrlCall.getDriverDetails(JSON.stringify(data)).then(response => {
        // console.log(response, '""""""""""""""""""""""""""""');
        this.emptyTable()
        if(response.data.response_code === 200 && response.data.is_success){
          this.setState({
            driverdata : response.data.data.driver_list,
            nextCount : response.data.data.next_count,
            totalCount : response.data.data.total_count,
            chunckValue : response.data.data.size          
          },() => {
            this.createTableData()
          })
          $('#processing').hide()  
        }else{
          $('#processing').hide() 
        }
        // console.log(this.state.driverdata, "?????????????")
    }).catch(err => {
        $('#processing').hide() 
        console.log(err,'error while fetching driver details')
    })
    
  
} 

pageNextClick = () => {
  const {totalCount,nextCount,dataCollection,datatable} = this.state
  // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
  this.counter = this.counter + 1  
  for (let i = 0; i < datatable.length; i++) {
    const element = datatable[i];
    dataCollection.push(element)
  }      
  this.setState({
    datatable : []
  },() => {
    this.DriverDetailsGet()
  })
}

pagePreviousClick = () => {
  const {dataCollection, chunckValue, nextCount} = this.state
  var pageSize = nextCount % chunckValue
  if(pageSize === 0){
    pageSize = chunckValue
  }
  // console.log('previous clicked', pageSize)    
  var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
  this.setState({
    datatable : last,
    nextCount : nextCount - pageSize
  },() => {      
    this.buttonStatus()  
    this.entriesData()    
  })
  this.counter = this.counter -1
}

optimizationSearch = (value) => {  
  setTimeout(() => {
    this.counter = 0
    if(value.length >= 3){
      this.setState({
        datatable : [],
        dataCollection : [],
        totalCount : '',
        chunckValue : '',
        nextCount : 0,
        searchText : value
      },() => {
        this.DriverDetailsGet()          
      })
    }else if(value.length === 0){
      this.setState({
        datatable : [],
        dataCollection : [],
        totalCount : '',
        chunckValue : '',
        nextCount : 0,
        searchText : value
      },() => {
        this.DriverDetailsGet()          
      })
    }      
  }, 1000);     
}

uniqueDriverId = (id) => {
  // console.log('driver id', id)  
  this.props.data.history.push({
    pathname : '/driver_details',
    state : {driver_id : id}
  })
  
} 

VehicleStatus = (status) => {
  const AVAILABLE = 1;
  const ASSIGNED = 2;
  const {t} = this.props
  if(status === AVAILABLE){
    return(
      <p className="cj_w_header" style={{color : "#28a745"}}>{t('ct.available')}</p>
    )
  }else if(status === ASSIGNED){
    return(
      <p className="cj_w_header" style={{color : "#ed7d28"}}>{t('ct.assigned')}</p>
    )
  }
}

  render(){ 
    const {t} = this.props         
    return (            
      <div className="driver_list_parent table_margin_top">        
        {/* <Grid container style={{marginBottom:"2em"}}>
        <Grid item xs className="tableHeading">
          <label>{t('dls.driver')}</label>
        </Grid>  
        <Grid item xs>
          
          </Grid>  
        </Grid>         */}
         {/*list view driver*/}
        {/* <div className="tableOuterDiv" id="driver_list_table" style={{marginBottom:"50px"}}>
          <div className="cardView tableParent">
            <Table responsive>
              <thead className="tableHeader">
                <tr>                                  
                  <th>{t('ct.table.h6')}</th>                                    
                  <th>{t('ct.table.h7')}</th>
                  <th>{t('ct.table.h8')}</th>                              
                  <th>{t('ct.table.h5')}</th>
                  <th>{t('ct.table.h9')}</th>
                </tr>
              </thead>
              <tbody className="tableBody">                
               {driverlist}
              </tbody>
            </Table>
          </div>
        </div> */}
        <div>                      
            
        <MDBDataTable
            displayEntries={false}
            paging={false}            
            scrollX
            entries={10}            
            displayEntries={false}
            striped
            bordered
            data={{columns : data1 , rows : this.state.datatable}}            
            onSearch = {this.optimizationSearch}
          />
          <Grid container style={{margin : "20px 0px"}}>
              <Grid item xs>
                <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
              </Grid>
              <Grid item xs style={{textAlign : "right"}}>
                <button className="btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                <button className="btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
              </Grid>
          </Grid>
        </div>
        {/* driver list view end */}                  
      </div>      
    );
  }
}

export default withTranslation()(DriverLists);
