import axios from 'axios';
import decode from 'jwt-decode';
import host from '../ipaddress'


// axios.defaults.baseURL = 'http://159.65.152.211:8002';



class AuthService {
       constructor(){
       
       }
     login(credentials){
      
        const headers = {
            'Content-Type': 'application/json',
            'timezone': 'test-timezone',
            'Authorization': 'JWT fefege...',
          }
         return axios.post(host+"v3/accounts/login_web_user/" , credentials, {
            headers: headers
          });
        
        //return axios.post(USER_API_BASE_URL + "generate-token", credentials);
        //console.log(credentials)
    }
    generateOtp(credentials){
        return axios.post(host+"v3/accounts/generate_login_web_otp/" , credentials);
       
       //return axios.post(USER_API_BASE_URL + "generate-token", credentials);
       // console.log(credentials)
   }

    
    isTokenExpired(token) {
        // console.log(token)
        try {
            const decoded = decode(token);
            // console.log(decode)
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }
    setToken(idToken) {
        // console.log(idToken)
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }
    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    getUserInfo(){
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
       return {headers: {Authorization: 'Bearer ' + this.getUserInfo().token }};
    }
    fetch(url, options) {
        // console.log(url)
        // console.log(options)
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    logOut() {
        localStorage.removeItem("userInfo");
        return axios.post(host+"v3/accounts/verify_otp_creds/" + 'logout', {}, this.getAuthHeader());
    }
}

export default new AuthService();
