import React, { useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom'
//import { useSelector } from "react-redux";
import jwtDecode from 'jwt-decode';


const authentication = {
  isAuthenticated : false,
  // token : localStorage.getItem('token'),
  loggedin(){    
    let token = localStorage.getItem('token')
    // console.log(token , "GOTTE TOKEN IN LOGGED ")
    if(token){
      // console.log('token')
      this.isAuthenticated = true;
    }else{
      // console.log('no token')
      this.isAuthenticated = false;
    }
    // setTimeout(cb,100)
  }
}



const PrivateRoute = ({ component: Component, ...rest }) => {  
  // console.log('in private route', )
  authentication.loggedin()
  //const auth = useSelector(state => state.auth)   
  // let auth =   localStorage.getItem('token')   
  // console.log('private routers are called',auth)
  // const [isAuthenticated, setIsAuthenticated] = useState(null) 
  // console.log('props value is', ...rest)
  // useEffect(() => {
  //     let token = localStorage.getItem('token')
  //         if(token){
  //           setIsAuthenticated(true)
  //           console.log('111111111111111111',token)
  //             // let tokenExpiration = jwtDecode(token).timestamp;
  //             // let dateNow = new Date();
  //             //    console.log(tokenExpiration < dateNow.getTime() / 1000,'what is the confition state')
  //             // if(tokenExpiration < dateNow.getTime()/1000){
  //             //     setIsAuthenticated(false)
  //             //     console.log('000000000000000')
  //             // }else{
  //             //     setIsAuthenticated(true)
  //             //     console.log('111111111111111111')
  //             // }
  //         } else {
  //           setIsAuthenticated(false)
  //           console.log('2222222222222222',token)
  //        }
  //    // eslint-disable-next-line
  //  }, [auth]) 

    // if(isAuthenticated === null){
    //   console.log('in private route')
    //   return <></>   }

    return (
      <Route {...rest} render={props =>
        !authentication.isAuthenticated ? (
          <Redirect to={{
            pathname : '/login',
            state : {from : props.location}
          }} />
        ) : (
          <Component {...props} />
        )
      }
      />
    );
  ;
}
  

export default PrivateRoute;