import React, { Component } from 'react';
import $ from 'jquery';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ApiUrlCall from '../API/apiurl';
import '../reports/reports.scss'
import Vehicle_report from './vehicle_report'
import Summery_report from './Summery_report.js';
import Driver_report from '../reports/Driver_report';
import Journey_report from '../reports/Journey_reports';
import Customized_report from '../reports/Customized_report';
import Location_new from '../reports/Location_new';
import FuelReport from '../reports/fuel_report';
import StopReport from '../reports/stop_report'
import TrackerManagementReport from '../reports/tracker_management_report'
import Trips_report from '../reports/trips_report'
import Location_report from '../reports/location_report'
import Travel_report from '../reports/travel_detail'
import Stoppage_report from '../reports/stoppage_report'
import DieselGenerator_report from '../reports/dg_report'
import DieselGenerator_og_report from '../reports/dg_report_og'
import Idling_detail_report from '../reports/idling_detail'
import Inactive_Summary_Detail from '../reports/inactive_summary'
import Navbar from '../../Config/Navbar';
import { withTranslation } from 'react-i18next';
import IMD_reports from './IMD_reports.js';
import Master_dashboard_mumbai from './Master_dashboard_mumbai.js';
import Temprature from './temprature.js';
import Trip_MPindus from './trip_MPindus.js';
import Mp_stoppege from './mp_stoppege.js';
import Mp_idling_reports from './mp_idling_reports.js';

class Reports extends Component {
  // _exporter;
  constructor(props) {
    super(props);
    this.state = {      
      
    }
  }

  // export = () => {
  //   this._exporter.save();
  // }
  componentDidMount() {
    setTimeout(() => {
      var navBarHeight = $('.navBar').outerHeight();
      console.log("nav bar height", navBarHeight)
      $('.report_parent').css({
        paddingTop: navBarHeight + 60,
        paddingLeft: "100px",
        paddingRight: "100px"
      })          
    }, 500);


  //  console.log("Group value from localStorage:", localStorage.getItem('group'));
    // console.log("setup function is calling")
  }
  // download = event => {
  //   console.log(event)
  //   this.doc.save('sample.pdf')
  //   console.log(this.doc)
  // } 
  // uniqueVehicleId = (serial_number) => {    
  //   this.props.history.push({
  //     pathname : '/vehicle_details',
  //     state : {serial_number : serial_number}
  //   })
    
  // }
  render() { 
    const {t} = this.props  
    const user_id = localStorage.getItem('user_id')
    
    return (
      <div>
      <Navbar/>
      <div className="report_parent">

        <div style={{ textAlign: "right",marginBottom : "30px"}}>
          <button className="btn orange-btn" onClick={() => {
            this.props.history.push('/sheduler_report')
                      }}>Scheduler Report</button>     
        </div>
         
        <Tabs  style={{ display: localStorage.getItem('group') == 32 || localStorage.getItem('group') == 33 ||  localStorage.getItem('group') == 34 ? "none" : "" }}>
          <TabList style={{ textAlign: 'left' }}>
            <Tab>{t('ars.header1')}</Tab>
            
            {/* <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.header1')}</Tab> */}
            {/* <Tab>{t('ars.sum.v2')}</Tab>        */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.sum.v2')}</Tab>
            {/* <Tab>{t('ars.dr.driver')}</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.dr.driver')}</Tab>
            <Tab style={{display : localStorage.getItem('group') == 31 ? "" : "none" }}> Fuel</Tab>
            <Tab>{t('ds.journey')}</Tab>
            {/* <Tab style={{display : localStorage.getItem('has_fuel_sensor') == "true" ? "" : "none"}}>{t('ars.header3')}</Tab>
            <Tab>{t('ars.header4')}</Tab> */}
            {/* <Tab>Stop Report</Tab> */}
            <Tab style={{display : parseInt(user_id) === 134243 ? "" : "none"}}>{t('ars.header2')}</Tab>
            <Tab>Trips</Tab>
            {/* <Tab>Location</Tab> */}
            {/* <Tab>Special Report</Tab> */}
            {/* <Tab>Travel</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>Travel</Tab>        
            <Tab>Stoppage</Tab>
            <Tab>Idling</Tab>
            {/* <Tab>Inactive</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>Inactive</Tab>     
            <Tab style={{display : localStorage.getItem('has_fuel_sensor') == "true" ? "" : "none"}}>{t('ars.header3')}</Tab>
            {/* <Tab>{t('ars.header4')}</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.header4')}</Tab>
            {/* <Tab>Location</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>Location</Tab>     
            <Tab style={{display : localStorage.getItem('has_diesel_generator') == "true" ? "" : "none"}}>DG Consumption</Tab>
            <Tab style={{display : localStorage.getItem('has_diesel_generator_og') == "true"  && localStorage.getItem('group') == 30 ? "" : "none" }}>DG Consumption</Tab>
            {/* <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}> Temprature </Tab>      */}
          </TabList>
          <TabPanel>
           <Summery_report/>
          </TabPanel>
          <TabPanel>
            <Vehicle_report />
          </TabPanel>
          <TabPanel>
          <Driver_report/>
          </TabPanel>
          <TabPanel>
            <FuelReport />
          </TabPanel>
          <TabPanel>
            <Journey_report/>
          </TabPanel>
          {/* <TabPanel>
            <FuelReport />
          </TabPanel>
          <TabPanel>
            <TrackerManagementReport />
          </TabPanel> */}
          {/* <TabPanel>
            <StopReport />
          </TabPanel> */}
          <TabPanel>
           <Customized_report/>
          </TabPanel>
          <TabPanel>
            <Trips_report/>
          </TabPanel>
          {/* <TabPanel>
            <Location_report/>
          </TabPanel> */}
          <TabPanel>
            <Travel_report/>
          </TabPanel>
          <TabPanel>
            <Stoppage_report/>
          </TabPanel>
          <TabPanel>
            <Idling_detail_report/>
          </TabPanel>
          <TabPanel>
            <Inactive_Summary_Detail/>
          </TabPanel>
          <TabPanel>
            <FuelReport />
          </TabPanel>
          <TabPanel>
            <TrackerManagementReport />
          </TabPanel>
          <TabPanel>
            <Location_new />
          </TabPanel>
          <TabPanel>
            <DieselGenerator_report/>
          </TabPanel>
          <TabPanel>
            <DieselGenerator_og_report/>
          </TabPanel>

         {/* <TabPanel>
        <Temprature></Temprature>
         </TabPanel> */}
        </Tabs>

     <Tabs  style={{display : localStorage.getItem('group') == 32 ? "" : "none"}}>
        
      <TabList style={{ textAlign: 'left' }}>
          
          <Tab> Master Dashboard</Tab>
          
      </TabList>


            <TabPanel>  
          <IMD_reports> </IMD_reports>
            </TabPanel>

    </Tabs>
      
        <Tabs  style={{display : localStorage.getItem('group') == 33 ? "" : "none"}}>
        
         <TabList style={{ textAlign: 'left' }}>
            
           <Tab> Master Dashboard</Tab>
            <Tab> Location</Tab>
            
         </TabList>

          <TabPanel>
            <Master_dashboard_mumbai></Master_dashboard_mumbai>
          </TabPanel>
     {/* Master_dashboard_mumbai */}
          <TabPanel>
            <Location_new />
          </TabPanel>
          </Tabs>
      

          <Tabs style={{display : localStorage.getItem('group') == 34 ? "" : "none"}}>
          <TabList style={{ textAlign: 'left' }}>
            <Tab>{t('ars.header1')}</Tab>
            {/* <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.header1')}</Tab> */}
            {/* <Tab>{t('ars.sum.v2')}</Tab>        */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.sum.v2')}</Tab>
            {/* <Tab>{t('ars.dr.driver')}</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.dr.driver')}</Tab>
            <Tab style={{display : localStorage.getItem('group') == 31 ? "" : "none" }}> Fuel</Tab>
            <Tab>{t('ds.journey')}</Tab>
            <Tab style={{display : parseInt(user_id) === 134243 ? "" : "none"}}>{t('ars.header2')}</Tab>
            <Tab>MP indus Trips</Tab>
            
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>Travel</Tab>        
            <Tab> MP indus Stoppage</Tab>
            <Tab>MP indus Idling </Tab>
            {/* <Tab>Inactive</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>Inactive</Tab>     
            <Tab style={{display : localStorage.getItem('has_fuel_sensor') == "true" ? "" : "none"}}>{t('ars.header3')}</Tab>
            {/* <Tab>{t('ars.header4')}</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('ars.header4')}</Tab>
            {/* <Tab>Location</Tab> */}
            <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>Location</Tab>     
            <Tab style={{display : localStorage.getItem('has_diesel_generator') == "true" ? "" : "none"}}>DG Consumption</Tab>
            <Tab style={{display : localStorage.getItem('has_diesel_generator_og') == "true"  && localStorage.getItem('group') == 30 ? "" : "none" }}>DG Consumption</Tab>
          </TabList>
          <TabPanel>
           <Summery_report/>
          </TabPanel>
          <TabPanel>
            <Vehicle_report />
          </TabPanel>
          <TabPanel>
          <Driver_report/>
          </TabPanel>
          <TabPanel>
            <FuelReport />
          </TabPanel>
          <TabPanel>
            <Journey_report/>
          </TabPanel>
          {/* <TabPanel>
            <FuelReport />
          </TabPanel>
          <TabPanel>
            <TrackerManagementReport />
          </TabPanel> */}
          {/* <TabPanel>
            <StopReport />
          </TabPanel> */}
          <TabPanel>
           <Customized_report/>
          </TabPanel>
          <TabPanel>
          <Trip_MPindus></Trip_MPindus>
          </TabPanel>
          {/* <TabPanel>
            <Location_report/>
          </TabPanel> */}
          <TabPanel>
            <Travel_report/>
          </TabPanel>
          <TabPanel>
         <Mp_stoppege></Mp_stoppege>
          </TabPanel>
          <TabPanel>
            <Mp_idling_reports></Mp_idling_reports>
          </TabPanel>
          <TabPanel>
            <Inactive_Summary_Detail/>
          </TabPanel>
          <TabPanel>
            <FuelReport />
          </TabPanel>
          <TabPanel>
            <TrackerManagementReport />
          </TabPanel>
          <TabPanel>
            <Location_new />
          </TabPanel>
          <TabPanel>
            <DieselGenerator_report/>
          </TabPanel>
          <TabPanel>
            <DieselGenerator_og_report/>
          </TabPanel>

         {/* <TabPanel>
        <Temprature></Temprature>
         </TabPanel> */}
        </Tabs>

       
      </div>
 </div>


    );
  }
}

export default withTranslation()(Reports);
