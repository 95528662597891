import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import Calendar from 'react-calendar/dist/entry.nostyle'
import CommonFunction from '../common_function';
import { TablePagination } from 'react-pagination-table';
import ApiUrlCall from '../API/apiurl';
import './custom_report.scss';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';



const SUMMARY  = "summary";
const OVERSPEED = "overspeed";
const TRIP = "trip";
const NIGHTMODE = "nightmode";
const ALLZONE = "all_zone"
const ALLAREA = "all_area"
const ALLSTATE = "all_state"
const ALLVEHICLE = "all_vehicle"


class Customized_report extends Component {
  _exporter_summary
  _exporter_overspeed
  _exporter_trip
  _exporter_nightmode
  _exporter_modal_trip
  _exporter_modal_night1
  _exporter_modal_night2
  constructor(props){
    super(props);
    this.state = {
      date: new Date(),
      startDate: new Date().setHours(0,0,0,0),
      endDate: new Date().setHours(23,59,59,0 ),
      selectedReport : 'summary',
      vehicleData : '',
      dropdownData : '',
      zoneList : [],
      areaList : [],
      stateList : [],
      vehicleList : [],
      openAlert : false,
      errorMessage : '',
      reportColumnName : '',
      reportheader : '',
      reportdata : '',
      noData : false,
      stateContent : '',
      areaContent : '',
      vehicleContent : '',
      tripData : '',
      nightTrip : '',
      modalreportColumnName : '',
      modalreportheader : '',
      modalreportdata : '',
      modalHeaderText : '',
      modalexcelshow : 0,
      sortText : 'Ascending'
    }
  }

  componentDidMount(){  
      analytics.logEvent('screen_view',{
        screen_name : "MahindraReport"
      })
      analytics.logEvent('page_view',{
        page_title : "MahindraReport",        
      })
      Amplify.configure(awswebapp);
      Analytics.record({
        name: constant.awsName, 
        // Attribute values must be strings
        attributes: { screen_name: 'WEB_MAHINDRA_REPORT'}
      });             
      this.getCategoryList()                  
      // this.getVehicleList()
  }

  sortingTableColumn = () => {
    var headers = $('.table-header').find('th')
    console.log('headers are', $(headers))
    $(headers).click((e) => {      
      var n = $(e.currentTarget).index()
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementsByClassName("table");
      switching = true;
      console.log('table is', table)
      //Set the sorting direction to ascending:
      dir = "asc"; 
      /*Make a loop that will continue until
      no switching has been done:*/
      while (switching) {
        //start by saying: no switching is done:
        switching = false;
        rows = table[0].rows;        
        /*Loop through all table rows (except the
        first, which contains table headers):*/
        for (i = 1; i < (rows.length - 1); i++) {
          //start by saying there should be no switching:
          shouldSwitch = false;
          /*Get the two elements you want to compare,
          one from current row and one from the next:*/
          x = rows[i].getElementsByTagName("TD")[n];
          y = rows[i + 1].getElementsByTagName("TD")[n];
          /*check if the two rows should switch place,
          based on the direction, asc or desc:*/
          if (dir == "asc") {
            if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
              //if so, mark as a switch and break the loop:
              shouldSwitch= true;              
              $(table).parent().parent().find('#sorting_text').html('')
              $(table).parent().parent().find('#sorting_text').append("Ascending")
              break;
            }
          } else if (dir == "desc") {
            if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
              //if so, mark as a switch and break the loop:
              shouldSwitch = true;              
              $(table).parent().parent().find('#sorting_text').html('')
              $(table).parent().parent().find('#sorting_text').append("Descending")
              break;
            }
          }
        }
        if (shouldSwitch) {
          /*If a switch has been marked, make the switch
          and mark that a switch has been done:*/
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          //Each time a switch is done, increase this count by 1:
          switchcount ++;      
        } else {
          /*If no switching has been done AND the direction is "asc",
          set the direction to "desc" and run the while loop again.*/
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }
    })
  }

  getCategoryList = () => {
    ApiUrlCall.getCategoryData().then(response => {
      // console.log('category list response', response)
      if(response.data.response_code === 200 && response.data.is_success === true){        
        this.setState({
          dropdownData : response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching category data.')
    })
  }


  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('.all_region_dd').hide()
    $('#end_date_cal').hide()
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('.all_region_dd').hide()
    $('#start_date_cal').hide()
  }

  StartdateClicked = date => {
    var isoStartDate = date
    isoStartDate.setHours(0,0,0,0)  
    // console.log('props value', CommonFunction.getTime(date))

    this.setState({ startDate: isoStartDate })
    $('#start_date_cal').slideUp(200)
  }

  EnddateClicked = date => {
    var {startDate} = this.state
    var isoStartDate = date
    isoStartDate.setHours(23,59,59,0)   
    // var Difference_In_Time = isoStartDate.getTime() - this.state.startDate.getTime();
    // var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    // if(Difference_In_Days > 7){ 
    //   $('#processing').hide()     
    //   this.setState({
    //     sevenDayFlag : false,   
    //     openAlert : true,
    //     errorMessage: "Not allowed to select more than 7 days"     
    //   })     
    //   return 
    // } 
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ endDate: isoStartDate })
    $('#end_date_cal').slideUp(200)
  }

  dropDownClick = (e) => {
    // $('#vehicles_select').slideDown('fast');
    var dropdownwidth = e.currentTarget.offsetWidth
    var checklist = e.currentTarget.nextSibling
    // console.log('drop down', $(e.currentTarget))
    $(e.currentTarget).parent().siblings().find('.all_region_dd').hide()
    $('#start_date_cal').hide()
    $('#end_date_cal').hide()
    $(checklist).css({
      width: dropdownwidth
    })
    $(checklist).slideToggle(300);

  }
  radioButton = (e) => {
    var radio_id = $(e.currentTarget).prop('id')
    var siblingRadio = $(e.currentTarget).parents()[2]
    var sibInput = $(siblingRadio).siblings().find('input')
    $(e.currentTarget).prop("checked", true)
    $(sibInput).prop("checked", false)
    this.setState({
      selectedReport : radio_id,
      reportColumnName : '',
      reportheader : '',
      reportdata : '',
    },() => {
      // console.log('selected report', this.state.selectedReport)
    })
  }
  
  uncheckArea = (check) => {
    if(check === 1){
      $('#vehicles_select').find('input').prop('checked', true)
    }else{
      $('#vehicles_select').find('input').prop('checked', false)
    }
  }

  uncheckState = (check) => {
    if(check === 1){
      $('#area_select').find('input').prop('checked', true)
    }else{
      $('#area_select').find('input').prop('checked', false)
    }    
  }

  uncheckZone = (check) => {
    if(check === 1){
      $('#state_select').find('input').prop('checked', true)
    }else{
      $('#state_select').find('input').prop('checked', false)
    }    
  }
  uncheckVehicle = (check) => {
    if(check === 1){
      $('#zone_select').find('input').prop('checked', true)
    }else{
      $('#zone_select').find('input').prop('checked', false)
    }    
  }

  removeCheckState = () => {
    this.uncheckZone(0)
    this.uncheckState(0)
    this.uncheckArea(0)
    this.setState({
      stateList : [],
      areaList : [],
      vehicleList : []
    }, () => {
      // console.log(this.state.stateList,'.......', this.state.areaList,'............', this.state.vehicleList)
    })
  }

  removeCheckArea = () => {    
    this.uncheckState(0)
    this.uncheckArea(0)
    this.setState({      
      areaList : [],
      vehicleList : []
    })
  }

  removeCheckVehicle = () => {    
    this.uncheckArea(0)
    this.setState({      
      vehicleList : []
    })
  }

  vehicleDrop = (e) => {
    var vehicle = $(e.currentTarget).prop('id')
    // var {zoneList} = this.state
    var allVehicle = $('#all_vehicle').prop('checked')    
    if(allVehicle){
      $('#all_vehicle').prop('checked', false)            
    }
    if(e.currentTarget.checked){
      this.state.vehicleList.push(vehicle)
      this.forceUpdate()
    }else{
      if(this.state.vehicleList.length > 0){
        var index = this.state.vehicleList.indexOf(vehicle)
        this.state.vehicleList.splice(index,1)
        this.forceUpdate()
      }      
    }
  }

  stateSelectList = (div) => {
    const {dropdownData, zoneList} = this.state
    var array = []
    if(zoneList.length > 0){
      for (let i = 0; i < zoneList.length; i++) {
        const element = zoneList[i];
        for (let j = 0; j < dropdownData.state_list.length; j++) {
          const element1 = dropdownData.state_list[j];
          if(parseInt(element) === element1[2]){
            array.push(element1)
          }
        }
      }
      this.setState({
        stateContent : array      
      })
    }else{
      $(div).next().find('.statedrop').addClass('disable-drop') 
      $(div).next().next().find('.areadrop').addClass('disable-drop') 
      $(div).next().next().next().find('.vehicledrop').addClass('disable-drop') 
      this.uncheckZone(0)
      this.uncheckState(0)
      this.uncheckArea(0)
      this.setState({
        stateContent : [],   
        areaContent : [],
        vehicleContent : [],
        stateList : [],
        areaList : [],
        vehicleList : [],
      })
    }
  }

  zoneDrop = (e) => {
    var zone = $(e.currentTarget).prop('id')
    var nextDrop = $(e.currentTarget).parents()[4]
    $(nextDrop).next().find('.statedrop').removeClass('disable-drop')    
    // var {zoneList} = this.state
    var allZone = $('#all_zone').prop('checked')
    if(allZone){
      $('#all_zone').prop('checked', false)               
    }
    if(e.currentTarget.checked){
      this.removeCheckState()
      this.state.zoneList.push(zone)
      this.forceUpdate()          
    }else{
      if(this.state.zoneList.length > 0){
        var index = this.state.zoneList.indexOf(zone)
        this.state.zoneList.splice(index,1)
        this.forceUpdate()
      }     
    }
    this.stateSelectList(nextDrop)    
  }

  areaSelectList = (div) => {
    const {dropdownData, stateList} = this.state
    var array = []    
    if(stateList.length > 0){      
      for (let i = 0; i < stateList.length; i++) {
        const element = stateList[i];        
        for (let j = 0; j < dropdownData.area_list.length; j++) {
          const element1 = dropdownData.area_list[j];          
          if(parseInt(element) === parseInt(element1[2])){            
            array.push(element1)
          }
        }
      }
      this.setState({
        areaContent : array      
      })
    }else{      
      $(div).next().find('.areadrop').addClass('disable-drop') 
      $(div).next().next().find('.vehicledrop').addClass('disable-drop')       
      this.uncheckState(0)
      this.uncheckArea(0)
      this.setState({        
        areaContent : [],
        vehicleContent : [],        
        areaList : [],
        vehicleList : [],
      })
    }
  }

  stateDrop = (e) => {
    var state = $(e.currentTarget).prop('id')
    var nextDrop = $(e.currentTarget).parents()[4]
    $(nextDrop).next().find('.areadrop').removeClass('disable-drop')  
    // var {zoneList} = this.state
    var allState = $('#all_state').prop('checked')    
    if(allState){
      $('#all_state').prop('checked', false)               
    }
    if(e.currentTarget.checked){      
      this.removeCheckArea()
      this.state.stateList.push(state)
      this.forceUpdate()               
    }else{
      if(this.state.stateList.length > 0){
        var index = this.state.stateList.indexOf(state)
        this.state.stateList.splice(index,1)
        this.forceUpdate()        
      }      
    }
    this.areaSelectList(nextDrop)
  }

  vehicleSelectList = (div) => {
    const {dropdownData, areaList} = this.state
    var array = []
    // console.log('area list', areaList)    
    if(areaList.length > 0){
      // console.log('1111111111')
      for (let i = 0; i < areaList.length; i++) { 
        const element = areaList[i];
        // console.log('22222222', element)
        for (let j = 0; j < dropdownData.serial_number_list.length; j++) {
          const element1 = dropdownData.serial_number_list[j];
          // console.log(element1,'33333333333', element)
          if(parseInt(element) === parseInt(element1[3])){
            // console.log('4444444444')
            array.push(element1)
          }
        }
      }
      this.setState({
        vehicleContent : array      
      }, () => {
        // console.log('vehicle content', this.state.vehicleContent)
      })
    }else{
      this.uncheckArea(0)
      $(div).next().find('.vehicledrop').addClass('disable-drop') 
      this.setState({
        vehicleContent : [],        
        vehicleList : [],     
      })
    }
  }

  areaDrop = (e) => {
    var area = $(e.currentTarget).prop('id')
    var nextDrop = $(e.currentTarget).parents()[4]
    $(nextDrop).next().find('.vehicledrop').removeClass('disable-drop') 
    // var {zoneList} = this.state
    var allArea = $('#all_area').prop('checked')    
    if(allArea){
      $('#all_area').prop('checked', false)               
    }
    if(e.currentTarget.checked){      
      this.removeCheckVehicle()
      this.state.areaList.push(area)
      this.forceUpdate()          
    }else{
      if(this.state.areaList.length > 0){
        var index = this.state.areaList.indexOf(area)
        this.state.areaList.splice(index,1)
        this.forceUpdate()
      }     
    }
    this.vehicleSelectList(nextDrop)
  }

  generateReport = () => {
    const {selectedReport, startDate, endDate} = this.state
    $('.all_region_dd').hide()
    $('#start_date_cal').hide()
    $('#end_date_cal').hide()
    var firstButton = $('.pagination-status').children()
    if(this.state.reportdata){
      console.log('1st button is')
      $(firstButton).first().next().trigger('click')
    }
    var dateValidate = CommonFunction.dateValidation(startDate, endDate)
    if(dateValidate){
      if(selectedReport === SUMMARY){
        this.getSummaryReport()
      }else if(selectedReport === OVERSPEED){
        this.getOverSpeedReport()
      }else if(selectedReport === TRIP){
        this.getDetailReport()
      }else if(selectedReport === NIGHTMODE){
        this.getNightReport()
      }      
    }else{
      this.setState({
        openAlert : true,
        errorMessage : "Start Date Greater than End Date"
      })
    }
  }

  getSummaryReport = () => {
    $('#processing').show()
    const {zoneList, areaList, stateList, vehicleList, startDate, endDate, reportdata, reportColumnName, reportheader} = this.state
    const data = {zone_list : zoneList, area_list : areaList, state_list : stateList, serial_number_list : vehicleList, 
      start_date : CommonFunction.ServerDateTime(startDate), end_date : CommonFunction.ServerDateTime(endDate)}
    const Header = ["Sr. No.", "Zone", "Area Office", "Vehicle", "Start Date","End Date", "SOD (km)", "EOD (km)", "Kms", "OSA (count)",
      "OSD (mm:ss)", "NDD (hh:mm)", "ND (km)", "DV", "NOT" ];
    const column = "Sr.Zone.Area.Vehicle.Start.End.SOD.EOD.Kms.OSA.OSD.NDD.ND.DV.NOT"      
    // console.log(CommonFunction.ServerDateTime(startDate),'report data is summary', CommonFunction.ServerDateTime(endDate))
    ApiUrlCall.customizedSummaryReport(JSON.stringify(data)).then(response => {
      if(response.data.response_code === 200 && response.data.is_success ===  true){
        // $('#processing').hide()
        if(response.data.data.result.length > 0){          
          var createdData = []
          response.data.data.result.map(data => {
            var finalData = {Sr : data[0], Zone : data[1], Area : data[2], Vehicle : data[3],
            Start : CommonFunction.tableDateFormate(data[4]), End : CommonFunction.tableDateFormate(data[5]), SOD : data[6], EOD : data[7], Kms : data[8], OSA : data[9],
            OSD : data[10], NDD : data[11], ND : data[12], DV : data[13], NOT : data[14]}
            createdData.push(finalData)
          })
          this.setState({
            reportdata : createdData,
            reportColumnName : column,
            reportheader : Header,
            noData : false
          },() => {
            $('#processing').hide()
            this.sortingTableColumn()
          })
        }else{
          $('#processing').hide()
          this.setState({
            noData : true,
            reportdata : '',
            reportColumnName : '',
            reportheader : ''
          })
        }
      }else{
        $('#processing').hide()
        this.setState({
          openAlert : true,
          errorMessage : response.data.response_message,
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while creating summary report', error)
    })
  }

  getOverSpeedReport = () => {
    $('#processing').show()
    const {zoneList, areaList, stateList, vehicleList, startDate, endDate, reportdata, reportColumnName, reportheader} = this.state
    const data = {zone_list : zoneList, area_list : areaList, state_list : stateList, serial_number_list : vehicleList, 
      start_date : CommonFunction.ServerDateTime(startDate), end_date : CommonFunction.ServerDateTime(endDate)}
    const Header = ["Sr. No.", "Zone", "State", "Area Office", "Vehicle", "Date","Time", "Speed (kmph)", "Duration (mm:ss)", "Location"];
    const column = "Sr.Zone.State.Area.Vehicle.Start.Time.speed.duration.location"    
    ApiUrlCall.customizedOverSpeedReport(JSON.stringify(data)).then(response => {
      // console.log('report data is', response)      
      if(response.data.response_code === 200 && response.data.is_success ===  true){
        // $('#processing').hide()
        if(response.data.data.result.length > 0){          
          var createdData = []
          response.data.data.result.map(data => {
            var finalData = {Sr : data[0], Zone : data[1],State : data[2], Area : data[3], Vehicle : data[4],
            Start : data[5], Time : data[6], speed : data[7], duration : data[8], location : data[9]}
            createdData.push(finalData)
          })
          this.setState({
            reportdata : createdData,
            reportColumnName : column,
            reportheader : Header,
            noData : false
          },() => {
            $('#processing').hide()
            this.sortingTableColumn()
          })
        }else{
          $('#processing').hide()
          this.setState({
            noData : true,
            reportdata : '',
            reportColumnName : '',
            reportheader : ''
          })
        }
      }else{
        this.setState({
          openAlert : true,
          errorMessage : response.data.response_message,
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while creating summary report', error)
    })
  }

  getSerialNumber = (name) => {
    const {dropdownData} = this.state
    for (let i = 0; i < dropdownData.serial_number_list.length; i++) {
      const element = dropdownData.serial_number_list[i];
      var name1 = element[2]
      var vehiclename = name1.split(" ")
      if(name === vehiclename[0]){
        // console.log(element,'serial_number', element[1])        
        return element[1]
      }  
    }    
  }

  getLatLng = (lat, lng) => {
    let addressLatLng = { lat: lat, lng: lng }
    ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
      // console.log('address is', response)
      if (response.data.response_code === 200 & response.data.is_success === true) {
        return response.data.data.address
      }else{
        return "no address"
      }
    }).catch(error => {
      console.log('error in request', error.res)
    })
  }

  modalTable = (data) => {
    // console.log('data is', data)
    const Header = ["Sr. No.", "Date", "Time", "Odometer (km)", "Address", "Ignition","Speed", "Status"];
    const column = "sr.date.time.OD.address.ign.speed.status"
    var reportData = []
    data.result.trip_selected.map(trip => {
      var add = (() => {
        if(trip[8]){
          return trip[8]
        }else{
          return '-'
        }        
      })()
      var finalData = {sr : trip[0], date : CommonFunction.tableDateFormate(trip[1]), time : CommonFunction.timeFormate(trip[2]),
      OD : trip[3], address :  add, ign : trip[5], speed : trip[6], status : trip[7]}
      reportData.push(finalData)
    })
    this.setState({
      modalreportheader : Header,
      modalreportColumnName : column,
      modalreportdata : reportData,
      modalHeaderText : "Trip Data"
    },() => {
      $('#trip-modal').slideDown('slow')
      $('html, body').css({
        overflow: 'hidden',
        height: '100%'
      });
    })
  }
  modalClose = () => {
    $('#trip-modal').fadeOut("slow")
    $('html, body').css({
        overflow: 'auto',
        height: '100%'
    });
}


  showTrips = (e) => {
    const {tripData} = this.state
    var vehicleName = $(e.currentTarget).parent().siblings()[4]   
    var serial_number = this.getSerialNumber($(vehicleName).text())
    var srNo = $(e.currentTarget).parent().siblings()[0]
    var start_datetime 
    var end_datetime
    this.setState({modalexcelshow : 1})
    for (let i = 0; i < tripData.length; i++) {
      const element = tripData[i];
      if(element.sr_no === parseInt($(srNo).text())){
        start_datetime = element.start
        end_datetime = element.end
      }
    } 
    var data = {serial_number : serial_number, start_date : start_datetime, end_date : end_datetime}  
    ApiUrlCall.singleTripDetail(JSON.stringify(data)).then(response => {
      // console.log('reponse', response)
      if(response.data.response_code === 200 && response.data.is_success){
        if(response.data.data.result.trip_selected.length > 0){
          this.modalTable(response.data.data)
        }
      }
    }).catch(error => {
      console.log('error while fetching trip data', error)
    })    
    
  }

  getDetailReport = () => {
    $('#processing').show()
    const {zoneList, areaList, stateList, vehicleList, startDate, endDate, reportdata, reportColumnName, reportheader} = this.state
    const data = {zone_list : zoneList, area_list : areaList, state_list : stateList, serial_number_list : vehicleList, 
      start_date : CommonFunction.ServerDateTime(startDate), end_date : CommonFunction.ServerDateTime(endDate)}
    const Header = ["Sr. No.", "Zone", "State", "Area Office", "Vehicle", "Start Date","End Date", "Start Time", "End Time",
    "SOD (km)", "EOD (km)","Distance (km)", "Duration (hh:mm)", "MS (kmph)", "OSA (count)", "EIA (count)",
      "DV", "Trip Detail", "Start Location", "End Location"];
    const column = "Sr.Zone.State.Area.Vehicle.Start.End.stime.etime.SOD.EOD.dist.dur.ms.OSA.EIA.DV.TD.SL.EL"
    var button = (() => {
      return(
        <button className="btn orange-border" 
        style={{marginTop : "0px", paddingBottom : "5px", paddingTop : "5px"}}
        onClick={this.showTrips}>Show</button>
      )
    })()
    // console.log('data trip', data)
    ApiUrlCall.customizedDetailReport(JSON.stringify(data)).then(response => {
      // console.log('report data is', response)      
      if(response.data.response_code === 200 && response.data.is_success ===  true){
        // $('#processing').hide()
        if(response.data.data.result.trips.length > 0){          
          var createdData = []
          var createTrip = []                    
          response.data.data.result.trips.map((data,index) => {
            var finalData = {Sr : data[0], Zone : data[1], State : data[2], Area : data[3], Vehicle : data[4],
            Start : CommonFunction.tableDateFormate(data[5]), End : CommonFunction.tableDateFormate(data[6]), 
            stime : CommonFunction.timeFormate(data[7]), etime : CommonFunction.timeFormate(data[8]), SOD : data[9], EOD : data[10],
            dist : data[11], dur : data[12], ms : data[13], OSA : data[14], EIA : data[15],  DV : data[16], TD : button, SL : data[18], EL : data[19]}
            var tripData = {sr_no : data[0], start : data[5], end : data[6]}
            createdData.push(finalData)
            createTrip.push(tripData)
          })
          this.setState({
            reportdata : createdData,
            tripData : createTrip,
            reportColumnName : column,
            reportheader : Header,
            noData : false
          },() => {
            $('#processing').hide()
            this.sortingTableColumn()
          })
        }else{
          $('#processing').hide()
          this.setState({
            noData : true,
            reportdata : '',
            reportColumnName : '',
            reportheader : ''
          })
        }
      }else{
        this.setState({
          openAlert : true,
          errorMessage : response.data.response_message,
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while creating summary report', error)
    })
  }

  modalTable2 = (data) => {
    // console.log('data is', data)
    const Header = ["Sr. No.","Zone", "Area Office", "Vehicle", "Odometer (km)", "Date", "Time", "Address", "Speed", "Ignition", "Status"];
    const column = "sr.zone.area.vehicle.od.date.time.address.speed.ign.status"
    var reportData = []
    data.result.trip_selected.map(dtrip => {
      var add = (() => {
        if(dtrip[11]){
          return dtrip[11]
        }else{
          return "-"
        }
      })()
      var finalData = {sr : dtrip[0], zone: dtrip[1], area : dtrip[2], vehicle : dtrip[3], od : dtrip[4], date : CommonFunction.tableDateFormate(dtrip[5]), 
      time : CommonFunction.timeFormate(dtrip[6]), address : add, speed : dtrip[8], ign : dtrip[9], status : dtrip[10]}
      reportData.push(finalData)
    })
    this.setState({
      modalreportheader : Header,
      modalreportColumnName : column,
      modalreportdata : reportData,
      modalHeaderText : "Night Mode Trip Data"   
    })
  }
  modalClose = () => {
    $('#trip-modal').fadeOut("slow")
    $('html, body').css({
        overflow: 'auto',
        height: '100%'
    });
}

  nightMode2 = (e) => {
    const {tripData} = this.state
    var vehicleName = $(e.currentTarget).parent().siblings()[3]   
    var serial_number = this.getSerialNumber($(vehicleName).text())
    var srNo = $(e.currentTarget).parent().siblings()[0]
    var start_datetime 
    var end_datetime
    this.setState({modalexcelshow : 3})
    for (let i = 0; i < tripData.length; i++) {
      const element = tripData[i];
      // console.log($(srNo).text(),'-----------------',element.sr_no)
      if(element.sr_no === parseInt($(srNo).text())){
        start_datetime = element.start
        end_datetime = element.end
      }
    } 
    var data = {serial_number : serial_number, start_date : start_datetime, end_date : end_datetime}  
    ApiUrlCall.singleNightTripDetailed(JSON.stringify(data)).then(response => {
      // console.log('reponse', response)
      if(response.data.response_code === 200 && response.data.is_success){
        if(response.data.data.result.trip_selected.length > 0){
          this.modalTable2(response.data.data)
        }
      }
    }).catch(error => {
      console.log('error while fetching trip data', error)
    })    
    
  }

  nightmodalTable = (data) => {
    // console.log('data is', data)
    const Header = ["Sr. No.", "Zone", "Area Office", "Vehicle", "Start Date","End Date", "Start Time", "End Time",
    "Start KM", "End KM","KM Driven", "Duration (hh:mm)", "Over Speed Count","MS (kmph)","Dealer Visit", "Detail", "Start Location", "End Location"];
    const column = "Sr.Zone.Area.Vehicle.Start.End.stime.etime.SOD.EOD.driven.dur.osc.ms.dv.details.SL.EL"
    const button = (() => {
      return(
        <button className="btn orange-border" 
        style={{marginTop : "0px", paddingBottom : "5px", paddingTop : "5px"}}
        onClick={this.nightMode2}>Show</button>
      )
    })()
    var reportData = []
    var createTrip = []
    data.result.night_selected_trip.map(night => {
      // console.log(night[14],'14 and 16 data', night[15])
      var finalData = {Sr : night[0], Zone : night[1], Area : night[2], Vehicle : night[3], Start : CommonFunction.tableDateFormate(night[4]),
      End : CommonFunction.tableDateFormate(night[5]), stime : CommonFunction.timeFormate(night[6]), etime : CommonFunction.timeFormate(night[7]),
      SOD : night[8], EOD : night[9], driven : night[10], dur : night[11], osc : night[12], ms : night[13], dv : night[14], 
      details : button, SL : night[16], EL : night[17]}
      var tripData = {sr_no : night[0], start : night[4], end : night[5]}
      reportData.push(finalData)
      createTrip.push(tripData)
    })
    this.setState({
      modalreportheader : Header,
      modalreportColumnName : column,
      modalreportdata : reportData,
      tripData : createTrip,
      modalHeaderText : 'Night Mode Trip Detail'
    },() => {
      $('#trip-modal').slideDown('slow')
      $('html, body').css({
        overflow: 'hidden',
        height: '100%'
      });
    })
  }
  modalClose = () => {
    $('#trip-modal').fadeOut("slow")
    $('html, body').css({
        overflow: 'auto',
        height: '100%'
    });
}

  showNightData = (e) => {
    $('#processing').show()
    const {nightTrip} = this.state
    // console.log('nightTrip', nightTrip)
    var vehicleName = $(e.currentTarget).parent().siblings()[3]   
    var serial_number = this.getSerialNumber($(vehicleName).text())
    var srNo = $(e.currentTarget).parent().siblings()[0]
    var start_datetime 
    var end_datetime
    this.setState({modalexcelshow : 2})
    for (let i = 0; i < nightTrip.length; i++) {
      const element = nightTrip[i];
      if(element.sr_no === $(srNo).text()){
        start_datetime = element.start
        end_datetime = element.end
      }
    } 
    var data = {serial_number : serial_number, start_date : start_datetime, end_date : end_datetime} 
    // console.log('night data', data) 
    ApiUrlCall.singleNightTrip(JSON.stringify(data)).then(response => {
      // console.log('reponse', response)
      if(response.data.response_code === 200 && response.data.is_success){
        // console.log('response is night', response.data)
        $('#processing').hide()
        if(response.data.data.result.night_selected_trip.length > 0){
          this.nightmodalTable(response.data.data)
        }
      }else{
        $('#processing').hide()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching trip data', error)
    })
  }

  getNightReport = () => {
    $('#processing').show()
    const {zoneList, areaList, stateList, vehicleList, startDate, endDate, reportdata, reportColumnName, reportheader} = this.state
    const data = {zone_list : zoneList, area_list : areaList, state_list : stateList, serial_number_list : vehicleList, 
      start_date : CommonFunction.ServerDateTime(startDate), end_date : CommonFunction.ServerDateTime(endDate)}
    const Header = ["Sr. No.", "Zone", "Area", "Vehicle", "Day Start","Day End", "KM Start", "KM End",
    "KM Driven", "Night Driven","Over Speeding Count", "Delear Visit", "No of Trips", "Detail"];
    const column = "Sr.Zone.Area.Vehicle.Start.End.kms.kme.kd.nd.osc.dv.not.d"
    var button = (() => {
      return(
        <button className="btn orange-border" 
        style={{marginTop : "0px", paddingBottom : "5px", paddingTop : "5px"}}
        onClick={this.showNightData}>Show</button>
      )
    })()
    
    ApiUrlCall.customizedNightModeReport(JSON.stringify(data)).then(response => {
      // console.log('report data is', response)      
      if(response.data.response_code === 200 && response.data.is_success ===  true){
        // $('#processing').hide()
        if(response.data.data.result.length > 0){          
          var createdData = []
          var array = []
          response.data.data.result.map(data => {
            var finalData = {Sr : data[0], Zone : data[1], Area : data[2],
            Vehicle : data[3], Start : CommonFunction.tableDateFormate(data[4]), End : CommonFunction.tableDateFormate(data[5]),
            kms : data[6], kme : data[7], kd : data[8],
            nd : data[9], osc : data[10], dv : data[11], not : data[12], d : button}
            var nightdata = {sr_no : data[0], start : data[4], end : data[5]}
            // console.log('finaldata', finalData)
            createdData.push(finalData)
            array.push(nightdata)
          })
          this.setState({
            reportdata : createdData,
            reportColumnName : column,
            reportheader : Header,
            nightTrip : array,
            noData : false
          },() => {
            $('#processing').hide()
            this.sortingTableColumn()
          })
        }else{
          $('#processing').hide()
          this.setState({
            noData : true,
            reportdata : '',
            reportColumnName : '',
            reportheader : ''
          })
        }
      }else{
        this.setState({
          openAlert : true,
          errorMessage : response.data.response_message,
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while creating summary report', error)
    })
  }

  selectAll = (e) => {   
    const {dropdownData, stateContent, areaContent, vehicleContent} = this.state 
    var id = $(e.currentTarget).prop('id')
    var curParent = $(e.currentTarget).parents()[1]
    var siblings = $(curParent).siblings().find('input')
    var nextDrop = $(e.currentTarget).parents()[4]    
    $(siblings).prop('checked', false)
    // console.log('next all drop', $(nextDrop))
        
    if(e.currentTarget.checked){      
      switch (id) {
        case ALLZONE:
          this.state.zoneList = []
          this.forceUpdate()
          dropdownData.zone_list.map(zone => {
            this.state.zoneList.push(zone[0])
          })
          this.forceUpdate() 
          this.uncheckVehicle(1)               
          break;
        case ALLAREA:
          this.state.areaList = []
          this.forceUpdate()
          areaContent.map(area => {
            this.state.areaList.push(area[0])
          })
          
        this.forceUpdate() 
        this.uncheckState(1)        
        $(nextDrop).next().find('.vehicledrop').removeClass('disable-drop') 
        this.vehicleSelectList(nextDrop)  
        break;  
        case ALLSTATE:
          this.state.stateList = []
          this.forceUpdate()          
          stateContent.map(state => {
            this.state.stateList.push(state[0])
          })
          this.forceUpdate() 
          this.uncheckZone(1)           
          $(nextDrop).next().find('.areadrop').removeClass('disable-drop') 
          this.areaSelectList(nextDrop)                
        break;
        case ALLVEHICLE:
          this.state.vehicleList = []
          this.forceUpdate()
          vehicleContent.map(vehicle => {            
            this.state.vehicleList.push(vehicle[1])
          })
          this.forceUpdate()  
          this.uncheckArea(1)        
          break;  
        default:
          break;
    }
    }else{      
      switch (id) {
        case ALLZONE:
          this.state.zoneList = []
          this.forceUpdate()
          this.uncheckVehicle(0)                    
          break;
        case ALLAREA:
          this.state.areaList = []
          this.forceUpdate()
          this.uncheckState(0) 
          $(nextDrop).next().find('.vehicledrop').removeClass('disable-drop') 
          this.vehicleSelectList(nextDrop) 
          break;  
        case ALLSTATE:
          this.state.stateList = []          
          this.forceUpdate()
          this.uncheckZone(0)  
          $(nextDrop).next().find('.areadrop').removeClass('disable-drop') 
          this.areaSelectList(nextDrop)
          break;
        case ALLVEHICLE:
          this.state.vehicleList = []
          this.forceUpdate()
          this.uncheckArea(0)  
          break;  
        default:
          break;
    }
  }
}
export_summary_report_table = () => {
  // console.log('22222222222')
  this._exporter_summary.save();
}
export_overspeed_report_table = () => {
  this._exporter_overspeed.save();
}
export_trip_report_table = () => {
  this._exporter_trip.save();
}
export_nightmode_report_table = () => {
  this._exporter_nightmode.save();
}
export_trip_modal = () => {
  this._exporter_modal_trip.save();
}
export_night_modal_1 = () => {
  this._exporter_modal_night1.save();
}
export_night_modal_2 = () => {
  this._exporter_modal_night2.save();
}

 
downloadExcel = () => {
  const {selectedReport} = this.state    
    if(selectedReport === SUMMARY){
      this.export_summary_report_table()
    }else if(selectedReport === OVERSPEED){
      this.export_overspeed_report_table()
    }else if(selectedReport === TRIP){
      this.export_trip_report_table()
    }else if(selectedReport === NIGHTMODE){
      this.export_nightmode_report_table()
    }
}


  render(){
    const {t} = this.props
    const {dropdownData, vehicleData, reportColumnName, reportdata, reportheader, stateContent, areaContent, vehicleContent,
    zoneList, areaList, stateList, vehicleList, modalreportColumnName, modalreportdata, modalreportheader} = this.state
    var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);   
    if(reportdata){
      var summary_data = process(this.state.reportdata, {
  
      }).data;  
    }
    if(modalreportdata){
      var modal_data = process(this.state.modalreportdata, {
  
      }).data;  
    }
    if(stateContent){
      // console.log('22222222', this.state.stateContent)
      var stateOption = stateContent.map(option => {
        return(
          <Grid key={option.serial_number} name={option[2]} container className="select_opt_row">
            <Grid item xs={2}>
              <input type="checkbox" onChange={this.stateDrop} id={option[0]} name="vehicle_tick"/>
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{option[1]}</p>
            </Grid>
          </Grid> 
        )
      })
    } 
    if(vehicleContent){
      var vehicleOption = vehicleContent.map(option => {        
        return(
          <Grid key={option.serial_number} name={option[3]} container className="select_opt_row">
            <Grid item xs={2}>
              <input type="checkbox" onChange={this.vehicleDrop} id={option[1]} name="vehicle_tick"/>
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{option[2]}</p>
            </Grid>
          </Grid> 
        )
      })
    }
    if(areaContent){
      var areaOption = areaContent.map(option => {
        return(
          <Grid key={option.serial_number} name={option[2]} container className="select_opt_row">
            <Grid item xs={2}>
              <input type="checkbox" onChange={this.areaDrop} id={option[0]} name="vehicle_tick"/>
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{option[1]}</p>
            </Grid>
          </Grid> 
        )
      })
    }
    if(dropdownData){   
         
      var zoneOption = dropdownData.zone_list.map(option => {
        return(
          <Grid key={option.serial_number} container className="select_opt_row">
            <Grid item xs={2}>
              <input type="checkbox" onChange={this.zoneDrop} id={option[0]} name="vehicle_tick"/>
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{option[1]}</p>
            </Grid>
          </Grid> 
        )
      })            
    }
    if(reportColumnName && reportdata && reportheader){
      var dataTable = (() => {
        return(
          <div style={{marginTop : "40px", overflow : "auto", marginBottom : "40px"}}>
            <Grid container>
              <Grid item xs>
                <p className="cj_w_header"><b>Total number of entries</b> : {this.state.reportdata.length}</p>
              </Grid>
              <Grid item xs style={{textAlign : "right"}}>
                <p className="p_header" id="sorting_text" style={{marginBottom : "0px"}}>Ascending</p>
                <p className="help_text" style={{marginBottom : "10px"}}>Change sorting by clicking on column header</p>
              </Grid>
            </Grid>
          <TablePagination              
              headers={ this.state.reportheader }
              data={ this.state.reportdata }
              columns={this.state.reportColumnName}
              perPageItemCount={ 10 }
              totalCount={ this.state.reportdata.length }
              arrayOption={ [["size", 'all', ' ']] }                            
              // paginationClassName="__btn"
          />
        </div>
        )
      })()
    }
    if(modalreportColumnName && modalreportdata && modalreportheader){
      var modalDataTable = (() => {
        return(
          <div>
            <p className="cj_w_header"><b>Total number of entries</b> : {this.state.modalreportdata.length}</p>
            <TablePagination              
              headers={ this.state.modalreportheader }
              data={ this.state.modalreportdata }
              columns={this.state.modalreportColumnName}
              perPageItemCount={ 10 }
              totalCount={ this.state.modalreportdata.length }
              arrayOption={ [["size", 'all', ' ']] }                            
              // paginationClassName="__btn"
              />             
          </div>
        )
      })()
    }
    return (
      <div className="c_report_parent">
        <div className="report_filter_div">
          {/* report selection div start */}
          <Grid container spacing={2}>
            <Grid item xs>
              <div className="fs_checkbox_parent">
                  <div style={{ display: "table-cell" }}>
                      <input type="radio" id="summary" defaultChecked="true" onChange={this.radioButton}></input>
                  </div>
                  <div style={{ display: "table-cell" }}>
                      <p>{t('ars.cust.summary')}</p>                      
                  </div>
              </div>
            </Grid>
            <Grid item xs>
              <div className="fs_checkbox_parent">
                  <div style={{ display: "table-cell" }}>
                      <input type="radio" id="overspeed" onChange={this.radioButton}></input>
                  </div>
                  <div style={{ display: "table-cell" }}>
                      <p>{t('ars.cust.speed')}</p>                      
                  </div>
              </div>
            </Grid>
            <Grid item xs>
              <div className="fs_checkbox_parent">
                  <div style={{ display: "table-cell" }}>
                      <input type="radio" id="trip" onChange={this.radioButton}></input>
                  </div>
                  <div style={{ display: "table-cell" }}>
                      <p>{t('ars.cust.trip')}</p>                      
                  </div>
              </div>
            </Grid>
            <Grid item xs>
              <div className="fs_checkbox_parent">
                  <div style={{ display: "table-cell" }}>
                      <input type="radio" id="nightmode" onChange={this.radioButton}></input>
                  </div>
                  <div style={{ display: "table-cell" }}>
                      <p>{t('ars.cust.night')}</p>                      
                  </div>
              </div>
            </Grid>
            <Grid item xs={1} className="pdf_excel_download">
                {/* <p className="BSC_semi_bold18" style={{cursor : "pointer"}}
                onClick={this.resetFilter}>Reset Filter</p> */}
                {/* <img src="images/group-6-copy-2.png"></img>&nbsp;<label className="BSC_semi_bold18">{t('ars.pdf')}</label> */}
            </Grid>
            <Grid item xs={1} className="pdf_excel_download" onClick={this.downloadExcel}>
              <img src="images/group-6-copy-2.png"></img>&nbsp;<label className="BSC_semi_bold18">{t('ars.excel')}</label>
              {/* SUmmay report excel data */}
              <ExcelExport
                data={summary_data}
                fileName={"Cust_Summary Report_"+CommonFunction.downloadReportDate(this.state.date)+".xlsx"}
                ref={(exporter) => { this._exporter_summary = exporter; }}>
                <ExcelExportColumn field="Sr" title="Sr. No." />
                <ExcelExportColumn field="Zone" title="Zone" />
                <ExcelExportColumn field="Area" title="Area Office" />
                <ExcelExportColumn field="Vehicle" title="Vehicle" />
                <ExcelExportColumn field="Start" title="Start Date" />
                <ExcelExportColumn field="End" title="End Date" />
                <ExcelExportColumn field="SOD" title="SOD (km)" />
                <ExcelExportColumn field="EOD" title="EOD (km)" />
                <ExcelExportColumn field="Kms" title="Kms" />
                <ExcelExportColumn field="OSA" title="OSA (count)" />
                <ExcelExportColumn field="OSD" title="OSD (mm:ss)" />
                <ExcelExportColumn field="NDD" title="NDD (hh:mm)" />
                <ExcelExportColumn field="ND" title="ND (km)" />
                <ExcelExportColumn field="DV" title="DV" />
                <ExcelExportColumn field="NOT" title="NOT" />
              </ExcelExport>

              {/* over speed excel data */}
              <ExcelExport
                data={summary_data}
                fileName={"Cust_Detail_Over_Speed Report_"+CommonFunction.downloadReportDate(this.state.date)+'.xlsx'}
                ref={(exporter) => { this._exporter_overspeed = exporter; }}>
                <ExcelExportColumn field="Sr" title="Sr. No." />
                <ExcelExportColumn field="Zone" title="Zone" />
                <ExcelExportColumn field="State" title="State" />
                <ExcelExportColumn field="Area" title="Area Office" />
                <ExcelExportColumn field="Vehicle" title="Vehicle" />
                <ExcelExportColumn field="Start" title="Date" />
                <ExcelExportColumn field="Time" title="Time" />
                <ExcelExportColumn field="speed" title="Speed (kmph)" />
                <ExcelExportColumn field="duration" title="Duration (mm:ss)" />
                <ExcelExportColumn field="location" title="Location" />                
              </ExcelExport>

              {/* trip excel data */}
              <ExcelExport
                data={summary_data}
                fileName={"Cust_Detail_Trip Report_1_"+CommonFunction.downloadReportDate(this.state.date)+'.xlsx'}
                ref={(exporter) => { this._exporter_trip = exporter; }}>
                <ExcelExportColumn field="Sr" title="Sr. No." />
                <ExcelExportColumn field="Zone" title="Zone" />
                <ExcelExportColumn field="State" title="State" />
                <ExcelExportColumn field="Area" title="Area Office" />
                <ExcelExportColumn field="Vehicle" title="Vehicle" />
                <ExcelExportColumn field="Start" title="Start Date" />
                <ExcelExportColumn field="End" title="End Date" />
                <ExcelExportColumn field="stime" title="Start Time" />
                <ExcelExportColumn field="etime" title="End Time" />
                <ExcelExportColumn field="SOD" title="SOD (km)" />
                <ExcelExportColumn field="EOD" title="EOD (km)" />
                <ExcelExportColumn field="dist" title="Distance (km)" />
                <ExcelExportColumn field="dur" title="Duration (hh:mm)" />
                <ExcelExportColumn field="ms" title="MS (kmph)" />
                <ExcelExportColumn field="OSA" title="OSA (count)" />
                <ExcelExportColumn field="EIA" title="EIA (count)" />
                <ExcelExportColumn field="DV" title="DV" />
                <ExcelExportColumn field="SL" title="Start Location" />
                <ExcelExportColumn field="EL" title="End Location" />
              </ExcelExport>

              {/* Night mode excel data */}
              <ExcelExport
                data={summary_data}
                fileName={"Cust_Night Mode Report_1_"+CommonFunction.downloadReportDate(this.state.date)+'.xlsx'}
                ref={(exporter) => { this._exporter_nightmode = exporter; }}>
                <ExcelExportColumn field="Sr" title="Sr. No." />
                <ExcelExportColumn field="Zone" title="Zone" />                
                <ExcelExportColumn field="Area" title="Area" />
                <ExcelExportColumn field="Vehicle" title="Vehicle" />
                <ExcelExportColumn field="Start" title="Day Start" />
                <ExcelExportColumn field="End" title="Day End" />                
                <ExcelExportColumn field="kms" title="KM Start" />
                <ExcelExportColumn field="kme" title="KM End" />
                <ExcelExportColumn field="kd" title="KM Driven" />
                <ExcelExportColumn field="nd" title="Night Driven" />
                <ExcelExportColumn field="osc" title="Over Speed Count" />
                <ExcelExportColumn field="dv" title="Dealer Visit" />                
                <ExcelExportColumn field="not" title="No of Trips" />                
              </ExcelExport>

              {/* trip modal excel data */}
              <ExcelExport
                data={modal_data}
                fileName={"Cust_Detail_Trip Report_2_"+CommonFunction.downloadReportDate(this.state.date)+'.xlsx'}
                ref={(exporter) => { this._exporter_modal_trip = exporter; }}>
                <ExcelExportColumn field="sr" title="Sr. No." />
                <ExcelExportColumn field="date" title="Date" />
                <ExcelExportColumn field="time" title="Time" />
                <ExcelExportColumn field="OD" title="Odometer (km)" />
                <ExcelExportColumn field="address" title="Address" />
                <ExcelExportColumn field="ign" title="Ignition" />
                <ExcelExportColumn field="speed" title="Speed" />
                <ExcelExportColumn field="status" title="Status" />                
              </ExcelExport>

              {/* night mode modal 1st */}
              <ExcelExport
                data={modal_data}
                fileName={"Cust_Night Mode Report_2_"+CommonFunction.downloadReportDate(this.state.date)+'.xlsx'}
                ref={(exporter) => { this._exporter_modal_night1 = exporter; }}>
                <ExcelExportColumn field="Sr" title="Sr. No." />
                <ExcelExportColumn field="Zone" title="Zone" />
                <ExcelExportColumn field="State" title="State" />
                <ExcelExportColumn field="Area" title="Area Office" />
                <ExcelExportColumn field="Vehicle" title="Vehicle" />
                <ExcelExportColumn field="Start" title="Start Date" />
                <ExcelExportColumn field="End" title="End Date" />
                <ExcelExportColumn field="stime" title="Start Time" />
                <ExcelExportColumn field="etime" title="End Time" />
                <ExcelExportColumn field="SOD" title="Start KM" />
                <ExcelExportColumn field="EOD" title="END KM" />
                <ExcelExportColumn field="driven" title="KM Driven" />
                <ExcelExportColumn field="dur" title="Duration (hh:mm)" />
                <ExcelExportColumn field="osc" title="Over Speed Count" />
                <ExcelExportColumn field="ms" title="MS (kmph)" />
                <ExcelExportColumn field="dv" title="Dealer Visit" />                
                <ExcelExportColumn field="SL" title="Start Location" />
                <ExcelExportColumn field="EL" title="End Location" />                
              </ExcelExport>

              {/* night mode modal 2nd */}
              <ExcelExport
                data={modal_data}
                fileName={"Cust_Night Mode Report_3_"+CommonFunction.downloadReportDate(this.state.date)+'.xlsx'}
                ref={(exporter) => { this._exporter_modal_night2 = exporter; }}>
                <ExcelExportColumn field="sr" title="Sr. No." />
                <ExcelExportColumn field="zone" title="Zone" />
                <ExcelExportColumn field="area" title="Area Office" />
                <ExcelExportColumn field="vehicle" title="Vehicle" />
                <ExcelExportColumn field="od" title="Odometer (km)" />
                <ExcelExportColumn field="date" title="Date" />
                <ExcelExportColumn field="time" title="Time" />
                <ExcelExportColumn field="address" title="Address" />
                <ExcelExportColumn field="speed" title="Speed" />
                <ExcelExportColumn field="ign" title="Ignition" />
                <ExcelExportColumn field="status" title="Status" />                
              </ExcelExport>
            </Grid>
          </Grid>
          {/* report selection div end */}
          <Grid spacing={2} container>
            <Grid item xs>
              <label className="work_sans_regular16">{t('ars.cust.zone')}</label>
              {/* <div className="input_div" onClick={this.showVehicles}></div> */}
              <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                <div className="summary_report_filters c_no-wrap">
                  <label>   
                    {zoneList.length > 0 ? zoneList.length+" Selected" : t('ars.cust.zone')}                 
                    {/* {this.state.chckebox_value.length <= 0 ? "Select Vehicle" : this.state.chckebox_value.length + "   Vehicles"} */}
                  </label>
                </div>
                <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
              </div>
              <div className="all_region_dd" style={{zIndex : "9"}}>
                <div className="dropdown_parent" id="zone_select">
                {/* <Grid container className="select_opt_row">
                  <Grid item xs={2}>
                    <input type="checkbox" onChange={this.selectAll} id="all_zone" name="vehicle_tick"/>
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{t('ct.all.text')}</p>
                  </Grid>
                </Grid>     */}
                {zoneOption}                              
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <label className="work_sans_regular16">{t('ars.cust.state')}</label>
              {/* <div className="input_div" onClick={this.showVehicles}></div> */}
              <div style={{ position: "relative" }} className="disable-drop statedrop" onClick={this.dropDownClick}>
                <div className="summary_report_filters c_no-wrap">
                  <label>   
                  {stateList.length > 0 ? stateList.length+" Selected" : t('ars.cust.state')}                 
                    {/* {this.state.chckebox_value.length <= 0 ? "Select Vehicle" : this.state.chckebox_value.length + "   Vehicles"} */}
                  </label>
                </div>
                <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
              </div>
              <div className="all_region_dd" style={{zIndex : "9"}}>
                <div className="dropdown_parent" id="state_select">
                <Grid container className="select_opt_row">
                  <Grid item xs={2}>
                    <input type="checkbox" onChange={this.selectAll} id="all_state" name="vehicle_tick"/>
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{t('ct.all.text')}</p>
                  </Grid>
                </Grid> 
                {stateOption}                                 
                </div>
              </div>
            </Grid>
            <Grid item xs>
              <label className="work_sans_regular16">{t('ars.cust.area')}</label>
              {/* <div className="input_div" onClick={this.showVehicles}></div> */}
              <div style={{ position: "relative" }} className="disable-drop areadrop" onClick={this.dropDownClick}>
                <div className="summary_report_filters c_no-wrap">
                  <label>   
                  {areaList.length > 0 ? areaList.length+" Selected" : t('ars.cust.area')}                 
                    {/* {this.state.chckebox_value.length <= 0 ? "Select Vehicle" : this.state.chckebox_value.length + "   Vehicles"} */}
                  </label>
                </div>
                <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
              </div>
              <div className="all_region_dd" style={{zIndex : "9"}}>
                <div className="dropdown_parent" id="area_select">
                <Grid container className="select_opt_row">
                  <Grid item xs={2}>
                    <input type="checkbox" onChange={this.selectAll} id="all_area" name="vehicle_tick"/>
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{t('ct.all.text')}</p>
                  </Grid>
                </Grid>  
                {areaOption}                                
                </div>
              </div>
            </Grid>
            <Grid item xs>

              <label className="work_sans_regular16">{t('ct.select.vehicle')}</label>
              {/* <div className="input_div" onClick={this.showVehicles}></div> */}
              <div style={{ position: "relative" }} className="disable-drop vehicledrop" onClick={this.dropDownClick}>
                <div className="summary_report_filters c_no-wrap">
                  <label>   
                  {vehicleList.length > 0 ? vehicleList.length+" Selected" : t('ct.select.vehicle')}                 
                    {/* {this.state.chckebox_value.length <= 0 ? "Select Vehicle" : this.state.chckebox_value.length + "   Vehicles"} */}
                  </label>
                </div>
                <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
              </div>
              <div className="all_region_dd" style={{zIndex : "9"}}>
                <div className="dropdown_parent" id="vehicles_select">
                <Grid container className="select_opt_row">
                  <Grid item xs={2}>
                    <input type="checkbox" onChange={this.selectAll} id="all_vehicle" name="vehicle_tick"/>
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{t('ct.all.text')}</p>
                  </Grid>
                </Grid> 
                {vehicleOption}                               
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <p>{t('ct.startdate')}</p>
              <div className="input_div" onClick={this.showStartDate}>
                <input type="text" placeholder={t('ct.startdate')} id="start_date" value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input" disabled></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="start_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.StartdateClicked}
                  value={this.state.date}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <p>{t('ct.enddate')}</p>
              <div className="input_div" onClick={this.showEndDate}>
                <input type="text" placeholder={t('ct.enddate')} id="end_date" value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input" disabled></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="end_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.EnddateClicked}
                  value={this.state.date}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
            </Grid>           
            <Grid item xs>
              <label>&nbsp;</label>
              <button className="btn orange-btn btn-block" style={{ marginTop: "0px" }} onClick={this.generateReport}>{t('ars.generate')}</ button>
            </Grid>                     
          </Grid>
        </div>
        {dataTable}
        <div style={{display : this.state.noData ? "" : "none"}}>
          <p className="p_big_grey" style={{textAlign : "center"}}>No Data Available</p>
        </div>
        {/* view stops modal*/}
        <div id="trip-modal" className="modal_parent" style={{left : "0"}}>
          <div className="modal_body" style={{width : "95%"}}>
              <Grid container>
                <Grid item xs={6}>
                  <p className="modal_header">{this.state.modalHeaderText}</p> 
                </Grid>
                <Grid item xs={6} style={{textAlign : "right"}}>
                  <div onClick={this.export_trip_modal}
                  className="pdf_excel_download"
                  style={{display : this.state.modalexcelshow === 1 ? "" : "none"}}>
                    <img src="images/group-6-copy-2.png"></img>&nbsp;<label className="BSC_semi_bold18">{t('ars.excel')}</label>
                  </div>
                  <div onClick={this.export_night_modal_1}
                  className="pdf_excel_download"
                  style={{display : this.state.modalexcelshow === 2 ? "" : "none"}}>
                    <img src="images/group-6-copy-2.png"></img>&nbsp;<label className="BSC_semi_bold18">{t('ars.excel')}</label>
                  </div>
                  <div onClick={this.export_night_modal_2}
                  className="pdf_excel_download"
                  style={{display : this.state.modalexcelshow === 3 ? "" : "none"}}>
                    <img src="images/group-6-copy-2.png"></img>&nbsp;<label className="BSC_semi_bold18">{t('ars.excel')}</label>
                  </div>
                </Grid>
              </Grid>
              {modalDataTable}
              <div className="close_modal" onClick={this.modalClose}>
                  <i class="fa fa-close" aria-hidden="true"></i>
              </div>
          </div>
      </div>
      {/* view stops modal end*/}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(Customized_report);
