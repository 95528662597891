import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import Table from 'react-bootstrap/Table';
import Slider from '@material-ui/core/Slider';
import WebCalendar from '../calendar/Calendar';
import Calendar from 'react-calendar/dist/entry.nostyle'
import { Link, BrowserRouter as Router } from 'react-router-dom';
import Navbar from '../../Config/Navbar';
import CommonFunction from '../common_function'
import CircularProgress from '@material-ui/core/CircularProgress';
import ApiUrlCall from '../API/apiurl';
import Map from '../map/map';
import Snackbar from '@material-ui/core/Snackbar';
import analytics from '../../Config/firebase'
import { isFuture } from 'date-fns';
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

const fuel1 = [
    {
        label: 'CURRENT DATE',
        field: 'timestamp',
        sort: 'asc',
        width: 200,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'FUEL CONSUMED (LITRES)',
        field: 'fuel_level',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'DISTANCE (KM)',
        field: 'distance',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'Idling (HH:MM)',
        field: 'idling',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'FUEL REFILL ALERT (COUNT)	',
        field: 'refuel_count',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'FUEL THEFT ALERT (COUNT)',
        field: 'theft_count',
        sort: 'asc',
        width: 200,
    }
]

const fuel2 = [
    {
        label: 'HOUR / DATE / WEEK / MONTH',
        field: 'timestamp',
        sort: 'asc',
        width: 200,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'FUEL CONSUMED (LITRES)',
        field: 'fuel_level',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'DISTANCE (KM)',
        field: 'distance',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'Idling (HH:MM)',
        field: 'idling',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'FUEL REFILL ALERT (COUNT)	',
        field: 'refuel_count',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'FUEL THEFT ALERT (COUNT)',
        field: 'theft_count',
        sort: 'asc',
        width: 200,
    }
]


const L = window.L
const MOVING = 2
const IDLING = 3
const PARKED = 4
const OVERSPEEDING = 7
const LOW_SATELLITES = 5
const SLEEPING = 6
const DNT = 8

class VehiclesDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            event: [],
            enterAlert: false,
            sliderValue: 0,
            boundaryRadius: 0,
            date: this.props.data,
            serial_number_is: this.props.location.state.serial_number,
            vehicleDetailsById: '',
            border_radiuslat: '',
            border_raduis_lon: '',
            vehicle_name: '',
            vehicle_make: '',
            vehicle_model: '',
            vehicle_fuel_type: '',
            vehicle_variant: '',
            registration_number: '',
            vehicle_fuel_capacity: '',
            fuel_level: '',
            last_refill: '',
            vehicle_vin: '',
            vehicle_odometer: '',
            vehicle_document_data: [],
            insurance_expiry_state: '',
            insurance_name_state: '',
            emi_amount_state: '',
            showMapSelect: false,
            addAddressTo: false,
            emi_end_date_state: '',
            driverstatic: [],
            showNext: false,
            vehicle_journey: [],
            vehicleLiveData: '',
            boundryradius: '',
            address_is: '',
            indexStop: 0,
            markerLat: '',
            markerLng: '',
            editBoundary: false,
            showDateWarning: false,
            modeConfigData: '',
            boundaryListData: '',
            boundaryTagName: '',
            boundaryAlertState: 1,
            speedValue: 0,
            openAlert: false,
            errorMessage: '',
            firstLoad: true,
            viewflag: false,
            vehicleList: [],
            ApplyToAll: false,
            documentData: '',
            boundariesContent: '',
            updateBoundary: [],
            AddBoundary: [],
            markerObj: '',
            radiusObj: '',
            stateIndex: 0,
            newRadius: 0,
            createMap: '',
            autoAddress: '',
            boundaryLength: 0,
            applyBoundaryData: [],
            boundaryFlag: false,
            currentDateData: [],
            filterData: [],
            fuelStatsData: [],
            hardware: [],
            fuelStatsDetails: [],
            fuelReportFilter: 2,
            filterLoadingFlag: false,
            boundary_type: 'default',
            boundaryStopAlert:'',
            boundarySpeedAlert:'',
            speed_region:'',
        }
        this.mounted = true
    }

    componentDidMount() {
        analytics.logEvent('screen_view', {
            screen_name: "VehicleDetail"
        })
        analytics.logEvent('page_view', {
            page_title: "VehicleDetail",
        })
        Amplify.configure(awswebapp);
        Analytics.record({
            name: constant.awsName,
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_VEHICLE_DETAILS' }
        });
        var navBarHeight = $('.navBar').outerHeight();
        $('.vehicle_detail_parent').css({
            paddingTop: navBarHeight + 60,
            paddingLeft: "100px",
            paddingRight: "100px"
        })
        this.mounted = true
        this.getVehicleDetails();
        this.getVehicleStaticdetails()
        this.getVehicleJourney();
        this.getVehilceLiveTrack()
        this.getModeConfig()
        this.getVehicleDocuments()
        this.fuelStatsDetailData()
        this.fuleStatsReportData()
        //this.tabrow();
    }

    componentWillUnmount() {
        this.mounted = false
    }


    fuelStatsDetailData = () => {
        var data = { serial_number: this.state.serial_number_is }
        ApiUrlCall.fuelStatsDetails(JSON.stringify(data)).then(response => {
            // console.log('resonse fuel stats', response)
            if (response.data.response_code === 200 && response.data.is_success) {
                this.setState({
                    fuelStatsDetails: response.data.data
                })
            }
        }).catch(error => {
            console.log('error while fetching fuel stats data', error)
        })
    }

    fuleStatsReportData = () => {
        const { filterLoadingFlag } = this.state
        if (filterLoadingFlag) {
            $('#processing').show()
        }
        var data = { summary_type: this.state.fuelReportFilter, serial_number: this.state.serial_number_is }
        ApiUrlCall.fuelStatsReport(JSON.stringify(data)).then(response => {
            // console.log('reponse data is', response)
            if (response.data.response_code === 200 && response.data.is_success) {
                // if(filterLoadingFlag){
                $('#processing').hide()
                // }       
                this.setState({
                    fuelStatsData: response.data.data.summary_list,
                    currentDateData: [],
                    filterData: []
                }, () => {
                    this.generateFuelReportData()
                })
            } else {
                this.setState({
                    openAlert: true,
                    errorMessage: "Something Went Wrong"
                })
                $('#processing').hide()
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching fule report stats data', error)
        })
    }

    emptyString = (data) => {
        if(data){
            return data
        }else{
            return "NA"
        }
    }

    generateFuelReportData = () => {
        const { fuelStatsData, fuelReportFilter } = this.state
        var array = []
        for (let i = 0; i < fuelStatsData.length; i++) {
            const element = fuelStatsData[i];
            if (parseInt(fuelReportFilter) === 1) {
                if (i == 0) {
                    var data = {
                        timestamp: CommonFunction.tableDateFormate(element.timestamp), fuel_level: element.fuel_level !== "" ? element.fuel_level : "NA",
                        refuel_count: element.refuel_count !== "" ? element.refuel_count : "NA", theft_count: element.theft_count !== "" ? element.theft_count : "NA",
                        distance : this.emptyString(element.distance), idling : CommonFunction.convertSecToHoursMin(element.idling_duration)
                    }
                } else {
                    var data = {
                        timestamp: CommonFunction.timeFormate(element.start_date) + " - " + CommonFunction.timeFormate(element.end_date), fuel_level: element.fuel_level !== "" ? element.fuel_level : "NA",
                        refuel_count: element.refuel_count !== "" ? element.refuel_count : "NA", theft_count: element.theft_count !== "" ? element.theft_count : "NA",
                        distance : this.emptyString(element.distance),idling : CommonFunction.convertSecToHoursMin(element.idling_duration)
                    }
                }
            } else if (parseInt(fuelReportFilter) === 2) {
                if (i == 0) {
                    var data = {
                        timestamp: CommonFunction.tableDateFormate(element.timestamp), fuel_level: element.fuel_level !== "" ? element.fuel_level : "NA",
                        refuel_count: element.refuel_count !== "" ? element.refuel_count : "NA", theft_count: element.theft_count !== "" ? element.theft_count : "NA",
                        distance : this.emptyString(element.distance),idling : CommonFunction.convertSecToHoursMin(element.idling_duration)
                    }
                } else {
                    var data = {
                        timestamp: CommonFunction.tableDateFormate(element.start_date), fuel_level: element.fuel_level !== "" ? element.fuel_level : "NA",
                        refuel_count: element.refuel_count !== "" ? element.refuel_count : "NA", theft_count: element.theft_count !== "" ? element.theft_count : "NA",
                        distance : this.emptyString(element.distance),idling : CommonFunction.convertSecToHoursMin(element.idling_duration)
                    }
                }
            }
            else {
                if (i == 0) {
                    var data = {
                        timestamp: CommonFunction.tableDateFormate(element.timestamp), fuel_level: element.fuel_level !== "" ? element.fuel_level : "NA",
                        refuel_count: element.refuel_count !== "" ? element.refuel_count : "NA", theft_count: element.theft_count !== "" ? element.theft_count : "NA",
                        distance : this.emptyString(element.distance),idling : CommonFunction.convertSecToHoursMin(element.idling_duration)
                    }
                } else {
                    var data = {
                        timestamp: CommonFunction.tableDateFormate(element.start_date) + " - " + CommonFunction.tableDateFormate(element.end_date),
                        fuel_level: element.fuel_level !== "" ? element.fuel_level : "NA",
                        refuel_count: element.refuel_count !== "" ? element.refuel_count : "NA", theft_count: element.theft_count !== "" ? element.theft_count : "NA",
                        distance : this.emptyString(element.distance),idling : CommonFunction.convertSecToHoursMin(element.idling_duration)
                    }
                }
            }
            array.push(data)
        }
        this.setState({
            currentDateData: [array[0]],
            filterData: array.slice(1, 4)
        })
    }

    getModeConfig = () => {
        const serial_number = JSON.stringify({ serial_number: this.state.serial_number_is, mode: 1 })
        ApiUrlCall.getModeConfigData(serial_number).then(response => {
            if (response.data.response_code === 200 && response.data.is_success === true) {
                // console.log('alert config is', response.data)
                this.setState({
                    modeConfigData: response.data.data.config
                }, () => {
                    var speed = this.state.modeConfigData.map(speed => {
                        if (speed.alert_id === 1) {
                            this.setState({
                                speedValue: speed.val
                            })
                        }
                    })
                })
            }
        }).catch(error => {
            console.log('error while fetching mode config', error.response.data)
        })
    }

    getBoundaryData = () => {
        // console.log('boundary alert list data called11111111111111')
        const serial_number = JSON.stringify({ serial_number: this.state.serial_number_is, mode: 1 })
        ApiUrlCall.getBoundaryAlertList(serial_number).then(response => {
             //console.log(response,'boundary alert list data called222222222222222')
            if (response.data.response_code === 200 && response.data.is_success === true) {
                // console.log('boundary list data', response.data)
                if (response.data.data.boundary_list.length > 0) {
                    // console.log('in boundary list set state')
                    this.setState({
                        boundaryListData: response.data.data.boundary_list,
                        boundaryLength: response.data.data.boundary_list.length
                        // address_is: response.data.data.boundary_list.slice(-1)[0].address,
                        // markerLat: parseFloat(response.data.data.boundary_list.slice(-1)[0].lat),
                        // markerLng: parseFloat(response.data.data.boundary_list.slice(-1)[0].lng),
                        // boundaryRadius: response.data.data.boundary_list.slice(-1)[0].radius,
                        // boundaryAlertState: response.data.data.boundary_list.slice(-1)[0].state
                    }, () => {
                        this.createBoundaryUI()
                        this.createMarkers()
                        this.createRadius()
                        // console.log('set state done and the value setis', this.state.markerLat, '.................', this.state.boundaryAlertState)
                    })
                }
            }
        }).catch(error => {
            console.log('error while fetching boundary list data', error)
        })
    }

    selectBoundary = (e) => {
        var index = $(e.currentTarget).attr('name')
        var address = $('#address_' + index).text()
        var tag = $('#tag_' + index).text()
        var lat = $('#lat_' + index).text()
        var lng = $('#lng_' + index).text()
        var radius = $('#radius_' + index).text()
        const boundarySaveData = {
            name: tag, lat: parseFloat(lat), address: address, lng: parseFloat(lng), radius: parseFloat(radius),
        }
        //console.log('START BOUNDARY DATA --' ,boundarySaveData , "BOUNDARY SAVE DATA ----\n\n")
        if (e.currentTarget.checked) {
            this.state.applyBoundaryData.push(boundarySaveData)
            this.forceUpdate()
            // console.log('boundary data is1111111', this.state.applyBoundaryData)
        } else {
            // console.log('uncheck')
            this.state.applyBoundaryData.map((boundary, index) => {
                // console.log(boundary.name,'.........',tag,'..........',index)
                if (boundary.name === tag) {
                    this.state.applyBoundaryData.splice(index, 1)
                }
            })
            this.forceUpdate()
            // console.log('boundary data is', this.state.applyBoundaryData)
        }
    }

    createBoundaryUI = () => {
        const { boundaryListData } = this.state
        const { t } = this.props
        var boundaryArray = []
        var speed_region_view = true
        for (let i = 0; i < boundaryListData.length; i++) {
            const element = boundaryListData[i];
            if (element.speed_region != ''){
                speed_region_view = true
                }
            else {
                speed_region_view = false
            }
            boundaryArray.push(
                <div>
                    <p style={{ display: element.state !== undefined && element.state == 1 ? 'none' : '', color: 'red', marginTop: "15px" }}>Alert is disabled can't edit</p>
                    <Grid container style={{ pointerEvents: element.state !== undefined && element.state == 1 ? 'auto' : 'none' }}>
                        <Grid item xs={4} className="padding20">
                            <div>
                                <span style={{ marginRight: "10px" }}>
                                    <input name={i} type="checkbox" onChange={this.selectBoundary} className="select_boundary"></input>
                                </span>
                                <p className="bs_regular" style={{ display: "inline" }}>{t('vds.header1')}</p>
                                <p className="edit_boundary_p" id={"address_" + i}> {element.address ? element.address : t('cs.notfound')} </p>
                                <span id={"lat_" + i} style={{ display: "none" }}>{element.lat}</span>
                                <span id={"lng_" + i} style={{ display: "none" }}>{element.lng}</span>
                                {/* <div className="vd_boundary_address">
                                <label className="p_margin0">address</label>
                                <input type="text" value={this.state.address_is} placeholder="Enter address" ></input> 
                            </div> */}
                            </div>
                        </Grid>
                        <Grid itex xs={2} className="padding20">
                            <div className="boundar_tag">
                                <p className="bs_regular">{t('vds.header2')}</p>
                                <p className="edit_boundary_p" id={"tag_" + i}>{element.name}</p>
                                {/* <input type="text" disabled placeholder="Enter Tag" id={"tag_"+i} defaultValue={element.name}
                                className="vd_input" style={{ marginBottom: "0px" }}></input> */}
                            </div>
                        </Grid>
                        <Grid item xs={1.5} className="padding20">
                            <div>
                                <p id={'text_' + i} className="bs_regular">{t('vds.header3')} :&nbsp;<span className="edit_boundary_p" id={"radius_" + i}>{element.radius}</span>
                                    <span className="edit_boundary_p">&nbsp;Km</span></p>
                                <Slider
                                    id={'slider_' + i}
                                    style={{ display: 'none' }}
                                    aria-labelledby="slider-image"
                                    min={0}
                                    max={100}
                                    step={0.5}
                                    valueLabelDisplay="auto"
                                    defaultValue={element.radius}
                                    onChange={this.radiusValue}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={1} className="padding20">
                            <div class="" style={{ float: "right" }}>
                                <img src="images/edit_boundary.png" icon="icon"
                                    onClick={this.editBoundaryDetails} name={i} className="edit_boundary"></img>
                                <img src="images/boundary_done.png" alt="icon"
                                    style={{ display: 'none' }} name={i} onClick={this.saveBoundaryDetails}></img>
                            </div>
                        </Grid>
                        <Grid item xs={1} className="padding20">
                            <div className="boundar_tag1" style={{display : speed_region_view == '' ? "none" : ""}}>
                                <p className="bs_regular">Speed Limit :</p>
                                <input type="text" defaultValue={element.speed_region} placeholder={t('vds.enter.speed')} className="enter_region_speed"
                                    onChange={(e) => {
                                        this.setState({
                                            speed_region: e.currentTarget.value
                                        }, () => {
                                            // console.log('speed value is', this.state.speedValue)
                                        })
                                    }}></input>&nbsp;&nbsp;<label>km/hr</label>
                            </div>
                        </Grid>
                        <Grid item xs={1} className="padding20">
                            <div class="" style={{ float: "right",display : speed_region_view == '' ? "none" : ""  }}>
                                     <img src="images/boundary_done.png" alt="icon" style={{ marginTop: "-10px" }} name={i}
                                                    onClick={this.save_region_Speed}></img>
                            </div>
                        </Grid>
                        <Grid item xs={1} className="padding20">
                            <div className="boundar_tag1" style={{display : speed_region_view == '' ? "none" : ""}} >
                            <p className="bs_regular" >Stoppage Time :</p>
                            <input type="text" defaultValue={element.stoppage_duration}  placeholder={t('Enter Stop Time')} className="enter_region_speed"
                                    onChange={(e) => {
                                        this.setState({
                                            stoppage_region: e.currentTarget.value
                                        }, () => {
                                            // console.log('speed value is', this.state.speedValue)
                                        })
                                    }}></input>&nbsp;&nbsp;<label>Min</label>
                            </div>
                        </Grid>
                        <Grid item xs={0.5} className="padding20">
                        <div class="" style={{ float: "right",display : speed_region_view == '' ? "none" : ""  }}>
                                     <img src="images/boundary_done.png" alt="icon" style={{ marginTop: "-10px" }} name={i}
                                                    onClick={this.save_region_Stoppage}></img>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        
    }
        this.setState({
            boundariesContent: boundaryArray
        })
        // console.log('boundaryListData', boundaryListData)
    }

    createMarkers = () => {
        const { boundaryListData } = this.state
        var markerArray = []
        for (let i = 0; i < boundaryListData.length; i++) {
            const element = boundaryListData[i];
            markerArray.push(
                {
                    position: [element.lat, element.lng],
                    rotateMarker: true,
                    icon: L.icon({
                        iconUrl: 'images/34x34.png',
                        iconRetinaUrl: 'images/34x34.png',
                        iconSize: [34, 34],
                        popupAnchor: [-3, -15]
                    }),
                    title: element.address,
                    draggable: false,
                    onClick: () => { },
                    onDragend: (e) => {
                        this.setState({
                            markerLat: e.target._latlng.lat,
                            markerLng: e.target._latlng.lng,
                        }, () => {
                            this.getLatLng(this.state.markerLat, this.state.markerLng)
                        })
                    },
                    setViewFlag: this.state.viewflag,
                }
            )
        }
        this.setState({
            markerObj: markerArray,
        })
    }

    createRadius = () => {
        const { boundaryListData } = this.state
        var radiusArray = []
        for (let i = 0; i < boundaryListData.length; i++) {
            const element = boundaryListData[i];
            radiusArray.push(
                {
                    position: [element.lat, element.lng],
                    radius: element.radius,
                }
            )
        }
        this.setState({
            radiusObj: radiusArray
        })
    }

    showDetails = (e) => {
        window.dispatchEvent(new Event('resize'));
        $('#vehicle_detail_div').slideToggle("slow", "swing")
        var toggleIcon = $(e.currentTarget).children()
        $(toggleIcon).toggle()
    }
    radiusValue = (e, value) => {
        var singlemarker = []
        var singelradius = []
        $('#radius_' + this.state.stateIndex).text(value)
        singlemarker.push(
            {
                position: [this.state.markerLat, this.state.markerLng],
                rotateMarker: true,
                icon: L.icon({
                    iconUrl: 'images/34x34.png',
                    iconRetinaUrl: 'images/34x34.png',
                    iconSize: [34, 34],
                    popupAnchor: [-3, -15]
                }),
                title: this.state.address_is,
                draggable: true,
                onClick: () => { },
                onDragend: (e) => {
                    this.setState({
                        markerLat: e.target._latlng.lat,
                        markerLng: e.target._latlng.lng,
                    }, () => {
                        this.getLatLng(this.state.markerLat, this.state.markerLng)
                        this.updateData()
                    })
                },
                setViewFlag: this.state.viewflag,
            }
        )
        singelradius.push(
            {
                position: [this.state.markerLat, this.state.markerLng],
                radius: value,
            }
        )
        this.setState({
            markerObj: singlemarker,
            radiusObj: singelradius,
        })

    }

    alertParameter = () => {
        this.getBoundaryData()
        this.getVehicleList()
        this.setState({
            enterAlert: true
        }, () => {
            $('#boundary_message').show()
        })
        window.dispatchEvent(new Event('resize'));
    }

    allVehicleAlert = () => {
        const { vehicleList, applyBoundaryData } = this.state
        var count = applyBoundaryData.length
        if (this.state.applyBoundaryData.length > 0 && this.state.vehicleList.length > 0) {
            $('#processing').show()
            this.hideScroll()
            for (let i = 0; i < applyBoundaryData.length; i++) {
                const element = applyBoundaryData[i];
                // console.log(element , " ELEMENT BOUNDARY ----\n\n")                   
                for (let j = 0; j < vehicleList.length; j++) {
                    const serial_number = vehicleList[j];
                    var ndata = {
                        serial_number: serial_number, name: element.name, lat: element.lat,
                        lng: element.lng, address: element.address, radius: element.radius
                    }
                    // console.log(serial_number ,'ELEMNET 1 AND DATA OBJ ', ndata)                                                        
                    ApiUrlCall.createBoundaryAlert(JSON.stringify(ndata)).then(response => {

                    }).catch(error => {
                        console.log('error while creating alert', error)
                        // continue
                    })
                    // console.log('speed sending data is', speedSaveData)                                   
                }
            }
            setTimeout(() => {
                this.setState({
                    openAlert: true,
                    errorMessage: count + " Boundaries Successfully Updated For All Vehicles",
                    applyBoundaryData: [],
                }, () => {
                    this.showScroll()
                    $('#processing').hide()
                    $('.select_boundary').prop("checked", false)
                })
            }, 2000);

            // this.setState({
            //     enterAlert: false
            // })
            // window.dispatchEvent(new Event('resize'));

        } else {
            // alert('enter tag name / address / speed')   
            this.setState({
                openAlert: true,
                errorMessage: 'Kindly selected one boundary'
            })
        }
    }

    getVehicleList = () => {
        ApiUrlCall.getVehicleList().then(response => {
            if (response.data.response_code === 200 && response.data.is_success === true) {
                var vehicleListArray = response.data.data.vehicle_summary_list
                for (let i = 0; i < vehicleListArray.length; i++) {
                    const element = vehicleListArray[i];
                    this.state.vehicleList.push(element.serial_number)
                }
                this.forceUpdate()
            }
        }).catch(error => {
            console.log('error while fetching vehicle list', error)
        })
    }

    editVehicleDetails = (serial_number) => {
        this.props.history.push({
            pathname: "/edit_vehicle_details",
            state: { serial_number: serial_number }
        })
    }

    showCalendar = (e) => {
        var calendar = e.currentTarget.nextSibling
        $(calendar).slideToggle("show", "swing")
    }

    getVehicleDetails = () => {
        // $('#processing').show()
        let serial_number = this.state.serial_number_is
        ApiUrlCall.getVehicledetails(serial_number).then(response => {
            // console.log(response,"response is")
            if (response.status === 200) {
                // $('#processing').hide()
                this.setState({
                    vehicleDetailsById: response.data,
                    vehicle_make: response.data.make,
                    vehicle_model: response.data.model,
                    vehicle_name: response.data.name,
                    vehicle_vin: response.data.vehicle_identification_number,
                    vehicle_fuel_type: response.data.fuel_type,
                    registration_number: response.data.registration_number,
                    vehicle_id: response.data.serial_number,
                    vehicle_odometer: response.data.odometer,
                    schema: response.data,
                    vehicle_fuel_capacity: response.data.fuel_capacity,
                    fuel_level: response.data.fuel_level,
                    last_refill: response.data.fuel_refill_on,
                    hardware: response.data.hw_attachment_list
                })
            }
        }).catch(error => {
            // $('#processing').hide()
            console.log('error while gettting vehicle details', error.response.data)
        })
    }

    getVehicleStaticdetails() {
        let serial_number = this.state.serial_number_is
        // console.log('serial number is', JSON.stringify(serial_number))
        $('#processing').show()
        ApiUrlCall.getVehicleStaticdetails(serial_number).then(res => {
            // console.log(res, "200 Response Data driver stats is")
            if (res.status === 200) {
                // $('#processing').hide()
                this.setState({ driverstatic: res.data })
            }
        }).catch(err => {
            // $('#processing').hide()
            console.log(err, "Vehicles Details Error")
        })
    }

    getVehilceLiveTrack = () => {
        $('.map_loading').show()
        const serial_number = JSON.stringify({ serial_number: this.state.serial_number_is })
        ApiUrlCall.getSingleVehicleLiveTrack(serial_number).then(response => {

          // console.log("getSingleVehicleLiveTrack",response)

            if (response.data.response_code === 200 && response.data.is_success) {
                this.setState({
                    vehicleLiveData: response.data.data,
                    markerLat: response.data.data.lat,
                    markerLng: response.data.data.lng,
                }, () => {
                    $('.map_loading').hide()
                    // this.getLatLng(this.state.markerLat, this.state.markerLng)
                })
            }
        })
    }

    getVehicleJourney() {
        let { startDate, endDate } = this.state
        let serial_number = this.state.serial_number_is
        if (this.state.firstLoad) {
            var parameters = { start_time: "", end_time: "" }
        } else {
            var parameters = { start_time: CommonFunction.ServerDateTime(startDate), end_time: CommonFunction.ServerDateTime(endDate) }
        }
        ApiUrlCall.getVehicleJourneydetails(parameters, serial_number).then(res => {
            // console.log('vehicle journey', res)
            if (res.status === 200) {
                this.setState({ vehicle_journey: res.data, firstLoad: false }, () => {
                    CommonFunction.tableCellWidth('vehicle_journey_table')
                })
            }
        }).catch(error => {
            console.log("vehicle journey error", error)
        })
    }
    goBack = () => {
        this.props.history.push('list_vehicle')
    }
    StartdateClicked = date => {
        var isoStartDate = date
        this.setState({ startDate: isoStartDate })
        $('#start_date_cal').slideUp(200)
    }

    // this method is used to create end date into ISO and send it to server
    EnddateClicked = date => {
        var isoStartDate = date
        isoStartDate.setHours(23, 59, 59, 0);
        this.setState({ endDate: isoStartDate }, () => {
            if (this.state.startDate > this.state.endDate) {
                this.setState({ showDateWarning: true })
                // console.log('select date is greater')
            } else {
                this.setState({ showDateWarning: false })
                // console.log('proper date is seleted.')
            }
        })
        $('#end_date_cal').slideUp(200)
    }

    showStartDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }
    showEndDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }


    // this method is used to formate date as per UI to show.
    dateFormate = (rdate) => {
        var date = new Date(rdate);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        var convertDate = day + '/' + month + '/' + year
        return convertDate
    }

    updateBoundaryApi = (data) => {
        $('#processing').show()
        ApiUrlCall.updateBoundary(JSON.stringify(data)).then(response => {
            if (response.data.response_code === 200 && response.data.is_success) {
                $('#processing').hide()
                this.getBoundaryData()
            } else {
                $('#processing').hide()
                this.setState({
                    openAlert: true,
                    errorMessage: response.data.response_message
                })
            }
            // console.log('update boundary response', response)
        }).catch(error => {
            $('#processing').hide()
            console.log("error while updating boundary alert", error)
        })
    }

    editBoundaryDetails = (e) => {
        const { markerObj, radiusObj } = this.state
        var save_icon = $(e.currentTarget).next()
        var parent = $(e.currentTarget).parents()[3]
        var siblings = $(parent).siblings().find('.edit_boundary')
        // console.log('parents siblings', parent)
        var index = $(e.currentTarget).attr('name')
        var address = $('#address_' + index).text()
        var radius = $('#radius_' + index).text()
        var lat = $('#lat_' + index).text()
        var lng = $('#lng_' + index).text()
        var singlemarker = []
        var singelradius = []
        singlemarker.push(
            {
                position: [parseFloat(lat), parseFloat(lng)],
                rotateMarker: false,
                icon: L.icon({
                    iconUrl: 'images/34x34.png',
                    iconRetinaUrl: 'images/34x34.png',
                    iconSize: [34, 34],
                    popupAnchor: [-3, -15]
                }),
                title: address,
                draggable: true,
                onClick: () => { },
                onDragend: (e) => {
                    this.setState({
                        markerLat: e.target._latlng.lat,
                        markerLng: e.target._latlng.lng,
                    }, () => {
                        this.getLatLng(this.state.markerLat, this.state.markerLng)
                        this.updateData()
                    })
                },
                setViewFlag: this.state.viewflag,
            }
        )
        singelradius.push(
            {
                position: [parseFloat(lat), parseFloat(lng)],
                radius: parseFloat(radius),
            }
        )
        this.setState({
            markerObj: singlemarker,
            radiusObj: singelradius,
            stateIndex: index,
        })
        $(siblings).css({
            pointerEvents: 'none'
        })
        // $('#text_'+index).hide()
        $('#slider_' + index).show()
        // console.log('.........',index)
        $(save_icon).show()
        $(e.currentTarget).hide()
        // console.log('save icon', save_icon)
    }
    saveBoundaryDetails = (e) => {
        var edit_icon = $(e.currentTarget).prev()
        var index = $(e.currentTarget).attr('name')
        var parent = $(e.currentTarget).parents()[3]
        var siblings = $(parent).siblings().find('.edit_boundary')
        var address = $('#address_' + index).text()
        var lat = $('#lat_' + index).text()
        var lng = $('#lng_' + index).text()
        var tag = $('#tag_' + index).text()
        var radius = $('#radius_' + index).text()
        $(siblings).css({
            pointerEvents: 'auto'
        })
        $('#text_' + index).show()
        $('#slider_' + index).hide()
        $(edit_icon).show()
        $(e.currentTarget).hide()
        var dataObj = { serial_number: this.state.serial_number_is, name: tag, lat: parseFloat(lat), lng: parseFloat(lng), radius: parseFloat(radius), address: address }
        // console.log('data to send in update', dataObj)
        this.updateBoundaryApi(dataObj)
    }

    hideScroll = () => {
        $('html, body').css({
            overflow: 'hidden',
            height: '100%',
            // pointerEvents: 'none'
        });
        $('.vehicle_detail_parent').scrollTop()
    }

    showScroll = () => {
        // console.log('show scroll')
        $('html, body').css({
            overflow: 'scroll',
            height: '100%',
            // pointerEvents: 'auto'
        });
    }


    updateData = () => {
        const { stateIndex } = this.state
        var addressDiv = $('#address_' + stateIndex)
        var latDiv = $('#lat_' + stateIndex)
        var lngDiv = $('#lng_' + stateIndex)
        var radiusDiv = $('#radius_' + stateIndex).text()
        // console.log('radius value is', radiusDiv)
        var singelradius = []
        // console.log()
        $(addressDiv).text(this.state.address_is)
        $(latDiv).text(this.state.markerLat)
        $(lngDiv).text(this.state.markerLng)
        singelradius.push(
            {
                position: [this.state.markerLat, this.state.markerLng],
                radius: parseFloat(radiusDiv),
            }
        )
        this.setState({
            radiusObj: singelradius
        })
    }


    getLatLng = (lat, lng) => {
        let addressLatLng = { lat: lat, lng: lng }
        ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
            if (response.data.response_code === 200 & response.data.is_success === true) {
                // console.log('getting address form mongo', response.data.data.address)
                this.setState({
                    address_is: response.data.data.address,
                }, () => {
                    // console.log('addres from mongo is', this.state.address_is)
                })
            }
        }).catch(error => {
            console.log('error in request', error.res)
        })
    }

    saveAlertSingle = () => {
        // console.log('speed value is', this.state.speedValue)
        if(this.state.boundary_type == 'default'){
        if (this.state.boundaryTagName && this.state.address_is) {
            const boundarySaveData = {
                serial_number: this.state.serial_number_is, name: this.state.boundaryTagName, lat: this.state.markerLat,
                lng: this.state.markerLng, radius: this.state.newRadius, address: this.state.address_is
            }
            $('#processing').show()
            this.hideScroll()
            ApiUrlCall.createBoundaryAlert(JSON.stringify(boundarySaveData)).then(response => {
                // console.log('boundary saved successfully', response)
                if (response.data.response_code === 200 && response.data.is_success) {
                    this.showScroll()
                    $('#processing').hide()
                    $('#add_boundary').fadeOut('slow')
                    this.getBoundaryData()
                    this.setState({
                        viewflag: false,
                    })
                } else {
                    this.showScroll()
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                this.showScroll()
                $('#processing').hide()
                console.log('error while creating alert', error)
            })
            // console.log('speed sending data is', speedSaveData)            
        } else {
            // alert('enter tag name / address / speed')   
            this.setState({
                openAlert: true,
                errorMessage: 'Address Not Found'
            })
        }
    }else if (this.state.boundary_type == 'region') {
        if (this.state.boundaryTagName && this.state.address_is) {
            const boundary_region_SaveData = {
                serial_number: this.state.serial_number_is, name: this.state.boundaryTagName, lat: this.state.markerLat,
                lng: this.state.markerLng, radius: this.state.newRadius, address: this.state.address_is,
                speed_region: this.state.boundarySpeedAlert, stoppage_duration: this.state.boundaryStopAlert
            }
            $('#processing').show()
            this.hideScroll()
            console.log(boundary_region_SaveData)
            ApiUrlCall.createRegionBoundaryAlert(JSON.stringify(boundary_region_SaveData)).then(response => {
                // console.log('boundary saved successfully', response)
                if (response.data.response_code === 200 && response.data.is_success) {
                    this.showScroll()
                    $('#processing').hide()
                    $('#add_boundary').fadeOut('slow')
                    this.getBoundaryData()
                    this.setState({
                        viewflag: false,
                    })
                } else {
                    this.showScroll()
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                this.showScroll()
                $('#processing').hide()
                console.log('error while creating alert', error)
            })
            // console.log('speed sending data is', speedSaveData)            
        } else {
            // alert('enter tag name / address / speed')   
            this.setState({
                openAlert: true,
                errorMessage: 'Address Not Found'
            })
        }

        
    } else {
        this.setState({
            openAlert: true,
            errorMessage: 'Boundary Type Not Selected'
        })
    }
    }

    getVehicleDocuments = () => {
        var data = { owner_type: 1, serial_number: this.state.serial_number_is }
        ApiUrlCall.getDetailDocuments(JSON.stringify(data)).then(response => {
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    documentData: response.data.data.doc_list
                })
            }
            // console.log('document data is >>>>>>>>>>>>>', response)
        }).catch(error => {
            console.log('error while fetching document data')
        })
    }

    saveSpeed = () => {
        $('#processing').show()
        if (this.state.speedValue) {
            const speedSaveData = { serial_number: this.state.serial_number_is, speed: this.state.speedValue }
            ApiUrlCall.createSpeedAlert(JSON.stringify(speedSaveData)).then(response => {
                // console.log('speeed cassandra data is', response)
                if (response.data.response_code === 200 & response.data.is_success === true) {
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: "Speed limit set successfully"
                    })
                } else {
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                $('#processing').hide()
                console.log('error while created speed alert', error)
            })
        }
    }

    newBoundary = () => {
        // var map = []
        window.dispatchEvent(new Event('resize'));
        $('#add_boundary').slideDown(200)
        // console.log(this.state.markerLat,'.......',this.state.markerLng,'.........',this.state.address_is)
        this.hideScroll()
        $('.vehicle_detail_parent').scrollTop()
        this.setState({
            viewflag: true,
        })
        // map.push(

        // )
        // this.setState({
        //     createMap : map
        // })
    }

    newRadiusChange = (e, value) => {
        // console.log('value is', value)
        this.setState({
            newRadius: value,
        })
    }

    mmiGetAddressApi = (e) => {
        var address = e.currentTarget.value
        // console.log('mmi get address', address)
        if (e.currentTarget.value.length > 4) {
            // console.log('mmi get address11111', address)
            ApiUrlCall.getAddressIQ(address).then(response => {
                if (response.status === 200) {
                    this.setState({
                        autoAddress: response.data.data.address_list
                    }, () => {
                        // console.log('address in state is', this.state.autoAddress)
                    })
                }
                $('#address_list').slideDown(200, "swing")
                // console.log('response of address is', response)
            }).catch(error => {
                console.log('error while fetching address from MMI', error)
            })
        } else {
            $('#address_list').fadeOut(200)
        }
    }
    getAddressData = (e) => {
        const { autoAddress } = this.state
        var indexVal = $(e.currentTarget).attr('name')
        // console.log('index value is', indexVal)
        this.setState({
            address_is: autoAddress[indexVal].display_name,
            markerLat: parseFloat(autoAddress[indexVal].lat),
            markerLng: parseFloat(autoAddress[indexVal].lon),
        }, () => {
            $('#search_address_val').val(this.state.address_is)
        })
        $('#address_list').fadeOut(200)
    }

    deleteBoundary = () => {
        const { applyBoundaryData } = this.state
        var count = applyBoundaryData.length
        if (applyBoundaryData.length > 0) {
            $('#processing').show()
            for (let i = 0; i < applyBoundaryData.length; i++) {
                const element = applyBoundaryData[i].name;
                var data = { serial_number: this.state.serial_number_is, name: element }
                //   console.log('data is', data)    
                ApiUrlCall.deleteBoundaryAlert(JSON.stringify(data)).then(response => {
                    // console.log('reponse is', response)
                    if (response.data.response_code === 200 && response.data.is_success) {

                    }
                }).catch(error => {
                    console.log('error while deleting boundary', error)
                })
            }
            //   console.log('.............................>calleds')
            setTimeout(() => {
                this.setState({
                    applyBoundaryData: [],
                    openAlert: true,
                    errorMessage: count + " Boundaries Deleted Successfully "
                }, () => {
                    $('#processing').hide()
                    $('.select_boundary').prop("checked", false)
                    this.getBoundaryData()
                })
            }, 2000);
        } else {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Select Boundary"
            })
        }
    }
    boundary_type =(e) => {

        //var vehicle_serival_number = $('#vehicle_').val()
        this.setState({
          boundary_type:e.currentTarget.value
          
        })
        //this.forceUpdate()
      }
    returnBoundaryType = () => {
  
        var boundary_array = [{"Name": "Default","Name1":'default'}, {"Name": "Region","Name1":'region'}]
         
       var boundary_type_list = boundary_array.map(boundary_type_list => {
           return (
             <option key={boundary_type_list.Name} value={boundary_type_list.Name1}>{boundary_type_list.Name}</option> 
           )
         })
         return (<Grid item xs={12}>              
         <select id={'report_'} value={this.state.boundary_type} className="vd_input" style={{ width: "100%" , marginBottom: "0px" ,color:"black"}}
             onChange={(e) => {
     
                 //console.log("e.currentTarget.value",e.currentTarget.value)
                 for (const option of document.getElementById('report_')) {
                   if (option.value === e.currentTarget.value)
                   {
                     option.selected = true
                   }
                   else{
                     option.selected = false                            
                   }
     
                 }
                 this.boundary_type(e)   
             }}
             >
             <option value={0}>Select</option> 
             {boundary_type_list}
         </select>
         </Grid> )
     }

     update_region_Speed = (dataObj_2) => {
        $('#processing').show()
        if (this.state.speed_region) {
            //const speed_region_SaveData = { serial_number: this.state.serial_number_is, speed: this.state.speed_region }
            ApiUrlCall.updateRegionSpeedAlert(JSON.stringify(dataObj_2)).then(response => {
                // console.log('speeed cassandra data is', response)
                if (response.data.response_code === 200 & response.data.is_success === true) {
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: "Region Speed limit set successfully"
                    })
                } else {
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                $('#processing').hide()
                console.log('error while created region speed alert', error)
            })
        }
    }
    
    save_region_Speed = (e) => {
        var index = $(e.currentTarget).attr('name')
        var tag = $('#tag_' + index).text()
        var dataObj_2 = { serial_number: this.state.serial_number_is, name: tag,speed_region:this.state.speed_region }
        console.log('data to send in update', dataObj_2)
        this.update_region_Speed(dataObj_2)
    }
    
    update_region_Stoppage = (dataObj_3) => {
        $('#processing').show()
        if (this.state.stoppage_region) {
            //const speed_region_SaveData = { serial_number: this.state.serial_number_is, speed: this.state.speed_region }
            ApiUrlCall.updateRegionStoppageAlert(JSON.stringify(dataObj_3)).then(response => {
                // console.log('speeed cassandra data is', response)
                if (response.data.response_code === 200 & response.data.is_success === true) {
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: "Region Stoppage time set successfully"
                    })
                } else {
                    $('#processing').hide()
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                $('#processing').hide()
                console.log('error while created region speed alert', error)
            })
        }
    }
    
    save_region_Stoppage = (e) => {
        var index = $(e.currentTarget).attr('name')
        var tag = $('#tag_' + index).text()
        var dataObj_3 = { serial_number: this.state.serial_number_is, name: tag,stoppage_region:this.state.stoppage_region }
        //console.log('data to send in update', dataObj_3)
        this.update_region_Stoppage(dataObj_3)
    }

    render() {
        const { t } = this.props
        var { autoAddress } = this.state
        var minDate = new Date();
        // minDate.setDate(minDate.getDate());
        var arrayOfStops = this.state.stopElement
        if (this.state.fuelStatsDetails) {
            var average_usage
            if (typeof this.state.fuelStatsDetails.avg_usage === 'string') {
                average_usage = 'NA'
            } else {
                average_usage = CommonFunction.zeroAppend(this.state.fuelStatsDetails.avg_usage)
            }
        }
        if (autoAddress) {
            var address_list = autoAddress.map((list, index) => {
                return <p className="search_address" name={index}
                    onClick={this.getAddressData}>{list.display_name}</p>
            })
        }
        if (this.state.stopElement) {
            var stopContent = arrayOfStops.map(stopContent => {
                return stopContent
            })
        }
        if (this.state.documentData) {
            if (this.state.documentData.length > 0) {
                var documents = this.state.documentData.map(doc => {
                    return (
                        <Grid item xs={6}>
                            <div className="margin_bot_20">
                                <a href={doc.url} target="_blank">
                                    <img src="images/group-6-copy-2.png" className="vd_icon" style={{ display: "inline", marginRight: "20px" }}></img>
                                </a>
                                <label className="cj_w_header" style={{ display: "inline", textTransform: "uppercase" }}>{doc.name}</label>
                            </div>
                        </Grid>
                    )
                })
            } else {
                var documents = (() => {
                    return (
                        <p className="cj_w_header">{t('dds.nodocument')}</p>
                    )
                })()
            }
        }
        var fuel_type = [t('ct.gas'), t('ct.diesel'), t('ct.petrol'), t('ct.cng'), t('ct.ethanol'), t('ct.bio')][this.state.vehicle_fuel_type]
        var single_joureny
        if (this.state.vehicle_journey && this.state.vehicle_journey.length > 0) {
            single_joureny = this.state.vehicle_journey.map(single_joureny => {
                return (
                    <tr key={single_joureny.id} id={single_joureny.serial_number}>

                        <td> {CommonFunction.tableAddressEllipsis(single_joureny.journey_detail.planned_start_loc, 7)} to {CommonFunction.tableAddressEllipsis(single_joureny.journey_detail.planned_end_loc, 7)}</td>
                        <td> {CommonFunction.tableDateFormate(single_joureny.started_at)} {CommonFunction.timeFormate(single_joureny.started_at)} </td>
                        <td> {CommonFunction.tableDateFormate(single_joureny.ended_at)} {CommonFunction.timeFormate(single_joureny.ended_at)} </td>
                        {/* <td>NA</td> */}
                        <td>Completed</td>
                    </tr>
                )
            })

        } else {
            single_joureny = <tr><td>{t('ct.nodata')}</td></tr>
            // console.log('in eles conditio', single_joureny)
        }

        if (this.state.modeConfigData) {
            const speed_id = 1
            const boundary_id = 52
            var speedData = this.state.modeConfigData.map(speed => {
                if (speed_id === speed.alert_id) {
                    if (speed !== undefined) {
                        return (
                            <div>
                                <p style={{ display: speed.state === 0 ? '' : 'none', color: 'red' }}>{t('vds.disable')}</p>
                                <input type="text" defaultValue={speed.val} placeholder={t('vds.enter.speed')} className="enter_speed"
                                    style={{ pointerEvents: speed.state === 0 ? 'none' : 'auto' }} onChange={(e) => {
                                        this.setState({
                                            speedValue: e.currentTarget.value
                                        }, () => {
                                            // console.log('speed value is', this.state.speedValue)
                                        })
                                    }}></input>&nbsp;&nbsp;<label>Kmph</label>
                            </div>
                        )
                    }
                }
            })
            // var boundarystate = this.state.modeConfigData.map(boundarystate => {
            //     if (boundary_id === boundarystate.alert_id) {
            //         return boundarystate.state
            //     } else {
            //         return 0
            //     }
            // })
            // console.log('boundary state is', boundarystate)
        }

        if (this.state.boundaryListData) {
            // console.log('boundary data is', this.state.boundaryListData.slice(-1)[0])
            // this.setState({
            // markerLat : parseInt(this.state.boundaryListData.slice(-1)[0].lat),
            // markerLng : parseInt(this.state.boundaryListData.slice(-1)[0].lng),
            // boundaryRadius : this.state.boundaryListData.slice(-1)[0].radius,
            // boundaryTagName : this.state.boundaryListData.slice(-1)[0].name,
            // boundaryAlertState : this.state.boundaryListData.slice(-1)[0].state
            // })
        }

        if (this.state.vehicleLiveData) {
            var liveTrack = (() => {
                return (
                    <Map
                        height={"100%"}
                        cluster={false}
                        markers={[
                            {
                                position: [this.state.vehicleLiveData.lat, this.state.vehicleLiveData.lng],
                                rotateMarker: true,
                                icon: L.icon({
                                    // iconUrl: (() => {
                                    //     switch (this.state.vehicleLiveData.vehicle_state) {
                                    //         case MOVING: case OVERSPEEDING:
                                    //             return 'images/vehicle_icon/Car moving.png'
                                    //             break;
                                    //         case IDLING:
                                    //             return 'images/vehicle_icon/Car idling.png'
                                    //             break;
                                    //         case PARKED:
                                    //             return 'images/vehicle_icon/Car parked.png'
                                    //             break;
                                    //         default:
                                    //             return 'images/vehicle_icon/Car lost connection.png'
                                    //             break;
                                    //     }
                                    // })(),
                                    iconUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveData.vehicle_type_id, 
                                        this.state.vehicleLiveData.vehicle_state,this.state.vehicleLiveData.tracker_state),
                                    iconRetinaUrl: '',
                                    iconSize: [40, 40],
                                    popupAnchor: [-3, -15]
                                }),
                                draggable: false,
                                //title: this.state.vehicleLiveData.address,
                                title: this.state.vehicleLiveData.name,
                                Address: this.state.vehicleLiveData.address,
                                Last_Reported_Time:CommonFunction.tableDateFormate(this.state.vehicleLiveData.last_reported_time)+', '+CommonFunction.timeFormate(this.state.vehicleLiveData.last_reported_time),
                                battery_percentage: this.state.vehicleLiveData.battery_percentage,
                                serial_number: this.state.serial_number_is,
                                latitude: this.state.vehicleLiveData.lat,
                                longitude:this.state.vehicleLiveData.lng,
                               //battery_percentage: 40,
                                onClick: () => { },
                                onDragend: () => { }
                            }
                        ]}
                        // ={[
                        //     {
                        //         lat : [this.state.vehicleLiveData.lat],
                        //         lng : [this.state.vehicleLiveData.lng]
                        //     } 
                        // ]}
                        center={[this.state.vehicleLiveData.lat, this.state.vehicleLiveData.lng]}
                    />
                )
            })()
        }

        if (this.state.markerObj && this.state.radiusObj) {
            var radiusMap = (() => {
                // if(this.state.markerLat && this.state.markerLng){
                return (
                    <Map

                        height={"100%"}
                        cluster={false}
                        markers={this.state.markerObj}
                        center={[this.state.markerLat, this.state.markerLng]}
                        circle={this.state.radiusObj}

                    />
                )
                // }
            })()
        }

        if (this.state.markerLat && this.state.markerLng) {
            var newBoundayMap = (() => {
                return (
                    <Map
                        height={"100%"}
                        cluster={false}
                        zoomControl={true}
                        markers={[
                            {
                                position: [this.state.markerLat, this.state.markerLng],
                                rotateMarker: false,
                                icon: L.icon({
                                    iconUrl: 'images/34x34.png',
                                    iconRetinaUrl: 'images/34x34.png',
                                    iconSize: [34, 34],
                                    popupAnchor: [-3, -15]
                                }),
                                title: this.state.address_is,
                                draggable: true,
                                onClick: () => { },
                                onDragend: (e) => {
                                    this.setState({
                                        markerLat: e.target._latlng.lat,
                                        markerLng: e.target._latlng.lng,
                                    }, () => {
                                        this.getLatLng(this.state.markerLat, this.state.markerLng)
                                    })
                                },
                                setViewFlag: this.state.viewflag,
                            }
                        ]}
                        center={[this.state.markerLat, this.state.markerLng]}
                        circle={[
                            {
                                position: [this.state.markerLat, this.state.markerLng],
                                radius: this.state.newRadius,
                            }
                        ]}
                    />
                )
            })()
        }

        return (
            <div>
                <Navbar />
                <div className="vehicle_detail_parent">
                    <div>
                        <h3 className="page_header">
                            <img src="images/arrow11.png" className="car-icon vd_icon_1" class="fa fa-long-arrow-left" aria-hidden="true" onClick={this.goBack}></img>
                            &nbsp;&nbsp;{t('vds.header')}
                        </h3>
                    </div>

                    <div className="vehicle_parent">
                        <Grid container>
                            <Grid item xs={9}>
                                <div>
                                    <div className="inline-block" style={{ marginRight: "3em" }}>
                                        <img src="images/vehicle-1.png" className="car-icon"></img>
                                        {/* <p style={{ color: '#28a745' }}>Available <i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i></p> */}
                                    </div>
                                    <div className="inline-block">
                                        <h3 style={{ marginBottom: "20px" }} className="journey_item_vehicle">{this.state.vehicle_name}</h3>
                                        <p className="cj_w_header">{this.state.vehicle_make} / {this.state.vehicle_model}</p>
                                    </div>
                                </div>
                                <p style={{ fontSize: "12px", color: "#dc3545", marginBottom: "0px", marginTop: "5px", display: "none" }}
                                    id="boundary_message">{t('vds.long')}*</p>
                            </Grid>
                            <Grid container item xs={3} style={{ display: this.state.enterAlert ? 'none' : '' }}>
                                {/* <Grid item xs={6} style={{ borderRight: "1px solid #cccccc" }}> */}
                                <Grid container item xs={12} onClick={this.getVehilceLiveTrack}>
                                    <Grid container item xs={2} className="pointer">
                                        <img src="images/group12.png " className="vd_icon"></img>
                                    </Grid>
                                    <Grid container item xs={10}>
                                        <label className="cj_w_header pointer">{t('vds.view.loc')}</label>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} onClick={this.alertParameter}>
                                    <Grid container item xs={2} className="pointer">
                                        <img src="images/group-1655.png" className="vd_icon"></img>
                                    </Grid>
                                    <Grid container item xs={10}>
                                        <label className="cj_w_header pointer">{t('vds.define')}</label>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} onClick={() => this.editVehicleDetails(this.state.serial_number_is)}>
                                    <Grid container item xs={2} className="pointer">
                                        <img src="images/shape.png" className="vd_icon"></img>
                                    </Grid>
                                    <Grid container item xs={10}>
                                        <label className="cj_w_header pointer">{t('vds.edit')}</label>
                                    </Grid>
                                </Grid>
                                {/* <div className="margin_bot_20 pointer" >
                                    </div>
                                    <div className="margin_bot_20 pointer" >
                                        
                                        
                                    </div>
                                    <div className="margin_bot_20 pointer">
                                        
                                        
                                    </div> */}
                                {/* </Grid> */}
                                {/* <Grid item xs={6}>
                                    <button className="btn orange-btn vl_assign">Assign</button>
                                </Grid> */}
                            </Grid>
                            <Grid container item xs={3} style={{ display: this.state.enterAlert ? '' : 'none', textAlign: "right" }}>
                                <div style={{ width: "inherit" }}>
                                    <button className="btn orange-btn" style={{
                                        float: "none", whiteSpace: "normal",
                                        wordWrap: "break-word", background: "-webkit-linear-gradient(#dc3545, #a8101e)"
                                    }}
                                        onClick={this.deleteBoundary}>{t('vds.delete')}</button><br />
                                    <button className=" btn-block orange-border" onClick={this.allVehicleAlert}
                                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{t('vds.apply')}</button>
                                </div>
                            </Grid>
                        </Grid>

                        <div style={{ display: this.state.enterAlert ? 'none' : '' }}>
                            <div id="vehicle_detail_div" style={{ display: "none" }}>
                                <hr className="custom-hr" />
                                <Grid container>
                                    <Grid container item xs="8" spacing={2}>
                                        <Grid container item xs={6} className="pt_pb partition">
                                            <Grid item xs={2} style={{ position: "relative" }}>
                                                <img src="images/group-84.png" className="car-icon vd_icon_1"></img>
                                            </Grid>
                                            <Grid item xs>
                                                <p className="p_big_grey p_margin0 grey-text">{this.state.registration_number}</p>
                                                <p className="p_margin0 cj_w_header">{t('vds.reg.no')}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} className="pt_pb">
                                            <Grid item xs={2} style={{ position: "relative" }}>
                                                <img src="images/petrol_icon.png" className="car-icon vd_icon_1"></img>
                                            </Grid>
                                            <Grid item xs>
                                                <p className="p_big_grey p_margin0 grey-text">{fuel_type}</p>
                                                <p className="p_margin0 cj_w_header">{t('vds.type')}</p>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container item xs={4} className="pt_pb">
                                            <Grid item xs={2} style={{ position: "relative" }}>
                                                <img src="images/fuel_level.png" className="car-icon vd_icon_1" width="25px"></img>
                                            </Grid>
                                            <Grid item xs>
                                                <p className="p_big_grey p_margin0 grey-text">{this.state.fuel_level} Liters</p>
                                                <p className="p_margin0 cj_w_header">{t('dds.current.fuel')}</p>
                                            </Grid>
                                        </Grid> */}
                                        <Grid container item xs={6} className="pt_pb partition">
                                            <Grid item xs={2} style={{ position: "relative" }}>
                                                <img src="images/petrol_icon.png" className="car-icon vd_icon_1"></img>
                                            </Grid>
                                            <Grid item xs>
                                                <p className="p_big_grey p_margin0 grey-text">{this.state.vehicle_vin}</p>
                                                <p className="p_margin0 cj_w_header">{t('vds.vin')}</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} className="pt_pb">
                                            <Grid item xs={2} style={{ position: "relative" }}>
                                                <img src="images/group-44.png" className="car-icon vd_icon_1"></img>
                                            </Grid>
                                            <Grid item xs>
                                                <p className="p_big_grey p_margin0 grey-text">{this.state.vehicle_fuel_capacity} Liters</p>
                                                <p className="p_margin0 cj_w_header">{t('vds.capacity')}</p>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container item xs={4} className="pt_pb">
                                            <Grid item xs={2} style={{ position: "relative" }}>
                                                <img src="images/last_fuel.png" className="car-icon vd_icon_1" width="25px"></img>
                                            </Grid>
                                            <Grid item xs>
                                                <p className="p_big_grey p_margin0 grey-text">{CommonFunction.dateFormate(this.state.last_refill)}</p>
                                                <p className="p_margin0 cj_w_header">{t('dds.last.fuel')}</p>
                                            </Grid>
                                        </Grid> */}
                                        <Grid container item xs={12}>
                                            <Grid item xs={12}>
                                                <p className="p_header">{t('jos.document')}</p>
                                            </Grid>
                                            {documents}
                                            {/* <Grid item xs={6}>
                                                <div className="margin_bot_20">
                                                    <img src="images/group-6-copy-2.png" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                                    <label className="cj_w_header" style={{display : "inline"}}>Loan</label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="margin_bot_20">
                                                    <img src="images/group-6-copy-2.png" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                                    <label className="cj_w_header" style={{display : "inline"}}>Insurance</label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="margin_bot_20">
                                                    <img src="images/group-6-copy-2.png" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                                    <label className="cj_w_header" style={{display : "inline"}}>Permit Name</label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="margin_bot_20">
                                                    <img src="images/group-6-copy-2.png" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                                    <label className="cj_w_header" style={{display : "inline"}}>PUC</label>
                                                </div>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs="4" style={{ position: "relative" }}>
                                        <div style={{ height: "100%" }}>
                                            {liveTrack}
                                        </div>
                                        <div className="map_loading">
                                            <CircularProgress className="map_loading_icon" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                        <div id="vehicle_alert_div" style={{ display: this.state.enterAlert ? '' : 'none' }}>
                            <hr className="custom-hr" />
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <p className="p_header">{t('vds.bound')} : {CommonFunction.zeroAppend(this.state.boundaryLength)}</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <button className="btn orange-btn"
                                                style={{ marginTop: "-5px", paddingTop: "5px", paddingBottom: "5px" }}
                                                onClick={this.newBoundary}>{t('vds.create.bound')}</button>
                                        </Grid>
                                    </Grid>
                                    <div className="boundary_map_view">
                                        {/* {this.renderMap()} */}
                                        {radiusMap}

                                    </div>
                                </Grid>
                            </Grid>
                            <div style={{ maxHeight: "30em", overflow: "auto", paddingTop: "1.5em" }}>
                                {this.state.boundariesContent}
                            </div>
                            <hr className="custom-hr" />
                            <Grid container>
                                <Grid item xs={4} className="padding20">
                                    <div style={{ borderRight: "1px solid #cccccc" }}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <p className="p_header">{t('vds.header4')}</p>
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                                <img src="images/boundary_done.png" alt="icon" style={{ marginTop: "-10px" }}
                                                    onClick={this.saveSpeed}></img>
                                            </Grid>
                                        </Grid>
                                        <p className="cj_w_header" style={{ fontSize: "12px" }}>{t('vds.speed.info')}</p>
                                        <p className="cj_w_header">{t('vds.vh.speed')}</p>
                                        {speedData}
                                    </div>
                                </Grid>
                                {/* <Grid item xs={4} className="padding20">
                                    <div>
                                        <p className="p_header">idling alert</p>
                                        <h6>Define the minimum idling of the vehicle</h6>
                                        <p>Idling value</p>
                                        <input type="text" placeholder="Enter Idling value" className="enter_speed"></input>
                                        &nbsp;&nbsp;<label>minutes</label>
                                    </div>
                                </Grid> */}
                            </Grid>
                        </div>

                        <div className="vd_open_close" style={{ display: this.state.enterAlert ? 'none' : '' }}>
                            <div class="small_button" onClick={this.showDetails}>
                                <i class="fa fa-angle-up del_info_icon" aria-hidden="true" style={{ display: "none" }}></i>
                                <i class="fa fa-angle-down del_info_icon" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>

                    <div className="vehicle_stats">
                        <p className="p_header">{t('vds.stats')}</p>
                        <hr className="custom-hr" />
                        <Grid container spacing={1}>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/dashboard.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{this.state.driverstatic.over_all_kms_driven}</p>
                                    <p className="p_margin0">{t('dds.km')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/dashboard.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{CommonFunction.zeroAppend(this.state.driverstatic.total_hours_driven)}</p>
                                    <p className="p_margin0">{t('dds.hrs')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/group-1115.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{CommonFunction.zeroAppend(this.state.driverstatic.total_no_of_journeys)}</p>
                                    <p className="p_margin0">{t('dds.journeys')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/group-1127.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{CommonFunction.zeroAppend(this.state.driverstatic.total_no_of_violations)}</p>
                                    <p className="p_margin0">{t('dds.violation')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/w_total_exp.png" className="car-icon vd_icon_1" width="35px"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{CommonFunction.rupeeFormat(this.state.driverstatic.total_expense)}</p>
                                    <p className="p_margin0">{t('vds.expense')}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="vehicle_stats" style={{ display: this.state.hardware.includes(18) ? "" : "none" }}>
                        <p className="p_header">{t('vds.fuel.details')}</p>
                        <hr className="custom-hr" />
                        <Grid container spacing={1}>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/dashboard.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{average_usage} Km/L</p>
                                    <p className="p_margin0">{t('vds.fuel.milage')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/dashboard.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{CommonFunction.zeroAppend(this.state.fuel_level)}</p>
                                    <p className="p_margin0">{t('dds.current.fuel')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/w_scheduled.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{CommonFunction.tableDateFormate(this.state.last_refill)}</p>
                                    <p className="p_margin0 cj_w_header">{t('dds.last.fuel')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/group-1127.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{this.state.fuelStatsDetails.last_refuel_level ? CommonFunction.zeroAppend(this.state.fuelStatsDetails.last_refuel_level) : "NA"} Litres</p>
                                    <p className="p_margin0">{t('dds.last.refuel')}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <hr className="custom-hr" />
                        <Grid container>
                            <Grid item xs={6} style={{ borderRight: "1px solid #cccccc" }}>
                                <p className="p_header">{t('dds.refill.alert')}</p>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{t('dds.refill.date')}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{CommonFunction.tableDateFormate(this.state.fuelStatsDetails.last_refuel_date) + " | " + CommonFunction.timeFormate(this.state.fuelStatsDetails.last_refuel_date)}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{t('dds.refill.location')}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{this.state.fuelStatsDetails.last_refuel_address ? this.state.fuelStatsDetails.last_refuel_address : "NA"}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{t('dds.refill.litre')}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{this.state.fuelStatsDetails.last_refuel_level ? CommonFunction.zeroAppend(this.state.fuelStatsDetails.last_refuel_level) : "NA"}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                                <p className="p_header">{t('dds.theft.alert')}</p>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{t('dds.theft.date')}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{CommonFunction.tableDateFormate(this.state.fuelStatsDetails.last_theft_date) + " | " + CommonFunction.timeFormate(this.state.fuelStatsDetails.last_theft_date)}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{t('dds.theft.location')}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{this.state.fuelStatsDetails.last_theft_address ? this.state.fuelStatsDetails.last_theft_address : "NA"}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{t('dds.theft.litre')}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className="cj_w_header">{this.state.fuelStatsDetails.last_theft_level ? CommonFunction.zeroAppend(this.state.fuelStatsDetails.last_theft_level) : "NA"}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="vehicle_stats" style={{ display: this.state.hardware.includes(18) ? "" : "none" }}>
                        <Grid container>
                            <Grid item xs>
                                <p className="p_header">{t('dds.fuel.stats')}</p>
                            </Grid>
                            <Grid item xs>
                                <select style={{ float: "right", position: "inherit" }} className="status-filter" defaultValue={this.state.fuelReportFilter}
                                    onChange={(e) => { this.setState({ fuelReportFilter: e.currentTarget.value, filterLoadingFlag: true }, () => { this.fuleStatsReportData() }) }}>
                                    <option value="1">{t('dds.dropdown.filter1')}</option>
                                    <option value="2">{t('dds.dropdown.filter2')}</option>
                                    <option value="3">{t('dds.dropdown.filter3')}</option>
                                    <option value="4">{t('dds.dropdown.filter4')}</option>
                                </select>
                            </Grid>
                        </Grid>
                        <hr className="custom-hr" />

                        <MDBDataTable
                            displayEntries={false}
                            searching={false}
                            paging={false}
                            scrollX
                            entries={10}
                            striped
                            bordered
                            data={{ columns: fuel1, rows: this.state.currentDateData }}
                        />
                        <div style={{ display: this.state.filterData.length > 0 ? "" : "none" }}>
                            <MDBDataTable
                                displayEntries={false}
                                width="100%"
                                searching={false}
                                paging={false}
                                scrollX
                                entries={10}
                                striped
                                bordered
                                data={{ columns: fuel2, rows: this.state.filterData }}
                            />
                        </div>
                    </div>

                    <div className="vd_journeys">
                        <div style={{ padding: "20px" }}>
                            <Grid spacing={4} container>
                                {/* <Grid item xs={2} style={{position:"relative"}}>
                        <p>Start Date</p>
                        <div className="vd_show_date" onClick={this.showCalendar}>
                            <label className="p_margin0">{this.state.date}</label>
                            <img src="images/fill-111.png" className="vd_journey_icon"></img>
                        </div>
                        <div style={{position : "absolute", display: "none"}}>
                         <Calendar
                      selectRange={false}
                      onChange={this.EnddateClicked}
                      value={this.state.date}
                      minDate={minDate}
                    /> 
                            <WebCalendar/> 
                        </div>
                    </Grid> */}
                                <Grid item xs={2}>
                                    <p className="cj_w_header">{t('ct.startdate')}</p>
                                    <div className="input_div" onClick={this.showStartDate}>
                                        <input type="text" placeholder={t('ct.startdate')} id="start_date" value={this.dateFormate(this.state.startDate)} className="journey_input"></input>
                                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                    </div>
                                    <div className="calendar_parent" id="start_date_cal">
                                        <Calendar
                                            selectRange={false}
                                            onChange={this.StartdateClicked}
                                            minDate={0}
                                            maxDate={minDate}
                                        />
                                    </div>
                                </Grid>
                                {/* <Grid item xs={2} style={{position:"relative"}}>
                        <p>End Date</p>
                        <div className="vd_show_date" onClick={this.showCalendar}>
                            <label className="p_margin0">{this.state.date}</label>
                            <img src="images/fill-111.png" className="vd_journey_icon"></img>
                        </div>
                        <div style={{position : "absolute", display: "none"}}>
                         <Calendar
                      selectRange={false}
                      onChange={this.EnddateClicked}
                      value={this.state.date}
                      minDate={minDate}
                    />
                            <WebCalendar/> 
                        </div>
                    </Grid> */}
                                <Grid item xs={2}>
                                    <p className="cj_w_header">{t('ct.enddate')}</p>
                                    <div className="input_div" onClick={this.showEndDate}>
                                        <input type="text" placeholder={t('ct.enddate')} id="end_date" value={this.dateFormate(this.state.endDate)} className="journey_input"></input>
                                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                    </div>
                                    <div className="calendar_parent" id="end_date_cal">
                                        <Calendar
                                            selectRange={false}
                                            onChange={this.EnddateClicked}
                                            minDate={0}
                                            maxDate={minDate}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <p>&nbsp;</p>
                                    <button className="btn orange-btn" style={{ marginTop: "0px", float: 'none' }}
                                        onClick={(e) => {
                                            this.getVehicleJourney()
                                        }}>{t('ct.generate')}</button>
                                </Grid>
                                <Grid item xs={4}>
                                    <p>&nbsp;</p>
                                    <p style={{ color: "red", display: this.state.showDateWarning ? '' : 'none' }}>{t('vds.warning1')}</p>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <div className="tableOuterDiv" style={{ width: "100%" }}>
                                <div className="tableParent" id="vehicle_journey_table">
                                    <Table responsive>
                                        <thead className="tableHeader">
                                            <tr>
                                                <th>{t('ct.table.h1')}</th>
                                                <th>{t('ct.table.h2')}</th>
                                                <th>{t('ct.table.h3')}</th>
                                                {/* <th>Driver Score</th> */}
                                                <th>{t('ct.table.h5')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tableBody">
                                            {single_joureny}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="add_boundary" className="modal_parent">
                    <div className="modal_body" style={{ width: "80%" }}>
                        <p className="modal_header">{t('vds.create.new')}</p>
                        <Grid container spacing={3}>
                        <Grid item xs={2}>
                                <p className="bs_regular">{t('Boundary Type')}</p>
                                    {this.returnBoundaryType()}
                            </Grid>
                            <Grid item xs style={{ display: (this.state.boundary_type === "region") ? "" : "none" }}>
                            <p className="bs_regular">{t('Stop Alert')}</p>
                                <input type="text" placeholder={t('duration')}
                                    className="vd_input" style={{ marginBottom: "0px" }} onChange={(e) => {
                                        this.setState({
                                            boundaryStopAlert: e.currentTarget.value
                                        })
                                    }}></input>
                             </Grid>
                             <Grid item xs style={{ display: (this.state.boundary_type === "region") ? "" : "none" }}>
                            <p className="bs_regular">{t('Speed Alert')}</p>
                                <input type="text" placeholder={t('speed')}
                                    className="vd_input" style={{ marginBottom: "0px" }} onChange={(e) => {
                                        this.setState({
                                            boundarySpeedAlert: e.currentTarget.value
                                        })
                                    }}></input>
                             </Grid>
                            <Grid item xs={2}>
                                <p className="bs_regular">{t('vds.header1')}</p>
                                <input type="text" placeholder={t('aeds.address')} autoComplete="off" defaultValue={this.state.address_is}
                                    className="vd_input" style={{ marginBottom: "0px" }}
                                    onKeyUp={this.mmiGetAddressApi} id="search_address_val"></input>
                                <div className="address_list suggesstion_dropdown" id="address_list">
                                    {address_list}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <p className="bs_regular">{t('vds.header2')}</p>
                                <input type="text" placeholder={t('vds.tag')}
                                    className="vd_input" style={{ marginBottom: "0px" }} onChange={(e) => {
                                        this.setState({
                                            boundaryTagName: e.currentTarget.value
                                        })
                                    }}></input>
                            </Grid>
                            <Grid item xs={2}>
                                <p className="bs_regular">{t('vds.header3')} : {this.state.newRadius}&nbsp;KM</p>
                                <Slider
                                    aria-labelledby="slider-image"
                                    min={0}
                                    max={100}
                                    step={0.5}
                                    valueLabelDisplay="auto"
                                    defaultValue={0}
                                    onChange={this.newRadiusChange}
                                />
                            </Grid>
                            <Grid item xs={2} style={{ marginTop: "15px" }}>
                                <button className="btn btn-block orange-btn" onClick={this.saveAlertSingle}>{t('ct.save')}</button>
                            </Grid>
                        </Grid>
                        <div style={{ width: "100%", height: "400px", marginTop: "20px" }}>
                            {newBoundayMap}
                        </div>
                        <div className="close_modal" onClick={() => {
                            $('#add_boundary').fadeOut("slow")
                            this.showScroll()
                        }}>
                            <i class="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <Snackbar
                    style={{ zIndex: "999999" }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1800}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(VehiclesDetails);
