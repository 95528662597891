import React, { Component} from 'react';
import $ from 'jquery';
import './vehicles1.scss'
import Table from 'react-bootstrap/Table';
import Grid from '@material-ui/core/Grid';
import ApiUrlCall from '../../API/apiurl';
// import Map from "mapmyindia-react";
import Map from '../../map/map'
//import Navbar from '../../Config/Navbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonFunction from '../../common_function'
import analytics from '../../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../../aws-exports';
import constant from '../../constant.json'
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { ProgressBar } from 'react-bootstrap';
import Digital from 'react-activity/lib/Digital';
import 'react-activity/lib/Digital/Digital.css';
import Calendar from 'react-calendar/dist/entry.nostyle'
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import Timekeeper from 'react-timekeeper';
import TreeView from "react-treeview";
import "react-treeview/react-treeview.css";

//import testfunction1 from "../map/map"

const data1 = [
  {
    label: 'VEHICLE NAME',
    field: 'vehicle_name',
    sort: 'asc',
    width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'FUEL TYPE',
    field: 'fuel_type',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'CURRENT FUEL LEVEL',
    field: 'current_fuel',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'VEHICLE NUMBER',
    field: 'reg_number',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'MAKE',
    field: 'make',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'MODEL',
    field: 'model',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'KMS Driven',
    field: 'distance_driven',
    sort: 'asc',
    width: 150,
  },
]


const L = window.L
const MOVING_ICON = 'images/vehicle_icon/Car moving.png'
const IDLING_ICON = 'images/vehicle_icon/Car idling.png'
const PARKED_ICON = 'images/vehicle_icon/Car parked.png'
const CONNECTION_LOST_ICON = 'images/vehicle_icon/Car lost connection.png'
var resetSliderFlag = true

class LiveTrack extends Component {
  counter = 0
  constructor(props) {
    super(props);
    this.mmiMapComponentRef = React.createRef()
    this.state = {
      vehicleData: '',
      searchVehicleData: [],
      showMap: false,
      showVehicleList: true,
      vehicleLiveTrack: '',
      markers: [],
      mapContent: '',
      mapIntialized: true,
      boundValueis: '',
      boundLat: [],
      boundLng: [],
      boundFlag: true,
      datatable: [],
      nextCount: 0,
      totalCount: '',
      buttonFlag: true,
      dataCollection: [],
      chunckValue: '',
      searchText: '',
      totalEntries: '',
      refreshDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      trackingSelect: 0,
      selectedVehicleName: '',
      selectedVehicleSerialNumber: '',
      setView: false,
      clusterMarker: true,
      points: '',
      polylineParam: '',
      lastPolylineLatLng: '',
      currentPolylineLatLng: '',
      vehicleStatus: 0,
      boundLat: [],
      boundLng: [],
      setIcon: true,
      live_or_replay: true,
      replayRouteData: '',
      slidedValue: 0,
      sliderDuration: 0,
      sliderMax: 0,
      playReplayRoute: true,
      openAlert: false,
      errorMessage: '',
      panningFlag: true,
      invalidShareNumber: false,
      shareNumber: '',
      replayDuration: '00:00',
      vehicleList : [],
      selectedVehicle: '',
      startTime : "12:00 am",
      sendStartTime : "00:00",
      showStartTime : false,
      endTime : "11:59 pm",
      sendEndTime : "23:59",
      showEndTime : false,
      endRR : false,
      replayIntervalSpeed : 1000,
      journeyStopList : [],
      vehicle_type_id : 1,
      all_vehicle_list : [],  
      nextCountFormap : 0,
      isEnd : false,
      isAllSelected: true,
      counter : 0,
      showLocation : false,
      hasLocationData : false,

    }
    this.getVehicleList = this.getVehicleList.bind(this)
  }

  updateChildMap = (array,isLastChunk) => {
    if (this.mmiMapComponentRef.current != null){
      this.mmiMapComponentRef.current.updateMarkers(array,isLastChunk,this.state.boundFlag)
    }     
  }

  componentDidMount() {
    analytics.logEvent('screen_view', {
      screen_name: "VehicleList"
    })
    analytics.logEvent('page_view', {
      page_title: "VehicleList",
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName,
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_VEHICLE_LIST' }
    });
    this.getVehicleList()
    this.getAllVehicleList()
    this.checkedUserHasLocationData()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.singleInterval)
  }


  buttonStatus = () => {
    const { nextCount, totalCount } = this.state
    // console.log(nextCount,'.................',totalCount)
    if (nextCount === totalCount) {
      // console.log('iffffffffff')      
      if (this.counter === 0) {
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      } else {
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    } else {
      // console.log('elseeeeeeee', this.counter)
      if (this.counter === 0) {
        // console.log('else 1111111111')
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')
        $('#dt-next').addClass('enabled-btn')
      } else {
        // console.log('else 22222222')
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')
      }
    }
  }

  showTimePicker = (e) => {
    $(e.currentTarget).next().slideToggle()
  }

  entriesData = () => {
    const { nextCount, totalCount, chunckValue } = this.state
    if (nextCount != totalCount) {
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start + '-' + end
      this.setState({
        totalEntries: entries_text
      })
    } else {
      if (nextCount <= 10) {
        if(nextCount == 0){ 
          this.setState({
            totalEntries : "0"
          })
        }else{
          var entries_text = '1'+'-'+totalCount
          this.setState({
            totalEntries : entries_text
          })
        }
      } else {
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if (module > 0) {
          start = nextCount - module + 1
          end = nextCount
          entries_text = start + '-' + end
        } else {
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start + '-' + end
        }
        this.setState({
          totalEntries: entries_text
        })
      }
    }
  }

  getFuelType = (type) => {
    const { t } = this.props
    // console.log(type, "fuel type is")
    var fuel_type = [t('ct.gas'), t('ct.diesel'), t('ct.petrol'), t('ct.cng'), t('ct.ethanol'), t('ct.bio')][type]
    return fuel_type
  }

  returnFuelLevel = (level) => {
    if(level){
      return CommonFunction.zeroAppend(level) + " Litres"
    }else{
      return "NA"
    }
  }

  createTableData = () => {
    const { vehicleData, datatable } = this.state
    var row = []
    // console.log('lenght is', vehicleData.length)
    if(this.state.showVehicleList){
      for (let i = 0; i < vehicleData.length; i++) {
        const element = vehicleData[i];
        var row_data = {
          vehicle_name: element.vehicle_name, fuel_type: this.getFuelType(element.fuel_type), reg_number: element.reg_number, make: element.make,
          model: element.model, distance_driven: element.distance_driven, 
          current_fuel: this.returnFuelLevel(element.fuel_level), 
        }
        datatable.push(row_data)
      }
      this.buttonStatus()
      this.entriesData()
      this.forceUpdate()
    }
    // this.intervalData()
  }

  emptyTable = () => {
    this.setState({
      datatable: []
    })
  }

  preferedDatavehicle = (data) => {
    const { selectedVehicle, vehicleData } = this.state
    // console.log('vehicle list', this.state.vehicleList)
    if(vehicleData.length > 0){
        var vehicle = ''
        var serial_number = []            
        for (let i = 0; i < vehicleData.length; i++) {
            const element = vehicleData[i];
            serial_number.push(parseInt(element.serial_number))
            vehicle.push(element.name)
        }
        this.setState({
            selectedVehicle : vehicle,
            prefer_vehicle : serial_number,
        })            
    }else{
        return
    }
    // console.log(prefer_vehicle,'selected vehicle is', selectedVehicle)


}

getAllVehicleList = () => {
  $('#processing').show()
  // return


    var data = { next_count: this.state.nextCount, search: this.state.searchText,start_time:'',end_time:'' }
  ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
    //console.log('vehicle list is', response)      
    this.emptyTable()
    if (response.data.response_code === 200 && response.data.is_success === true) {
      this.setState({
        vehicleData: response.data.data.vehicle_summary_list,
        searchVehicleData: response.data.data.vehicle_summary_list,
        nextCount: response.data.data.next_count,
        totalCount: response.data.data.total_count,
        chunckValue: response.data.data.size
      }, () => {
        this.createTableData()
      })
      $('#processing').hide()
    } else {
      $('#processing').hide()
    }
  }).catch(error => {
    $('#processing').hide()
    console.log(error, "error while fetching vehicle list");

  })
}

  getVehicleList = () => {
    $('#processing').show()
    // return
    if (this.state.showMap) {
      var data = { next_count: this.state.nextCount, search: this.state.searchText, search_column: "vehicle_name",start_time:'',end_time:'' }
    } else if (this.state.showVehicleList) {
      var data = { next_count: this.state.nextCount, search: this.state.searchText,start_time:'',end_time:'' }
    }
    ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
      // console.log('vehicle list is', response)      
      this.emptyTable()
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          vehicleData: response.data.data.vehicle_summary_list,
          searchVehicleData: response.data.data.vehicle_summary_list,
          nextCount: response.data.data.next_count,
          totalCount: response.data.data.total_count,
          chunckValue: response.data.data.size
        }, () => {
          this.createTableData()
        })
        $('#processing').hide()
      } else {
        $('#processing').hide()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching vehicle list");

    })
  }

  pageNextClick = () => {
    const { totalCount, nextCount, dataCollection, datatable } = this.state
    // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
    this.counter = this.counter + 1
    for (let i = 0; i < datatable.length; i++) {
      const element = datatable[i];
      dataCollection.push(element)
    }
    this.setState({
      datatable: []
    }, () => {
      this.getVehicleList()
    })
  }

  pagePreviousClick = () => {
    const { dataCollection, chunckValue, nextCount } = this.state
    var pageSize = nextCount % chunckValue
    if (pageSize === 0) {
      pageSize = chunckValue
    }
    // console.log('previous clicked', pageSize)    
    var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
    this.setState({
      datatable: last,
      nextCount: nextCount - pageSize
    }, () => {
      this.buttonStatus()
      this.entriesData()
    })
    this.counter = this.counter - 1
  }

  optimizationSearch = (value) => {
    setTimeout(() => {
      this.counter = 0
      if (value.length >= 3) {
        this.setState({
          datatable: [],
          dataCollection: [],
          totalCount: '',
          chunckValue: '',
          nextCount: 0,
          searchText: value
        }, () => {
          this.getVehicleList()
        })
      } else if (value.length === 0) {
        this.setState({
          datatable: [],
          dataCollection: [],
          totalCount: '',
          chunckValue: '',
          nextCount: 0,
          searchText: value
        }, () => {
          this.getVehicleList()
        })
      }
    }, 1000);
  }

  // boundMarkers = () => {    
  //   var latitudeArr = []
  //   var longitudeArr = []    
  //   this.state.markers.map(b => {
  //     if (b.position && Array.isArray(b.position)) {
  //       latitudeArr.push(b.position[0])
  //       longitudeArr.push(b.position[1])
  //     }
  //     this.setState({
  //       boundLat : latitudeArr,
  //       boundLng : longitudeArr
  //     },()=>{

  //     })
  //   })    
  // }

  vlListView = (e) => {
    $(e.currentTarget).addClass('selected_border')
    $(e.currentTarget).siblings().removeClass('selected_border')
    this.setState({
      showMap: false,
      showVehicleList: true,
      searchText: "",
      totalCount: 0,
      nextCount: 0,
      boundFlag: true,
      mapContent: "",
      trackingSelect: 0,
      clusterMarker: true,
    }, () => {
      /////clearInterval(this.interval)
      clearInterval(this.singleInterval)
      this.resetLiveTrack()
      // this.selectAllTracking()
      this.getVehicleList()
    })
  }

  setIntervalLiveData = () => {
    $('#processing').show()
    this.getVehicleMarker()
    //this.interval = setInterval(this.getVehicleMarker, 5000)
  }

  getVehicleMarker = () => {
    if(this.state.trackingSelect != 0){
       return
    }

    this.mounted = true
    ApiUrlCall.getAllVehicleLiveTrackingNew({next_count : this.state.nextCountFormap}).then(response => {
      //console.log('vehicle response is',response)     
      if (response.data.response_code === 200 && response.data.is_success) {
        $('#processing').hide()
        this.setState({
          vehicleLiveTrack: response.data.data.live_track,
          nextCountFormap : response.data.data.next_count,
          isEnd : response.data.data.is_end
        }, () => {  
           if (this.state.isEnd){
             this.onFeatchVehiclesCompletion()
          }else {
              this.getVehicleMarker()
          }
          this.drawMarker()
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching data is', error.response)
    })       
  }

  onFeatchVehiclesCompletion = () =>{
    setTimeout(function(){
      this.setState({
          nextCountFormap : 0,
          markers : [],
          isEnd : false,
          vehicleLiveTrack : []
        },() =>{
          this.getVehicleMarker()
        })
    }.bind(this),10000)     
  }

  getJourneyStopDetails = (serial_number , start_date , end_date , stop_type) =>{
    var journey_id = "" //this.props.location.state.journey_id
    ApiUrlCall.JourneyGetStops( JSON.stringify({ journey_id : journey_id , stop_type : stop_type , serial_number : serial_number , start_date : start_date , end_date : end_date }) ).then(
        response=>{
            if (response.data.response_code==200 && response.data.is_success){
                this.setState( {
                    journeyStopList: response.data.data.stop_list
                },()=>{
                    this.createStopsObj()
                } )

            }
        }
    ).catch(
        error=>{
            console.log("ERROR WHILE GETTING JOURNEY STOPS DETAILS" , error)
        }
    )
    
}

createStopsObj = () => {
    // console.log('create stops object', STOPDETAILS)
    const {journeyStopList} = this.state
    var array = []
       

    if (journeyStopList.length > 0) {
        for (let i = 0; i < journeyStopList.length; i++) {
            const element = journeyStopList[i];
            array.push(
                {
                    position: [element.lat, element.lng],
                    name: element.name,
                    images: element.pic_list
                }
            )
        }
        // console.log('stop array', array)
        this.setState({
            stopObj: array
        }, () => {
            // console.log('stops', this.state.stopObj)
        })
    }
    // this.forceUpdate()
} 

 
      getAddress1 = (objectvehicle,index) => {
        let addressLatLng = { lat:objectvehicle.lat, lng:objectvehicle.lng }
        ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
            // console.log('address is', response)
              //console.log('address is', response.data.data.address)
              objectvehicle["address"]=response.data.data.address
              let newvehicleListtrack =[...this.state.vehicleLiveTrack]
              newvehicleListtrack[index]["address"]=response.data.data.address
              //console.log("my print",newvehicleListtrack)
              //console.log("my print new",objectvehicle)
              //return response.data.data.address
              this.setState({
                vehicleLiveTrack:newvehicleListtrack
              })
                })
            } 
 

  updateMarker =() =>  {
    let timeduration = this.state.vehicleLiveTrack.length*300
    for (let i = 0; i < this.state.vehicleLiveTrack.length; i++) {
      this.getAddress1(this.state.vehicleLiveTrack[i],i)
    }
    setTimeout(function(){
      this.drawMarker()
    }.bind(this),timeduration)
  }


  drawMarker = () => {
    const array = []
    const icon = [MOVING_ICON, IDLING_ICON, PARKED_ICON, CONNECTION_LOST_ICON]
    if (this.state.vehicleLiveTrack) {
      for (let i = 0; i < this.state.vehicleLiveTrack.length; i++) {
        var data = { name: this.state.vehicleLiveTrack[i].name }
                array.push(
        {
            position: [this.state.vehicleLiveTrack[i].lat, this.state.vehicleLiveTrack[i].lng],
            rotateMarker: true,
            icon: L.icon({
              iconUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id, 
                this.state.vehicleLiveTrack[i].vehicle_state,this.state.vehicleLiveTrack[i].tracker_state),
              iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id, 
                  this.state.vehicleLiveTrack[i].vehicle_state,this.state.vehicleLiveTrack[i].tracker_state),
              iconSize: [40, 40],
              popupAnchor: [-3, -15]
            }),
            draggable: false,
            setViewFlag: this.state.setView,
            title: this.state.vehicleLiveTrack[i].name ,
            Address: this.state.vehicleLiveTrack[i].address,
            Last_Reported_Time:CommonFunction.tableDateFormate(this.state.vehicleLiveTrack[i].last_reported_time)+', '+CommonFunction.timeFormate(this.state.vehicleLiveTrack[i].last_reported_time),
            battery_percentage: this.state.vehicleLiveTrack[i].battery_percentage,
            serial_number: this.state.vehicleLiveTrack[i].serial_number,
            latitude: this.state.vehicleLiveTrack[i].lat,
            longitude:this.state.vehicleLiveTrack[i].lng,
            //  Battery_percentage: 20,
            popupData: data,
            vehicle_type_id : this.state.vehicleLiveTrack[i].vehicle_type_id,
            onClick: () => { },
            onDragend: () => { }
          }
        )
      }
    }
    this.setState({
        markers: array,
      }, () => {
          if(this.state.mapContent == ""){
            //console.log("Inside draw new map ******** ")
            this.returnMap123()
          }
          else{
            //console.log("Inside update new map ******** ")
            this.updateChildMap(array,this.state.isEnd)  
          }
      })  
  }

  returnMap123 = () => {
    const maps = []
    if (this.state.markers && this.state.trackingSelect == 0) {
      //console.log("IN RETURN MAP -- LIVE TRACK ", this.state.markers+"is LastChuck **** "+this.state.isEnd)
      if (this.state.markers.length > 0) {
        maps.push(
          <Map
            ref = {this.mmiMapComponentRef}
            height={"92%"}
            cluster={this.state.clusterMarker}
            markers={this.state.markers}
            stops={this.state.stopObj}
            pauseBound={this.state.boundFlag}
            isLastChunk={this.state.isEnd}
            showlocation={this.state.showLocation}
            onZoom={() => {
              this.setState({
                boundFlag: false
              })
            }}
          />
        )
      }
    } else {
      //console.log('replay route map pushed')
      maps.push(
        <Map
          height={"90%"}
          zoom={14}
          stops={this.state.stopObj}
          cluster={this.state.clusterMarker}
          isLastChunk = {this.state.isEnd}
          vehicleReplayRoute={true}
          // panningflag = {this.state.panningFlag}                     
          replayIntervalSpeed = {this.state.replayIntervalSpeed } 
          vehicle_type_id = { this.state.replayRouteData.vehicle_type_id }
          showlocation={false}
          replayroutedata={
            {
              replay: this.state.replayRouteData.replay_data,
              index: this.state.slidedValue,
              flag: this.state.playReplayRoute,
              sliderValue: this.slideValue,
              serial_number: this.state.selectedVehicleSerialNumber,
              show: this.state.live_or_replay,
              title: this.state.selectedVehicleName              
            }

          }
          polyline={
            {
              points: this.state.points,
              polylineParam: this.state.polylineParam,
              icon: this.state.setIcon,
              state: this.state.vehicleStatus,
              speed: 500,
              show: this.state.live_or_replay,
              serial_number: this.state.selectedVehicleSerialNumber,
              title: this.state.selectedVehicleName,
              vehicle_type_id : this.state.replayRouteData.vehicle_type_id
            }
          }
        />
      )

    }
    this.setState({
      mapContent: maps
    })
  }

  returnMap = () => {
    const maps = []
    if (this.state.markers && this.state.trackingSelect == 0) {
      //console.log("IN RETURN MAP -- LIVE TRACK ", this.state.markers)
      if (this.state.markers.length > 0) {
        maps.push(
          <Map
            height={"92%"}
            cluster={this.state.clusterMarker}
            markers={this.state.markers}
            stops={this.state.stopObj}
            isLastChunk={this.state.isEnd}
            showlocation={this.state.showLocation}
            // bounds = {[
            //   {
            //   lat : this.state.boundLat,
            //   lng: this.state.boundLng
            //   }
            // ]}
            pauseBound={this.state.boundFlag}
            onZoom={() => {
              this.setState({
                boundFlag: false
              })
            }}
            
          />
        )
      }
    } else {
      //console.log('Replay route map pushed')
      maps.push(
        <Map
          height={"90%"}
          zoom={14}
          stops={this.state.stopObj}
          cluster={this.state.clusterMarker}
          vehicleReplayRoute={true}
          isLastChunk={this.state.isEnd}
          showlocation={false}
          // panningflag = {this.state.panningFlag}                     
          replayIntervalSpeed = {this.state.replayIntervalSpeed } 
          vehicle_type_id = { this.state.replayRouteData.vehicle_type_id }
          replayroutedata={
            {
              replay: this.state.replayRouteData.replay_data,
              index: this.state.slidedValue,
              flag: this.state.playReplayRoute,
              sliderValue: this.slideValue,
              serial_number: this.state.selectedVehicleSerialNumber,
              show: this.state.live_or_replay,
              title: this.state.selectedVehicleName              
            }

          }
          polyline={
            {
              points: this.state.points,
              polylineParam: this.state.polylineParam,
              icon: this.state.setIcon,
              state: this.state.vehicleStatus,
              speed: 500,
              show: this.state.live_or_replay,
              serial_number: this.state.selectedVehicleSerialNumber,
              title: this.state.selectedVehicleName,
              vehicle_type_id : this.state.replayRouteData.vehicle_type_id
            }
          }
        />
      )

    }
    this.setState({
      mapContent: maps
    },() =>{
      //$('#processing').hide()
    })
    //console.log(this.state.mapContent.length,"length")
  }

  vlMapView = (e) => {
    e.preventDefault()
    if (this.state.showMap === false) {
      $(e.currentTarget).addClass('selected_border')
      $(e.currentTarget).siblings().removeClass('selected_border')
      this.setState({
        showMap: true,
        showVehicleList: false,
        searchVehicleData: [],
      }, () => {
        this.setIntervalLiveData()
        window.dispatchEvent(new Event('resize'));
      })
    }
  }

  uniqueVehicleId = (serial_number) => {
    this.props.history.push({
      pathname: '/vehicle_details',
      state: { serial_number: serial_number }
    })
  }

  VehicleStatus = (status) => {
    const AVAILABLE = 1;
    const ASSIGNED = 2;
    const SERVICING = 3
    const { t } = this.props
    if (status === AVAILABLE) {
      return (
        <p className="cj_w_header" style={{ color: "#28a745" }}>{t('dds.available')}</p>
      )
    } else if (status === ASSIGNED) {
      return (
        <p className="cj_w_header" style={{ color: "#ed7d28" }}>{t('dds.assigned')}</p>
      )
    } else if (status === SERVICING) {
      return (
        <p className="cj_w_header" style={{ color: "#227cd0" }}>{t('dds.servicing')}</p>
      )
    }
  }

  refreshButton = () => {
    var date = new Date()
    this.setState({
      refreshDate: date,
      nextCount: 0,
    }, () => {
      this.getVehicleList()
    })
  }

  resetVariable = () => {
    this.setState({
      selectedVehicleName: '',
      selectedVehicleSerialNumber: '',
      searchText: '',
    })
  }

  resetLiveTrack = () => {
    this.setState({
      points: '',
      polylineParam: '',
      lastPolylineLatLng: '',
      currentPolylineLatLng: '',
      vehicleStatus: 0,
      boundLat: [],
      boundLng: [],
      setIcon: true,
    })
  }

  resetReplayRoute = () => {
    this.setState({
      replayRouteData: '',
      slidedValue: 0,
      sliderDuration: 0,
      sliderMax: 0,
      playReplayRoute: true,
      startDate: new Date(),
      endDate: new Date()
    })
  }

  selectAllTracking = () => {
    if (this.state.trackingSelect == 0) {
      //console.log("selectAllTracking")
      return
    }
    this.setState({
      trackingSelect: 0,
      setView: false,
      clusterMarker: true,
      mapContent: '',
      boundFlag: true,
      nextCount: 0,
      searchText: '',
      totalCount: 0,
    }, () => {
      clearInterval(this.singleInterval)
      this.setIntervalLiveData()
      // this.resetVariable()
      // this.resetLiveTrack()
      // console.log("CALLING RESET REPLAY 1\n")
      // this.resetReplayRoute()
      // this.getVehicleList()
    })
  }

  selectLiveTracking = () => {
    if (this.state.trackingSelect == 1) {
      return
    }
    const { vehicleData } = this.state
    // console.log('vehicle data is', vehicleData)
    var serial_number = vehicleData[0].serial_number
    var start_date = ""
    var end_date = ""
    var stop_type = 2
    this.getJourneyStopDetails(serial_number , start_date , end_date , stop_type )
    this.setState({
      trackingSelect: 1,
      live_or_replay: true,
      selectedVehicleSerialNumber: vehicleData[0].serial_number,
      selectedVehicleName: vehicleData[0].vehicle_name,
      startDate: new Date(),
      endDate: new Date()
    }, () => {
      clearInterval(this.interval)
      //console.log("CALLING RESET REPLAY 2--\n")
      this.resetReplayRoute()
      this.generateTracking()
    })
  }

  selectReplayRoute = () => {
    if (this.state.trackingSelect == 2) {
      return
    }
    const { vehicleData } = this.state
    this.setState({
      trackingSelect: 2,
      live_or_replay: false,
      clusterMarker: false,
      selectedVehicleSerialNumber: vehicleData[0].serial_number,
      selectedVehicleName: vehicleData[0].vehicle_name,
      mapContent: ''
    }, () => {
      this.generateTracking()
    })
  }

  
  searchVehicle = (e) => {
    var vehicle = e.currentTarget.value
    if (vehicle.length > 3 ) {
      this.setState({
        nextCount: 0,
        searchText: vehicle
      }, () => {
        $('#s_vehicle_list').slideDown()
        this.getVehicleList()
      })
    } else {
      $('#s_vehicle_list').slideUp()
    }
  }

  getCustomerVehicleList = () => {
    var local_list = localStorage.getItem('tracker_list')
    if (local_list == null ){
      var data = {}
      ApiUrlCall.getVehcile_Name(data).then(response => {
        if (response.data.response_code == 200 && response.data.is_success) {
          var list = response.data.data.vehicle_list
          localStorage.setItem("tracker_list", JSON.stringify(list) )
          this.setState({
            all_vehicle_list : list
          })
        }
      })
    } else{
      local_list = JSON.parse(localStorage.getItem('tracker_list'))
      this.setState({
        all_vehicle_list : local_list
      })
      
    } 

  }



  displayAllVehicles = (e) => {
    var vehicle = e.currentTarget.value
    //console.log(" ON CLICKD " , vehicle )
    if (vehicle.length == 0 ){
      this.setState({
        nextCount: 0,
        searchText: vehicle
      }, () => {
        $('#s_vehicle_list').slideDown()        
        this.getCustomerVehicleList()
      })
    }
    
    else {
      $('#s_vehicle_list').slideUp()
    }
  }

  selectVehicleTracking = (e) => {
    var serial_number = $(e.currentTarget).prop('id')
    var vehicle_name = $(e.currentTarget).text()
    this.setState({
      selectedVehicleName: vehicle_name,
      selectedVehicleSerialNumber: serial_number,
      setView: true,
      clusterMarker: false,
    }, () => {
      this.resetLiveTrack()
      $('#s_vehicle_list').slideUp()
      $('#search_input').val("")
    })
  }

  getSingleTrackerLiveTracking = () => {
    const serial_number = JSON.stringify({ serial_number: this.state.selectedVehicleSerialNumber })
    let polylineLast = this.state.lastPolylineLatLng
    let polylineCurrent = this.state.currentPolylineLatLng
    ApiUrlCall.getSingleVehicleLiveTrack(serial_number).then(response => {
      // console.log('single live tracking data', response)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        if (polylineLast) {
          $('#processing').hide()
          // console.log('qqqqqqqqqqqq')
          this.setState({
            lastPolylineLatLng: polylineCurrent,
            currentPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
            setIcon: false,
            boundLat: [...this.state.boundLat, response.data.data.lat],
            boundLng: [...this.state.boundLng, response.data.data.lng],
            vehicleStatus: response.data.data.vehicle_state,
          }, () => {
            this.liveTrack()
          })
        } else {
          $('#processing').hide()
          // console.log('>>>>>>>>>>>>>>>>')
          this.setState({
            vehicleStatus: response.data.data.vehicle_state,
            currentPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
            lastPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
            setIcon: true,            
          }, () => {
            this.liveTrack()
          })
        }

      }
    }).catch(error => {
      $('#processing').hide()
      clearInterval(this.singleInterval)
      console.log("error while fetching single tracking data", error)
    })
  }

  liveTrack = () => {
    // console.log('11111111111')  
    if (this.state.lastPolylineLatLng && this.state.currentPolylineLatLng) {
      // console.log('222222222222222')
      var oldlat = this.state.lastPolylineLatLng.lat
      var oldlng = this.state.lastPolylineLatLng.lng
      var newlat = this.state.currentPolylineLatLng.lat
      var newlng = this.state.currentPolylineLatLng.lng
      var pts = [{ "oldlat": oldlat, "oldlng": oldlng }, { "newlat": newlat, "newlng": newlng }]
      var oldLatLng = new L.LatLng(oldlat, oldlng)
      var newLatLng = new L.LatLng(newlat, newlng);

      var polylineParam =
      {
        weight: 2, // The thickness of the polyline               
      };
      this.setState({
        points: pts,
        polylineParam: polylineParam,
        vehicleStatus: this.state.vehicleStatus,
      }, () => {
        this.returnMap()
        // console.log('points value is', this.state.points)
      })
    }
  }

  getReplayRouteDuration = () => {
    const { replayRouteData } = this.state
    var start = replayRouteData.replay_data[0].datetime
    var end = replayRouteData.replay_data.slice(-1)[0].datetime
    var start_time = new Date(start)
    var end_time = new Date(end)
    var diffsecond = end_time - start_time
    var seconds = diffsecond / 1000
    var minutes = seconds / 60
    var hours = minutes / 60
    if(hours !== undefined){
      this.setState({
        replayDuration : hours.toFixed(2)
      })      
    }

  }

  getReplayRouteData = () => {
    // console.log('get replay route data')
    
    var start_time = CommonFunction.createServerDateTime(this.state.startDate,this.state.sendStartTime)
    var end_time = CommonFunction.createServerDateTime(this.state.endDate, this.state.sendEndTime)
    
    var urlData = {
      serial_number: this.state.selectedVehicleSerialNumber, start_date: start_time ,
      end_date: end_time
    }
    $('#processing').show()
    // console.log(this.state.live_or_replay,'>>>>>>>>>>>>>>>>>>>>>>>>>>> selected replay route vehicle', urlData)
    // var url_Json_data = JSON.stringify(url_Json_data)
    ApiUrlCall.getReplayRoute(JSON.stringify(urlData)).then(response => {
      //console.log(this.state.slidedValue,'response of replay route is', response)        
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          replayRouteData: response.data.data,
          slidedValue: 0,
          playReplayRoute: true,
          sliderMax: response.data.data.replay_data.length,
        }, () => {
          $('#processing').hide()
          //console.log("IN API RESPONSE ")
          this.returnMap()
          //console.log("MAP RETURNED ")
          this.getReplayRouteDuration()
          //console.log("REPLAY DURATION CALLED ")
        })
      }
      else {
        $('#processing').hide()
        this.returnMap()
        this.setState({
          openAlert: true,
          errorMessage: response.data.response_message,
        })
      }
    }).catch(error => {
      console.log('error while fetching replay route data is', error)
    })
  }


  generateTracking = () => {
    const { trackingSelect } = this.state
    this.setState({
      mapContent: '',
      replayDuration : '00:00'
    })
    if (trackingSelect == 1) {
      //console.log("GENERATE TRACKING 1 CALLED ")
      $('#processing').show()
      clearInterval(this.singleInterval)
      var stop_type = 2
      var serial_number = this.state.selectedVehicleSerialNumber
      var start_date = ""
      var end_date = ""
      this.getSingleTrackerLiveTracking()
      this.singleInterval = setInterval(this.getSingleTrackerLiveTracking, 10000)
      this.getJourneyStopDetails( serial_number , start_date , end_date , stop_type  )
    } else if (trackingSelect == 2) {
      //console.log("GENERATE TRACKING 2 CALLED ")
      $('#processing').show()
      var stop_type = 3
      // console.log('genereate replay route')
      var serial_number = this.state.selectedVehicleSerialNumber
      var start_date = CommonFunction.ServerDateTime(this.state.startDate.setHours(0, 0, 0, 0))
      var end_date = CommonFunction.ServerDateTime(this.state.endDate)
      this.getJourneyStopDetails( serial_number , start_date , end_date , stop_type  )
      clearInterval(this.interval)
      clearInterval(this.singleInterval)
      this.resetLiveTrack()
      this.getReplayRouteData()
      if ( $("#start_tracking_btn").html()=="Play 1x" ){
        $("#start_tracking_btn").html("Play 3x")
        this.setState({
          replayIntervalSpeed : 1000

        }) 
      }
      else if( $("#start_tracking_btn").html() == "Play 3x"){
        $("#start_tracking_btn").html("Play 1x")
        this.setState({
          replayIntervalSpeed : 300

        })
      } 
    }
  }

  playRr = (e) => {
    $(e.currentTarget).hide()
    $(e.currentTarget).siblings().show()
    // if (this.state.endRR ){
    //   this.setState({
    //     slidedValue : 0
    //   })
    // }
    this.setState({
      playReplayRoute: true,
    }, () => {
      this.returnMap()
    })
  }

  pauseRr = (e) => {
    $(e.currentTarget).hide()
    $(e.currentTarget).siblings().show()
    //console.log("PAUSE CLICKED ")
    this.setState({
      playReplayRoute: false,
    }, () => {
      this.returnMap()
    })
  }

  slideValue = (value, endReplay) => {
    if (!endReplay) {
      this.setState({
        slidedValue: value
      })
    } else {
      //console.log(" IN ELSE SLIDE VAL ")
      //$('#pause_rr').hide()
      //$("#play_rr").show()
      // this.setState({
      //   endRR : true 
      // })


      // this.setState({
      //   slidedValue: 0,
      // }, () => {
      //   $('#pause_rr').click()
      //   this.setState({
      //     playReplayRoute: false,
      //   })
      // })
    }
  }

  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  StartdateClicked = date => {
    date.setHours(0, 0, 0, 0);
    this.setState({ startDate: date })
    $('#start_date_cal').slideUp(200)
    $('#end_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  EnddateClicked = date => {
    date.setHours(23, 59, 59, 0);
    this.setState({ endDate: date })
    $('#end_date_cal').slideUp(200)
    $('#start_date_cal').slideUp(200)
  }

  togglePanning = (e) => {
    const { panningFlag } = this.state
    var panningState = $(e.currentTarget).children()
    $(panningState).toggle()
    if (panningFlag) {
      this.setState({
        panningFlag: false
      }, () => {
        // console.log('panning flag', this.state.panningFlag)    
        this.returnMap()
      })
    } else {
      this.setState({
        panningFlag: true
      }, () => {
        // console.log('panning flag', this.state.panningFlag)    
        this.returnMap()
      })
    }
  }

  shareMobileNumber = (e) => {
    var mobile_number = $(e.currentTarget).val()
    if (mobile_number.length !== 10) {
      this.setState({
        invalidShareNumber: false
      })
    } else {
      this.setState({
        invalidShareNumber: true,
        shareNumber: mobile_number
      })
    }
  }

  shareTracking = () => {
    const { invalidShareNumber, shareNumber, trackingSelect, selectedVehicleSerialNumber,
      startDate, endDate } = this.state
    var sendMessage
    if (invalidShareNumber) {
      if (trackingSelect == 1) {
        var data = { serial_number: selectedVehicleSerialNumber, mobile_number: shareNumber, sharing_type: trackingSelect, expiry_minutes: "30" }
        sendMessage = "Live Track Shared Successfully"
      } else if (trackingSelect == 2) {
        var data = {
          serial_number: selectedVehicleSerialNumber, start_date: CommonFunction.ServerDateTime(startDate),
          end_date: CommonFunction.ServerDateTime(endDate), mobile_number: shareNumber, sharing_type: trackingSelect, expiry_minutes: "30"
        }
        sendMessage = "Replay Route Shared Successfully"
      }
      ApiUrlCall.shareTracking(JSON.stringify(data)).then(response => {
        //console.log('share response is', response)
        if (response.data.response_code == 200 && response.data.is_success) {
          this.setState({
            shareNumber: '',
            openAlert: true,
            errorMessage: sendMessage
          }, () => {
            $('#share_number_input').val("")
          })
        }
      }).catch(error => {
        console.log('error while sharing Live Track or Replay Route', error)
      })
    } else {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Enter 10 digit mobile number"
      })
    }
  }




  selectStartTime = (newTime) => { 
    //console.log(" SET START TIME ", this.state.sendStartTime)  
    this.setState({
      startTime : newTime.formatted12,
      sendStartTime : newTime.formatted24
    })
  }

  showStartTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if(this.state.showStartTime){
      this.setState({
        showStartTime : false,
        showEndTime : false
      })
    }else{      
      this.setState({
        showStartTime : true,
        showEndTime : false
      })
    }
  }

  selectEndTime = (newTime) => {    
    this.setState({
      endTime : newTime.formatted12,
      sendEndTime : newTime.formatted24
    })
  }

  showEndTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if(this.state.showEndTime){
      this.setState({
        showEndTime : false,
        showStartTime : false
      })
    }else{
      this.setState({
        showEndTime : true,
        showStartTime : false
      })
    }
  }

  /////////////////////////////Grouping///////////////////////////



  show_Location = () =>{
    $('#processing').show()
    this.setState({
      showLocation : true ,
      markers : [],
    },() =>{
      this.returnMap()
    })
     
  }

  checkedUserHasLocationData = () =>{
    ApiUrlCall.checkUserHasLocationData().then(response => {
      if (response.data.response_code === 200 && response.data.is_success) {
        this.setState({
          hasLocationData : response.data.has_data
        },() =>{
          if(this.state.hasLocationData){
            $("#show_Location_btn").show()
          }else{
            $("#show_Location_btn").hide()
          }
        })
      
      }
    })
  }



  ///////////////////////////////////////////////////////////////

  render() {
    const { t } = this.props
    const { vehicleList , selectedVehicle } = this.state
    var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);
    var modalMinDate = new Date();
    var { all_vehicle_list, mapContent } = this.state

    //console.log("render method of vehicle " + ApiUrlCall.getVehicleIcon(1,3))


    if (all_vehicle_list) {
      if (all_vehicle_list.length > 0) {
        var search_list = all_vehicle_list.map(data => {
          return <p id={data.serial_number} class= "vehicle" onClick={this.selectVehicleTracking}>{data.name}</p>
        })
      }
    }
    if(selectedVehicle) {
      var vehicle_list = selectedVehicle
  }
    if (mapContent) {
      var map_data = mapContent.map(data => {
        return data
      })
    }

    if (this.state.replayRouteData && this.state.slidedValue !== undefined) {
      if (this.state.slidedValue !== this.state.sliderMax) {
        var speed = this.state.replayRouteData.replay_data[this.state.slidedValue].speed
        var distancDriven = this.state.replayRouteData.replay_data[this.state.slidedValue].distance_driven
        var time = this.state.replayRouteData.replay_data[this.state.slidedValue].datetime
      }
      // else {
      //   this.setState({
      //     slidedValue: 0,
      //   }, () => {
      //     $('#pause_rr').click()
      //     this.setState({
      //       playReplayRoute: false,
      //     })
      //   })
      // }
    }


    return (
      <div>
        {/* <Navbar /> */}
        <div className="vehicle_list">
          <div>
            <div className="vehicle_list_view">
              <div onClick={this.vlListView} className="selected_border">
                <p className="work_sans_regular16">{t('vls.list')}</p>
              </div>
              <div onClick={this.vlMapView}>
                <p className="work_sans_regular16">{t('vls.map')}</p>
              </div>
              
            </div>
            {/*list view vehicle*/}
            <div style={{ display: this.state.showVehicleList ? '' : 'none' }}>
              <div style={{ position: "absolute" }}>
                <button className="btn orange-btn" style={{ float: "none", marginTop: "-22px" }} onClick={this.refreshButton}>Refresh</button>
                <div style={{ display: "inline-block", marginLeft: "10px" }}>
                  <p className="p_header" style={{ marginBottom: "0px" }}>{CommonFunction.tableDateFormate(this.state.refreshDate) + " " + CommonFunction.timeFormate(this.state.refreshDate)}</p>
                  <p style={{ fontSize: "13px" }}>Last Updated On</p>
                </div>
              </div>
              <MDBDataTable
                displayEntries={false}
                paging={false}
                scrollX
                entries={10}
                displayEntries={false}
                striped
                bordered
                data={{ columns: data1, rows: this.state.datatable }}
                onSearch={this.optimizationSearch}
              />
              <Grid container style={{ margin: "20px 0px" }}>
                <Grid item xs>
                  <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                </Grid>
                <Grid item xs style={{ textAlign: "right" }}>
                  <button className="btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                  <button className="btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                </Grid>
              </Grid>
            </div>
            {/* vehicle list view end */}
            {/* Map view vehicle list */}
            <div style={{ display: this.state.showMap ? '' : 'none', position: "relative", height: "650px" }}>
              <div id="map_div" className="map_view_div">
                <Grid container spacing={2}>
                  <Grid item xs>
                    <div class="grid-container-vehicle">
                      <div class="grid-item-vehicle" onClick={this.selectAllTracking}>
                        <p className={this.state.trackingSelect == 0 ? "select-tracking" : ""}>ALL</p>
                      </div>
                      <div class="grid-item-vehicle" onClick={this.selectLiveTracking}>
                        <p className={this.state.trackingSelect == 1 ? "select-tracking" : ""}>Live Track</p>
                      </div>
                      <div class="grid-item-vehicle" onClick={this.selectReplayRoute}>
                        <p className={this.state.trackingSelect == 2 ? "select-tracking" : ""}>Replay Route</p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
                    {/* <p className="cj_w_header"><b>Start Date</b></p> */}
                    <div className="input_div" onClick={this.showStartDate}>
                      <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>
                      
                      <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="start_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.StartdateClicked}
                        value={this.state.startDate}
                        maxDate={this.state.refreshDate}
                        minDate={minDate}
                      />
                    </div>
                  </Grid>
                  <Grid item xs style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
              
                  <div onClick={this.showStartTimeContent} className="time_div" disabled >
                    <p>{this.state.startTime}</p>
                  </div>
                  {this.state.showStartTime &&
                    <div style={{position : "absolute", zIndex : "99999"}}>
                      <Timekeeper 
                      time={this.state.startTime}
                      switchToMinuteOnHourSelect
                      onDoneClick={() => {
                        this.setState({
                          showStartTime : false
                        })
                      }}
                      onChange={this.selectStartTime}
                      />
                    </div>
                  }
                </Grid>
                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
                    {/* <p className="cj_w_header"><b>End Date</b></p>   */}
                    <div className="input_div" onClick={this.showEndDate} style={{ zIndex: "99" }}>
                      <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input"></input>
                      <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="end_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.EnddateClicked}
                        value={this.state.endDate}
                        maxDate={this.state.refreshDate}
                        minDate={minDate}
                      />
                    </div>
                  </Grid>
                  <Grid item xs style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
                    <div onClick={this.showEndTimeContent} className="time_div">
                      <p>{this.state.endTime}</p>
                    </div>
                    {this.state.showEndTime &&
                      <div style={{position : "absolute", zIndex : "99999"}}>
                        <Timekeeper 
                        time={this.state.endTime}
                        switchToMinuteOnHourSelect
                        onDoneClick={() => {
                          this.setState({
                            showEndTime : false
                          })
                        }}
                        onChange={this.selectEndTime}
                        />
                      </div>
                    }
                  </Grid>
                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none", position: "relative" }}>
                  
                    <input type="text" className="addtional_detail_input" style={{ width: "100%" }} placeholder="Enter Vehicle Name"
                      onKeyUp={this.searchVehicle} onClick={this.displayAllVehicles}  id="search_input" autoComplete="off"></input>
                    <div className="vehile_search_list" id="s_vehicle_list" style={{width: "200px" , height : "200px" , overflow : "scroll" ,zIndex :9999 }}>
                    <input id="searchbar" onChange={this.searchVehicle123} type="search"
                      name="search" placeholder="Search ..."/>
                      {search_list}
                    </div>
                  </Grid> 
                  {/* VEHICLE SEARCH LIST  */}
                  <Grid item xs={1} style={{ display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none" }}>
                    <button id="start_tracking_btn" className="btn btn-block orange-btn" onClick={this.generateTracking}>Play 1x</button>
                  </Grid>
                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 0 ? "" : "none"}}>
                    <button id="show_Location_btn" className="btn btn-block orange-btn" onClick={this.show_Location}>Show Location</button>
                  </Grid>
                  <Grid item xs={3} style={{
                    display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none",
                    textAlign: "right"
                  }}>
                    <i><b>Selected Vehicle</b></i>
                    <p className="selected-vehicle" style={{ display: this.state.selectedVehicleName ? "" : "none" }}>{this.state.selectedVehicleName}</p>
                  </Grid>
                </Grid>
                {map_data}
                <div className="replay_route_slider" style={{ display: this.state.trackingSelect == 2 ? '' : 'none', left: "0" }}>
                  <Grid container>
                    <Grid item xs={6} className="rr_slider" style={{ borderRight: "1px solid #979797" }}>
                      <div>
                        <div>
                          <img src="images/Play.png" alt="icon" height="25px" id="play_rr" onClick={this.playRr}
                            style={{ display: this.state.playReplayRoute ? 'none' : '' }}></img>
                          <img src="images/Pause.png" alt="icon" height="25px" onClick={this.pauseRr}
                            style={{ display: this.state.playReplayRoute ? '' : 'none' }} id="pause_rr"></img>
                        </div>
                        <div className="slider_div">
                          <Slider
                            min={0}
                            max={this.state.sliderMax}
                            step={1}
                            value={this.state.slidedValue}
                            onChangeCommitted={(e, value) => {
                              this.setState({
                                slidedValue: value
                              }, () => {
                                this.returnMap()
                                // if(this.state.slidedValue !== this.state.sliderMax) {
                                // this.rrSliderDuration(this.state.replayRouteData.replay_data[this.state.slidedValue].datetime,
                                //     this.state.replayRouteData.replay_data.splice(-1)[0].datetime)
                                // console.log('slider value is', this.state.slidedValue)
                                // }else{
                                //     this.setState({
                                //         slidedValue : 0,
                                //     })
                                // }
                              })
                            }}
                          />
                        </div>
                        {/* <div style={{ margin: "0px 5px 0px 5px" }}>
                          <p style={{ color: "#666666" }}> {this.state.replayDuration} </p>
                        </div> */}
                      </div>
                    </Grid>
                    <Grid item container xs={6} style={{ textAlign: "center" }}>
                      <Grid container item xs={4}>
                        <Grid item xs={3}>
                          <p style={{ marginTop: "15px" }}>Time</p>
                        </Grid>
                        <Grid item xs={9}>
                          <p><span className="rr_value" style={{fontSize : "25px", lineHeight : "1.7em"}}>{time ? CommonFunction.timeFormate(time) : ""}</span></p>
                        </Grid>
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={6}>
                          <p style={{ borderLeft: "1px solid #979797", marginTop: "15px" }}>{t('ct.speed')}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <p><span className="rr_value">{speed}</span>&nbsp;<b>km/h</b></p>
                        </Grid>
                      </Grid>
                      <Grid item container xs={4}>
                        <Grid item xs={6}>
                          <div style={{ borderLeft: "1px solid #979797", marginTop: "15px" }}>
                            <p>{t('ct.distance')}</p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <p><span className="rr_value">{distancDriven}</span>&nbsp;<b>kms</b></p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <div className="share_live_replay" style={{ display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none" }}>
                  <div className="grid-container">
                    <div className="grid-item">
                      <input type="number" id="share_number_input" className="vd_input" style={{ backgroundColor: "#fff", borderRadius: "none", margin: "0px" }}
                        placeholder="Enter Mobile Number" onChange={this.shareMobileNumber}></input>
                    </div>
                    <div className="grid-item" style={{ borderLeft: "1px solid #cccccc", padding: "5px" }}>
                      <i className="fa fa-share-alt fa-2x v_start_journey" style={{ lineHeight: "40px" }} onClick={this.shareTracking} aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div className="panning_button" style={{ display: "none" }}
                  onClick={this.togglePanning}>
                  <p>Disable Panning</p>
                  <p style={{ display: "none" }}>Enable Panning</p>
                </div>
              </div>
              <div className="map_loading">
                <CircularProgress className="map_loading_icon" />
              </div>
            </div>
            {/* Map view vehicle list end */}
          </div>

        </div>
        <div id="data_loading" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday" style={{ width: "300px" }}>
            <ProgressBar now={this.state.length} label={`${this.state.length}%`} style={{ marginBottom: "5px" }} />
            <p style={{ textAlign: "center", fontSize: "20px" }} className="cj_w_header">Loading <span><Digital color="#F0762B" size={20} speed={0.9} animating={true} /></span></p>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1200}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(LiveTrack);
