

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import './setting.scss';
import ApiUrlCall from '../API/apiurl';
import { withTranslation } from 'react-i18next';

class DeleteAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigate: false,
            mobile_number: '',
        };

        this.logOut = this.logOut.bind(this);
    }

    logOut = () => {
        localStorage.clear();
        this.setState({ navigate: true });
    }

    componentDidMount() {
        setTimeout(() => {
            var navBarHeight = $('.navBar').outerHeight();
            $('.setting_parent').css({
                paddingTop: navBarHeight + 60,
            });
        }, 500);
        this.delete_your_accounts();
    }

    delete_your_accounts = () => {
        var user_id = localStorage.getItem("user_id");

        ApiUrlCall.delete_your_account(user_id).then(response => {
       //     console.log("Response delete account number ", response);
            if (response.data.response_code === 200) {
                this.setState({
                    clusterData: response.data.data,
                    mobile_number: response.data.data.mobile_number, // Set mobile number in state
                });
            }
        });
    }

    handleDelete = (e) => {
        e.preventDefault();
        const confirmed = window.confirm("Are you sure you want to delete your account permanently?");
        if (confirmed) {
            this.delete_account();
        }
    }

    delete_account = () => {
        var user_id = localStorage.getItem("user_id");
    
        ApiUrlCall.account_delete(user_id).then(response => {
      //      console.log("Response delete account number ", response);
            if (response.data.response_code === 200) {
                // Show a popup with a success message
                alert("Your account has been deleted successfully.");
    
                // Wait for 3 seconds, then navigate to the login page
                setTimeout(() => {
                    localStorage.clear() 
                    this.props.history.push("/login");
                }, 1000);
            }
        }).catch(error => {
            console.error("Error deleting account: ", error);
        });
    }
    



    render() {
        const { t } = this.props;
        return (
            <div>
                <Navbar />
                <div className="setting_parent">
                    <div className="login-container">
                        <h2>Delete Account</h2>
                        <form onSubmit={this.handleDelete}>
                            <h4>{this.state.mobile_number}</h4>
                            <button type="submit">Delete</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(DeleteAccount));


