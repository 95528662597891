import React, { Component } from 'react';
import $ from 'jquery';
import './login.scss'
// import Grid from '@material-ui/core/Grid';
import AuthService from '../src/Component/Login/Authservice';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import { Grid } from '@material-ui/core';
import analytics from './Config/firebase'

const is_endpoint_updated = false
class Login extends Component {
  constructor(props) {
    super(props);
    this.increment = this.increment.bind(this)
    this.state = {
      showPassword: true,
      showOtp: false,
      username: '',
      password: '',
      message: '',
      mobile_number: '',
      otp: '',
      step: 'init',
      items: [],
      isLoaded: false,
      redirectToReferrer: false,
      token: '',
      counter: 0,
      clicks: 0,
      showgetOtp: false,
      showreSendOtp: false,
      showloginWithOtp: false,
      showlogin: true,
      openAlert : false,
      errorMessage : '',
      redirectToReferrer: false,
      enterInput : "Registered Email or Mobile number",
      demoClick : false,
      validateMobile : true,
    }

    this.login = this.login.bind(this)
    this.loginWithOtp = this.loginWithOtp.bind(this)

  }
  increment = () => {

    this.setState({
      counter: this.state.counter + 1
    });
    // console.log(this.state.counter)
    // console.log(this.state.counter)
  }

  componentWillUnmount(){
    window.location.reload(false)
  }

  componentDidMount() {
    $('.fixed-icon').hide()
    analytics.logEvent('screen_view',{
      screen_name : "Login"
    })
    // console.log(this.state.limit)
    // localStorage.clear();
    var loginHeight = $(window).outerHeight()
    // console.log('height of the login')

    $('.login_parent').css({
      height: loginHeight
    })
        
  }

  showSuggestion = (div) => {
    if (div.value) {
      $(div).prev().slideDown(400)
    } else {
      $(div).prev().fadeOut(300)
    }
  }

  handleChange = (e) => {
    this.showSuggestion(e.currentTarget)
    // console.log('...................')
    if (/^\d+$/.test(e.target.value)) {
      document.getElementById('password_input').value = '';
      $('#password_input').prev().hide()
      // if(e.currentTarget.value.length > 10){
      //   $(e.currentTarget).bind('keyup', function (evt) {          
      //     console.log('in if statement mobile')            
      //       return false;
      //   });
      // }
      // $('#switch_mobile').hide()
      // $('#switch_email').show()
      this.setState({
        showPassword: false,
        mobile_number: e.target.value,
        showgetOtp: true,
        showlogin: false,
        showloginWithOtp: false,
        showreSendOtp: false
      })

    } else {
      // if(!e.currentTarget.value){
      //   $('#switch_mobile').hide()
      //   $('#switch_email').hide()        
      // }else{
      //   $('#switch_mobile').show()
      //   $('#switch_email').hide()      
      // }
      document.getElementById('otp_input').value = '';
      $('#otp_input').prev().hide()
      if(this.state.demoClick){
        this.setState({
          showlogin : false,
        })
      }else{
        this.setState({
          showPassword: true,
          username: e.target.value,
          showgetOtp: false,
          showlogin: true,
          showloginWithOtp: false,
          showreSendOtp: false
        })
      }
    }
    this.setState({ Password: e.target.value })
  }
  getOtp = (e) => {
    if(this.state.validateMobile){
      e.preventDefault();
      $('#resend_otp').css({
        pointerEvents: 'none',
        color : "#505050",
      })    
      const credentials = { mobile_number: this.state.mobile_number };
      // console.log('mobile number is', this.state.mobile_number, '............', credentials)
      AuthService.generateOtp(credentials).then(res => {
        // console.log('opt response',res)
        if (res.data.response_code === 200 && res.data.is_success === true) {
          // console.log('generate otp data', res.data)
          // this.state.mobile_number = res.data.mobile_number;
          // this.state.step = 'otp';
          this.setState({
            showgetOtp: false,
            showreSendOtp: true,
            showlogin: false,          
            showOtp: true,
            step: 'otp',
          }, () => {
            $('#email_mobile').prop('disabled',true)
            // console.log('state value is', this.state.mobile_number)
          })
        }else{
          this.setState({
            openAlert : true,
            errorMessage : res.data.response_message,
          })
        }
      }).catch(err => {
        this.setState({
          showgetOtp: true,
          showreSendOtp: false,
          showlogin: false,
          // showloginWithOtp: false,
          showOtp: false,
        })
        console.log(err, 'error in getting otp')
      })
      setTimeout(function () {
        $('#resend_otp').css({
          pointerEvents: 'auto',
          color : "#f0762b",
        })
      }, 30000)
    }else{
      this.setState({
        openAlert : true,
        errorMessage : "Enter a valid number"
      })
    }
  }


  reSendOtp = (e) => {
    // console.log(this.state.mobile_number);
    // console.log(this.state.otp);
    // console.log("this method is calling");
    e.preventDefault();

    const credentials = { mobile_number: this.state.mobile_number };
    AuthService.generateOtp(credentials).then(res => {      
      if (res.data.response_code === 200 && res.data.is_success === true) {        
        
      }
    }).catch(err => console.log(err, 'error while resending OTP'))
  }




  loginWithOtp = (e) => {    
    e.preventDefault();
    const {demoClick} = this.state    
    if(demoClick){
      var credentials = { mobile_number: this.state.mobile_number, otp: this.state.otp, is_demo : demoClick };
    }else{
      var credentials = { mobile_number: this.state.mobile_number, otp: this.state.otp };
    }
    //var count = 0;
    AuthService.login(credentials).then(res => { 
       //console.log('demo login response', res)  
      // return   
      if (res.data.response_code === 200 && res.data.is_success === true) {        
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("user_id", res.data.data.user)
        localStorage.setItem("username", res.data.data.customer_name)
        localStorage.setItem("user_name", res.data.data.username)
        localStorage.setItem("is_endpoint",is_endpoint_updated)
        localStorage.setItem("group",res.data.data.group_id)
        localStorage.setItem("is_demo",res.data.data.is_demo)
        // localStorage.setItem('is_people_tracking', false)
        // localStorage.setItem('has_fuel_sensor',false)
        localStorage.setItem('default_view',res.data.data.default_view)
        this.setState({
          redirectToReferrer : true,
        })
        window.location.reload(false)
        // this.props.history.push({
        //   pathname : '/',
        //   // state : {user_id : res.data.data.owner_id, token : res.data.data.token}
        // });
        // console.log(this.props.history)

      } else {        

        this.setState({ message: res.data.response_message, openAlert : true, errorMessage : res.data.response_message })
        this.setState({
          counter: this.state.counter + 1
        })
        if (this.state.counter > 3) {
          this.setState({ message: res.data.message, openAlert : true, errorMessage : "You have exceeded number of attempts. Please contact our Customer Care." })          
        } else {
        }
      }

    });
  }

  login = (e) => {
    console.log(this.state.username);
    // console.log(this.state.password);
    // console.log("this method is calling.............................");    
    e.preventDefault();
    // analytics.logEvent('logged_in')
    const credentials = { username: this.state.username, password: this.state.password };
    AuthService.login(credentials).then(res => {
      // console.log(res.status)
      // console.log(credentials);
      //console.log("login data is", res)
      // return
      if (res.data.response_code === 200) {
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("username", res.data.data.customer_name)
        localStorage.setItem("user_id", res.data.data.user)
        localStorage.setItem("user_name", res.data.data.username)
        localStorage.setItem("is_endpoint",is_endpoint_updated)
        localStorage.setItem("group",res.data.data.group_id)
        console.log("mrunmyaee" ,res.data.data.group_id)
        // localStorage.setItem('is_people_tracking', false)
        // localStorage.setItem('has_fuel_sensor',false)
      //  localStorage.setItem('default_view',res.data.data.default_view)
        // this.props.history.push('/');
        this.setState({
          redirectToReferrer : true,
        })
        window.location.reload(false)
        // console.log(this.props.history)
         console.log(localStorage.getItem("group", res.data.data.group_id), "data after login which needs to be stored", localStorage.getItem("user_id", res.data.data.owner_id))
     
        } else {
        this.setState({ openAlert : true, errorMessage : res.data.response_message })
        this.setState({
          counter: this.state.counter + 1
        })
        // console.log('wrong password', this.state.counter)
        if (this.state.counter > 3) {
          this.setState({ message: res.data.message, openAlert : true, errorMessage : "You have exceeded number of attempts. Please contact our Customer Care." })
          // console.log(this.state.counter, ">>>>>>>>>>>>>>>>>>>>>>>")
          // alert("You have exceed number of attempt,Please Contact our costomer care")          
          // document.getElementById('username_waring').style.display = "block"
        }
        else {
        }
      }
    });
  }

  
  otpValue = (e) => {
    this.showSuggestion(e.currentTarget)
    this.setState({
      otp: e.target.value
    })
    if (e.target.value.length <= 6) {
      // console.log('lenght of otp is')
      this.setState({
        showgetOtp: false,                
        showlogin: false,
        showloginWithOtp: true
      })
    }else{
      this.setState({
        openAlert : true,
        errorMessage : "OTP entered is more than 6 digit. Enter Valid OTP"
      })
    }
  }
  passwordValue = (e) => {
    this.showSuggestion(e.currentTarget)
    this.setState({
      password: e.target.value,
      showgetOtp: false,
      showreSendOtp: false,
      showlogin: true,
      showloginWithOtp: false
    })
  }

  demoLogin = (e) => {
    $(e.currentTarget).hide()
    $('#email_mobile').val("")
    $('#email_mobile').prop("type","number")
    this.setState({
      showgetOtp : true,
      showlogin : false,
      showPassword : false,
      demoClick : true,
      enterInput : "Enter Mobile number",
      mobile_number : ''
    })
  }

  mobileNumberValidation = (e) => { 
    if(this.state.showgetOtp){         
      console.log('111111',e.currentTarget.value.length)   
      if(e.currentTarget.value.length !== 10){
        this.setState({
          validateMobile : false
        })        
      }else{
        this.setState({
          validateMobile : true
        })
      }
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    // console.log('login component called', from,'..........', redirectToReferrer)
    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }
    return (

      <div className="login_parent">
        <Grid container>
          <Grid item xs={6}>
            <img src="images/logo.png" alt="icon" style={{ padding: "20px" }}></img>
          </Grid>
          <Grid item xs={6} style={{position : "relative"}}>
              <p className="cj_w_header customer_care">Need help? <span style={{color : "#F0762B"}}>
              <i class="fa fa-volume-control-phone" aria-hidden="true"></i> 9029070083 (24x7)</span></p>
          </Grid>
        </Grid>
        <div className="login_details_div">
          <p className="login_header">Login ID</p>
          <span className="textbox_suggestion">{this.state.enterInput}</span>
          <input type="text" id="email_mobile" autoComplete="off" className="form-control" onKeyUp={this.mobileNumberValidation} onChange={this.handleChange} name="email_mobile" placeholder={this.state.enterInput}></input>
          {/* <label id="switch_mobile" className="switch_to" onClick={() => {
            $('#email_mobile').attr('type','number')
          }}>Switch to Mobile No</label>
          <label id="switch_email" className="switch_to" onClick={() => {
            $('#email_mobile').attr('type','text')
          }}>Switch to Email ID</label> */}
          <span style={{ display: !this.state.demoClick ? '' : 'none' }} className="textbox_suggestion">Password</span>
          <input
            id="password_input"
            type="password"
            autoComplete="off"
            className="form-control"
            placeholder="Password"
            style={{ display: this.state.showPassword && !this.state.demoClick ? '' : 'none' }}
            onChange={this.passwordValue}
            onKeyUp={(e) => {
              if(e.keyCode === 13){
                $('#username_login').click()
              }
            }}
          ></input>
          <img src="images/show_password.png" className="show_password" alt="icon" style={{ display: "none" }} onClick={(e) => {
            $('#password_input').attr('type', 'password')
            $(e.currentTarget).next().show()
            $(e.currentTarget).hide()
          }}></img>
          <img src="images/view_password.png" className="show_password" style={{ display: this.state.showPassword && !this.state.demoClick ? '' : 'none' }} alt="icon" onClick={(e) => {
            $('#password_input').attr('type', 'text')
            $(e.currentTarget).prev().show()
            $(e.currentTarget).hide()
          }}></img>
          <span className="textbox_suggestion">OTP</span>
          <input
            id="otp_input"
            type="number"
            autoComplete="off"
            className="form-control"            
            placeholder="Enter OTP"
            style={{ display: this.state.showOtp ? '' : 'none' }}
            onChange={this.otpValue}
            onKeyUp={(e) => {
              if(e.keyCode === 13){
                $('#mobile_login').click()
              }
            }}></input>
          <label className="switch_to" style={{ display: this.state.showreSendOtp ? '' : 'none' }} onClick={this.reSendOtp} id="resend_otp">Resend OTP</label>
          {/* <p id="username_waring" style={{ display: "", color: "dc3545", marginTop: "-4em", marginBottom: "3em" }}>
            You have exceeded number of attempts.<br/> Please contact our Customer Care.</p> */}
          {/* <p id="otp_warning" style={{ display: "none", color: "red", marginTop: "-4em", marginBottom: "3em" }}>2 try left</p> */}

          <button id="username_login" className="btn orange-btn sign_in_btn" style={{ display: this.state.showlogin ? '' : 'none' }} onClick={this.login}>Sign in</button>
          <button className="btn orange-btn sign_in_btn" style={{ display: this.state.showgetOtp ? '' : 'none' }} onClick={this.getOtp}>Get OTP</button>
          {/* <button className="btn orange-btn sign_in_btn" style={{ display: this.state.showreSendOtp ? '' : 'none' }} onClick={this.reSendOtp} id="resend_otp">Resend OTP</button> */}
          <button id="mobile_login" className="btn orange-btn sign_in_btn" style={{ display: this.state.showOtp ? '' : 'none' }} onClick={this.loginWithOtp}>Sign in</button>
          <p className="p_header v_start_journey" style={{textAlign : "center"}}
          onClick={this.demoLogin}>Login As Demo User</p>
        </div>
        <img src="images/login_bg.png" alt="icon" className="login_bg_img"></img>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}


export default Login;
