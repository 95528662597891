import React, { Component } from 'react';
import $, { event } from 'jquery';
//import XLSX from 'xlsx'; // Import XLSX library for Excel file generation

import * as XLSX from 'xlsx'; 

import Grid from '@material-ui/core/Grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import Snackbar from '@material-ui/core/Snackbar';
import html2canvas from "html2canvas";
import '../reports/reports.scss'
import Table from 'react-bootstrap/Table';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Calendar from 'react-calendar/dist/entry.nostyle'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import Timekeeper from 'react-timekeeper';

const jsPDF = require('jspdf') 


var Chart = require("chart.js");


const initialState = { 
  dateRange: {}
};
var GRAPH_BAR_BACKGROUND_COLORS = [ 
  
  'orange',
  '#808080',
   'rgb(243,0,159)',
  'rgb(255,111,97)',
  'rgb(254,174,81)',
  'rgb(255, 0, 71)',
  'rgb(238, 130, 238)',
  'rgb(107,0,183)',
]
class Temprature extends Component {
  _exporter;
  _exporter_ignition;
  _exporter1_table; 
  _exporter_distance;
  _exporter_duration;
  _exporter_alert;
  _exporter_trip;

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      counter: 0,
      selectedDays: [],
      dateRange: initialState,
      isRange: this.props.range,
      startDate: new Date(),
      endDate: new Date(),
      serial_number_is: [],
      fuel_report: '',
      refuel_report:'',
      economy_report:'',
      fuel_theft_report:'',

      checked: '',
      uncheck: true,
      openAlert: false,
      vehicleName: '',
      message: '',
      chckebox_value: [],
      excel_table_data: '',
      export_table_data: '',
      label_list: '',
      message1: '',
      reportDate: "12/01/2020-20/01/2020",
      dateRange: initialState,
      isChecked: [],
      is_checked: true,
      chart_canvas_array: [],
      fuel_incidence_chart_array: [],
      refuel_chart_array:[],
      fuel_economy_array:[],
      theft_data_array: [],
      alert_chart_array: [],
      driver_score_array: [],
      sevenDayFlag : true,
      driverReportData : '',
      distData : '',
      durData : '',
      alertData : '',
      tripData : '',
      startTime : "12:00 am",
      sendStartTime : "00:00",
      showStartTime : false,
      endTime : "11:59 pm",
      sendEndTime : "23:59",
      showEndTime : false,

     vehicle_name: '',
     counter_region: 0,
      Summery_report: '',
      checkedd: false,
      fuel_data: [],
       theft_data:[],
      summery_excel_data: '',  
      averahe_duration: '',
      average_distance: '',
      total_expenses: "",
      region_list: '',
      message: '',
      serial_number: '',
      value: '',
      value: [],
      distance_chart: [],
      duration_chart:[],
      expenses_chart:[],
      sevenDayFlag : true,
      excelData : '',
      alertGraph : '',
      groupDataList: [],
      vlcGroupDataList: [],
      initial_stage : true,
      checkbox_value_forGroup: [],
      option_type : ["Vehicle Based Report","Group Based Report"],
      choice_value : "Vehicle Based Report",
      table_data :[],
      refule_table_data:[],
      theft_table_data:[],

    }
    const multiDataSet2 = [
      {
        columns: props.columns,
        data: props.data
      }
    ]
    // this.checkbox = this.checkbox.bind(this);

  }

  export_driver_report_iginiton = () => {
    this._exporter_ignition.save();
  }

  export_driver_report_table = () => {
    this._exporter1_table.save();
  }
  
  export_vehicle_report_duration = () => {
    this._exporter_duration.save();
  }
  export_vehicle_report_distance = () => {
    this._exporter_distance.save();
  }

  export_vehicle_report_alert = () => {
    this._exporter_alert.save();
  }
  export_vehicle_report_trip = () => {
    this._exporter_trip.save();
  }
  // drop down show option list function start
  dropDownClick = (e) => {
    var dropdownwidth = e.currentTarget.offsetWidth
    var checklist = e.currentTarget.nextSibling
    $(checklist).css({
      width: dropdownwidth
    })
    $(checklist).slideToggle(200);

  }

  downloadFuelReportExcel = () => {
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
    }
  
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("Fuel Report");
    const ws_data = [["Vehicle Name", "Time", "Fuel Value", "Latitude", "Longitude", "Address", "Ignition State"]];
  
    // Iterate through each fuel report data and its data points
    this.state.fuel_report.fuel_data.forEach((fuelData, outerIndex) => {
      fuelData.odometer.forEach((value, innerIndex) => {
        // Construct data for each row
        const rowData = {
          vehicleName: fuelData.vehicle_name,
          label: this.state.fuel_report.label_list[innerIndex],
          fuelValue: fuelData.fuel_value[innerIndex] + "%",
          latitude: parseFloat(fuelData.latitude[innerIndex]).toFixed(4),
          longitude: parseFloat(fuelData.longitude[innerIndex]).toFixed(4),
          address: fuelData.address[innerIndex],
          ignitionState: fuelData.ignition_status[innerIndex]
        };
  
        // Push the data object to the array
        ws_data.push([
          rowData.vehicleName,
          rowData.label,
          rowData.fuelValue,
          rowData.latitude,
          rowData.longitude,
          rowData.address,
          rowData.ignitionState
        ]);
      });
    });
  
    // Convert data to Excel sheet format
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Fuel Report"] = ws;
  
    // Generate Excel file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const fileName = "Fuel_Report.xlsx";
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
  
    // Cleanup after download
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
  
  
downloadrefuelExcel = () => {
  function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
  }

  if (this.state.refuel_report) {
      const wb = XLSX.utils.book_new();
      wb.SheetNames.push("Refuel Report");
      const ws_data = [["Vehicle Name", "Time", "Address", "Refuel Value"]];

      // Iterate through each refuel data and its data points
      this.state.refuel_report.refuel_data.forEach(refuelData => {
          refuelData.dataPoints.forEach((dataPoint, index) => {
              const rowData = {
                  vehicleName: refuelData.vehicle_name,
                  label: dataPoint.event_time,
                  address: refuelData.address[index], // Correctly index address
                  refuelValue: dataPoint.refuel_value + " L"
              };
              ws_data.push([
                  rowData.vehicleName,
                  rowData.label,
                  rowData.address, // Include address here
                  rowData.refuelValue
              ]);
          });
      });

      // Convert data to Excel sheet format
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Refuel Report"] = ws;

      // Generate Excel file
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const fileName = "Refuel_Report.xlsx";
      const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();

      // Cleanup after download
      setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
      }, 0);
  }
}

  downloadtheft_Excel = () => {
    // Helper function to convert a string to an ArrayBuffer
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
    }
  
    // Check if fuel_theft_report is available in the state
    if (this.state.fuel_theft_report) {
      // Create a new workbook and add a sheet
      const wb = XLSX.utils.book_new();
      wb.SheetNames.push("Fuel Theft Report");
      
      // Define the header row
      const ws_data = [["Vehicle Name", "Time", "Location", "Theft Fuel Value"]];
  
      
      this.state.fuel_theft_report.theft_data.forEach(theftData => {
        theftData.dataPoints.forEach((dataPoint, index) => {
          // Construct the row data
          const rowData = {
            vehicleName: theftData.vehicle_name,
            label: dataPoint.event_time,
            address: theftData.address[index], // Correctly index address if necessary
            theftValue: dataPoint.theft_value + " L"
          };
          // Add the row to the data array
          ws_data.push([
            rowData.vehicleName,
            rowData.label,
            rowData.address,
            rowData.theftValue
          ]);
        });
      });
  
      // Convert the data array to a sheet
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Fuel Theft Report"] = ws;
  
      // Write the workbook to binary format
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      
      // Create a Blob from the binary data
      const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
      
     
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = "Fuel_Theft_Report.xlsx";
      a.click();
  
      // Cleanup after download
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
  
  




  downloadEconomyExcel = () => {
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
    }
  
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("Economy Report");
    const ws_data = [["Vehicle Name", "Time", "Fuel Value", "Total Distance", "Total Fuel Used"]];
  
    // Iterate through each economy report data and its data points
    this.state.economy_report.fuel_economy_data.forEach(economyData => {
      economyData.dataPoints.forEach(dataPoint => {
        // Push data for each data point
        ws_data.push([
          economyData.vehicle_name,
          dataPoint.event_time,
          dataPoint.fuel_economy + " L", // Include fuel economy data here
          economyData.total_distance + " km", // Include total distance data here
          economyData.total_fuel_used + " L" // Include total fuel used data here
        ]);
      });
    });
  
    // Convert data to Excel sheet format
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Economy Report"] = ws;
  
    // Generate Excel file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const fileName = "Economy_Report.xlsx";
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
  
    // Cleanup after download
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
  

  // drop down show option list function end

  // show calendar click event start
  showCalendar = (e) => {
    var calendardiv = e.currentTarget.parentNode.parentNode.nextSibling
    $(calendardiv).toggle();
  }
  // show calendar click event end

  componentDidMount() {
  // console.log("componenet did moounts")
    analytics.logEvent('screen_view',{
      screen_name : "DriverReport"
    })
    analytics.logEvent('page_view',{
      page_title : "DriverReport",        
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName, 
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_DRIVER_REPORT'}
    });
    this.onChange_selectDefault();
    //console.log("componentDidMount function is calling")
   
   // this.getDriverList();
     
    this.getVehicleList();
    this.getVehicleListForGroup();

  }


 getVehicleList = () => {
  // console.log("getvehicle list");
  var data = {};
  ApiUrlCall.getVehcile_Name_latest(data)
    .then((response) => {
      // console.log("getvehicle_name", response.data);
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState(
          {
            vehicle_name: response.data.data,
          },
          () => {
            const serial_numbers = [];

            for (let index = 0; index < this.state.vehicle_name.vehicle_list.length; index++) {
              const vehicle = this.state.vehicle_name.vehicle_list[index];
              if (vehicle.is_expired === false) {
                serial_numbers.push(vehicle.serial_number);
              }
              if (serial_numbers.length >= 1) {
                break;
              }
            }

            this.setState({
              isChecked: serial_numbers,
              chckebox_value: serial_numbers,
            });

            this.generate_Default_Report_fuel(serial_numbers);
            this.generate_Default_Report_Refuel(serial_numbers);
            this.generate_Default_Report_fuel_theft(serial_numbers);
            this.generate_Default_Report_fuel_Economy(serial_numbers);

          }
        );
      }
    })
    .catch((error) => {
      console.log(error, "error while fetching vehicle list");
    });
};

 getVehicleListForGroup = () => {
  var data = {}
  ApiUrlCall.ShowVehicleGroupList(JSON.stringify(data)).then(response => {
  //  console.log(response)
    if (response.data.response_code == 200 && response.data.is_success) {
      this.setState({
        groupDataList: response.data.data.group_data_list,
      })
      var data1 = []
      for (var i = 0; i < this.state.groupDataList.length; i++) {
        var obj = { groupName: this.state.groupDataList[i].group_name,serialNoList:[] }
        var vehicle_data = this.state.groupDataList[i].vehicle_data_list
        
        for (var j = 0; j < vehicle_data.length; j++) {
          obj.serialNoList.push(vehicle_data[j].serial_number)
        }          
        data1.push(obj)
      }
      this.setState({ vlcGroupDataList: data1, })
    }
  }).catch(error => {
    console.log('error while fetching vehicle group data', error)
  })
}

showVehicles = (e) => {
  $(e.currentTarget).next().slideToggle(200, "swing")
}
searchVehicle= (e) => {
  let input = document.getElementById('searchbar').value
  input=input.toLowerCase();
  let x = document.getElementsByClassName('vehicle');

  for (var i = 0; i < x.length; i++){
    if (!x[i].innerHTML.toLowerCase().includes(input)) {
        x[i].style.display="none";
    }
    else {
        x[i].style.display="";               
    }
  }
}

  dateFormate = (rdate) => {
   // console.log("date formate")
    var date = new Date(rdate);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    var convertDate = day + '/' + month + '/' + year
    return convertDate
  }

  showTimePicker = (e) => {
    $(e.currentTarget).next().slideToggle()
  }


  selectVehicleOpt = (list) => {
  //   console.log('list of vehicle', list)
    var input = $('#vehicles_select').find('input')
    for (let j = 0; j < input.length; j++) {
      const element = input[j];
      // console.log('value of input is', $(element).val())
      for (let i = 0; i < list.length; i++) {
        const element1 = list[i]; 
        // console.log($(element).val(),'>>>>>>>>>>',element1)
        if($(element).val() === element1.toString()){
          // console.log('selected list is', $(element))
          $(element).prop('checked',true)     
        }else{
          // console.log('unselected list is', $(element))
        }
      }
    }
  }

// ****************************************************************  FUEL INCIDENCE   ***************************************************************
 
generate_Default_Report_fuel(serial_number) {
  $("#processing").show();
  this.selectVehicleOpt(serial_number);
  var past_date = new Date();
  const { t } = this.props;
  past_date.setDate(past_date.getDate() - 1);
  this.state.startDate = past_date;
  past_date.setHours(0, 0, 0, 0);

  var current_date = new Date();
  current_date.setDate(current_date.getDate());
  this.state.endDate = current_date;
  this.forceUpdate();
  past_date = CommonFunction.createServerDateTime(past_date, this.state.sendStartTime);
  current_date = CommonFunction.createServerDateTime(current_date, this.state.sendEndTime);

  const Summery_report_details = {
      serial_number_list: serial_number,
      start_time: past_date,
      end_time: current_date
  };

  JSON.stringify(Summery_report_details);

 //console.log("fuel inci >>>>>>>>>>>>", Summery_report_details);

  ApiUrlCall.generate_fuel_report_graph(Summery_report_details)
      .then((res) => {
  
        console.log("generate_fuel_report_graph", res.data.data);

          if (res.data.is_success === true) {
              $('#processing').hide();
              this.setState(
                  {
                      fuel_report: res.data.data
                  },
                  () => {
                      CommonFunction.tableCellWidth('fuel_report_table');
                  }
              );

              if (this.state.fuel_report) {
                const vehiclename = this.state.vehicle_name;
                var driver_report_data = this.state.fuel_report.fuel_data.map(driver_report_data => {
      
                  return driver_report_data.duration_driven_list;
                });
                var fuel_report_data1 = this.state.fuel_report.label_list.map(fuel_report_data1 => {
                  this.state.label_list = fuel_report_data1
      
                  return fuel_report_data1.label_list;
                });
      
                var ctx = document.getElementById('fuelgraph');
                Chart.defaults.global.defaultFontFamily = "Lato";
                Chart.defaults.global.defaultFontSize = 18;
      
                var fuel_count_data = document.getElementById("fuelgraph");
                // var GRAPH_BAR_BACKGROUND_COLORS = ['rgba(0,0,255)', 'rgba(	255, 191, 0)', 'rgba(	255, 128, 0)', 'rgba(179, 179, 0)'
                //   , 'rgb(255,204,179)', 'rgb(255,126,0)', 'rgb(255,204,179)', 'rgb(179,242,255)', 'rgb(0,77,153)', 'rgb(255,230,179)']
      
                Chart.defaults.global.defaultFontFamily = "Lato";
                Chart.defaults.global.defaultFontSize = 18;
      
                var driver_distance_count_data = []
                for (var index = 0; index < this.state.fuel_report.fuel_data.length; index++) {
                  var fuel_data = {
                    label: this.state.fuel_report.fuel_data[index].vehicle_name,
                    data: this.state.fuel_report.fuel_data[index].fuel_value,
                    //backgroundColor: GRAPH_BAR_BACKGROUND_COLORS[index],
                    //borderColor: 'rgba( )',
                    borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',
                    borderWidth: 2,
                    fill: false,
      
                  };
                  driver_distance_count_data.push(fuel_data)
      
                }
                var label_list_data = []
                // console.log(CommonFunction.graphDateFormate(this.state.startDate, "$$$$$$$$$$$$$$$$"))
                for (var i = 0; i < this.state.fuel_report.label_list.length; i++) {
                  // console.log(CommonFunction.getParsedDate(label_list_data[i]),"&&&&&&&&&&&7")
      
                  //label_list_data.push(CommonFunction.graphDateFormate(this.state.fuel_report.label_list[i]))
                 label_list_data.push(CommonFunction.graphDateTimeFormate(this.state.fuel_report.label_list[i]))
                }
                var distnace_graph_Data = {
                  labels: label_list_data,
                  datasets: driver_distance_count_data
                };
      
      
      
                var chartOptions = {
                  responsive: true,
                  legend: {
                    position: "top",
                    align : "start",
                    labels : {
                      boxWidth : 5,
                      fontSize : 13,
                      usePointStyle : true,
                    },
                  },
                  title: {
                    display: true,
                    //text: "Chart.js Bar Chart"
                  },
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Fuel(liter)',
                        fontSize : 22,
                      },
                      ticks: {
                        beginAtZero: true , fontSize : 13,
                      },
                      gridLines : {
                        borderDash : [2,2]
                      }
                    }],
                    xAxes: [{
                      ticks : {
                        fontSize : 13,
                      },
                      gridLines : {
                        display : false
                      }
                    }]
                  }
                }
                if (this.state.chart_canvas_array.length > 0) {
                  for (var i = 0; i < this.state.chart_canvas_array.length; i++) {
                    this.state.chart_canvas_array[i].destroy();
                  }
                }
                var barChart = new Chart(fuel_count_data, {
      
                  //type: 'bar',
                  type: 'line',
                  data: distnace_graph_Data,
                  options: chartOptions
                });
                this.state.chart_canvas_array.push(barChart)
              }



          } else {
              this.setState({ message: res.data.response_message });
              //$('#processing').show()
          }
      })
      .catch((err) => {
          $('#processing').hide();
          console.log("journy report error ", err);
      });
  // console.log(this.state.fuel_report, "journye state data")
}


// ************************************************************  REFULE  GRAPH *********************************************************************************88

  generate_Default_Report_Refuel(serial_number) {
    $("#processing").show();
    this.selectVehicleOpt(serial_number);
    var past_date = new Date();
    const { t } = this.props;
    past_date.setDate(past_date.getDate() - 1);
    this.state.startDate = past_date;
    past_date.setHours(0, 0, 0, 0);

    var current_date = new Date();
    current_date.setDate(current_date.getDate());
    this.state.endDate = current_date;
    this.forceUpdate();
    past_date = CommonFunction.createServerDateTime(past_date, this.state.sendStartTime);
    current_date = CommonFunction.createServerDateTime(current_date, this.state.sendEndTime);

    const Summery_report_details = {
        serial_number_list: serial_number,
        start_time: past_date,
        end_time: current_date
    };

    JSON.stringify(Summery_report_details);
  //  console.log("refuel respond >>>>>>>>>", Summery_report_details);

    ApiUrlCall.generate_refuel_report_graph(Summery_report_details)
        .then((res) => {
        //    console.log("generate_refuel_report_graph", res.data.data);

            if (res.data.is_success === true) {
                $('#processing').hide();
                this.setState(
                    {
                        refuel_report: res.data.data
                    },
                    () => {
                        CommonFunction.tableCellWidth('refuel_report_table');
                    }
                );

                if (this.state.refuel_report) {
                    const vehicleName = this.state.vehicle_name;
                    var fuelReportData = this.state.refuel_report.refuel_data.map((fuelReportData) => {
                        return fuelReportData.dataPoints;
                    });

                    var eventTimeList = fuelReportData[0].map((dataPoint) => {
                        return dataPoint.event_time;
                    });
                    var refuelValueList = fuelReportData[0].map((dataPoint) => {
                        return dataPoint.refuel_value;
                    });

                    if (eventTimeList[0] === 0) {
                        eventTimeList[0] = past_date; 
                        refuelValueList[0] = 0;
                    }

                    var ctx = document.getElementById('refuelgraph');
                    Chart.defaults.global.defaultFontFamily = "Lato";
                    Chart.defaults.global.defaultFontSize = 18;

                    var refuelCountData = [];
                    for (var index = 0; index < fuelReportData.length; index++) {
                        var refuelData = {
                            label: this.state.refuel_report.refuel_data[index].vehicle_name,
                            data: fuelReportData[index].map((dataPoint) => {
                                return {
                                    x: new Date(dataPoint.event_time).getTime(), // Convert event_time to milliseconds
                                    y: dataPoint.refuel_value
                                };
                            }),
                            borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
                            hoverBorderColor: 'rgba(200, 200, 200, 1)',
                            borderWidth: 2,
                            fill: false
                        };
                        refuelCountData.push(refuelData);
                    }

                    var eventTimeLabels = eventTimeList.map((eventTime) => {
                        return CommonFunction.graphDateTimeFormate(eventTime);
                    });

                    var distanceGraphData = {
                        labels: eventTimeLabels,
                        datasets: refuelCountData
                    };
                    var chartOptions = {
                        responsive: true,
                        legend: {
                            position: "top",
                            align: "start",
                            labels: {
                                boxWidth: 5,
                                fontSize: 13,
                                usePointStyle: true
                            }
                        },
                        title: {
                            display: true
                            //text: "Chart.js Bar Chart"
                        },
                        scales: {
                            yAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Fuel (Liter)',
                                        fontSize: 22
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        fontSize: 13
                                    },
                                    gridLines: {
                                        borderDash: [2, 2]
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        fontSize: 13
                                    },
                                    gridLines: {
                                        display: false
                                    }
                                }
                            ]
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += tooltipItem.yLabel + ' Liter';
                                    return label;
                                }
                            }
                        }
                    };

                    if (this.state.refuel_chart_array.length > 0) {
                        for (var i = 0; i < this.state.refuel_chart_array.length; i++) {
                            this.state.refuel_chart_array[i].destroy();
                        }
                    }
                    var barChart2 = new Chart(ctx, {
                        type: 'line',
                        data: distanceGraphData,
                        options: chartOptions
                    });
                    this.state.refuel_chart_array.push(barChart2);
                }



            } else {
                this.setState({ message: res.data.response_message });
                //$('#processing').show()
            }
        })
        .catch((err) => {
            $('#processing').hide();
            console.log("journy report error ", err);
        });
    // console.log(this.state.fuel_report, "journye state data")
}

   // ***********************************************************  FUEL THEFT ******************************************************************************

  generate_Default_Report_fuel_theft(serial_number) {
    $("#processing").show();
    this.selectVehicleOpt(serial_number);
    var past_date = new Date();
    const { t } = this.props;
    past_date.setDate(past_date.getDate() - 1);
    this.state.startDate = past_date;
    past_date.setHours(0, 0, 0, 0);

    var current_date = new Date();
    current_date.setDate(current_date.getDate());
    this.state.endDate = current_date;
    this.forceUpdate();
    past_date = CommonFunction.createServerDateTime(past_date, this.state.sendStartTime);
    current_date = CommonFunction.createServerDateTime(current_date, this.state.sendEndTime);

    const Summery_report_details = {
        serial_number_list: serial_number,
        start_time: past_date,
        end_time: current_date
    };

    JSON.stringify(Summery_report_details);
    //console.log(Summery_report_details, ">>>>>>>>>>>>");

    ApiUrlCall.generate_fuel_theft_report_graph(Summery_report_details)
        .then((res) => {
      //      console.log("generate_fuel_theft_report_graph", res.data.data);

            if (res.data.is_success === true) {
                $('#processing').hide();
                this.setState(
                    {
                        fuel_theft_report: res.data.data
                    },
                    () => {
                        CommonFunction.tableCellWidth('theft_report_table');
                    }
                );

                if (this.state.fuel_theft_report) {
                    const vehicleName = this.state.vehicle_name;

                    var theft_data_report = this.state.fuel_theft_report.theft_data.map((theft_data_report) => {
                        return theft_data_report.dataPoints;
                    });

                    var eventTimeList = theft_data_report[0].map((dataPoint) => {
                        return dataPoint.event_time;
                    });

                    var theftValueList = theft_data_report[0].map((dataPoint) => {
                        return dataPoint.theft_value;
                    });

                    // Check if eventTimeList at 0 index has a zero value
                    if (eventTimeList[0] === 0) {
                        eventTimeList[0] = past_date; // Set event time to start time
                        theftValueList[0] = 0; // Set theft value to zero
                    }

                    var ctx = document.getElementById('theftgraph');
                    Chart.defaults.global.defaultFontFamily = "Lato";
                    Chart.defaults.global.defaultFontSize = 18;

                    var theftCountData = [];
                    for (var index = 0; index < theft_data_report.length; index++) {

                        var refuelData = {
                            label: this.state.fuel_theft_report.theft_data[index].vehicle_name,
                            data: theft_data_report[index].map((dataPoint) => {
                                return {
                                    x: new Date(dataPoint.event_time).getTime(), // Convert event_time to milliseconds
                                    y: dataPoint.theft_value
                                };
                            }),
                            borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
                            hoverBorderColor: 'rgba(200, 200, 200, 1)',
                            borderWidth: 2,
                            fill: false
                        };
                        theftCountData.push(refuelData);
                    }
                    var eventTimeLabels = eventTimeList.map((eventTime) => {
                        return CommonFunction.graphDateTimeFormate(eventTime);
                    });

                    var theftGraphData = {
                        labels: eventTimeLabels,
                        datasets: theftCountData
                    };
                    var chartOptions = {
                        responsive: true,
                        legend: {
                            position: "top",
                            align: "start",
                            labels: {
                                boxWidth: 5,
                                fontSize: 13,
                                usePointStyle: true
                            }
                        },
                        title: {
                            display: true
                            //text: "Chart.js Bar Chart"
                        },
                        scales: {
                            yAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Fuel (Liter)',
                                        fontSize: 22
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        fontSize: 13
                                    },
                                    gridLines: {
                                        borderDash: [2, 2]
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        fontSize: 13
                                    },
                                    gridLines: {
                                        display: false
                                    }
                                }
                            ]
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += tooltipItem.yLabel + ' Liter';
                                    return label;
                                }
                            }
                        }
                    };

                    if (this.state.theft_data_array.length > 0) {
                        for (var i = 0; i < this.state.theft_data_array.length; i++) {
                            this.state.theft_data_array[i].destroy();
                        }
                    }
                    var barChart2 = new Chart(ctx, {
                        type: 'line',
                        data: theftGraphData,
                        options: chartOptions
                    });

                    this.state.theft_data_array.push(barChart2);
                }
            } else {
                this.setState({ message: res.data.response_message });
                //$('#processing').show()
            }
        })
        .catch((err) => {
            $('#processing').hide();
            console.log("journy report error ", err);
        });
    // console.log(this.state.fuel_report, "journye state data")
}


  //  **************************************************************************  FUEL ECONOMY ********************************************************************

  //  generate_Default_Report_fuel_Economy(serial_number) {
  //   // console.log("genrate defult driver list ",serial_number)
  //      $("#processing").show()
  //      this.selectVehicleOpt(serial_number)
  //      var past_date = new Date();
  //      const {t} = this.props
  //      past_date.setDate(past_date.getDate() - 1);
  //      this.state.startDate = past_date
  //      past_date.setHours(0,0,0,0)
   
  //      var current_date = new Date();
  //      current_date.setDate(current_date.getDate());
  //      this.state.endDate = current_date
  //      this.forceUpdate()
  //      past_date = CommonFunction.createServerDateTime(past_date,this.state.sendStartTime)
  //      current_date = CommonFunction.createServerDateTime(current_date,this.state.sendEndTime)
   
  //      const Summery_report_details = {
  //        serial_number_list: serial_number,
  //        start_time: past_date,
  //        end_time: current_date
  //      };
   
  //      JSON.stringify(Summery_report_details)
  //        //console.log(Summery_report_details, ">>>>>>>>>>>>")
        
  //        ApiUrlCall.generate_economy_report_graph(Summery_report_details).then(res =>{
         
  //       //  console.log("generate_economy_report_graph", res.data.data);
         
  //         if (res.data.is_success === true) {
  //             $('#processing').hide();
  //             this.setState({
  //                economy_report: res.data.data
  //             }, () => {
  //               CommonFunction.tableCellWidth('economy_report_table');
  //             });
  //             if (this.state.economy_report) {
  //               const vehicleName = this.state.vehicle_name;
  //               var fuelReportData = this.state.economy_report.fuel_economy_data.map(fuelReportData => {
  //                   return fuelReportData.dataPoints;
  //               });
  //               var eventTimeList = fuelReportData[0].map(dataPoint => {
  //                   return dataPoint.event_time;
  //               });

  //               var refuelValueList = fuelReportData[0].map(dataPoint => {
  //                   return dataPoint.refuel_value;
  //               });
  //               var ctx = document.getElementById('economygraph');
  //               Chart.defaults.global.defaultFontFamily = "Lato";
  //               Chart.defaults.global.defaultFontSize = 18;
      
  //               var refuelCountData = []
  //               for (var index = 0; index < fuelReportData.length; index++) {

  //                 var refuelData = {
  //                   label: this.state.economy_report.fuel_economy_data[index].vehicle_name,
  //                   data: fuelReportData[index].map(dataPoint => {
  //                       return {
  //                           x: new Date(dataPoint.event_time).getTime(), // Convert event_time to milliseconds
  //                           y: dataPoint.refuel_value
  //                       };
  //                   }),
  //                   borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
  //                   hoverBorderColor: 'rgba(200, 200, 200, 1)',
  //                   borderWidth: 2,
  //                   fill: false,
  //               };
  //                   refuelCountData.push(refuelData);
  //               }
  //               var eventTimeLabels = eventTimeList.map(eventTime => {
  //                   return CommonFunction.graphDateTimeFormate(eventTime);
  //               });
      
  //               var distanceGraphData = {
  //                   labels: eventTimeLabels,
  //                   datasets: refuelCountData
  //               };
      
  //               var chartOptions = {
  //                 responsive: true,
  //                 legend: {
  //                     position: "top",
  //                     align: "start",
  //                     labels: {
  //                         boxWidth: 5,
  //                         fontSize: 13,
  //                         usePointStyle: true,
  //                     },
  //                 },
  //                 title: {
  //                     display: true,
  //                     //text: "Chart.js Bar Chart"
  //                 },
  //                 scales: {
  //                     yAxes: [{
  //                         scaleLabel: {
  //                             display: true,
  //                             labelString: 'Fuel Liter',
  //                             fontSize: 30,
  //                         },
  //                         ticks: {
  //                             beginAtZero: true,
  //                             fontSize: 13,
  //                         },
  //                         gridLines: {
  //                             borderDash: [2, 2]
  //                         }
  //                     }],
  //                     xAxes: [{
  //                         ticks: {
  //                             fontSize: 13,
  //                         },
  //                         gridLines: {
  //                             display: false
  //                         }
  //                     }]
  //                 },
  //                 tooltips: {
  //                     callbacks: {
  //                         label: function(tooltipItem, data) {
  //                             var label = data.datasets[tooltipItem.datasetIndex].label || '';
  //                             if (label) {
  //                                 label += ': ';
  //                             }
  //                             label += tooltipItem.yLabel + ' Liter';
  //                             return label;
  //                         }
  //                     }
  //                 }
  //             }
              
      
  //               if (this.state.fuel_economy_array.length > 0) {
  //                   for (var i = 0; i < this.state.fuel_economy_array.length; i++) {
  //                       this.state.fuel_economy_array[i].destroy();
  //                   }
  //               }
      
  //               var barChart4 = new Chart(ctx, {
  //                   type: 'line',
  //                   data: distanceGraphData,
  //                   options: chartOptions
  //               });
  //               this.state.fuel_economy_array.push(barChart4);
  //           }
  //         } else {
  //             this.setState({ message: res.data.response_message });
  //             //$('#processing').show()
  //         }
  //       }).catch(err => {
  //         $('#processing').hide();
  //         console.log("journey report error ", err);
  //       }); 
  //      // console.log(this.state.fuel_report, "journye state data")
  //    }
   
  generate_Default_Report_fuel_Economy(serial_number) {
    $("#processing").show();
    this.selectVehicleOpt(serial_number);
    var past_date = new Date();
    const { t } = this.props;
    past_date.setDate(past_date.getDate() - 1);
    this.state.startDate = past_date;
    past_date.setHours(0, 0, 0, 0);

    var current_date = new Date();
    current_date.setDate(current_date.getDate());
    this.state.endDate = current_date;
    this.forceUpdate();
    past_date = CommonFunction.createServerDateTime(past_date, this.state.sendStartTime);
    current_date = CommonFunction.createServerDateTime(current_date, this.state.sendEndTime);

    const Summery_report_details = {
        serial_number_list: serial_number,
        start_time: past_date,
        end_time: current_date
    };

    JSON.stringify(Summery_report_details);
    //console.log(Summery_report_details, ">>>>>>>>>>>>");

    ApiUrlCall.generate_economy_report_graph(Summery_report_details)
        .then((res) => {
            //  console.log("generate_economy_report_graph", res.data.data);

            if (res.data.is_success === true) {
                $('#processing').hide();
                this.setState(
                    {
                        economy_report: res.data.data
                    },
                    () => {
                        CommonFunction.tableCellWidth('economy_report_table');
                    }
                );
                if (this.state.economy_report) {
                    const vehicleName = this.state.vehicle_name;
                    var fuelReportData = this.state.economy_report.fuel_economy_data.map((fuelReportData) => {
                        return fuelReportData.dataPoints;
                    });
                    var eventTimeList = fuelReportData[0].map((dataPoint) => {
                        return dataPoint.event_time;
                    });

                    var economyValueList = fuelReportData[0].map((dataPoint) => {
                        return dataPoint.refuel_value;
                    });

                 
                    if (eventTimeList[0] === 0) {
                        eventTimeList[0] = past_date; 
                        economyValueList[0] = 0; 
                    }

                    var ctx = document.getElementById('economygraph');
                    Chart.defaults.global.defaultFontFamily = "Lato";
                    Chart.defaults.global.defaultFontSize = 18;

                    var refuelCountData = [];
                    for (var index = 0; index < fuelReportData.length; index++) {
                        var refuelData = {
                            label: this.state.economy_report.fuel_economy_data[index].vehicle_name,
                            data: fuelReportData[index].map((dataPoint) => {
                                return {
                                    x: new Date(dataPoint.event_time).getTime(), // Convert event_time to milliseconds
                                    y: dataPoint.refuel_value
                                };
                            }),
                            borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
                            hoverBorderColor: 'rgba(200, 200, 200, 1)',
                            borderWidth: 2,
                            fill: false
                        };
                        refuelCountData.push(refuelData);
                    }
                    var eventTimeLabels = eventTimeList.map((eventTime) => {
                        return CommonFunction.graphDateTimeFormate(eventTime);
                    });

                    var distanceGraphData = {
                        labels: eventTimeLabels,
                        datasets: refuelCountData
                    };

                    var chartOptions = {
                        responsive: true,
                        legend: {
                            position: "top",
                            align: "start",
                            labels: {
                                boxWidth: 5,
                                fontSize: 13,
                                usePointStyle: true
                            }
                        },
                        title: {
                            display: true
                            //text: "Chart.js Bar Chart"
                        },
                        scales: {
                            yAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Fuel (Liter)',
                                        fontSize: 22
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        fontSize: 13
                                    },
                                    gridLines: {
                                        borderDash: [2, 2]
                                    }
                                }
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        fontSize: 13
                                    },
                                    gridLines: {
                                        display: false
                                    }
                                }
                            ]
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += tooltipItem.yLabel + ' Liter';
                                    return label;
                                }
                            }
                        }
                    };

                    if (this.state.fuel_economy_array.length > 0) {
                        for (var i = 0; i < this.state.fuel_economy_array.length; i++) {
                            this.state.fuel_economy_array[i].destroy();
                        }
                    }

                    var barChart4 = new Chart(ctx, {
                        type: 'line',
                        data: distanceGraphData,
                        options: chartOptions
                    });
                    this.state.fuel_economy_array.push(barChart4);
                }
            } else {
                this.setState({ message: res.data.response_message });
                //$('#processing').show()
            }
        })
        .catch((err) => {
            $('#processing').hide();
            console.log("journey report error ", err);
        });
    // console.log(this.state.fuel_report, "journye state data")
}




  hideAllDropDown = () => {
    $('#vehicles_select').parent().hide()
    $('#end_date_cal').hide()
    $('#start_date_cal').hide()
    this.setState({
      showStartTime : false,
      showEndTime : false,
    })
  }


  create_Fuel_Report = () => {
    $('#processing').show()
    const {t} = this.props
    var start_date = CommonFunction.createServerDateTime(this.state.startDate,this.state.sendStartTime)
    var end_date = CommonFunction.createServerDateTime(this.state.endDate,this.state.sendEndTime)
    var dateValidate = CommonFunction.dateValidation(this.state.startDate , this.state.endDate)    
    this.hideAllDropDown()
    if(!dateValidate){
      $('#processing').hide()
      this.setState({
        openAlert : true,
        message1 : "Kindly select valid date"
      })
      return
    }
    var Difference_In_Time = this.state.endDate.getTime() - this.state.startDate.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(Difference_In_Days > 90){ 
      $('#processing').hide()     
      this.setState({
        sevenDayFlag : false,   
        openAlert : true,
        message1 : "Not allowed to select more than 90 days"                 
      })     
      return 
    }else{      
      this.setState({
        sevenDayFlag : true,
      })
    } 
    // console.log(this.state.serial_number_is, "kkkkkkkk")
    if(this.state.sevenDayFlag){
      const joureny_details = {    
        serial_number_list: this.state.chckebox_value,
        start_time: start_date,
        end_time: end_date
      };
      JSON.stringify("happy",joureny_details)
 //  console.log("create respond  >>>>>>>>",joureny_details)
// *****************************************************  fuel incidence  ***************************************************************

  ApiUrlCall.generate_fuel_report_graph(joureny_details).then(res =>{

  //   console.log("create fuel_report_graph", res.data.data);
     
    if (res.data.is_success === true) {
        $('#processing').hide();
        this.setState({
            fuel_report: res.data.data
        }, () => {
            CommonFunction.tableCellWidth('fuel_report_table');
        });
  
if (this.state.fuel_report) {
  const vehiclename = this.state.vehicle_name;
  var driver_report_data = this.state.fuel_report.fuel_data.map(driver_report_data => {

    return driver_report_data.duration_driven_list;
  });
  var fuel_report_data1 = this.state.fuel_report.label_list.map(fuel_report_data1 => {
    this.state.label_list = fuel_report_data1

    return fuel_report_data1.label_list;
  });

  var ctx = document.getElementById('fuelgraph');
  Chart.defaults.global.defaultFontFamily = "Lato";
  Chart.defaults.global.defaultFontSize = 18;

  var fuel_count_data = document.getElementById("fuelgraph");
  // var GRAPH_BAR_BACKGROUND_COLORS = ['rgba(0,0,255)', 'rgba(	255, 191, 0)', 'rgba(	255, 128, 0)', 'rgba(179, 179, 0)'
  //   , 'rgb(255,204,179)', 'rgb(255,126,0)', 'rgb(255,204,179)', 'rgb(179,242,255)', 'rgb(0,77,153)', 'rgb(255,230,179)']

  Chart.defaults.global.defaultFontFamily = "Lato";
  Chart.defaults.global.defaultFontSize = 18;

  var driver_distance_count_data = []
  
  for (var index = 0; index < this.state.fuel_report.fuel_data.length; index++) {
    var fuel_data = {
      label: this.state.fuel_report.fuel_data[index].vehicle_name,
      data: this.state.fuel_report.fuel_data[index].fuel_value,
     
     // data :this.state.fuel_report.fuel_data[index].fuel_value = this.state.fuel_report.fuel_data[index].fuel_value < 0 ? 0 : this.state.fuel_report.fuel_data[index].fuel_value,
      //backgroundColor: GRAPH_BAR_BACKGROUND_COLORS[index],
      //borderColor: 'rgba( )',
      borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
      hoverBorderColor: 'rgba(200, 200, 200, 1)',
      borderWidth: 2,
      fill: false,

    };
    driver_distance_count_data.push(fuel_data)

  }

  var label_list_data = []
  // console.log(CommonFunction.graphDateFormate(this.state.startDate, "$$$$$$$$$$$$$$$$"))
  for (var i = 0; i < this.state.fuel_report.label_list.length; i++) {
    // console.log(CommonFunction.getParsedDate(label_list_data[i]),"&&&&&&&&&&&7")

    //label_list_data.push(CommonFunction.graphDateFormate(this.state.fuel_report.label_list[i]))
   label_list_data.push(CommonFunction.graphDateTimeFormate(this.state.fuel_report.label_list[i]))
  }
  var distnace_graph_Data = {
    labels: label_list_data,
    datasets: driver_distance_count_data
  };



  var chartOptions = {
    responsive: true,
    legend: {
      position: "top",
      align : "start",
      labels : {
        boxWidth : 5,
        fontSize : 13,
        usePointStyle : true,
      },
    },
    title: {
      display: true,
      //text: "Chart.js Bar Chart"
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Fuel(liter)',
          fontSize : 22,
        },
        ticks: {
          beginAtZero: true , fontSize : 13,
        },
        gridLines : {
          borderDash : [2,2]
        }
      }],
      xAxes: [{
        ticks : {
          fontSize : 13,
        },
        gridLines : {
          display : false
        }
      }]
    }
  }
  if (this.state.chart_canvas_array.length > 0) {
    for (var i = 0; i < this.state.chart_canvas_array.length; i++) {
      this.state.chart_canvas_array[i].destroy();
    }
  }
  var barChart = new Chart(fuel_count_data, {

    //type: 'bar',
    type: 'line',
    data: distnace_graph_Data,
    options: chartOptions
  });
  this.state.chart_canvas_array.push(barChart)
}






} else {
  this.setState({ message: res.data.response_message });
  //$('#processing').show()
}
}).catch(err => {
$('#processing').hide();
console.log("journey report error ", err);
});

// *************************************************************   fuel REfuel  *****************************************************************

  ApiUrlCall.generate_refuel_report_graph(joureny_details).then(res =>{
  
  //  console.log("create refuel_report_graph", res.data.data);
      
        if (res.data.is_success === true) {
            $('#processing').hide();
            this.setState({
                refuel_report: res.data.data
            }, () => {
                CommonFunction.tableCellWidth('fuel_economy_report_table');
            });
    
            if (this.state.refuel_report) {
                const vehicleName = this.state.vehicle_name;
                var fuelReportData = this.state.refuel_report.refuel_data.map(fuelReportData => {
                    return fuelReportData.dataPoints;
                });
    
                var eventTimeList = fuelReportData[0].map(dataPoint => {
                    return dataPoint.event_time;
                });
    
                var refuelValueList = fuelReportData[0].map(dataPoint => {
                    return dataPoint.refuel_value;
                });
                if (eventTimeList[0] === 0) {
                  eventTimeList[0] = start_date; 
                  refuelValueList[0] = 0; 
                }

                var ctx = document.getElementById('refuelgraph');
                Chart.defaults.global.defaultFontFamily = "Lato";
                Chart.defaults.global.defaultFontSize = 18;
    
                var refuelCountData = []
                for (var index = 0; index < fuelReportData.length; index++) {
                  
                  var refuelData = {
                    label: this.state.refuel_report.refuel_data[index].vehicle_name,
                    data: fuelReportData[index].map(dataPoint => {
                        return {
                            x: new Date(dataPoint.event_time).getTime(), // Convert event_time to milliseconds
                            y: dataPoint.refuel_value
                        };
                    }),
                    borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
                    hoverBorderColor: 'rgba(200, 200, 200, 1)',
                    borderWidth: 2,
                    fill: false,
                };
                    refuelCountData.push(refuelData);
                }
    
                var eventTimeLabels = eventTimeList.map(eventTime => {
                    return CommonFunction.graphDateTimeFormate(eventTime);
                });
    
                var distanceGraphData = {
                    labels: eventTimeLabels,
                    datasets: refuelCountData
                };
    
             

                var chartOptions = {
                  responsive: true,
                  legend: {
                      position: "top",
                      align: "start",
                      labels: {
                          boxWidth: 5,
                          fontSize: 13,
                          usePointStyle: true,
                      },
                  },
                  title: {
                      display: true,
                      //text: "Chart.js Bar Chart"
                  },
                  scales: {
                      yAxes: [{
                          scaleLabel: {
                              display: true,
                              labelString: 'Fuel (Liter)',
                              fontSize: 22,
                          },
                          ticks: {
                              beginAtZero: true,
                              fontSize: 13,
                          },
                          gridLines: {
                              borderDash: [2, 2]
                          }
                      }],
                      xAxes: [{
                          ticks: {
                              fontSize: 13,
                          },
                          gridLines: {
                              display: false
                          }
                      }]
                  },
                  tooltips: {
                      callbacks: {
                          label: function(tooltipItem, data) {
                              var label = data.datasets[tooltipItem.datasetIndex].label || '';
                              if (label) {
                                  label += ': ';
                              }
                              label += tooltipItem.yLabel + ' Liter';
                              return label;
                          }
                      }
                  }
              }
              
    
                if (this.state.refuel_chart_array.length > 0) {
                    for (var i = 0; i < this.state.refuel_chart_array.length; i++) {
                        this.state.refuel_chart_array[i].destroy();
                    }
                }
    
                var barChart2 = new Chart(ctx, {
                    type: 'line',
                  //  type: 'bar',
                    data: distanceGraphData,
                    options: chartOptions
                });
    
                this.state.refuel_chart_array.push(barChart2);
            }
    
        } else {
            this.setState({ message: res.data.response_message });
            //$('#processing').show()
        }
    }).catch(err => {
        $('#processing').hide();
        console.log("journey report error ", err);
    });
    
  
// *************************************************** Fuel Theft *********************************************************************************

ApiUrlCall.generate_fuel_theft_report_graph(joureny_details).then(res => {
    
  // console.log("generate_fuel_theft_report_graph",res.data.data)

   if (res.data.is_success === true) {
     $('#processing').hide()
     this.setState({
      fuel_theft_report: res.data.data
     }, () => {
       CommonFunction.tableCellWidth('theft_report_table')
     })


     if (this.state.fuel_theft_report) {
      const vehicleName = this.state.vehicle_name;

      var theft_data_report = this.state.fuel_theft_report.theft_data.map(theft_data_report => {
          return theft_data_report.dataPoints;
      });

      var eventTimeList = theft_data_report[0].map(dataPoint => {
          return dataPoint.event_time;
      });

      var theftValueList = theft_data_report[0].map(dataPoint => {
          return dataPoint.theft_value;
      });

      
       if (eventTimeList[0] === 0) {
        eventTimeList[0] = start_date; 
        theftValueList[0] = 0;
    }

      var ctx = document.getElementById('theftgraph');
      Chart.defaults.global.defaultFontFamily = "Lato";
      Chart.defaults.global.defaultFontSize = 18;

      var theftCountData = []
      for (var index = 0; index < theft_data_report.length; index++) {
        
        var refuelData = {
          label: this.state.fuel_theft_report.theft_data[index].vehicle_name,
          data: theft_data_report[index].map(dataPoint => {
              return {
                  x: new Date(dataPoint.event_time).getTime(), // Convert event_time to milliseconds
                  y: dataPoint.theft_value
              };
          }),
          borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
          hoverBorderColor: 'rgba(200, 200, 200, 1)',
          borderWidth: 2,
          fill: false,
      }; 
          theftCountData.push(refuelData);
      }

      var eventTimeLabels = eventTimeList.map(eventTime => {
          return CommonFunction.graphDateTimeFormate(eventTime);
      });

      var theftGraphData = {
          labels: eventTimeLabels,
          datasets: theftCountData
      };

      // var chartOptions = {
      //     responsive: true,
      //     legend: {
      //         position: "top",
      //         align: "start",
      //         labels: {
      //             boxWidth: 5,
      //             fontSize: 13,
      //             usePointStyle: true,
      //         },
      //     },
      //     title: {
      //         display: true,
      //         //text: "Chart.js Bar Chart"
      //     },
      //     scales: {
      //         yAxes: [{
      //             scaleLabel: {
      //                 display: true,
      //                 labelString: 'Fuel (Liter)',
      //                 fontSize: 22,
      //             },
      //             ticks: {
      //                 beginAtZero: true,
      //                 fontSize: 13,
      //             },
      //             gridLines: {
      //                 borderDash: [2, 2]
      //             }
      //         }],
      //         xAxes: [{
      //             ticks: {
      //                 fontSize: 13,
      //             },
      //             gridLines: {
      //                 display: false
      //             }
      //         }]
      //     }
      // }

      var chartOptions = {
        responsive: true,
        legend: {
            position: "top",
            align: "start",
            labels: {
                boxWidth: 5,
                fontSize: 13,
                usePointStyle: true,
            },
        },
        title: {
            display: true,
            //text: "Chart.js Bar Chart"
        },
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Fuel (Liter)',
                    fontSize: 22,
                },
                ticks: {
                    beginAtZero: true,
                    fontSize: 13,
                },
                gridLines: {
                    borderDash: [2, 2]
                }
            }],
            xAxes: [{
                ticks: {
                    fontSize: 13,
                },
                gridLines: {
                    display: false
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += tooltipItem.yLabel + ' Liter';
                    return label;
                }
            }
        }
    }
    

      if (this.state.theft_data_array.length > 0) {
          for (var i = 0; i < this.state.theft_data_array.length; i++) {
              this.state.theft_data_array[i].destroy();
          }
      }

      var barChart2 = new Chart(ctx, {
          type: 'line',
          data: theftGraphData,
          options: chartOptions
      });

      this.state.theft_data_array.push(barChart2);
  }
   }
   
   else {
     this.setState({ message: res.data.response_message })
     //$('#processing').show()

   }
 }).catch(err => {
   $('#processing').hide()
   console.log("journy report error ", err)

 })

//  ***************************************************** FUEL ECONOMY ******************************************************************************


ApiUrlCall.generate_economy_report_graph(joureny_details).then(res =>{
     
//  console.log("generate_economy_report_graph", res.data.data);
  if (res.data.is_success === true) {
      $('#processing').hide();
      this.setState({
         economy_report: res.data.data
      }, () => {
         CommonFunction.tableCellWidth('fuel_economy_report_table');
      });

      if (this.state.economy_report) {
        const vehicleName = this.state.vehicle_name;
        var fuelReportData = this.state.economy_report.fuel_economy_data.map(fuelReportData => {
            return fuelReportData.dataPoints;
        });

        var eventTimeList = fuelReportData[0].map(dataPoint => {
            return dataPoint.event_time;
        });

        var economyValueList = fuelReportData[0].map(dataPoint => {
            return dataPoint.refuel_value;
        });
        
        if (eventTimeList[0] === 0) {
          eventTimeList[0] = start_date; 
          economyValueList[0] = 0;
      }
        var ctx = document.getElementById('economygraph');
        Chart.defaults.global.defaultFontFamily = "Lato";
        Chart.defaults.global.defaultFontSize = 18;

        var refuelCountData = []
        for (var index = 0; index < fuelReportData.length; index++) {

          var refuelData = {
            label: this.state.economy_report.fuel_economy_data[index].vehicle_name,
            data: fuelReportData[index].map(dataPoint => {
                return {
                    x: new Date(dataPoint.event_time).getTime(), // Convert event_time to milliseconds
                    y: dataPoint.fuel_economy
                };
            }),
            borderColor: GRAPH_BAR_BACKGROUND_COLORS[index],
            hoverBorderColor: 'rgba(200, 200, 200, 1)',
            borderWidth: 2,
            fill: false,
        };  
            refuelCountData.push(refuelData);
        }

        var eventTimeLabels = eventTimeList.map(eventTime => {
            return CommonFunction.graphDateTimeFormate(eventTime);
        });

        var distanceGraphData = {
            labels: eventTimeLabels,
            datasets: refuelCountData
        };

        // var chartOptions = {
        //     responsive: true,
        //     legend: {
        //         position: "top",
        //         align: "start",
        //         labels: {
        //             boxWidth: 5,
        //             fontSize: 13,
        //             usePointStyle: true,
        //         },
        //     },
        //     title: {
        //         display: true,
        //         //text: "Chart.js Bar Chart"
        //     },
        //     scales: {
        //         yAxes: [{
        //             scaleLabel: {
        //                 display: true,
        //                 labelString: 'Fuel (Liter)',
        //                 fontSize: 22,
        //             },
        //             ticks: {
        //                 beginAtZero: true,
        //                 fontSize: 13,
        //             },
        //             gridLines: {
        //                 borderDash: [2, 2]
        //             }
        //         }],
        //         xAxes: [{
        //             ticks: {
        //                 fontSize: 13,
        //             },
        //             gridLines: {
        //                 display: false
        //             }
        //         }]
        //     }
        // }
           

        var chartOptions = {
          responsive: true,
          legend: {
              position: "top",
              align: "start",
              labels: {
                  boxWidth: 5,
                  fontSize: 13,
                  usePointStyle: true,
              },
          },
          title: {
              display: true,
              //text: "Chart.js Bar Chart"
          },
          scales: {
              yAxes: [{
                  scaleLabel: {
                      display: true,
                      labelString: 'Fuel (Liter)',
                      fontSize: 22,
                  },
                  ticks: {
                      beginAtZero: true,
                      fontSize: 13,
                  },
                  gridLines: {
                      borderDash: [2, 2]
                  }
              }],
              xAxes: [{
                  ticks: {
                      fontSize: 13,
                  },
                  gridLines: {
                      display: false
                  }
              }]
          },
          tooltips: {
              callbacks: {
                  label: function(tooltipItem, data) {
                      var label = data.datasets[tooltipItem.datasetIndex].label || '';
                      if (label) {
                          label += ': ';
                      }
                      label += tooltipItem.yLabel + ' Liter';
                      return label;
                  }
              }
          }
      }
      
        if (this.state.fuel_economy_array.length > 0) {
            for (var i = 0; i < this.state.fuel_economy_array.length; i++) {
                this.state.fuel_economy_array[i].destroy();
            }
        }

        var barChart4 = new Chart(ctx, {
            type: 'line',
            data: distanceGraphData,
            options: chartOptions
        });

        this.state.fuel_economy_array.push(barChart4);
    }

  } else {
      this.setState({ message: res.data.response_message });
      //$('#processing').show()
  }
}).catch(err => {
  $('#processing').hide();
  console.log("journey report error ", err);
});
    }else{
      $('#processing').hide()
      this.setState({
        openAlert : true,
        message1 : "Not allowed to select more than 90 days"
      })
    }
    // console.log(this.state.fuel_report, "journye state data")
  }

  reset = () => {
    this.setState({ dateRange: initialState });
    // console.log('in reset')
  }
  
  onChange_selectDefault(e) {
    // current array of options
    const options = this.state.isChecked
    let index
    // console.log(this.state.isChecked)
    // check if the check box is checked or unchecked
    if (this.state.isChecked.checked) {

      // add the numerical value of the checkbox to options array
      options.push(+this.state.isChecked)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(+this.state.isChecked)

      options.splice(index, 1)
    }

    // sort the array
    options.sort()

    // update the state with the new array of options
    this.setState({ options: options })
  }

  // onChange(e) {
  //   const options = this.state.chckebox_value
  //   let index
  //   console.log(this.state.chckebox_value)
  
  //   if (e.target.checked) {
  //     options.push(+e.target.value)
  //     this.forceUpdate()
  //   } else {
   
  //     index = options.indexOf(+e.target.value)

  //     options.splice(index,1 )
  //     this.forceUpdate()
  //   }
  //   options.sort()
  //   this.setState({ options: options })
  // }



// onChange(e) {
//   const selectedSerialNumber = +e.target.value;
//   const { chckebox_value } = this.state;

//   // Check if the selected vehicle is already in the selected list
//   const isSelected = chckebox_value.includes(selectedSerialNumber);

//   let updatedSelection = [];

//   // If the vehicle is already selected, remove it (toggle off)
//   if (isSelected) {
//       updatedSelection = chckebox_value.filter(serialNumber => serialNumber !== selectedSerialNumber);
//   } else {
//       // If the vehicle is not selected, deselect all other vehicles and select the new one
//       updatedSelection = [selectedSerialNumber];
//   }

//   this.setState({ chckebox_value: updatedSelection });
// }




onChange(e) {
  const selectedSerialNumber = +e.target.value;
  const { chckebox_value } = this.state;

  // Check if the selected vehicle is already in the selected list
  const isSelected = chckebox_value.includes(selectedSerialNumber);

  let updatedSelection = [];

  // If the vehicle is already selected, remove it (toggle off)
  if (isSelected) {
    updatedSelection = chckebox_value.filter(serialNumber => serialNumber !== selectedSerialNumber);
  } else {
    // If the vehicle is not selected, deselect all other vehicles and select the new one
    updatedSelection = [selectedSerialNumber];
  }

  this.setState({ chckebox_value: updatedSelection });
}

// onChange(e) {
//   const selectedSerialNumber = +e.target.value;
//   let { chckebox_value } = this.state;  

//   // Check if the selected vehicle is already in the selected list
//   const isSelected = chckebox_value.includes(selectedSerialNumber);

//   // If the vehicle is already selected, remove it (toggle off)
//   if (isSelected) {
//     chckebox_value = chckebox_value.filter(serialNumber => serialNumber !== selectedSerialNumber);
//   } else {
//     // If the vehicle is not selected, deselect all other vehicles and select the new one
//     chckebox_value = [selectedSerialNumber];
//   }

//   this.setState({ chckebox_value });
// }



  StartdateClicked = date => {
    var isoStartDate = date
    this.setState({ startDate: isoStartDate })
    $('#start_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  EnddateClicked = date => {
    var isoStartDate = date
    isoStartDate.setHours(23,59,59,0);
    var Difference_In_Time = isoStartDate.getTime() - this.state.startDate.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(Difference_In_Days > 90){ 
      $('#processing').hide()     
      this.setState({       
        sevenDayFlag : false,   
        openAlert : true,
        message1 : "Not allowed to select more than 90 days"     
      })     
      return 
    }else{
      this.setState({
        sevenDayFlag : true,
      })
    } 
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ endDate: isoStartDate })
    $('#end_date_cal').slideUp(200)
  }
  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('#end_date_cal').slideUp()
    this.setState({
      showStartTime : false,
      showEndTime : false,
    })
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('#start_date_cal').slideUp()
    this.setState({
      showStartTime : false,
      showEndTime : false,
    })
  }

  dataViewClick = (e) => {
    
    const graphClick = "graph_btn"
    const tableClick = "table_btn"
    $(e.currentTarget).addClass('active_view_btn')
    $(e.currentTarget).removeClass('inactive_view_btn')
    $(e.currentTarget).siblings().addClass('inactive_view_btn')
    $(e.currentTarget).siblings().removeClass('active_view_btn')
    if (e.currentTarget.id === graphClick) {
      document.getElementById("graphical_view_div").style.display = "block";
      document.getElementById("table_view_div").style.display = "none";
    } else if (e.currentTarget.id === tableClick) {
      document.getElementById("table_view_div").style.display = "block";
      CommonFunction.tableCellWidth('fuel_report_table')
   
      document.getElementById("graphical_view_div").style.display = "none";
    }
  }

  refueldataViewClick = (e) => {
    const graphClick = "graph_btn"
    const tableClick = "table_btn"
    $(e.currentTarget).addClass('active_view_btn')
    $(e.currentTarget).removeClass('inactive_view_btn')
    $(e.currentTarget).siblings().addClass('inactive_view_btn')
    $(e.currentTarget).siblings().removeClass('active_view_btn')
    if (e.currentTarget.id === graphClick) {
      document.getElementById("refule_graphical_view_div").style.display = "block";
      document.getElementById("refule_table_view_div").style.display = "none";
    } else if (e.currentTarget.id === tableClick) {
      document.getElementById("refule_table_view_div").style.display = "block";
      CommonFunction.tableCellWidth('refuel_report_table')
   
      document.getElementById("refule_graphical_view_div").style.display = "none";
    }
  }
  //  ******************************************** theft  graph **************************************

  theftdataViewClick = (e) => {
    const graphClick = "graph_btn"
    const tableClick = "table_btn"
    $(e.currentTarget).addClass('active_view_btn')
    $(e.currentTarget).removeClass('inactive_view_btn')
    $(e.currentTarget).siblings().addClass('inactive_view_btn')
    $(e.currentTarget).siblings().removeClass('active_view_btn')
    if (e.currentTarget.id === graphClick) {
      document.getElementById("theft_graphical_view_div").style.display = "block";
      document.getElementById("theft_table_view_div").style.display = "none";
    } else if (e.currentTarget.id === tableClick) {
      document.getElementById("theft_table_view_div").style.display = "block";
      CommonFunction.tableCellWidth('theft_report_table')
   
      document.getElementById("theft_graphical_view_div").style.display = "none";
    }
  }

// ***************************************  ECONOMY  ***********************************
  economydataViewClick = (e) => {
    const graphClick = "graph_btn"
    const tableClick = "table_btn"
    $(e.currentTarget).addClass('active_view_btn')
    $(e.currentTarget).removeClass('inactive_view_btn')
    $(e.currentTarget).siblings().addClass('inactive_view_btn')
    $(e.currentTarget).siblings().removeClass('active_view_btn')
    if (e.currentTarget.id === graphClick) {
      document.getElementById("economy_graphical_view_div").style.display = "block";
      document.getElementById("economy_table_view_div").style.display = "none";
    } else if (e.currentTarget.id === tableClick) {
      document.getElementById("economy_table_view_div").style.display = "block";
      CommonFunction.tableCellWidth('fuel_economy_report_table')
   
      document.getElementById("economy_graphical_view_div").style.display = "none";
    }
  }

  doubleDigit = (value) => {
    if(value <= 9){
      return '0'+value
    }else{
      return value
    }
  }


  selectStartTime = (newTime) => {    
    this.setState({
      startTime : newTime.formatted12,
      sendStartTime : newTime.formatted24
    })
  }

  showStartTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if(this.state.showStartTime){
      this.setState({
        showStartTime : false,
        showEndTime : false
      })
    }else{      
      this.setState({
        showStartTime : true,
        showEndTime : false
      })
    }
  }

  selectEndTime = (newTime) => {    
    this.setState({
      endTime : newTime.formatted12,
      sendEndTime : newTime.formatted24
    })
  }

  showEndTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if(this.state.showEndTime){
      this.setState({
        showEndTime : false,
        showStartTime : false
      })
    }else{
      this.setState({
        showEndTime : true,
        showStartTime : false
      })
    }
  }

  render() {
    // console.log(this.state.isChecked, "??????????????")
    // console.log(this.state.chckebox_value)
    const { checked } = this.state;
    const {t} = this.props
    const checkedCount = Object.keys(checked).filter(key => checked[key]).length;
    const disabled = checkedCount > 1;
    const value = this.state.value;
    const selected = value.length;




    if (this.state.vlcGroupDataList) {
      let counter = 0
      //console.log(this.state.vehicle_name,)
      var groupdataname = this.state.vlcGroupDataList.map(groupdataname => {
        //console.log(groupdataname.serialNoList)
        //this.state.serial_number_is = vehicledataname.serial_number
        return (
          <Grid container className="select_opt_row">
            <Grid item xs={2}>
              <input type="radio"
                // onClick={this.checkbox}
                // defaultChecked={this.state.checkbox_value}
                value={groupdataname.serialNoList}
                onChange={this.onChangeForGroup.bind(this)} name="vehicle_tick" />
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{groupdataname.groupName}</p>
            </Grid>
          </Grid>
        )
      })
    }

    var minDate = new Date(); minDate.setDate(minDate.getDate() - 180);

    if (this.state.vehicle_name) {
      let counter = 0

      var vehicledataname = this.state.vehicle_name.vehicle_list.map(vehicledataname => {
        this.state.serial_number_is = vehicledataname.serial_number
       //console.log(this.state.vehicle_name)
         if (counter <= 1) {
           this.state.is_checked = false
         }
        
        if(vehicledataname.is_expired == false){
     // console.log(vehicledataname.name)
          return (
            <div class="vehicle">
                <Grid container className="select_opt_row" >
                  <Grid item xs={2}>
                    <input type="radio"
                      // onClick={this.checkbox}
                      //defaultChecked={CommonFunction.manage_vehicle_new(this.state.is_checked)}
                      value={vehicledataname.serial_number}
                      onChange={this.onChange.bind(this)} name="vehicle_tick" />
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{vehicledataname.name}</p>
                  </Grid>
                </Grid>
            </div>
          )
        }
      })

    }

  if (this.state.fuel_report) {
    const vehiclename = this.state.vehicle_name;
  
    var table_data = []; // Initialize an empty array to store table data
  
    var fuel_report_data2 = this.state.fuel_report.fuel_data.map((fuel_report_data2, outerIndex) => {
  

      return fuel_report_data2.odometer.map((value, innerIndex) => {
        // Create a data object for each row
        const rowData = {
          vehicleName: fuel_report_data2.vehicle_name,
          label: this.state.fuel_report.label_list[innerIndex],
          fuelValue: fuel_report_data2.fuel_value[innerIndex],
          latitude: parseFloat(fuel_report_data2.latitude[innerIndex]).toFixed(4),
          longitude: parseFloat(fuel_report_data2.longitude[innerIndex]).toFixed(4),
          address: fuel_report_data2.address[innerIndex],
          ignitionState: fuel_report_data2.ignition_status[innerIndex],
        };
  
        // Push the data object to the array
        table_data.push(rowData);
  
        // Return JSX for the table row
        return (
          <tr key={`${outerIndex}-${innerIndex}`}>
            <td className="work_sans_regular16"> {rowData.vehicleName} </td>
            <td>{rowData.label}</td>
            <td className="BSC_semi_bold16">{rowData.fuelValue} L</td>
            <td className="BSC_semi_bold16">{rowData.latitude},{rowData.longitude}</td>
            <td className="BSC_semi_bold16">{rowData.address}</td>
            <td className="BSC_semi_bold16">{rowData.ignitionState}</td>
          </tr>
        );
      });
    });
  }

if (this.state.refuel_report) {
  const vehiclename = this.state.vehicle_name;

  var refule_table_data = []; // Initialize an empty array to store table data

  var refuel_report_data2 = this.state.refuel_report.refuel_data.map((refuel_report_data2, outerIndex) => {
      return refuel_report_data2.dataPoints.map((dataPoint, innerIndex) => {
          // Accessing both event_time and refuel_value from dataPoint
          const eventTime = dataPoint.event_time;
          const refuelValue = dataPoint.refuel_value;

          // Create a data object for each row
          const rowData = {
              label: eventTime,
              refuelValue: refuelValue,
              vehicleName: refuel_report_data2.vehicle_name,
              address: refuel_report_data2.address[innerIndex], // Correctly index address
          };

          // Push the data object to the array
          refule_table_data.push(rowData);

          // Return JSX for the table row
          return (
              <tr key={`${outerIndex}-${innerIndex}`}>
                  <td className="work_sans_regular16"> {rowData.vehicleName} </td>
                  <td className="work_sans_regular16">{rowData.label}</td>
                  <td className="BSC_semi_bold16">{rowData.address}</td>
                  <td className="BSC_semi_bold16">{rowData.refuelValue} L </td>
                  {/* <td className="BSC_semi_bold16">{rowData.latitude},{rowData.longitude}</td> */}
              </tr>
          );
      });
  });
}

  if (this.state.fuel_theft_report) {
    const vehiclename = this.state.vehicle_name;

    var refule_table_data = []; // Initialize an empty array to store table data

    var theft_data_report_data2 = this.state.fuel_theft_report.theft_data.map((theft_data_report_data2, outerIndex) => {

        return theft_data_report_data2.dataPoints.map((dataPoint, innerIndex) => {
          const eventTime = dataPoint.event_time;
          const theft_value = dataPoint.theft_value;
            // Create a data object for each row
            const rowData = {
                label: eventTime,
                theft_fuelValue: theft_value,
                vehicleName: theft_data_report_data2.vehicle_name,
                address: theft_data_report_data2.address[innerIndex],
               
            };

            // Push the data object to the array
            refule_table_data.push(rowData);

            // Return JSX for the table row
            return (
                <tr key={`${outerIndex}-${innerIndex}`}>
                    <td className="work_sans_regular16"> {rowData.vehicleName} </td>
                    <td className="work_sans_regular16">{rowData.label}</td>
                    <td className="BSC_semi_bold16">{rowData.address}</td>
                    <td className="BSC_semi_bold16">{rowData.theft_fuelValue}  L</td>
                    {/* <td className="BSC_semi_bold16">{rowData.latitude},{rowData.longitude}</td> */}
                </tr>
            );
        });
    });
}

  if (this.state.economy_report) {
   // const vehiclename = this.state.vehicle_name;
    var economy_table_data = []; // Initialize an empty array to store table data

    var economy_report_data2 = this.state.economy_report.fuel_economy_data.map((economy_report_data2, outerIndex) => {

        return economy_report_data2.dataPoints.map((dataPoint, innerIndex) => {
            // Create a data object for each row
            const event_time = dataPoint.event_time;
            const fuel_economy = dataPoint.fuel_economy;
            const rowData = {

                vehicleName: economy_report_data2.vehicle_name,
                label: event_time,
                fuelValue: fuel_economy,
                total_distance: economy_report_data2.total_distance,
                total_fuel_used: economy_report_data2.total_fuel_used

                // address: economy_report_data2.address[innerIndex],
                //latitude: parseFloat(economy_report_data2.latitude[innerIndex]).toFixed(4),
                //longitude: parseFloat(economy_report_data2.longitude[innerIndex]).toFixed(4),
            };

            // Push the data object to the array
            economy_table_data.push(rowData);

            // Return JSX for the table row
            return (
                <tr key={`${outerIndex}-${innerIndex}`}>
                    <td className="work_sans_regular16"> {rowData.vehicleName} </td>
                    <td className="work_sans_regular16">{rowData.label}</td>
                    <td className="BSC_semi_bold16">{rowData.fuelValue}  L </td>
                    <td className="BSC_semi_bold16">{rowData.total_distance}  </td>
                    <td className="BSC_semi_bold16">{rowData.total_fuel_used}  L </td>
                    {/* <td className="BSC_semi_bold16">{rowData.address}</td> */}                 
                    {/* <td className="BSC_semi_bold16">{rowData.latitude},{rowData.longitude}</td> */}
                </tr>
            );
        });
    });
}




    if(this.state.chckebox_value.length > 0){
      if(this.state.chckebox_value.length == 1){
        var lableText = this.state.chckebox_value.length+" "+t('ars.sum.v1')
      }else{
        var lableText = this.state.chckebox_value.length+" "+t('ars.sum.v2')
      }
    }
    
    else{
      var lableText =t('ars.sum.v3')
    }
    var excel_name = "Fuel_Report_"+CommonFunction.downloadReportDate(this.state.date)+".xlsx"
   
    return (
      <div>
        <div className="report_filter_div">
          <Grid spacing={2} container>
            <Grid item xs>

              <p>{t('ct.startdate')}</p>
              <div className="input_div" onClick={this.showStartDate}>
                <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={this.dateFormate(this.state.startDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="start_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.StartdateClicked}
                  value={this.state.date}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
            </Grid>
            <Grid item xs>
              <p>Start Time</p>
              <div onClick={this.showStartTimeContent} className="time_div">
                <p>{this.state.startTime}</p>
              </div>
              {this.state.showStartTime &&
                <div style={{position : "absolute", zIndex : "9"}}>
                  <Timekeeper 
                  time={this.state.startTime}
                  switchToMinuteOnHourSelect
                  onDoneClick={() => {
                    this.setState({
                      showStartTime : false
                    })
                  }}
                  onChange={this.selectStartTime}
                  />
                </div>
              }
            </Grid>
            <Grid item xs>
              <p>{t('ct.enddate')}</p>
              <div className="input_div" onClick={this.showEndDate}>
                <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={this.dateFormate(this.state.endDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="end_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.EnddateClicked}
                  value={this.state.date}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
            </Grid>
            <Grid item xs>
              <p>End Time</p>
              <div onClick={this.showEndTimeContent} className="time_div">
                <p>{this.state.endTime}</p>
              </div>
              {this.state.showEndTime &&
                <div style={{position : "absolute", zIndex : "9"}}>
                  <Timekeeper 
                  time={this.state.endTime}
                  switchToMinuteOnHourSelect
                  onDoneClick={() => {
                    this.setState({
                      showEndTime : false
                    })
                  }}
                  onChange={this.selectEndTime}
                  />
                </div>
              }
            </Grid>
            

<Grid item xs >
<label className="work_sans_regular16">{t('ars.sum.v2')}</label>

<div style={{ position: "relative" }} onClick={this.dropDownClick}>
  <div className="summary_report_filters">
    <label>
      {lableText}
    </label>
  </div>
  <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
</div>
<div className="all_region_dd">
  <div className="dropdown_parent" id="vehicles_select">
  <Grid container>

  <Grid item xs>
  <input id="searchbar" onChange={this.searchVehicle} type="search"
        name="search" placeholder="Search ..."/>
    </Grid>
  </Grid>
    {vehicledataname}                  
  </div>
</div>
</Grid>

        <Grid item xs>
              <label>&nbsp;</label>
          <button className="btn orange-btn btn-block" style={{ marginTop: "6px" }} onClick={this.create_Fuel_Report}>{t('ars.generate')}</button>
        </Grid>
           
          </Grid>
         
        </div>
        
  {/* *******************************************************************  feul incidence *************************************************************       */}

        <div className="box_graph_view"style={{ marginTop: "30px" }}>
            <Grid spacing={2} container >
                <Grid item xs>
                  <h3 className="BSC_extra_bold"> Temprature </h3>
                </Grid> 
        <div className="graph_btn">
            <div className="active_view_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="graph_btn">
            <label>{t('ars.graph')}</label>
            </div>
            <div className="inactive_view_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="table_btn">
            <label>table</label>
            </div> 
          </div>   
          <Grid spacing={2} container>
                <Grid item xs>
                </Grid>        
                <Grid item xs={2} className="pdf_excel_download" onClick={this.downloadFuelReportExcel} style={{ marginRight: "65px" }}>
                  <img src="images/group-6-copy-2.png" style={{ marginRight: "25px" }}></img>
                  <label className="BSC_semi_bold18">{t('ars.excel')}</label>
                </Grid>
          </Grid>            
          </Grid>
        <div style={{ marginTop: "30px" }}>

          {/* start of graphical view */}
          <div id="graphical_view_div">

            <div className="vr_graph_view">
             
              <div className="vr_graph_view_fuel">
                <Grid container>
                  <canvas id="fuelgraph" width="1000" height="300"></canvas>  
                </Grid>
              </div>


           </div>
          </div>
       
          <div id="table_view_div" style={{ display: "none", marginBottom: "50px" }}>
            <Grid container>
              <div className="tableOuterDiv" style={{ width: "100%" }}>
                <div className="cardView tableParent" id="fuel_report_table">
                  <Table responsive>
                    <thead className="tableHeader">
                      <tr className="BSC_extra_bold18" >
                        <th>Vehical Name</th>
                        <th>Event Time</th>
                        <th>Fuel Value</th>
                        <th> Coordinates </th>
                        <th>Address</th>
                        <th> Ignition State</th>

                      </tr>
                    </thead>
                    <tbody className="tableBody">

                      {fuel_report_data2}

                    </tbody>
                  </Table>
                </div>
              </div>

            </Grid>
          </div>
          {/* end of table view */}
        </div>        
      </div>



        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.message1}
        />
      </div>

      
    );
  }

}
export default withTranslation()(Temprature);


