import React, { Component } from 'react';
import $ from 'jquery';
import Profile from './profile';
import DashboardSetting from './dashboard_setting';
import AlertReport from './alert_report';
import ApiItegration from './apiintegration'
import Region from './region';
import NewRegion from './new_region'
import VendorIntegration from './vendor_integration'
import TrackerManagement from './tracker_management'
import Location from './add_location'
import Tooltip from '@material-ui/core/Tooltip';
import Navbar from '../../Config/Navbar';
import './setting.scss'
import {Link}  from 'react-router-dom'
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resizeMap : false,
            tabComponent : <Profile/>,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            var navBarHeight = $('.navBar').outerHeight()
            $('.setting_parent').css({
              paddingTop : navBarHeight+60,
            })            
        }, 500);
    }

    optionSeletion = (div) => {
        $(div).addClass('settting_sel')
        $(div).siblings().removeClass('settting_sel')
    }

    profileClick = (e) =>{
        this.optionSeletion(e.currentTarget)           
        this.setState({
            tabComponent : <Profile/>
        })
    }

    dashSettingClick = (e) => {
        this.optionSeletion(e.currentTarget)        
        this.setState({
            tabComponent : <DashboardSetting />
        })
        // $('#tab_component').append(<DashboardSetting />)
    }   
    
    alertReportClick = (e) => {
        this.optionSeletion(e.currentTarget)        
        this.setState({
            tabComponent : <AlertReport />
        })        
    }

    apiIntegrationClick = (e) => {
        this.optionSeletion(e.currentTarget)
        this.setState({
            tabComponent : <ApiItegration />
        })
    }

    newRegionClick = (e) => {
        this.optionSeletion(e.currentTarget)
        this.setState({
            tabComponent : <NewRegion />
        })
    }

    vendorClick = (e) => {
        this.optionSeletion(e.currentTarget)
        this.setState({
            tabComponent : <VendorIntegration />
        })
    }
    trackerManagementClick = (e) => {
       this.optionSeletion(e.currentTarget)
        this.setState({
            tabComponent : <TrackerManagement />
        })
    }

    addlocationClick = (e) => {
        this.optionSeletion(e.currentTarget)
         this.setState({
             tabComponent : <Location />
         })
     }
    // regionClick = (e) => {
    //     this.optionSeletion(e.currentTarget)
    //     this.showDiv('region_div')
    //     window.dispatchEvent(new Event('resize'));
    // }

    render() {
        const {t} = this.props
        return (
            <div>
                <Navbar/>
                <div className="setting_parent">
                    <Link to="/"><img src="images/arrow.png"></img></Link>
                    <p className="page_header" style={{display : "inline",marginLeft:"15px"}}>{t('ss.setting')}</p>
                    <span style={{float : "right", display : "none"}}>version : {constant.app_version}</span>
                    <div className="setting_opt">
                        <div onClick={this.profileClick} className="settting_sel">
                            <p className="p_margin0">{t('ss.profile')}</p>
                        </div>
                        <div onClick={this.dashSettingClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.dash.setting')}</p>
                        </div>
                        <div onClick={this.alertReportClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.alert.report')}</p>
                        </div>
                        {/* <div onClick={this.alertReportClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                        <div onClick={this.alertReportClick} style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.alert.report')}</p>
                            </div>
                        </div> */}
                        <div onClick={this.apiIntegrationClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.api.header')}</p>
                        </div>
                        {/* <div onClick={this.newRegionClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.region')}</p>
                        </div> */}
                        <div onClick={this.newRegionClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                        <div onClick={this.newRegionClick} style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.region')}</p>
                        </div>
                        </div>
                        {/* 8 = vendor api integration */}
                        <div style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                            <div onClick={this.vendorClick} style={{display : localStorage.getItem('group') == 8 ? "" : "none"}}>
                                <p className="p_margin0">{t('ss.vendor')}</p>
                            </div>
                        </div>
                        {/* <div onClick={this.trackerManagementClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>                            
                            <Tooltip title={<span style={{fontSize : "12px"}}>Map Your Vehicle & Tracker from this section</span>} placement="top">
                                <p className="p_margin0">{t('ss.tracker.mgmt')}<i class="fa fa-info-circle" style={{marginLeft : "5px"}} aria-hidden="true"></i></p>
                            </Tooltip>
                        </div> */}
                         <div onClick={this.trackerManagementClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>                            
                         <div onClick={this.trackerManagementClick} style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>
                            <Tooltip title={<span style={{fontSize : "12px"}}>Map Your Vehicle & Tracker from this section</span>} placement="top">
                                <p className="p_margin0">{t('ss.tracker.mgmt')}<i class="fa fa-info-circle" style={{marginLeft : "5px"}} aria-hidden="true"></i></p>
                            </Tooltip>
                            </div>
                        </div>
                        {/* <div onClick={this.addlocationClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.add.location')}</p>
                        </div> */}
                        <div onClick={this.addlocationClick} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                        <div onClick={this.addlocationClick} style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>
                            <p className="p_margin0">{t('ss.add.location')}</p>
                        </div>
                        </div>
                        {/* <div onClick={this.regionClick}>
                            <p className="p_margin0">Regions</p>
                        </div> */}
                    </div>
                    {/* contend to show on slection */}
                    <div style={{marginTop: "22px"}}>
                        <div id="tab_component">
                            {this.state.tabComponent}
                        </div>                                                                        
                        {/* <div id="region_div">   
                            <Region />
                        </div> */}
                    </div>
                    {/* contend to show on slection end*/}
                </div>                
            </div>
        );
    }
}

export default withTranslation()(Setting);
