import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Map from '../map/map'
import ApiUrlCall from '../API/apiurl';
import { count } from '@progress/kendo-data-query/dist/npm/array.operators';

const L = window.L
class Region extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoneContent: [],
      zoneCounter: 0,
      zoneMarker: [],
      zoneRadiusMarker: [],
      regionData: [],
      regionList: '',
    }
  }

  componentDidMount() {
    console.log('region loaded')
    this.getZoneList()

  }
  componentWillUnmount() {
    console.log('region unmounted')
  }

  editRegion = (e) => {
    var zoneRow = $(e.currentTarget).parent().parent().parent()
    var nameInput = $(zoneRow).find('.add_name')
    var nameP = $(zoneRow).find('.add_name').next()
    var regionInput = $(zoneRow).find('.add_region')
    var regionP = $(zoneRow).find('.add_region').next()
    var regionSlider = $(zoneRow).find('.region_radius').siblings()
    var regionRadius = $(zoneRow).find('.region_radius')
    var saveImage = $(zoneRow).find('.save_region')
    var saveImageSibling = $(zoneRow).find('.save_region').parent().siblings()
    console.log('HTML DOM ELEMENT', nameInput)
    $(nameInput).show(); $(regionInput).show(); $(regionSlider).show(); $(saveImage).show()
    $(nameP).hide(); $(regionP).hide(); $(regionRadius).hide(); $(saveImageSibling).hide()
  }

  saveRegion = (e, index) => {
    var zoneRow = $(e.currentTarget).parent().parent().parent()
    var nameInput = $(zoneRow).find('.add_name')
    var nameP = $(zoneRow).find('.add_name').next()
    var regionInput = $(zoneRow).find('.add_region')
    var regionP = $(zoneRow).find('.add_region').next()
    var regionSlider = $(zoneRow).find('.region_radius').siblings()
    var regionRadius = $(zoneRow).find('.region_radius')
    var saveImage = $(zoneRow).find('.save_region')
    var saveImageSibling = $(zoneRow).find('.save_region').parent().siblings()
    //adding text in the p tag    
    $(nameP).text(this.state.regionData[index].name)
    $(regionP).text(this.state.regionData[index].address)
    $(regionRadius).text(this.state.regionData[index].radius)
    console.log('HTML DOM ELEMENT', this.state.regionData)
    $(nameInput).hide(); $(regionInput).hide(); $(regionSlider).hide(); $(saveImage).hide()
    $(nameP).show(); $(regionP).show(); $(regionRadius).show(); $(saveImageSibling).show()
  }

  // for adding zone from plus icon.
  editRegionDynamic = (div) => {
    console.log('div on method',div, $('#' + div))
    var zoneRow = document.getElementById(div)
    var nameInput = $(zoneRow).find('.add_name')
    var nameP = $(zoneRow).find('.add_name').next()
    var regionInput = $(zoneRow).find('.add_region')
    var regionP = $(zoneRow).find('.add_region').next()
    var regionSlider = $(zoneRow).find('.region_radius').siblings()
    var regionRadius = $(zoneRow).find('.region_radius')
    var saveImage = $(zoneRow).find('.save_region')
    var saveImageSibling = $(zoneRow).find('.save_region').parent().siblings()
    console.log('HTML DOM ELEMENT', zoneRow)
    $(nameInput).show(); $(regionInput).show(); $(regionSlider).show(); $(saveImage).show()
    $(nameP).hide(); $(regionP).hide(); $(regionRadius).hide(); $(saveImageSibling).hide()
  }


  removeZone = (e) => {
    var removeIndex = $(e.currentTarget).parent().parent().parent().prop('id')
    var zoneContentArray = this.state.zoneContent
    var zoneDataArray = this.state.regionData
    zoneContentArray.splice(removeIndex, 1)
    zoneDataArray.splice(removeIndex, 1)
    this.setState({
      zoneCounter: this.state.zoneCounter - 1
    }, () => {
      console.log('zone content arry is', zoneContentArray,'zone data array is', zoneDataArray)
      var zoneId = $('#zone_component').children()
      for (let i = 0; i < zoneId.length; i++) {
        console.log('.....', i, '......', [i])
        $('#zone_component').children().eq(i).attr('id', i)      
      }
    })
  }

  addZone = () => {
    var zoneArray = this.state.zoneContent
    var counter = this.state.zoneCounter
    var regionArray = this.state.regionData
    var regionListArray = this.state.regionList
    console.log('11111111111111111111111',regionListArray.length,'............',counter)
    if (regionListArray  !== "" && counter === regionListArray.length) {
      counter = counter + 1
      console.log('11111111111111111111111',regionListArray.length,'............',counter)
      // if (regionListArray.length === counter) {
        console.log('2222222222222222222222')
        // this.setState({zoneCounter : counter + 1},() => console.log('zone counter....',this.state.zoneCounter))
        for (let i = 0; i < regionListArray.length; i++) {
          console.log('3333333333333333333333333')
          const element = regionListArray[i];
          var regionObj = {id: element.id, name: element.name, address: element.address, lat: element.lat, lng: element.lng, radius: element.radius }
          regionArray.push(regionObj)
          console.log('444444444444444', this.state.zoneContent)
          zoneArray.push(
            <Grid container className="zone_comp" key={i} spacing={4} id={i}>
              <Grid item xs={3}>
                <input type="text" defaultValue={this.state.regionData[i].name} className="add_name vd_input" placeholder="Name" style={{ display: "none" }}
                  onChange={(e) => {
                    const { regionData } = this.state
                    regionData[i].name = e.currentTarget.value
                    this.setState({
                      regionData,
                    }, () => {
                      console.log('region data is', this.state.regionData)
                    })
                  }}></input>
                <p className="edit_boundary_p">{this.state.regionData[i].name}</p>
              </Grid>
              <Grid item xs={4}>
                <input type="text" defaultValue={this.state.regionData[i].address} className="vd_input add_region" placeholder="Region" style={{ display: "none" }}
                  onChange={(e) => {
                    const { regionData } = this.state
                    regionData[i].address = e.currentTarget.value
                    this.setState({
                      regionData,
                    }, () => {
                      console.log('region data is', this.state.regionData)
                    })
                  }}></input>
                <p className="edit_boundary_p">{this.state.regionData[i].address}</p>
              </Grid>
              <Grid item xs={3} style={{ textAlign: "center" }}>
                <Slider
                  style={{ display: "none" }}
                  aria-labelledby="slider-image"
                  min={0}
                  max={100}
                  step={0.5}
                  valueLabelDisplay="auto"
                  defaultValue={0}
                  onChange={(e, value) => {
                    const { regionData } = this.state
                    regionData[i].radius = value
                    this.setState({
                      regionData,
                    }, () => {
                      console.log('radius value in state', this.state.radiusValue)
                    })
                  }}
                />
                <p className="edit_boundary_p region_radius" >{this.state.regionData[i].radius}</p>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <i><img src="images/edit_zone.png" style={{ marginRight: "20px" }} alt="icon" onClick={this.editRegion}></img></i>
                <i><img src="images/group-10.png" alt="icon" onClick={this.removeZone}></img></i>
                <i><img src="images/save_region.png" className="save_region" style={{ marginLeft: "20px", display: "none" }} alt="icon"
                  onClick={(e) => this.saveRegion(e, i)}></img></i>
              </Grid>
            </Grid>
          )
        }
        this.forceUpdate()
      } else {        
          console.log('region array is', this.state.regionData)          
          var regionObj = { name: "", address: "", lat: 0.0, lng: 0.0, radius: 0 }
          regionArray.push(regionObj)
          var counter = this.state.zoneCounter       
          console.log('the value of counter is', counter)
          zoneArray.push(
            <Grid container className="zone_comp" key={counter} spacing={4} id={counter}>
              <Grid item xs={3}>
                <input type="text" className="add_name vd_input" placeholder="Name" style={{ display: "none" }}
                  onChange={(e) => {
                    const { regionData } = this.state
                    regionData[counter].name = e.currentTarget.value
                    this.setState({
                      regionData,
                    }, () => {
                      console.log('region data is', this.state.regionData)
                    })
                  }}></input>
                <p className="edit_boundary_p">{this.state.regionData[counter].name}</p>
              </Grid>
              <Grid item xs={4}>
                <input type="text" className="vd_input add_region" placeholder="Region" style={{ display: "none" }}
                  onChange={(e) => {
                    const { regionData } = this.state
                    regionData[counter].address = e.currentTarget.value
                    this.setState({
                      regionData,
                    }, () => {
                      console.log('region data is', this.state.regionData)
                    })
                  }}></input>
                <p className="edit_boundary_p">Zone 1</p>
              </Grid>
              <Grid item xs={3} style={{ textAlign: "center" }}>
                <Slider
                  style={{ display: "none" }}
                  aria-labelledby="slider-image"
                  min={0}
                  max={100}
                  step={0.5}
                  valueLabelDisplay="auto"
                  defaultValue={0}
                  onChange={(e, value) => {
                    const { regionData } = this.state
                    regionData[counter].radius = value
                    this.setState({
                      regionData,
                    }, () => {
                      console.log('radius value in state', this.state.radiusValue)
                    })
                  }}
                />
                <p className="edit_boundary_p region_radius" >{this.state.regionData[counter].radius}</p>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <i><img src="images/edit_zone.png" style={{ marginRight: "20px" }} alt="icon" onClick={this.editRegion}></img></i>
                <i><img src="images/group-10.png" alt="icon" onClick={this.removeZone}></img></i>
                <i><img src="images/save_region.png" className="save_region" style={{ marginLeft: "20px", display: "none" }} alt="icon"
                  onClick={(e) => this.saveRegion(e, counter)}></img></i>
              </Grid>
            </Grid>
          )
          this.setState({
            zoneCounter : this.state.zoneCounter + 1
          },() => {
            this.editRegionDynamic(counter)
          })
          // console.log('array of content is', zoneArray)
          // this.forceUpdate()
          // setTimeout(this.editRegionDynamic(counter),500)
      }

    // }
  }

  createZone = () => {
    var data = this.state.regionData
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const sendData = data[i];
        if('id' in sendData){
          console.log('has key', sendData)
          ApiUrlCall.updateZone(sendData.id,JSON.stringify(sendData)).then(response => {
            console.log(response, "zone created and response is")
          }).catch(error => {
            console.log('error while creating zone', error.response.data)
          })
        }else{
          console.log('has no key', sendData)
          ApiUrlCall.createZone(JSON.stringify(sendData)).then(response => {
            console.log(response, "zone created and response is")
          }).catch(error => {
            console.log('error while creating zone', error.response.data)
          })
        }



      }
    } else {
      alert('create zone')
    }
  }

  getZoneList = () => {
    $('#processing').show()
    ApiUrlCall.getZone().then(response => {
      console.log('region list is', response)
      if (response.status === 200) {
        $('#processing').hide()
        this.setState({
          regionList: response.data,
          zoneCounter: response.data.length,
        },() => {
          console.log('00000000000000', this.state.zoneCounter)
          this.addZone()
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching zone list', error)
    })
  }

  render() {
    return (
      <div className="region_parent">
        <div className="user_profile">
          <p className="p_header">your fleet summary</p>
          <Grid container>
            <Grid container item xs={3} style={{ borderRight: "1px solid #e2e2e2" }}>
              <Grid item xs={5}>
                <img src="images/vehicle-1.png" className="s_center_image" alt="icon"></img>
              </Grid>
              <Grid item xs={7}>
                <p className="renew_modal_header p_margin0">23</p>
                <p className="p_header">vehicles</p>
              </Grid>
            </Grid>
            <Grid container item xs={3}>
              <Grid item xs={5}>
                <img src="images/setting_driver.png" style={{ marginTop: "10px" }} className="s_center_image" alt="icon"></img>
              </Grid>
              <Grid item xs={7}>
                <p className="renew_modal_header p_margin0">23</p>
                <p className="p_header">drivers</p>
              </Grid>
            </Grid>
            <Grid item xs>
              <button className="btn orange-btn" onClick={this.createZone}>save settings</button>
            </Grid>
          </Grid>
        </div>

        <div className="user_profile" style={{ marginTop: "40px", marginBottom: "40px" }}>
          <p className="p_header">Region</p>
          <div className="region_map_div">
            <Map
              height={"100%"}
              cluster = {false}
              markers={[
                {
                  position: [19.0775296, 72.8301568],
                  icon: L.icon({
                    iconUrl: 'images/34x34.png',
                    iconRetinaUrl: 'images/34x34.png',
                    iconSize: [34, 34],
                    popupAnchor: [-3, -15]
                  }),
                  draggable: false,
                  title: 'zone1',
                  onClick: () => { },
                  onDragend: () => { }
                },
                {
                  position: [19.063983, 72.8558633],
                  icon: L.icon({
                    iconUrl: 'images/34x34.png',
                    iconRetinaUrl: 'images/34x34.png',
                    iconSize: [34, 34],
                    popupAnchor: [-3, -15]
                  }),
                  draggable: false,
                  title: "zone 2",
                  onClick: () => { },
                  onDragend: () => { }
                }
              ]}
              circle={[
                {
                  position: [19.0775296, 72.8301568],
                  radius: 3,
                },
                {
                  position: [19.063983, 72.8558633],
                  radius: 4,
                }
              ]}
            />
          </div>
          <div>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <p className="p_header">region name</p>
              </Grid>
              <Grid item xs={4}>
                <p className="p_header">center point of region</p>
              </Grid>
              <Grid item xs={3} style={{ textAlign: "center" }}>
                <p className="p_header">radius of the region (KM)</p>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <p className="p_header">action</p>
              </Grid>
            </Grid>
          </div>

          <div id="zone_component">
            {this.state.zoneContent}
          </div>
          <div>
            <img src="images/add_zone.png" alt="icon" onClick={this.addZone}></img>
          </div>
        </div>
      </div>
    );
  }
}

export default Region;