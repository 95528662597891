import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import { withTranslation } from 'react-i18next';
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from 'react-spinner-material';


const ENGINE_IMMOBILIZATION = 1
const FUELSENSOR = 18

class VehicleTrackingSolution extends Component {
  constructor(props) {
    super(props);
    this.state = {    
      subscriptionData: '', 
      list : [],
      initial : true,
      nextCount : 0,
      totalVehicles : 0,
      hasMore: true,
      isLoading : false, 
    }
  }

  componentDidMount() {    
    this.getVehicleSubsriptionData()
  }

  getVehicleSubsriptionData = () => {
    if (this.state.initial){
      $('#processing').show()
      const data = {
        next_count : this.state.nextCount,
      }
      ApiUrlCall.accountSubscriptionNew(data).then(response => {
         //console.log('response is', response)
        if (response.data.response_code && response.data.is_success === true) {
          $('#processing').hide()
          //console.log(response.data.data.subscriptions,"in if",this.state.initial)
          this.setState({
            subscriptionData: response.data.data.subscriptions,
            initial : false,
            nextCount : response.data.data.next_count,
            totalVehicles : response.data.data.total_count,
            isLoading : true,
          })
          response.data.data.subscriptions.map((array) => {
            this.state.list.push(array)      
          })

        }
      }).catch(error => {
        console.log('error while fetching subsription data', error)
      })
    }
    else{
      //$('#processing').show()
      if (this.state.list.length >= this.state.totalVehicles) {
        this.setState({ 
          hasMore: false, 
        });
        return;
      }
      const data = {
        next_count : this.state.nextCount,
      }
      ApiUrlCall.accountSubscriptionNew(data).then(response => {
        //console.log('response is', response)
        if (response.data.response_code && response.data.is_success === true) {
          //$('#processing').hide()
          //console.log(response.data.data.subscriptions,"in else")
          this.setState({
            subscriptionData: [],
            nextCount : response.data.data.next_count,
            isLoading : true,
          })
          response.data.data.subscriptions.map((array) => {
            this.state.list.push(array)      
          })
        }
      }).catch(error => {
        console.log('error while fetching subsription data', error)
      })
    }
    
  }


render() {

  let { userProfile } = this.state
  const {t} = this.props
  let condition

 

  if (this.state.isLoading){
    condition = <h4 style={{ textAlign: "center", marginTop : "50px", marginLeft : "800px"}}><Spinner size="sm" animation="border" variant="primary" spinnerColor={"#333"} spinnerWidth={2} visible={true} /></h4>
    //condition = <h4>Loading...</h4>
  }
  
  
  var subData = (() => {
    if (this.state.subscriptionData) {
      let vehicleSub = this.state.subscriptionData.map(vehicleSub => {
        return (
          <Grid item xl={3} xs={4}>
            <div className="vehicle_sub">
              <Grid container>
                <Grid item xs>
                  <p className="p_header">{vehicleSub.name}</p>
                </Grid>
                <Grid container item xs style={{position : "relative"}}>
                  <div style={{position : "absolute", right : "0"}}>
                    <div class="grid-container">
                      <div class="grid-item" style={{display :vehicleSub.hw_attachments_list.includes(FUELSENSOR) ? "" : "none" , marginRight : "10px"}}>
                        <div className="fuel_present">F</div>
                      </div>
                      <div class="grid-item" style={{display : vehicleSub.hw_attachments_list.includes(ENGINE_IMMOBILIZATION) ? "" : "none"}}>
                        <div className="ei_present">EI</div>
                      </div>
                    </div>
                  </div>                  
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}>
                  <p className="cj_w_header">{t('ss.p.sub')}</p>
                  <p className="cj_w_header">{t('ss.p.install')}</p>
                  <p className="cj_w_header">{t('ss.p.exp')}</p>
                </Grid>
                <Grid item xs={4} className="sub_data">
                  <p>{vehicleSub.plan}</p>
                  <p>{CommonFunction.tableDateFormate(vehicleSub.addedd_on)}</p>
                  <p>{CommonFunction.tableDateFormate(vehicleSub.expiry_date)}</p>
                </Grid>
              </Grid>
              {/* <button className="btn orange-btn sub_btn" style={{ float: "none" }}>renew subscription</button> */}
            </div>
          </Grid>
        )
      })
      return vehicleSub
    }
  })()

  var subData123 = (() => {
    if (this.state.list) {
      let vehicleSub = this.state.list.map(vehicleSub => {
        return (
          <Grid item xl={3} xs={4}>
            <div className="vehicle_sub">
              <Grid container>
                <Grid item xs>
                  <p className="p_header">{vehicleSub.name}</p>
                </Grid>
                <Grid container item xs style={{position : "relative"}}>
                  <div style={{position : "absolute", right : "0"}}>
                    <div class="grid-container">
                      <div class="grid-item" style={{display :vehicleSub.hw_attachments_list.includes(FUELSENSOR) ? "" : "none" , marginRight : "10px"}}>
                        <div className="fuel_present">F</div>
                      </div>
                      <div class="grid-item" style={{display : vehicleSub.hw_attachments_list.includes(ENGINE_IMMOBILIZATION) ? "" : "none"}}>
                        <div className="ei_present">EI</div>
                      </div>
                    </div>
                  </div>                  
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}>
                  <p className="cj_w_header">{t('ss.p.sub')}</p>
                  <p className="cj_w_header">{t('ss.p.install')}</p>
                  <p className="cj_w_header">{t('ss.p.exp')}</p>
                </Grid>
                <Grid item xs={4} className="sub_data">
                  <p>{vehicleSub.plan}</p>
                  <p>{CommonFunction.tableDateFormate(vehicleSub.addedd_on)}</p>
                  <p>{CommonFunction.tableDateFormate(vehicleSub.expiry_date)}</p>
                </Grid>
              </Grid>
              {/* <button className="btn orange-btn sub_btn" style={{ float: "none" }}>renew subscription</button> */}
            </div>
          </Grid>
        )
      })
      //console.log(vehicleSub,"vehicleSub")
      return vehicleSub
    }
  })()


  return (
    <div className="profile_parent">            
      <div>
        <Grid container style={{marginBottom : "20px", marginTop : "20px"}}>
          {/* <Grid item xs>
            <p className="p_header" style={{ marginBottom: "30px" }}>{t('ss.p.with')}</p>
          </Grid> */}
          {/* <Grid container item xs> */}
            <Grid item xs={3}>
              <div className="fuel_legend"></div>
              <p style={{display : "inline"}}>Fuel Sensor Available</p>              
            </Grid>
            <Grid item xs={3}>
              <span className="ei_legend"></span> 
              <p style={{display : "inline"}}>Eng. Immobilization Available</p>  
            </Grid>
          {/* </Grid> */}
        </Grid>
        <div>
          <Grid container spacing={4}>
            {subData}
          </Grid>
        </div>
      </div>
      <div style={{ display: this.state.subscriptionData < 10 ? '' : 'none' }}>
       
      <InfiniteScroll
          dataLength={this.state.list.length}
          next={this.getVehicleSubsriptionData}
          hasMore={this.state.hasMore}
          loader={condition}
          //loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center",marginTop : "50px" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div>
          <Grid container spacing={4}>
            {subData123}
          </Grid>
            </div>          
        </InfiniteScroll>

        </div>     
    </div>
  );
}
}

export default withTranslation()(VehicleTrackingSolution);
