import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

const data1 = [
    {
        label: 'USER NAME / USER ID',
        field: 'username',
        sort: 'asc',
        width: 150,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'EMAIL ADDRESS',
        field: 'email',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'MOBILE NUMBER',
        field: 'mobile_number',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'VEHICLES ASSIGNED',
        field: 'vehicle',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'INVITED BY',
        field: 'invited_by',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'INVITE SENT / RESENT ON',
        field: 'invite_sent',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'ACTION',
        field: 'action',
        sort: 'asc',
        width: 150,
    }
]


class TemporaryUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datatable: [],
            nextCount: 0,
            totalCount: 0,
            dataCollection: [],
            chunckValue: '',
            searchText: '',
            totalEntries: 0
        }
    }

    componentDidMount() {
        // var navBarHeight = $('.navBar').outerHeight()
        // $('.vehicleRoute').css({
        //   paddingTop : navBarHeight+60,
        // })
    }

    buttonStatus = () => {
        const { nextCount, totalCount } = this.state
        // console.log(nextCount,'.................',totalCount)
        if (nextCount === totalCount) {
            // console.log('iffffffffff')      
            if (this.counter === 0) {
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            } else {
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            }
        } else {
            // console.log('elseeeeeeee', this.counter)
            if (this.counter === 0) {
                // console.log('else 1111111111')
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('disable-btn')
                $('#dt-next').addClass('enabled-btn')
            } else {
                // console.log('else 22222222')
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').addClass('enabled-btn')
                $('#dt-next').removeClass('disable-btn')
            }
        }
    }

    entriesData = () => {
        const { nextCount, totalCount, chunckValue } = this.state
        if (nextCount != totalCount) {
            var start = nextCount - chunckValue + 1
            var end = nextCount
            var entries_text = start + '-' + end
            this.setState({
                totalEntries: entries_text
            })
        } else {
            if (nextCount <= 10) {
                if(nextCount == 0){ 
                    this.setState({
                      totalEntries : "0"
                    })
                  }else{
                    var entries_text = '1'+'-'+totalCount
                    this.setState({
                      totalEntries : entries_text
                    })
                  }
            } else {
                var module = nextCount % chunckValue
                var start
                var end
                var entries_text
                if (module > 0) {
                    start = nextCount - module + 1
                    end = nextCount
                    entries_text = start + '-' + end
                } else {
                    start = nextCount - chunckValue
                    end = nextCount
                    entries_text = start + '-' + end
                }
                this.setState({
                    totalEntries: entries_text
                })
            }
        }
    }


    createTableData = () => {
        const { driverdata, datatable } = this.state
        // console.log('lenght is', driverdata.length)
        for (let i = 0; i < driverdata.length; i++) {
            const element = driverdata[i];
            var row_data = {
                driver_name: element.driver_name, start_location: element.last_journey_start_loc,
                end_location: element.last_journey_end_loc, mobile_no: element.mobile_no, status: this.VehicleStatus(element.status),
                action: <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer" }} onClick={() => this.uniqueDriverId(element.id)}>Driver Details</p>
            }
            datatable.push(row_data)
        }
        this.buttonStatus()
        this.entriesData()
        this.forceUpdate()
        // this.intervalData()
    }

    emptyTable = () => {
        this.setState({
            datatable: []
        })
    }

    pageNextClick = () => {
        const { totalCount, nextCount, dataCollection, datatable } = this.state
        // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
        this.counter = this.counter + 1
        for (let i = 0; i < datatable.length; i++) {
            const element = datatable[i];
            dataCollection.push(element)
        }
        this.setState({
            datatable: []
        }, () => {
            this.DriverDetailsGet()
        })
    }

    pagePreviousClick = () => {
        const { dataCollection, chunckValue, nextCount } = this.state
        var pageSize = nextCount % chunckValue
        if (pageSize === 0) {
            pageSize = chunckValue
        }
        // console.log('previous clicked', pageSize)    
        var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
        this.setState({
            datatable: last,
            nextCount: nextCount - pageSize
        }, () => {
            this.buttonStatus()
            this.entriesData()
        })
        this.counter = this.counter - 1
    }

    optimizationSearch = (value) => {
        setTimeout(() => {
            this.counter = 0
            if (value.length >= 3) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.DriverDetailsGet()
                })
            } else if (value.length === 0) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.DriverDetailsGet()
                })
            }
        }, 1000);
    }

    render() {
        return (
            <div className="temp_parent table_margin_top">
                <div>
                    <MDBDataTable
                        displayEntries={false}
                        paging={false}
                        scrollX
                        entries={10}
                        displayEntries={false}
                        striped
                        bordered
                        data={{ columns: data1, rows: this.state.datatable }}
                        onSearch={this.optimizationSearch}
                    />
                    <Grid container style={{ margin: "20px 0px" }}>
                        <Grid item xs>
                            <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                            <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                            <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default TemporaryUser;
