import React, { Component } from 'react';
import $ from 'jquery';
import { MDBDataTable } from 'mdbreact';
import Grid from '@material-ui/core/Grid';
import ApiUrlCall from '../../API/apiurl';
import CommonFunction from '../../common_function'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Snackbar from '@material-ui/core/Snackbar';
import { withTranslation } from 'react-i18next';


const data1 = [
    {
        label: '',
        field: 'checkbox',
        sort: 'asc',
        width: 50,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'USERS NAME / ID',
        field: 'user_id',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'EMAIL ADDRESS',
        field: 'email',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'MOBILE NUMBER',
        field: 'mobile_number',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'VISIT ASSIGNED',
        field: 'visit_assigned',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'INVITE BY',
        field: 'invite_by',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'INVITE SENT / RESENT ON',
        field: 'invite_sent',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'CURRENT STATUS',
        field: 'current_status',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'ACTION',
        field: 'action',
        sort: 'asc',
        width: 150,
    }
]

const ALL = 1
const ACTIVE = 2
const INACTIVE = 3

class ManagementUserList extends Component {
    counter = 0
    constructor(props) {
        super(props);
        this.state = {
            datatable: [],
            nextCount: 0,
            totalCount: 0,
            dataCollection: [],
            chunckValue: '',
            searchText: '',
            totalEntries: 0,
            adminData: [],
            openAlert: false,
            errorMessage: '',
            deleteUser: [],
            inviteType: 1
        }
    }

    componentDidMount() {
        this.getUserList()
    }

    getUserList = () => {
        $('#processing').show()
        var data = { role_id_list: [3], status: this.state.inviteType, search: this.state.searchText }
        ApiUrlCall.ptAdminList(JSON.stringify(data)).then(response => {
            $('#processing').hide()
            // console.log('admin list', response)
            this.emptyTable()
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    adminData: response.data.data.user_list,
                    nextCount: response.data.data.next_count,
                    totalCount: response.data.data.total_count,
                    chunckValue: response.data.data.size
                }, () => {
                    this.createTableData()
                })
            } else if (response.data.response_code == 410 && !response.data.is_success) {
                if ($('#pplt_expire_dialog').is(':hidden')) {
                    $('#pplt_expire_dialog').slideDown()
                }
            } else {
                this.setState({
                    openAlert: true,
                    errorMessage: response.data.data.response_message
                })
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching admin list', error)
        })
    }

    buttonStatus = () => {
        const { nextCount, totalCount } = this.state
        // console.log(nextCount,'.................',totalCount)
        if (nextCount === totalCount) {
            // console.log('iffffffffff')      
            if (this.counter === 0) {
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            } else {
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            }
        } else {
            // console.log('elseeeeeeee', this.counter)
            if (this.counter === 0) {
                // console.log('else 1111111111')
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('disable-btn')
                $('#dt-next').addClass('enabled-btn')
            } else {
                // console.log('else 22222222')
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').addClass('enabled-btn')
                $('#dt-next').removeClass('disable-btn')
            }
        }
    }

    entriesData = () => {
        const { nextCount, totalCount, chunckValue } = this.state
        if (nextCount != totalCount) {
            var start = nextCount - chunckValue + 1
            var end = nextCount
            var entries_text = start + '-' + end
            this.setState({
                totalEntries: entries_text
            })
        } else {
            if (nextCount <= 10) {
                if (nextCount === 0) {
                    var entries_text = '0' + '-' + totalCount
                } else {
                    var entries_text = '1' + '-' + totalCount
                }
                this.setState({
                    totalEntries: entries_text
                })
            } else {
                var module = nextCount % chunckValue
                var start
                var end
                var entries_text
                if (module > 0) {
                    start = nextCount - module + 1
                    end = nextCount
                    entries_text = start + '-' + end
                } else {
                    start = nextCount - chunckValue
                    end = nextCount
                    entries_text = start + '-' + end
                }
                this.setState({
                    totalEntries: entries_text
                })
            }
        }
    }

    selectUserDelete = (e) => {
        const { deleteUser } = this.state
        var userId = $(e.currentTarget).prop('id')
        if (e.currentTarget.checked) {
            deleteUser.push(userId)
            this.forceUpdate()
            // console.log('delete user', deleteUser)
        } else {
            var removedArray = deleteUser.findIndex(x => x == userId)
            deleteUser.splice(removedArray, 1)
            this.forceUpdate()
            // console.log('delete user', deleteUser)
        }
    }

    getActive = (value) => {
        if (value) {
            return <p className="cj_w_header">Active</p>
        } else {
            return <p className="cj_w_header">Inactive</p>
        }
    }

    userDetails = (id) => {
        console.log(id, 'props', this.props)
        this.props.data.history.push({
            pathname: '/p_send_invite',
            state: { user_id: id }
        })
    }

    returnAction = (data) => {
        if (data.is_deleted) {
            return <p className="cj_w_header" style={{ color: '#DC3545' }}>User Deleted</p>
        } else {
            return <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer", textDecoration: "underline" }} onClick={() => this.userDetails(data.user_id)}>User Details</p>
        }
    }


    createTableData = () => {
        const { adminData, datatable } = this.state
        // console.log('lenght is', driverdata.length)
        for (let i = 0; i < adminData.length; i++) {
            const element = adminData[i];
            var row_data = {
                checkbox: <input type="checkbox" id={element.user_id} onChange={this.selectUserDelete}
                    style={{ pointerEvents: element.is_deleted ? "none" : "auto", opacity: element.is_deleted ? "0.3" : "1" }}></input>,
                user_id: element.name, email: element.email, mobile_number: element.mobile_number,
                visit_assigned: element.visits_assigned, invite_by: element.invited_by,
                invite_sent: CommonFunction.tableDateFormate(element.invited_on) + ", " + CommonFunction.timeFormate(element.invited_on), current_status: this.getActive(element.is_active),
                action: this.returnAction(element)
            }
            datatable.push(row_data)
        }
        this.buttonStatus()
        this.entriesData()
        this.forceUpdate()
        // this.intervalData()
    }

    emptyTable = () => {
        this.setState({
            datatable: []
        })
    }

    pageNextClick = () => {
        const { totalCount, nextCount, dataCollection, datatable } = this.state
        // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
        this.counter = this.counter + 1
        for (let i = 0; i < datatable.length; i++) {
            const element = datatable[i];
            dataCollection.push(element)
        }
        this.setState({
            datatable: []
        }, () => {
            this.getUserList()
        })
    }

    pagePreviousClick = () => {
        const { dataCollection, chunckValue, nextCount } = this.state
        var pageSize = nextCount % chunckValue
        if (pageSize === 0) {
            pageSize = chunckValue
        }
        // console.log('previous clicked', pageSize)    
        var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
        this.setState({
            datatable: last,
            nextCount: nextCount - pageSize
        }, () => {
            this.buttonStatus()
            this.entriesData()
        })
        this.counter = this.counter - 1
    }

    optimizationSearch = (value) => {
        setTimeout(() => {
            this.counter = 0
            if (value.length >= 3) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getUserList()
                })
            } else if (value.length === 0) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getUserList()
                })
            }
        }, 1000);
    }

    resetStateValue = () => {
        this.counter = 0
        this.setState({
            datatable: [],
            dataCollection: [],
            totalCount: '',
            chunckValue: '',
            nextCount: 0,
            totalEntries: ''
        }, () => {
            this.counter = 0
            this.getUserList()
        })
    }

    deleteUserData = () => {
        const { deleteUser } = this.state
        if (deleteUser.length > 0) {
            var data = { customer_id_list: deleteUser }
            ApiUrlCall.ptDeleteUser(JSON.stringify(data)).then(response => {
                if (response.data.response_code == 200 && response.data.is_success) {
                    this.resetStateValue()
                } else if (response.data.response_code == 410 && !response.data.is_success) {
                    if ($('#pplt_expire_dialog').is(':hidden')) {
                        $('#pplt_expire_dialog').slideDown()
                    }
                } else {
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                console.log('error while deleting user', error)
            })
        } else {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly select atleast one user"
            })
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="management_admin_list">
                <div style={{ marginTop: "20px" }}>
                    <div className="grid-container" style={{ width: "fit-content", position: "absolute" }}>
                        <div className="grid-item">
                            <select className="status-filter" defaultValue={this.state.userRole} style={{ width: "fit-content", position: "relative" }}
                                onChange={(e) => {
                                    this.setState({
                                        inviteType: e.currentTarget.value
                                    }, () => {
                                        this.resetStateValue()
                                    })
                                }}>
                                <option value="1">{t('ct.all.text')}</option>
                                <option value="2">Active</option>
                                <option value="3">Inactive</option>
                            </select>
                        </div>
                        <div className="grid-item">
                            <button className="btn orange-btn" style={{ float: "none", margin: "0px", marginLeft: "20px" }}
                                onClick={this.deleteUserData}>Delete</button>
                        </div>
                    </div>
                    <MDBDataTable
                        displayEntries={false}
                        paging={false}
                        scrollX
                        entries={10}
                        displayEntries={false}
                        striped
                        bordered
                        data={{ columns: data1, rows: this.state.datatable }}
                        onSearch={this.optimizationSearch}
                    />
                    <Grid container style={{ margin: "20px 0px" }}>
                        <Grid item xs>
                            <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                            <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                            <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                        </Grid>
                    </Grid>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1200}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(ManagementUserList);