import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

const data1 = [
    {
        label: '',
        field: 'checkbox',
        sort: 'asc',
        width: 50,
    },
    {
        label: 'USER NAME / USER ID',
        field: 'username',
        sort: 'asc',
        width: 150,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'EMAIL ADDRESS',
        field: 'email',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'MOBILE NUMBER',
        field: 'mobile_number',
        sort: 'asc',
        width: 150,
    },    
    {
        label: 'INVITED BY',
        field: 'invited_by',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'ACTION',
        field: 'action',
        sort: 'asc',
        width: 150,
    }    
]


class DemoUser extends Component {
    counter = 0
    constructor(props) {
        super(props);
        this.state = {
            deleteUser : [],
            userList : '',
            datatable: [],
            nextCount: 0,
            totalCount: 0,
            dataCollection: [],
            chunckValue: '',
            searchText: '',
            totalEntries: 0
        }
    }

    componentDidMount() {
        // var navBarHeight = $('.navBar').outerHeight()
        // $('.vehicleRoute').css({
        //   paddingTop : navBarHeight+60,
        // })
        this.getUserList()
    }
    getUserList = () =>{
        $('#processing').show()
        var data = {role_id : 3}
        ApiUrlCall.inviteUserList(JSON.stringify(data)).then(response => {            
            this.emptyTable()
            if(response.data.response_code === 200 && response.data.is_success){
              $('#processing').hide()
              this.setState({
                userList : response.data.data.user_list,
                nextCount : response.data.data.next_count,
                totalCount : response.data.data.total_count,
                chunckValue : response.data.data.size
              },() => {
                this.createTableData()          
              })
            }else{
              $('#processing').hide()
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching user list', error)
        })
    }

    buttonStatus = () => {
        const { nextCount, totalCount } = this.state
        // console.log(nextCount,'.................',totalCount)
        if (nextCount === totalCount) {
            // console.log('iffffffffff')      
            if (this.counter === 0) {
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            } else {
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            }
        } else {
            // console.log('elseeeeeeee', this.counter)
            if (this.counter === 0) {
                // console.log('else 1111111111')
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('disable-btn')
                $('#dt-next').addClass('enabled-btn')
            } else {
                // console.log('else 22222222')
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').addClass('enabled-btn')
                $('#dt-next').removeClass('disable-btn')
            }
        }
    }

    entriesData = () => {
        const { nextCount, totalCount, chunckValue } = this.state
        if (nextCount != totalCount) {
            var start = nextCount - chunckValue + 1
            var end = nextCount
            var entries_text = start + '-' + end
            this.setState({
                totalEntries: entries_text
            })
        } else {
            if (nextCount <= 10) {
                if(nextCount == 0){ 
                    this.setState({
                      totalEntries : "0"
                    })
                  }else{
                    var entries_text = '1'+'-'+totalCount
                    this.setState({
                      totalEntries : entries_text
                    })
                  }
            } else {
                var module = nextCount % chunckValue
                var start
                var end
                var entries_text
                if (module > 0) {
                    start = nextCount - module + 1
                    end = nextCount
                    entries_text = start + '-' + end
                } else {
                    start = nextCount - chunckValue
                    end = nextCount
                    entries_text = start + '-' + end
                }
                this.setState({
                    totalEntries: entries_text
                })
            }
        }
    }

    returnNaData = (val) => {
        if(val){
            return val
        }else{
            return "NA"
        }
    }

    selectUserDelete = (e) => {
        const {deleteUser} = this.state
        var userId = $(e.currentTarget).prop('id')
        if(e.currentTarget.checked){
            deleteUser.push(userId)
            this.forceUpdate()            
        }else{
            var removedArray = deleteUser.findIndex(x => x == userId)            
            deleteUser.splice(removedArray,1)
            this.forceUpdate()
        }
    }

    returnAction = (data) => {
        if(data.is_deleted){
            return <p className="cj_w_header" style={{ color: '#DC3545'}}>User Deleted</p>
        }else if( data.is_expired){
            return <p className="cj_w_header" style={{ color: '#DC3545'}}>User Expired</p>
        }else{
            return <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer", textDecoration : "underline" }} onClick={() => this.userDetails(data.user_id)}>User Details</p>
        }
    }

    userDetails = (id) => {
        // console.log(id,'props', this.props)
        this.props.data.history.push({
            pathname: '/create_user',
            state: { user_id: id }
        })
    }

    createTableData = () => {
        const { userList, datatable } = this.state
        // console.log('lenght is', driverdata.length)
        for (let i = 0; i < userList.length; i++) {
            const element = userList[i];
            var row_data = {
                checkbox : <input type="checkbox" id={element.user_id} onChange={this.selectUserDelete}
                style={{pointerEvents : element.is_deleted || element.is_expired ? "none" : "auto"}}></input>,
                username: this.returnNaData(element.name), email: this.returnNaData(element.email),
                mobile_number: this.returnNaData(element.mobile_number), invited_by: element.invited_by,
                action : this.returnAction(element)
            }
            datatable.push(row_data)
        }
        this.buttonStatus()
        this.entriesData()
        this.forceUpdate()
        // this.intervalData()
    }

    emptyTable = () => {
        this.setState({
            datatable: []
        })
    }

    pageNextClick = () => {
        const { totalCount, nextCount, dataCollection, datatable } = this.state
        // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
        this.counter = this.counter + 1
        for (let i = 0; i < datatable.length; i++) {
            const element = datatable[i];
            dataCollection.push(element)
        }
        this.setState({
            datatable: []
        }, () => {
            this.getUserList()
        })
    }

    pagePreviousClick = () => {
        const { dataCollection, chunckValue, nextCount } = this.state
        var pageSize = nextCount % chunckValue
        if (pageSize === 0) {
            pageSize = chunckValue
        }
        // console.log('previous clicked', pageSize)    
        var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
        this.setState({
            datatable: last,
            nextCount: nextCount - pageSize
        }, () => {
            this.buttonStatus()
            this.entriesData()
        })
        this.counter = this.counter - 1
    }

    optimizationSearch = (value) => {
        setTimeout(() => {
            this.counter = 0
            if (value.length >= 3) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getUserList()
                })
            } else if (value.length === 0) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getUserList()
                })
            }
        }, 1000);
    }

    deleteSelectedUser = () => {
        const {deleteUser} = this.state
        if(deleteUser.length > 0){
            $('#processing').show()
            var data = {invited_id_list : deleteUser}
            ApiUrlCall.deleteInviteUser(JSON.stringify(data)).then(response => {
                $('#processing').hide()
                // console.log('repsonse is', response)
                if(response.data.response_code === 200 && response.data.is_success){
                    this.setState({
                        deleteUser : [],
                        datatable: [],
                        dataCollection: [],
                        totalCount: '',
                        chunckValue: '',
                        nextCount: 0,
                    },() => {this.getUserList()})
                    // this.getUserList()
                }
            }).catch(error => {
                console.log('error while deleting user', error)
            })
        }else{
            alert('select atleast one user')
        }
    }

    render() {
        return (
            <div className="demo_parent table_margin_top">
                <div>
                    <button className="btn orange-btn" style={{position : "absolute", float : "none"}} onClick={this.deleteSelectedUser}>Delete User</button>
                    <MDBDataTable
                        displayEntries={false}
                        paging={false}
                        scrollX
                        entries={10}
                        displayEntries={false}
                        striped
                        bordered
                        data={{ columns: data1, rows: this.state.datatable }}
                        onSearch={this.optimizationSearch}
                    />
                    <Grid container style={{ margin: "20px 0px" }}>
                        <Grid item xs>
                            <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                            <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                            <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default DemoUser;
