import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Snackbar from '@material-ui/core/Snackbar';
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import host from '../ipaddress'

const data1 = [
  {
    label: 'INVOICE NUMBER',
    field: 'invoice',
    sort: 'asc',
    width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'PRODUCT PRICE (INR / 1P)',
    field: 'price',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'PURCHASE QUANTITY',
    field: 'quantity',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'TOTAL AMOUNT PAID (INR)',
    field: 'total_amount',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'TRN ID',
    field: 'trn_id',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'PAYMENT DATE',
    field: 'payment_date',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'SUBSCRIPTION START DATE',
    field: 'sub_start_date',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'SUBSCRIPTION END DATE',
    field: 'sub_end_date',
    sort: 'asc',
    width: 200,
  }
  // {
  //   label: 'ACTION',
  //   field: 'action',
  //   sort: 'asc',
  //   width: 150,
  // }

]

class PeopleTrackingSolution extends Component {
  counter = 0
  constructor(props) {
    super(props);
    this.state = {
      datatable: [],
      nextCount: 0,
      totalCount: '',
      chunckValue: '',
      searchText: '',
      totalEntries: '',
      openAlert: false,
      errorMessage: '',
      tableData: [],
      sumValue: 0,
      licenseQuantity: '',
      singlePrice: 70,
      transactionID : ''
    }
  }

  componentDidMount() {
    this.getSubscriptionList()
  }

  emptyTable = () => {
    this.setState({
      datatable: []
    })
  }

  getSubscriptionList = () => {
    $('#processing').show()
    ApiUrlCall.ptSubscriptionList().then(response => {
      console.log('response is report', response)
      this.emptyTable()
      if (response.data.response_code === 200 && response.data.is_success) {
        $('#processing').hide()
        this.setState({
          tableData: response.data.data.sub_list,
          nextCount: response.data.data.next_count,
          totalCount: response.data.data.total_count,
          chunckValue: response.data.data.size
        }, () => {
          this.createTableData()
        })
      } else {
        $('#processing').hide()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while getting fuel report', error)
    })
  }

  buttonStatus = () => {
    const { nextCount, totalCount } = this.state
    // console.log(nextCount,'.................',totalCount)
    if (nextCount === totalCount) {
      // console.log('iffffffffff')      
      if (this.counter === 0) {
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      } else {
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    } else {
      // console.log('elseeeeeeee', this.counter)
      if (this.counter === 0) {
        // console.log('else 1111111111')
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')
        $('#dt-next').addClass('enabled-btn')
      } else {
        // console.log('else 22222222')
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')
      }
    }
  }

  entriesData = () => {
    const { nextCount, totalCount, chunckValue } = this.state
    if (nextCount != totalCount) {
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start + '-' + end
      this.setState({
        totalEntries: entries_text
      })
    } else {
      if (nextCount <= 10) {
        if (nextCount === 0) {
          this.setState({
            totalEntries: "0"
          })
        } else {
          var entries_text = '1' + '-' + totalCount
          this.setState({
            totalEntries: entries_text
          })
        }
      } else {
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if (module > 0) {
          start = nextCount - module + 1
          end = nextCount
          entries_text = start + '-' + end
        } else {
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start + '-' + end
        }
        this.setState({
          totalEntries: entries_text
        })
      }
    }
  }

  createTableData = () => {
    const { tableData, datatable } = this.state
    var row = []
    // console.log('lenght is', vehicleData.length)
    for (let i = 0; i < tableData.length; i++) {
      const element = tableData[i];
      var row_data = {
        invoice: element.invoice_id, price: element.price_per_sku,
        quantity: element.quantity, total_amount: element.total_price,
        trn_id: element.trn_no, payment_date: CommonFunction.tableDateFormate(element.payment_date) + ", " + CommonFunction.timeFormate(element.payment_date),
        sub_start_date: CommonFunction.tableDateFormate(element.subscription_start_date) + ", " + CommonFunction.timeFormate(element.subscription_start_date),
        sub_end_date: CommonFunction.tableDateFormate(element.subscription_end_date) + ", " + CommonFunction.timeFormate(element.subscription_end_date)
        // action : "action"
      }
      datatable.push(row_data)
    }
    this.buttonStatus()
    this.entriesData()
    this.forceUpdate()
    // this.intervalData()
  }

  pageNextClick = () => {
    const { totalCount, nextCount, dataCollection, datatable } = this.state
    // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
    this.counter = this.counter + 1
    for (let i = 0; i < datatable.length; i++) {
      const element = datatable[i];
      dataCollection.push(element)
    }
    this.setState({
      datatable: []
    }, () => {
      this.FuelReport()
    })
  }

  pagePreviousClick = () => {
    const { dataCollection, chunckValue, nextCount } = this.state
    var pageSize = nextCount % chunckValue
    if (pageSize === 0) {
      pageSize = chunckValue
    }
    // console.log('previous clicked', pageSize)    
    var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
    this.setState({
      datatable: last,
      nextCount: nextCount - pageSize
    }, () => {
      this.buttonStatus()
      this.entriesData()
    })
    this.counter = this.counter - 1
  }

  optimizationSearch = (value) => {
    setTimeout(() => {
      this.counter = 0
      if (value.length >= 3) {
        this.setState({
          datatable: [],
          dataCollection: [],
          totalCount: '',
          chunckValue: '',
          nextCount: 0,
          searchText: value
        }, () => {
          this.FuelReport()
        })
      } else if (value.length === 0) {
        this.setState({
          datatable: [],
          dataCollection: [],
          totalCount: '',
          chunckValue: '',
          nextCount: 0,
          searchText: value
        }, () => {
          this.FuelReport()
        })
      }
    }, 1000);
  }

  openPaymentModal = () => {
    $('#add_payment_details').slideDown()
    this.setState({
      sumValue: 0,
      licenseQuantity: ""
    }, () => {
      $('#total_value').val("0")
      $('#license_quantity').val("")
    })
  }

  cancelModal = () => {
    $('#add_payment_details').fadeOut()
  }

  enterQuantity = (e) => {
    const { singlePrice, sumValue } = this.state
    var quantity = e.target.value
    if (quantity) {
      var sum = parseInt(quantity) * parseInt(singlePrice)
    }
    this.setState({      
      licenseQuantity: quantity                      
    })
    setTimeout(() => {
      if (quantity.length > 0) {
        ApiUrlCall.ptSubscriptionPrice(JSON.stringify({ quantity: quantity })).then(response => {
          // console.log('response price', response)
          if (response.data.response_code == 200 && response.data.is_success) {
            this.setState({
              sumValue: response.data.data.total_price
            }, () => {
              $('#total_value').val(this.state.sumValue)
            })
          }
        }).catch(error => {
          console.log('error while getting price')
          if (sum !== undefined) {
            $('#total_value').val(sum)
          } else {
            $('#total_value').val("0")
          }          
        })
      } else {        
        this.setState({
          sumValue: 0
        }, () => {
          $('#total_value').val(this.state.sumValue)
        })
      }
    }, 1000);

  }

  payNowClick = () => {
    const { licenseQuantity, sumValue, singlePrice } = this.state
    if (!licenseQuantity) {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Enter Product Quantity"
      })
      return
    }
    ApiUrlCall.ptTransactionID().then(response => {      
      if(response.data.response_code == 200 && response.data.is_success){
        this.setState({
          transactionID : response.data.data.transaction_id
        },() => {
          if(this.state.transactionID){                        
            window.open(host+"v3/people_tracking/payments/redirect_payment/?transaction_id="+this.state.transactionID, "_self"); 
          }
        })
      }else{
        this.setState({
          openAlert : true,
          errorMessage : response.data.response_message
        })
      }

    }).catch(error => {
      console.log('error while fetching transaction ID', error)
    })    
  }

  render() {
    return (
      <div className="profile_parent">
        <div style={{ marginTop: "30px" }}>
          {/* <button className="btn orange-btn" style={{ float: "none", position: "absolute" }}
            onClick={this.openPaymentModal}>Add new payment</button> */}
          <MDBDataTable
            displayEntries={false}
            paging={false}
            scrollX
            entries={10}
            displayEntries={false}
            striped
            bordered
            data={{ columns: data1, rows: this.state.datatable }}
            onSearch={this.optimizationSearch}
          />
          <Grid container style={{ margin: "20px 0px" }}>
            <Grid item xs>
              <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
            </Grid>
            <Grid item xs style={{ textAlign: "right" }}>
              <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
              <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
            </Grid>
          </Grid>
        </div>
        <div id="add_payment_details" className="p_modal_parent" style={{ display: "none", right: "0px" }}>
          <div className="p_modal_boday">
            <p className="p_header">My Purchase Details</p>
            <hr />
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Product Price (INR / 1 PC)</p>
              </Grid>
              <Grid item xs style={{ position: "relative" }}>
                <input type="text" placeholder="Enter Vehicle Name" className="journey_input" id="single_price" defaultValue={this.state.singlePrice} disabled></input>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Product Quantity *</p>
              </Grid>
              <Grid item xs>
                <input type="number" placeholder="Enter Vehicle Name" className="journey_input" id="license_quantity"
                  onChange={this.enterQuantity}></input>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Total Amount Paid (INR)</p>
              </Grid>
              <Grid item xs style={{ position: "relative" }}>
                <input type="text" placeholder="Enter Vehicle Name" className="journey_input" id="total_value" defaultValue={this.state.sumValue} disabled></input>
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={4}>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.cancelModal}>Cancel</button>
              </Grid>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.payNowClick}>Pay Now</button>
              </Grid>
            </Grid>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1200}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(PeopleTrackingSolution);