import firebase from 'firebase/app';
require('firebase/analytics')

const firebaseConfig = {
  apiKey: "AIzaSyBDL4G6aWusHZhhVo6wiCdVIwyV5bc7ui4",
  authDomain: "mpconsumerapp3.firebaseapp.com",
  databaseURL: "https://mpconsumerapp3.firebaseio.com",
  projectId: "mpconsumerapp3",
  storageBucket: "mpconsumerapp3.appspot.com",
  messagingSenderId: "276379531074",
  appId: "1:276379531074:web:2f91ac511c44156ac17adb",
  measurementId: "G-214LL8BFM7"
};
const fire = firebase.initializeApp(firebaseConfig);
const analytics = fire.analytics()

export default analytics