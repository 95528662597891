import React, { Component } from 'react';
import $ from 'jquery';
import Calendar from 'react-calendar/dist/entry.nostyle'
import './calendar.scss'

/* Send props for range i.e true or false.
true : to use calendar for week selection
flase : for single date selection*/

const initialState = {
    dateRange: {}
};

class WebCalendar extends Component {
  constructor(props){
    super(props);
    this.state = {      
      date : new Date(),
      dateRange: initialState,
      isRange : this.props.range
    }
  }

  dateClicked = date =>{
    let rangeDate = [];
    console.log('props value',date)
    rangeDate.push(date)
    this.setState({dateRange: rangeDate})
  }

  arrayMultipleDate = (start_date,end_date) =>{
    console.log('start_date', start_date)
    console.log('end_date',end_date)
  }

  reset = () =>{
    this.setState({dateRange : initialState});
    console.log('in reset')
  }

  onDateClicked = (numberOfDays) =>{
    if(numberOfDays > 7){
        //$('.react-calendar__month-view__days').children().removeClass('react-calendar__tile--active')
        alert('you have selected days more than 7')
        this.reset()
        this.forceUpdate()
        console.log('this item', Calendar)        
    }
  }

  render(){
    console.log('props value on render', this.state.isRange)
    var minDate = new Date();minDate.setDate(minDate.getDate() - 90 );
    if(this.state.isRange){
      if(this.state.dateRange[0]){
        var start_date = this.state.dateRange[0][0]
        var end_date = this.state.dateRange[0][1]
        var Difference_In_Time = end_date.getTime() - start_date.getTime();
        var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        this.arrayMultipleDate(start_date,end_date)
      }
    }else{
      console.log('select single date', this.state.dateRange);
    }
    return (
      <div className="calendar-component">
          <Calendar
          selectRange={this.state.isRange}
          onChange={this.dateClicked}
          value={this.state.date}
          onClickDay={this.onDateClicked(Difference_In_Days)}
          maxDate={this.state.date}
          minDate={minDate}
          />
          </div>

    );
  }
}

export default WebCalendar;
