/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:915923fd-0f13-411d-aec6-a72ac7034d70",
    "aws_cognito_region": "ap-south-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "a9a41c62fd22438e9b26296e6b5a9651",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
