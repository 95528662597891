import React, { Component } from 'react';
import $ from 'jquery';
import './create_new_journey.scss';
import Grid from '@material-ui/core/Grid';
import Table from 'react-bootstrap/Table';
import VehicleLists from '../vehicles/vehilcleList';
import { BrowserRouter as Router, Link, } from "react-router-dom";
import Navbar from '../../Config/Navbar';
import Calendar from 'react-calendar/dist/entry.nostyle'
import '../calendar/calendar.scss'
import CommonFunction from '../common_function';
import ApiUrlCall from '../API/apiurl'
import Snackbar from '@material-ui/core/Snackbar';
import { FleetSummary } from '../widgets/widget';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';

class CreateJourney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      vehicleSelect: [],
      driverSelect: [],
      driverSelectE: [],
      selectedStop: 0,
      startLocation: '',
      endLocation: '',
      startDate: new Date().toISOString(),
      startTime: '',
      endDate: new Date().toISOString(),
      endTime: '',
      jsonStartDateTime: '',
      jsonEndDateTime: '',
      stops: [],
      drivers: '',
      CreateJourneyData: '',
      showVehicleList: [],
      showDriverListJor: '',
      openAlert : false,
      errorMessage : '',
      nextCount : 0,
      searchText : '',
      driverSearch : '',
      vehicleArray : '',
      DriverArray : '',
      overrideJourney : 0,
      moving_state:'',
    }
  }

  componentDidMount() {
    analytics.logEvent('screen_view',{
      screen_name : "JourneyVehicleDriver"
    })
    analytics.logEvent('page_view',{
      "page_title" : "JourneyVehicleDriver"
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName, 
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_JOURNEY_ADD_VEHICLE_DRIVER'}
    });
    // console.log('>>>>>>>>>> props', this.props.location)
    var navBarHeight = $('.navBar').outerHeight();
    $('.create_journey_parent').css({
      paddingTop: navBarHeight + 60,
    })
    
    this.getEditJourneyData()
  }


  getEditJourneyData = () => {
    var journey_id = this.props.location.state.journey_id
    var moving_state = this.props.location.state.moving_state
    //console.log("datt",moving_state)
    this.setState({
      moving_state: moving_state
    })
    // console.log("journey_id", journey_id)
    $('#processing').show()
    ApiUrlCall.uniqueJourneyDetail(journey_id).then(response => {
       //console.log('journey detail is', response.data)
      if (response.status === 200) {        
        $('#processing').hide()
        this.setState({
          CreateJourneyData: response.data
        },() => {
          this.journeyDataVD();
          this.getVehicleListJourney();
          this.getDriverListJOurney()
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching journey data', error)
    })
  }

  journeyDataVD = () => {
    const {CreateJourneyData} = this.state
    if(CreateJourneyData.journey_vehicle_detail.length > 0){
      for (let i = 0; i < CreateJourneyData.journey_vehicle_detail.length; i++) {
        const element = CreateJourneyData.journey_vehicle_detail[i];        
        var data = {journey_id : this.props.location.state.journey_id, assigned_to : element.assigned_to.id, 
          vehicleName: element.serial_number, jv_map_id : element.id, is_updated : 0}
        this.state.driverSelectE.push(data)
      }
      this.forceUpdate()      
    }
  }

  selectedValue = (id) => {
    var {driverSelect} = this.state
    for (let i = 0; i < driverSelect.length; i++) {
      const element = driverSelect[i].driverInfo.assigned_to; 
      if(element === id){
        return true
      }
    }
    return
  }

  disableDriver = () => {    
    var allInput = $('.driver_table').find('input')
    // console.log('all input', allInput)         
    
      for (let j = 0; j < allInput.length; j++) {
        const element1 = $(allInput[j]).attr('name');
        var disable = this.selectedValue(element1)
        if(disable){          
          $("input[name="+element1+"]").attr('disabled',true)
        }else{          
          $("input[name="+element1+"]").attr('disabled',false)
        }        
      }                    
  }

  selectVehicle = (e) => {
    var vehicle_data = e.target.parentNode.parentNode.children
    var vehicle_serial_number = $(e.target.parentNode.parentNode).prop('id')
    var vehicle_name = vehicle_data[0].innerText
    var vehicle_make = vehicle_data[1].innerText
    var vehicle_model = vehicle_data[2].innerText
    var vehicle_type = vehicle_data[3].innerText
    var vehicle_status = vehicle_data[4].innerText
    this.state.vehicleSelect = []
    var vehicleArray = this.state.vehicleSelect        
    if (e.target.checked) {
      var vehicleObj = { name: vehicle_name, make: vehicle_make, model: vehicle_model, type: vehicle_type, status: vehicle_status, id: vehicle_serial_number }
      this.state.vehicleSelect.push(vehicleObj)
      this.forceUpdate()
    } else {
      var array_index = vehicleArray.findIndex(x => x.name === vehicle_name)
      vehicleArray.map((item, index, vehicleArray) => {
        if (vehicle_name == item.name) {
          vehicleArray.splice(array_index, 1)
          var driverCheckbox = document.getElementById(item.name)
          $(driverCheckbox).find('.driver_list').find('input').prop('checked', false)
          this.forceUpdate()
        }
      })
    }
  }

  selectDriver = (e) => {
    var driver_data = e.currentTarget.parentNode.parentNode.children
    var sibling_input = e.currentTarget.parentNode.parentNode
    var driver_id = $(sibling_input).prop('id')
    // console.log('driver id is')
    var driver_name = driver_data[1].innerText
    var driver_number = driver_data[4].innerText
    var image_td = driver_data[0]
    var driver_image = $(image_td).children().prop('src')
    this.state.driverSelect=[]
    var driverArray = this.state.driverSelect
    var appendId = $(e.currentTarget).parents()[8]
    var serial_id = $(appendId).prop('id')
    var checkbox = e.currentTarget.parentNode.parentNode.parentNode
    var prevcheckval = $(sibling_input).siblings().find('input:checked').val()    
    var showCount = $(checkbox).parents()[5]    
    if (e.target.checked) {
      var hide_list = $(checkbox).parents()[4]
      $(hide_list).slideUp(200)
      // console.log('hide list is')
      var array_index_prev = driverArray.findIndex(x => x.driverInfo.assigned_to === prevcheckval)
      driverArray.map((item, index, driverArray) => {
        if (prevcheckval == item.driverInfo.assigned_to) {
          driverArray.splice(array_index_prev, 1)
          this.forceUpdate()
          // console.log('driver data in checked state', this.state.driverSelect)
        }
      })
      $(showCount).find('.selected_driver').show()
      $(showCount).find('.selected_driver').find('span').empty()
      $(showCount).find('.selected_driver').find('span').append(driver_name)
      $(sibling_input).siblings().find('input:checked').prop('checked', false)
      var driverObj = { vehicleName: serial_id, driverInfo: { assigned_to: driver_id } }
      this.state.driverSelect.push(driverObj)
      this.forceUpdate()
      // console.log('driver data', this.state.driverSelect)
    } else {
      // console.log('check box is uncheck')
      $(showCount).find('.selected_driver').hide()
      var array_index = driverArray.findIndex(x => x.driverInfo.assigned_to === driver_id)
      driverArray.map((item, index, driverArray) => {
        if (driver_id == item.driverInfo.assigned_to) {
          driverArray.splice(array_index, 1)
          this.forceUpdate()
          // console.log('driver data', this.state.driverSelect)
        }
      })
    }
  }

  showDriverList = (e) => {
    var driverList = $(e.target.parentNode.parentNode.parentNode).find('.driver_list')
    var table_id = $(driverList).find('.tableParent').attr('id')
    // console.log('table id is', table_id)
    this.disableDriver()    
    $(driverList).slideToggle("slow", "swing")
    CommonFunction.tableCellWidth(table_id)
  }

  searchContent = (input, table, column) => {
    var search_input, search_filter, search_table, search_tbody, search_tr, search_td, i, txtValue;
    search_input = document.getElementById(input);
    search_filter = search_input.value.toUpperCase();
    search_table = document.getElementById(table);
    search_tr = search_table.getElementsByTagName("tr");
    for (i = 0; i < search_tr.length; i++) {
      search_td = search_tr[i].getElementsByTagName("td")[column];
      if (search_td) {
        txtValue = search_td.textContent || search_td.innerText;
        if (txtValue.toUpperCase().indexOf(search_filter) > -1) {
          search_tr[i].style.display = "";
        } else {
          search_tr[i].style.display = "none";
        }
      }
    }
  }

  viewStop = () => {
    $('#journey_stops').slideDown("slow", "swing")
    $('#journey_stops').find('.stop-nav').last().hide()    
  }

  searchVehicle = (e) => {
    var text = e.currentTarget.value
    if(text.length > 2){
      this.setState({
        searchText : text,
      },() => {
        this.getVehicleListJourney()
      })
    }else if(text.length === 0){
      this.setState({
        searchText : ''
      },() => {
        this.getVehicleListJourney()
      })
    }
  }

  getVehicleListJourney = () => {
    // $('#processing').show()
    var data = {next_count : this.state.nextCount , search : this.state.searchText, search_column: "vehicle_name",size:1000,start_time: this.state.CreateJourneyData.start_time, end_time: this.state.CreateJourneyData.end_time}
    ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
      console.log("vehicle list data", response.data)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        // $('#processing').hide()
        response.data.data.vehicle_summary_list.map(data =>{
          console.log(data.service_expired)
          if(data.service_expired == false){
            this.state.showVehicleList.push(data)
          }
        })
        this.setState({
          //showVehicleList: response.data.data.vehicle_summary_list
        }, () => {
          CommonFunction.tableCellWidth('cj_vehicle_list')
        })
      }
    }).catch(error => {
      // $('#processing').hide()
      console.log("error while getting vehicle list", error)
    })
  }

  searchDriver = (e) => {
    var text = e.currentTarget.value
    if(text.length > 2){
      this.setState({
        driverSearch : text,
      },() => {
        this.getDriverListJOurney()
      })
    }else if(text.length === 0){
      this.setState({
        driverSearch : ''
      },() => {
        this.getDriverListJOurney()
      })
    }
  }

  getDriverListJOurney = () => {
    // $('#processing').show()    
    var table_id = $('.driver_list').find('.tableParent')
    var data = {next_count : this.state.nextCount, search : this.state.driverSearch,size:1000,start_time: this.state.CreateJourneyData.start_time, end_time: this.state.CreateJourneyData.end_time}
    ApiUrlCall.getDriverDetails(JSON.stringify(data)).then(response => {
      // console.log("driver list>>>>>>>>>>>", response)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        // $('#processing').hide()
        this.setState({
          showDriverListJor : response.data.data.driver_list
        },() => {
          for (let i = 0; i < table_id.length; i++) {
            const element = table_id[i];
            var id = $(element).attr('id')
            CommonFunction.tableCellWidth(id)
          }
        })
      }
      else{
        // $('#processing').hide()
      }      
      // if()
    }).catch(error => {
      // $('#processing').hide()
      console.log("error while fetching driver list", error)
    })
  }

  AddJourneyVehicle = () => {
    if(this.state.overrideJourney === 1){
      // $('#journey_override').slideDown("slow")
      $('#insuf_fuel').slideDown("slow")
      return
    }
    var {driverSelect, vehicleSelect} = this.state
    if (driverSelect.length > 0) {
      driverSelect.map(journeyData => {
        var j_journeyid = this.state.CreateJourneyData.id;
        var j_vehicleid = journeyData.vehicleName;
        var j_vehicle_driver = { assigned_to: journeyData.driverInfo.assigned_to }
        // console.log(j_journeyid, '...........', j_vehicleid, '..............', j_vehicle_driver)
        ApiUrlCall.createJourneyVehicle(j_journeyid, j_vehicleid, JSON.stringify(j_vehicle_driver)).then(response => {
          console.log('vehicle added', response)
          if (response.status === 201) {
            this.setState({
              openAlert: true,
              errorMessage: "Journey Created Successfully"
            })
            setTimeout(() => {
              this.props.history.push({
                pathname: 'journey'})
            }, 2000);
            
          }
        }).catch(error => {
          console.log("error while adding vehicle to a journey", error.response.data)
        })
      })
    } else {
      var message
      if(vehicleSelect.length === 0){
        message = 'Select atleast one vehicle'
      }else{
        message = "Assign Driver to Selected Vehicle"
      }
      this.setState({
        openAlert : true,
        errorMessage : message
      })
    }
    // console.log('length', driverSelect.length)
  }

  editJourney = (id,moving_state) => {
     //console.log("anky",id)
    this.props.history.push({
      pathname: '/edit_journey_details',
      state: { journey_id: id ,
               my_state : moving_state
      }
    })
  }

  VehicleStatus = (status) => {
    const AVAILABLE = 1;
    const ASSIGNED = 2;
    const SERVICING = 3;
    const{t} = this.props
    if(status === AVAILABLE){
      return(
        <p className="cj_w_header" style={{color : "#28a745"}}>{t('ct.available')}</p>
      )
    }else if(status === ASSIGNED){
      return(
        <p className="cj_w_header" style={{color : "#ed7d28"}}>{t('ct.assigned')}</p>
      )
    }else if(status === SERVICING){
      return(
        <p className="cj_w_header" style={{color : "#227cd0"}}>{t('dds.servicing')}</p>
      )
    }
  }

  setVehicleName = (e) => {
    const {driverSelectE} = this.state
    var index = $(e.currentTarget).parent().parent().parent().parent().prop('id')
    var serial_number =$(e.currentTarget).parent().prop('id')
    var name = $(e.currentTarget).text()
    driverSelectE[index].vehicleName = serial_number
    driverSelectE[index].is_updated = 1
    $(e.currentTarget).parent().parent().slideUp()
    $(e.currentTarget).parent().parent().prev().val(name)
    this.forceUpdate()
    // console.log('driver data is', driverSelectE)
  }

  showVeihcleData = (div,text) => {
    var data = {next_count : 0 , search : text,search_column: "vehicle_name"}
    var array = []
    ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
      // console.log("vehicle list data", response.data)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        // $('#processing').hide()
        var vehicle_data = response.data.data.vehicle_summary_list
        if(vehicle_data.length > 0){
          for (let i = 0; i < vehicle_data.length; i++) {
            const element = vehicle_data[i];
            array.push(
              <div id={element.serial_number}>
                <p className="cj_w_header" style={{margin : "0px"}} onClick={this.setVehicleName}>{element.vehicle_name}</p>
                <hr className="drop-hr"/>
              </div>
            )            
          }
          this.setState({
            vehicleArray : array
          },() => {
            $(div).slideDown()
            // console.log($(div),'------->>>>>>><<<<<<<<<<<<------',this.state.vehicleArray)
          })
        }        
      }
    }).catch(error => {
      // $('#processing').hide()
      console.log("error while getting vehicle list", error)
    })
  }

  existingVehicle = (e) => {
    var text = e.currentTarget.value
    // console.log('text is', text)
    var showDiv = $(e.currentTarget).next()
    if(e.currentTarget.value.length > 2){
      this.showVeihcleData(showDiv,text)
    }else{
      this.setState({
        vehicleArray : ''
      },() => {
        $(showDiv).slideUp()
      })
    }
  }

  showDriverData = (div,text) => {
    var data = {next_count : 0 , search : text}
    var array = []
    ApiUrlCall.getDriverDetails(JSON.stringify(data)).then(response => {
      // console.log("vehicle list data", response.data)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        // $('#processing').hide()
        var driver_data = response.data.data.driver_list
        if(driver_data.length > 0){
          for (let i = 0; i < driver_data.length; i++) {
            const element = driver_data[i];
            array.push(
              <div id={element.id}>
                <p className="cj_w_header" style={{margin : "0px"}} onClick={this.setDriverName}>{element.driver_name}</p>
                <hr className="drop-hr"/>
              </div>
            )            
          }
          this.setState({
            DriverArray : array
          },() => {
            $(div).slideDown()
            // console.log($(div),'------->>>>>>><<<<<<<<<<<<------',this.state.DriverArray)
          })
        }        
      }
    }).catch(error => {
      // $('#processing').hide()
      console.log("error while getting vehicle list", error)
    })
  }


  setDriverName = (e) => {
    const {driverSelectE} = this.state
    var index = $(e.currentTarget).parent().parent().parent().parent().prop('id')
    var driver_id =$(e.currentTarget).parent().prop('id')
    var name = $(e.currentTarget).text()
    driverSelectE[index].assigned_to = driver_id
    driverSelectE[index].is_updated = 1
    $(e.currentTarget).parent().parent().slideUp()
    $(e.currentTarget).parent().parent().prev().val(name)
    this.forceUpdate()
    // console.log('driver data is', driverSelectE)
  }

  existingDriver = (e) => {
    var text = e.currentTarget.value
    // console.log('text is', text)
    var showDiv = $(e.currentTarget).next()
    if(e.currentTarget.value.length > 2){
      this.showDriverData(showDiv,text)
    }else{
      this.setState({
        driverArray : ''
      },() => {
        $(showDiv).slideUp()
      })
    }
  }

  updateJourney = () => {
    const {driverSelectE} = this.state
    for (let i = 0; i < driverSelectE.length; i++) {
      const element = driverSelectE[i];
      // console.log('element', element)
      ApiUrlCall.updateJourney(JSON.stringify(element)).then(response => {
        // console.log('response is', response)
      })
    }
    this.getEditJourneyData(this.props.location.state.journey_id)
    // console.log('------------------', driverSelectE)
  }
  render() {
    const {t} = this.props
    var minDate = new Date(); minDate.setDate(minDate.getDate());
    if (this.state.CreateJourneyData) {
      var numberOfStop = this.state.CreateJourneyData.stops.length
      // console.log(">>>>stops", numberOfStop)
    }

    if (this.state.showVehicleList) {
      var journeyVehicles = this.state.showVehicleList.map(j_vehicle => {
        var type_vehicle = ["Car", "Bike", "Bus", "Garbage", "Ambulance", "Fire Truck", "Taxi", "VIP", "Blood Bank", "Ganesha", "Truck"][j_vehicle.vehicle_type - 1]        
        return (
          <tr key={j_vehicle.serial_number} id={j_vehicle.serial_number}>
            <td>{j_vehicle.vehicle_name}</td>
            <td>{j_vehicle.make ? j_vehicle.make : '-'}</td>
            <td>{j_vehicle.model ? j_vehicle.model : '-'}</td>
            <td>{type_vehicle}</td>
            <td>{this.VehicleStatus(j_vehicle.status)}</td>
            <td><input type="radio" name="vehicle_tick" onClick={this.selectVehicle} style={{pointerEvents : j_vehicle.status === 3 || j_vehicle.status === 2 ? "none" : "auto"}} /></td>
          </tr>
        )
      })
    }

    if (this.state.showDriverListJor) {
      var journeyDrivers = this.state.showDriverListJor.map(j_driver => {
        return (
          <tr className="driver_table" key={j_driver.id} id={j_driver.id}>
            <td style={{ display: "none" }}><img src="/images/dark_map.png" className="img-circle" alt="driver image"></img></td>
            <td>{j_driver.driver_name} </td>
            <td>{CommonFunction.tableAddressEllipsis(j_driver.last_journey_start_loc,7)} to 
            {CommonFunction.tableAddressEllipsis(j_driver.last_journey_end_loc,7)}</td>
            <td>{j_driver.mobile_no}</td>
            <td>{this.VehicleStatus(j_driver.status)}</td>
            <td><input type="radio"  name={j_driver.id} value={j_driver.id} onClick={this.selectDriver} 
            style={{pointerEvents : j_driver.status === 2 ? "none" : "auto"}}/></td>
          </tr>
        )
      })
    }

    if(this.state.vehicleArray){
      var vehicle_list = this.state.vehicleArray.map(div => {
        return div
      })
    }

    if(this.state.DriverArray){
      var driver_list = this.state.DriverArray.map(div => {
        return div
      })
    }

    if(this.state.CreateJourneyData){
      if(this.state.CreateJourneyData.journey_vehicle_detail.length > 0){
        var existingData = this.state.CreateJourneyData.journey_vehicle_detail.map((data,index) => {
          return(
            <Grid container spacing={3} id={index}>
                <Grid item xs style={{position : "relative"}}>
                  <p className="cj_w_header">Vehicle Name</p>
                  <input id={data.serial_number} type="text" className="addtional_detail_input" 
                  defaultValue={data.vehicle_name} style={{width : "100%"}} onKeyUp={this.existingVehicle}
                  placeholder="Enter Vehicle Name"></input>
                  <div className="dv_dropdow"  style={{display : "none"}}>                    
                    {vehicle_list}                    
                  </div>
                </Grid>
                <Grid item xs style={{position : "relative"}}>
                  <p className="cj_w_header">Driver Name</p>
                  <input id={data.assigned_to.id} type="text" className="addtional_detail_input" 
                  defaultValue={data.assigned_to.driver_name} style={{width : "100%"}} onKeyUp={this.existingDriver}></input>
                  <div className="dv_dropdow" style={{display : "none"}}>
                    {driver_list}
                  </div>
                </Grid>                
            </Grid>
          )
        })
      }
    }

    var vehilceList = this.state.vehicleSelect.map((vehilceList, index) => {
      var driver_input = vehilceList.id + 'search_driver'
      var driver_table = vehilceList.id + 'driver_list'
      return (
        <div className="create_journey_card" key={vehilceList.id} id={vehilceList.id}>
          <Grid container>
            <Grid item xs={3}>
              <img src="images/vehicle-1.png" alt="car icon" style={{ marginBottom: "10px" }}></img>
              <p className="journey_item_vehicle">{vehilceList.name}</p>
              {/* <span style={{ textTransform: "uppercase" }}><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>{vehilceList.status}</span><br /> */}
              <button className="btn orange-btn" style={{ float: 'none' }} onClick={this.showDriverList}>{t('dvjs.assign')}</button>
            </Grid>
            <Grid item xs container>
              <Grid container style={{ borderBottom: "1px solid #eeeeee" }}>
                <Grid item xs={3} className="padding_left_20">
                  <p className="cj_w_header">{t('ct.make')}</p>
                  <p className="p_date_time">{vehilceList.make}</p>
                </Grid>
                <Grid item xs={3} className="padding_left_20">
                  <p className="cj_w_header">{t('ct.model')}</p>
                  <p className="p_date_time">{vehilceList.model}</p>
                </Grid>
                <Grid item xs={3} className="padding_left_20">
                  <p className="cj_w_header">{t('dvjs.type')}</p>
                  <p className="p_date_time">{vehilceList.type}</p>
                </Grid>
                <Grid item xs={3}>

                  {/* <span style={{ float: "right" }}>
                    <div className="small_button" style={{ display: "inline-block", marginRight: "10px" }}>
                      <i className="fa fa-exclamation-circle del_info_icon" aria-hidden="true"></i>
                    </div>
                    <div className="small_button" style={{ display: "inline-block" }}>
                      <i className="fa fa-trash-o del_info_icon" aria-hidden="true"></i>
                    </div>
                  </span> */}

                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "20px" }}>
                {/* <Grid item xs className="padding_left_20">
                  <p>Additional Details</p>
                  <input type="text" placeholder="Enter Weight To Be Carried" className="addtional_detail_input"></input>
                  <span style={{ marginLeft: "10px", color: "#F0762B", fontWeight: "bold" }}>Kg/Ltrs</span>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <div className="selected_driver" style={{ display: "none", marginTop: "15px" }}>
            <p className="cj_w_header p_margin0">{t('dvjs.selectdriver')}: <span className="p_header"></span></p>
          </div>
          {/* {selectedDriver} */}

          <div className="driver_list">
            <hr className="cj_hr" />
            <Grid container>
              <Grid item xs>
                <label className="p_header">{t('dvjs.driverlist')}</label>
                <p className='help_text' style={{fontSize : "15px"}}>Please select Available Drivers</p>
              </Grid>
              <Grid item xs>
                <div style={{ textAlign: "right"}} >
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <input type="text" id={driver_input} 
                    // onKeyUp={() => this.searchContent(driver_input, driver_table, 1)} 
                    onKeyUp={this.searchDriver}
                    placeholder={t('ct.search.driver')} title="Type in a name" className="addtional_detail_input"></input>
                    <i class="fa fa-search search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="tableOuterDiv" style={{ margin: "20px -20px -20px -20px" }}>
              <div className="tableParent" id={driver_table}>
                <Table responsive>
                  <thead className="tableHeader">
                    <tr>
                      <th>{t('ct.table.h6')}</th>
                      <th>{t('ct.table.h7')}</th>
                      <th>{t('ct.table.h8')}</th>
                      <th>{t('ct.table.h5')}</th>
                      <th>{t('ct.table.h9')}</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    {journeyDrivers}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      )
    })


    if (this.state.CreateJourneyData.stops) {
      var listOfStop = this.state.CreateJourneyData.stops.map(listOfStop => {
        return (
          <Grid container className="stop_margin_bottom" key={listOfStop.id}>
            <Grid item xs={1}>
              <img src="images/stop.png" className="navgation_image stop-img"></img>
              <div className="stop-nav"></div>
            </Grid>
            <Grid item xs={11}>
              <p className="cj_w_header" style={{paddingLeft : "10px"}}>{listOfStop.location}</p>
            </Grid>
          </Grid>
        )
      })
    }

    return (
      <div>
        <Navbar />
        <div className="create_journey_parent">
          {/* add vehicle and driver to journey */}
          <div style={{ paddingLeft: "100px", paddingRight: "100px", marginBottom: "30px" }}>
            <h3 className="page_header">
              <Link to="journey" style={{ color: "#000000" }}><img src="images/arrow.png" alt="icon"></img></Link>
              &nbsp;&nbsp;{t('dvjs.create')}
        </h3>
          </div>
          <div id="add_vehicle_driver">
            <div className="cj_enter_detail_vehicle">

              {/* enter journey details */}
              <div className="create_journey_card">
                {/* <Grid container>
                <div>
                  step
              </div>
              </Grid> */}
                <Grid container>
                  <Grid item xs={6}>
                    <label className="p_header">{t('dvjs.detail')}</label>
                  </Grid>
                  <Grid item xs={6}>
                    <div class="small_button" style={{ float: "right" }} onClick={() => this.editJourney(this.state.CreateJourneyData.id,this.state.moving_state)}>
                      <i class="fas fa-pencil-alt del_info_icon" aria-hidden="true"></i>
                    </div>
                  </Grid>
                </Grid>
                <hr className="cj_hr" />
                <Grid spacing={3} container>
                  <Grid item xs={5} container style={{ borderRight: "1px solid #ececec" }}>
                    <Grid container style={{ marginBottom: "15px" }}>
                      <Grid item xs={2} >
                        <img src="images/location.png" width="20px" className="navgation_image"></img>
                        <div className="navigation"></div>
                      </Grid>
                      <Grid item xs={10}>
                        <div>
                          <p className="cj_w_header">{t('ct.start.location')}</p>
                          <p className="p_date_time">{this.state.CreateJourneyData.planned_start_loc}</p>
                        </div>
                        {/* <div>
                        <p>End Location</p>
                        <p className="p_date_time">{this.state.endLocation}</p>
                      </div> */}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} >
                        <img src="images/location.png" width="20px" className="navgation_image"></img>
                      </Grid>
                      <Grid item xs={10}>
                        <div>
                          <p className="cj_w_header">{t('ct.end.location')}</p>
                          <p className="p_date_time">{this.state.CreateJourneyData.planned_end_loc}</p>
                        </div>
                        {/* <div>
                        <p>End Location</p>
                        <p className="p_date_time">{this.state.endLocation}</p>
                      </div> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} container style={{ borderRight: "1px solid #ececec", textAlign: "center" }}>
                    <Grid item xs={6}>
                      <div>
                        <p className="cj_w_header">{t('ct.start.datetime')}</p>
                        <p className="p_date_time">{CommonFunction.dateFormate(this.state.CreateJourneyData.start_time)} | {CommonFunction.timeFormate(this.state.CreateJourneyData.start_time)}</p>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        <p className="cj_w_header">{t('ct.end.datetime')}</p>
                        <p className="p_date_time">{CommonFunction.dateFormate(this.state.CreateJourneyData.end_time)} | {CommonFunction.timeFormate(this.state.CreateJourneyData.end_time)}</p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} container style={{ textAlign: "center" }}>
                    <Grid item xs={6} >
                      <p className="p_header">{t('ct.stops')}</p>
                      <p className="p_big_grey">{numberOfStop < 10 ? '0' + numberOfStop : numberOfStop}</p>
                    </Grid>
                    <Grid item xs={6} >
                      <button class="btn orange-btn" style={{ float: "none" }} onClick={this.viewStop}>{t('ct.view.stops')}</button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              {/* existing vehicle and driver detail */}
                <div className="create_journey_card" style={{display : this.state.driverSelectE.length > 0 ? "" : "none"}}>
                      {existingData}
                      <button className="btn orange-btn" style={{float : "none", marginTop : "20px"}} onClick={this.updateJourney}>Update</button>
                </div>
              {/* end */}


              {/* select vehicle for journey */}
              <div className="create_journey_card">
                <Grid container>
                  <label className="p_header">
                    {t('dvjs.which')}
              </label>
                </Grid>
                <hr className="cj_hr" />
                <div style={{ textAlign: "right",position : "relative" }} >
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <input type="text" id="search_vehicle" 
                    // onKeyUp={() => this.searchContent("search_vehicle", "vehicle_list", 0)} 
                    onKeyUp={this.searchVehicle}
                    placeholder={t('ct.search.vehicle')} title="Type in a name" className="addtional_detail_input"></input>
                    <i class="fa fa-search search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                  </div>
                  {/* <div class="small_button" style={{ display: "inline-block", marginLeft: "20px" }}>
                  <i class="fa fa-filter del_info_icon" aria-hidden="true"></i>
                </div> */}
                <p className='help_text' style={{fontSize : "15px", position : "absolute", top : "0"}}>Please select Available Vehicles</p>
                </div>
                <div className="tableOuterDiv" style={{ margin: "20px -20px -20px -20px" }}>
                  <div className="tableParent" id="cj_vehicle_list">
                    <Table responsive id="vehicle_list">
                      <thead className="tableHeader">
                        <tr>
                          <th>{t('ct.table.h10')}</th>
                          <th>{t('ct.make')}</th>
                          <th>{t('ct.model')}</th>
                          <th>{t('ct.table.h11')}</th>
                          <th>{t('ct.table.h15')}</th>
                          <th>{t('ct.table.h9')}</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {journeyVehicles}
                      </tbody>
                    </Table>
                  </div>
                </div>

              </div>
            </div>

            {/* show vehicle attached to journey */}
            <div className="cj_attach_vehicle">
              <label className="p_header" style={{ marginBottom: "10px", marginLeft: "20px" }}>{t('dvjs.other.detail')}</label>
              {vehilceList}
              <div style={{ textAlign: "center" }}>
                <button className="btn generate-btn" onClick={this.AddJourneyVehicle}>{t('dvjs.create')}</button>
              </div>
            </div>
          </div>
          {/* end */}


          {/* view stops modal*/}
          <div id="journey_stops" className="modal_parent">
            <div className="modal_body">
              <p className="modal_header">{t('ct.stops')}</p>
              <Grid container className="stop_margin_bottom">
                <Grid item xs={1}>
                  <img src="images/location.png" className="navgation_image stop-img"></img>
                  <div className="stop-nav"></div>
                </Grid>
                <Grid item xs={11}>
                  <p className="cj_w_header" style={{paddingLeft : "10px"}}>{this.state.CreateJourneyData.planned_start_loc}</p>
                </Grid>
              </Grid>
              {listOfStop}
              <Grid container className="stop_margin_bottom">
                <Grid item xs={1}>
                  <img src="images/location.png" className="navgation_image stop-img"></img>
                  <div className="stop-nav"></div>
                </Grid>
                <Grid item xs={11}>
                  <p className="cj_w_header" style={{paddingLeft : "10px"}}>{this.state.CreateJourneyData.planned_end_loc}</p>
                </Grid>
              </Grid>
              <div className="close_modal" onClick={() => {
                $('#journey_stops').fadeOut("slow")                
              }}>
                <i class="fa fa-close" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          {/* view stops modal end*/}


          {/* Journey Override modal*/}
          <div id="journey_override" className="modal_parent">
            <div className="modal_body">              
              <div className="jo_header">
                <img src="images/journey_override.png"></img> 
                <p className="p_header" style={{marginTop : "10px", marginBottom : "0px"}}>Vehicle Already Assigned</p>
                <p className="cj_w_header">The vehicle you have picked is already<br/>assigned to another journey</p>
              </div>
              <p className="p_header">Journey Details</p>
              <Grid container style={{borderTop : "1px solid #ccc"}}>
                <Grid item xs>
                  <p className="cj_w_header jo_p">Journey</p>
                </Grid>
                <Grid item xs>
                  <p className="cj_w_header jo_p" style={{textAlign : "right"}}>Jaipur to Hyderabad</p>
                </Grid>
              </Grid>
              <Grid container style={{borderTop : "1px solid #ccc"}}>
                <Grid item xs>
                  <p className="cj_w_header jo_p">Vehicle No</p>
                </Grid>
                <Grid item xs>
                  <p className="cj_w_header jo_p" style={{textAlign : "right"}}>MH 02 4002</p>
                </Grid>
              </Grid>
              <Grid container style={{borderTop : "1px solid #ccc"}}>
                <Grid item xs>
                  <p className="cj_w_header jo_p">Date & Time</p>
                </Grid>
                <Grid item xs>
                  <p className="cj_w_header jo_p" style={{textAlign : "right"}}>08 July 2020, 13:00</p>
                </Grid>
              </Grid>
              <p className="cj_w_header"><b>Are you sure you want to overwrite the  above journey?</b></p>
              <div style={{textAlign : "center"}}>
                <button className="btn orange-border jo_cancel" onClick={() => {
                    $('#journey_override').fadeOut("slow")  
                }}>Cancel</button>
                <button className="btn orange-btn" style={{float : "none"}}>Overwrite</button>
              </div>
              <div className="close_modal" onClick={() => {
                $('#journey_override').fadeOut("slow")                
              }}>
                <i class="fa fa-close" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          {/* Journey Override modal end*/}         

        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1800}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(CreateJourney);
