import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Snackbar from '@material-ui/core/Snackbar';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import ApiReport from './api_report';
import Table from 'react-bootstrap/Table'; 
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'; 
import { process } from '@progress/kendo-data-query';
import Tooltip from '@material-ui/core/Tooltip';


class ApiIntegration extends Component {
  _exporter_vehicleList
  constructor(props){
    super(props);
    this.state = {
        checkedA : false,
        text : 'Api Integration Disabled',
        emailAddress : '',
        openAlert : false,
        errorMessage : '',
        keyData : '',
        showReport : false,
        ReportContent : '',
        vehicleListData : '',
        apiList : '',
        documentData : ''
    }
  }

  componentDidMount(){
    analytics.logEvent('screen_view',{
        screen_name : "ApiIntergation"
      })
      analytics.logEvent('page_view',{
        page_title : "ApiIntergation",        
      })
      Amplify.configure(awswebapp);
      Analytics.record({
        name: constant.awsName, 
        // Attribute values must be strings
        attributes: { screen_name: 'WEB_API_INTEGRATION'}
      });        
      this.getApiState()
      this.getVehicleListData()
      this.getApiDoc()    
  }

  export_vehicle_list_report = () => {
    // console.log('22222222222')
    this._exporter_vehicleList.save();
  }

  getApiDoc = () => {
      ApiUrlCall.getApiDocument().then(response => {
        // console.log('response document', response)
        if(response.data.response_code === 200 && response.data.is_success){
            this.setState({
                documentData : response.data.data
            })
        }else{
            this.setState({
                openAlert : true,
                errorMessage : response.data.response_message
            })
        }
      }).catch(error => {
          console.log('error while fetching api documents', error)
      })
  }

  getVehicleListData = () => {
      ApiUrlCall.apiVehicleList().then(response => {
        //console.log('response vehicle list is', response)
        if(response.data.response_code === 200 && response.data.is_success){
            this.setState({
                vehicleListData : response.data.data.vehicle_list
            })
        }
      }).catch(error => {
          console.log('error while fetching api vehicle list', error)
      })
  }

  getApiState = () => {
      $('#processing').show()
      ApiUrlCall.getApiIntegrationState().then(response => {
        //console.log('reponspe', response)
        if(response.data.response_code === 200 && response.data.is_success){
            var message
            if(response.data.data.is_enabled){
                message = "Api Integration Enabled"
            }else{
                message = "Api Integration Disabled"
            }
            this.setState({
                checkedA : response.data.data.is_enabled,
                text : message,
                keyData : response.data.data,
            }, () => {
                $('#processing').hide()
            })
        }else{
            $('#processing').hide()
            this.setState({
                openAlert : true,
                errorMessage : response.data.response_message
            })
        }
      }).catch(error => {
        $('#processing').hide()
        console.log('error while fetching api state', error)
      })
  }

  setApiState = (value) => {
    const data = {toggle_api : value}
    ApiUrlCall.setApiIntegrationState(JSON.stringify(data)).then(response => {
         //console.log('response', response)
        if(response.data.response_code === 200 && response.data.is_success){
            this.getApiState()
        }else{
            this.setState({
                openAlert : true,
                errorMessage : response.data.response_message,
            })
        }
    }).catch(error => {
        console.log('error while setting api state', error)
    })
  }

  downloadFile = (link,formate,name) => {
    //   console.log('download method is called')
    axios({
        url: link,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name+'.'+formate);
        document.body.appendChild(link);
        link.click();
      }); 
  }


  validateEmail = (emailField) => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var inputValue = document.getElementById(emailField).value
    if(inputValue.match(reg)){
      return true;
    }else{      
      return false;
    }
  }

  sendEmail = () => {
    const {emailAddress} = this.state
    var validEmail = this.validateEmail("email_id")
    const data = {email : emailAddress}
    if(emailAddress){
        if(validEmail){
            ApiUrlCall.sentEmailViaReport(JSON.stringify(data)).then(response => {
                // console.log('response email', response)
                if(response.data.response_code === 200 && response.data.is_success){
                    $('#email_id').val('')
                }
                this.setState({
                    openAlert : true,
                    errorMessage : response.data.response_message
                })
            }).catch(error => {
                console.log('error while sending email',error)
            })
        }else{
            this.setState({
                openAlert : true,
                errorMessage : "Invalid Email",
            })
        }
    }else{
        this.setState({
            openAlert : true,
            errorMessage : "Enter Email Address",
        })
    }
  }

  toggle = (event) => {
    //   console.log('i m toggling')
      $('.api_parent').scrollTop()
      var textval
      if (event.target.checked) {
        this.setApiState(true)
      }else{
        $('#warning_message').slideDown(200)        
      }
      this.setState({ ...this.state, 
        [event.target.name]: event.target.checked, 
        text : textval,
    });
  }

  disabledYes = () => {
    $('#warning_message').fadeOut(200)
    this.setApiState(false)
  }

  disabledNo = () => {
      $('#warning_message').fadeOut(200)
      this.getApiState()
  }

  viewReport = () => {
      this.setState({
          ReportContent : <ApiReport />,
          showReport : true,
      })
  }

  regenerateApiKey = () => {
      $('#regenerate_api').slideDown("slow")
  }

  generateYes = () => {
    ApiUrlCall.regenerateApiKey().then(response => {
        // console.log('response', response)
        if(response.data.response_code === 200 && response.data.is_success){
            $('#regenerate_api').fadeOut('slow')
            this.getApiState()
        }else{
            this.setState({
                openAlert : true,
                errorMessage : response.data.response_message
            })
        }
    }).catch(error => {
        console.log('error while re-generating API key', error)
    })
  }
  generateNo = () => {
      $('#regenerate_api').fadeOut('slow')
  }

  viewApiVehicleList = () => {
    $('#api_vehicle_list').slideDown('slow')
  }
  modalClose = () => {
    $('#api_vehicle_list').fadeOut("slow")    
}

copyToClipboard = (element) => {        
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    this.setState({
        openAlert : true,
        errorMessage : "Text Copied to Clipboard",
    })
}


  render(){
    const {t} = this.props
    const {keyData, vehicleListData,} = this.state
    // console.log('checked status is', this.state.checkedA)
    if(vehicleListData){
        var vehicleList = vehicleListData.map(list  => {
            return(
                <tr>
                    <td>{list.vehicle_id}</td>
                    <td>{list.vehicle_name}</td>
                </tr>
            )
        })
        var vehicleData = process(vehicleListData, {
    
        }).data;  
    }

    
    return (
      <div className="api_parent">
        <div className="user_profile">
            <Grid container>
                <Grid item xs>
                    <Switch
                    checked={this.state.checkedA}
                    onChange={this.toggle}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <p className="cj_w_header" style={{display : "inline"}}>{this.state.text}</p>
                    <p className="help_text">{t('ss.api.help2')}</p>
                </Grid>
                <Grid item xs>
                    <p className="cj_w_header" style={{display : keyData.is_enabled ? "inline" : "none",
                    float : "right", marginTop : "8px"}}><b>API Key</b> : <span id="api_key">{keyData.api_key}</span></p>
                </Grid>
            </Grid>
        </div>
        <div id="email_div" style={{marginTop : "25px", marginBottom : "15px",
                    pointerEvents : this.state.checkedA ? "auto" : "none",
                    opacity : this.state.checkedA ? "1" : "0.4"}}>
            <Grid container>
                <Grid item xs={8}>
                    <img src="images/arrow.png" alt="icon" style={{marginRight : "10px",
                    display : this.state.showReport ? "" : "none", cursor : "pointer"}} onClick={() => {
                        this.setState({
                            showReport : false,
                            ReportContent : '',
                        })
                    }}></img>
                    <p className="p_header" style={{display : "inline"}}>{t('ss.api.sub')}</p>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>                        
                        <Grid item xs={9}>
                            <input type="text" placeholder={t('ss.api.email')} id="email_id" className="addtional_detail_input"
                            style={{width : "100%"}} onChange={(e) => {
                                this.setState({
                                    emailAddress : e.currentTarget.value
                                })
                            }} autoComplete="on"></input>
                        </Grid>
                        <Grid item xs={3}>
                            <button className="btn btn-block orange-btn" style={{marginTop : "0px", padding : "8px 10px"}}
                            onClick={this.sendEmail}>{t('ss.api.send')}</button>
                        </Grid>
                    </Grid>
                    <p className="help_text">{t('ss.api.help1')}</p>
                </Grid>
            </Grid>
        </div>
        <div id="api_content_div" style={{display : this.state.showReport ? "none" : "",
        pointerEvents : this.state.checkedA ? "auto" : "none",
        opacity : this.state.checkedA ? "1" : "0.4"}}>            
            <Grid container spacing={5} style={{marginTop : "20px"}}>    
                <Grid item xl={3} xs={4}>
                    <Grid container spacing={2} className="user_profile" style={{padding : "5px"}}>
                        <Grid item xl={2} xs={3}>
                            <img className="api_image" style={{width : "100%"}} src="images/user_profile_img.png" alt="icon"></img>
                        </Grid>
                        <Grid item xl={10} xs={9}>
                            <p className="p_header">{t('ss.api.tile1')}</p>
                            <Grid container>
                                <Grid item xs={6} className="clickable-text">
                                    <a href={this.state.documentData.user_guide_url} target="_blank" className="href_style">
                                        <p>{t('ss.api.view')}</p>
                                    </a>
                                </Grid>
                                <Grid item xs={6} className="clickable-text">
                                    <p onClick={() => this.downloadFile(this.state.documentData.user_guide_url,"pdf",t('ss.api.tile1'),)}>{t('ss.api.download')}</p>                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={3} xs={4}>
                    <Grid container spacing={2} className="user_profile" style={{padding : "5px"}}>
                        <Grid item xl={2} xs={3}>
                            <img className="api_image" style={{width : "100%"}} src="images/setting_document.png" alt="icon"></img>
                        </Grid>
                        <Grid item xl={10} xs={9}>
                            <p className="p_header">{t('ss.api.tile2')}</p>
                            <Grid container>
                                <Grid item xs={6} className="clickable-text">
                                    <a href={this.state.documentData.doc_url} target="_blank" className="href_style">
                                        <p>{t('ss.api.view')}</p>
                                    </a>
                                </Grid>
                                <Grid item xs={6} className="clickable-text">
                                    <p onClick={() => this.downloadFile(this.state.documentData.doc_url,"pdf",t('ss.api.tile2'))}>{t('ss.api.download')}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={3} xs={4}>
                    <Grid container spacing={2} className="user_profile" style={{padding : "5px"}}>
                        <Grid item xl={2} xs={3}>
                            <img className="api_image" style={{width : "100%"}} src="images/setting_list.png" alt="icon"></img>
                        </Grid>
                        <Grid item xl={10} xs={9}>
                            <p className="p_header">{t('ss.api.tile3')}</p>
                            <Grid container>
                                <Grid item xs={6} className="clickable-text" onClick={this.viewApiVehicleList}>
                                    <p>{t('ss.api.view')}</p>
                                </Grid>
                                <Grid item xs={6} className="clickable-text">
                                    <p onClick={this.export_vehicle_list_report}>{t('ss.api.download')}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={3} xs={4}>
                    <Grid container spacing={2} className="user_profile" style={{padding : "5px"}}>
                        <Grid container style={{padding : "0px 10px"}}>
                            <Grid item xs={1}>
                                <Tooltip title={<span style={{fontSize : "12px"}}>{t('ss.api.can')}</span>} placement="top">
                                    <div className="can_use"></div>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title={<span style={{fontSize : "12px"}}>{t('ss.api.rec')}</span>} placement="top">
                                    <div className="recommded"></div>
                                </Tooltip>                                
                            </Grid>
                            <Grid item xs={10}>
                                <div style={{textAlign : "right", width : "100%"}}>
                                    <p style={{display : keyData.key_generated_on !== " " ? "" : "none" , fontSize : "12px"
                                    }}><b>{t('ss.api.last.gen')} :</b> {CommonFunction.graphDateFormate(keyData.key_generated_on)}</p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xl={2} xs={3}>
                            <img className="api_image" style={{width : "100%"}} src="images/setting_api.png" alt="icon"></img>
                        </Grid>
                        <Grid item xl={10} xs={9}>
                            <Grid container>
                                <Grid item xs>
                                    <p className="p_header">{t('ss.api.tile4')}</p> 
                                </Grid>
                                <Grid item xs={3}>
                                    <Tooltip style={{display : keyData.key_age_status === 1 ? "" : "none"}} title={<span style={{fontSize : "12px"}}>{keyData.key_age <= 1 ? keyData.key_age+" day" : keyData.key_age+" days"}</span>} placement="top">
                                        <div className="can_use age">{CommonFunction.zeroAppend(keyData.key_age)}</div>
                                        {/* <div style={{display : keyData.key_age_status === 2 ? "" : "none"}} className="recommded age">{CommonFunction.zeroAppend(keyData.key_age)}</div>                                     */}
                                    </Tooltip>
                                    <Tooltip style={{display : keyData.key_age_status === 2 ? "" : "none"}} title={<span style={{fontSize : "12px"}}>{keyData.key_age <= 1 ? keyData.key_age+" day" : keyData.key_age+" days"}</span>} placement="top">
                                        {/* <div className="can_use age">{CommonFunction.zeroAppend(keyData.key_age)}</div> */}
                                        <div className="recommded age">{CommonFunction.zeroAppend(keyData.key_age)}</div>                                    
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6} className="clickable-text">
                                    <p onClick={() => {
                                        this.copyToClipboard("#api_key")
                                    }}>{t('ss.api.copy')}</p>
                                </Grid>
                                <Grid item xs={6} className="clickable-text" onClick={this.regenerateApiKey}>
                                    <p>{t('ss.api.regenerate')}</p>
                                </Grid>
                                <ExcelExport
                                    data={vehicleData}
                                    fileName="Detail_Over_Speed.xlsx"
                                    ref={(exporter) => { this._exporter_vehicleList = exporter; }}>
                                    <ExcelExportColumn field="vehicle_id" title="Vehicle Id" />
                                    <ExcelExportColumn field="vehicle_name" title="Vehicle Name" />                                                  
                                </ExcelExport>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Grid>
                <Grid item xl={3} xs={4}>
                    <Grid container spacing={2} className="user_profile" style={{padding : "5px"}}>
                        <Grid item xl={2} xs={3}>
                            <img className="api_image" style={{width : "100%"}} src="images/setting_report.png" alt="icon"></img>
                        </Grid>
                        <Grid item xl={10} xs={9}>
                            <p className="p_header">{t('ss.api.tile5')}</p>
                            <Grid container>
                                <Grid item xs={6} className="clickable-text">
                                    <p onClick={this.viewReport}>{t('ss.api.view')}</p>
                                </Grid>                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        <div style={{display : this.state.showReport ? "" : "none"}}>
            {this.state.ReportContent}
        </div>
        {/* warning message modal */}
        <div id="warning_message" className="modal_parent" style={{ marginLeft: "-100px"}}>
            <div className="modal_body renew_modal_body" style={{ width: "60%", textAlign : "center", lineHeight : "2em" }}>
            <p className="p_header">{t('ss.api.warning.text')}.<br/><br/>{t('ss.api.warning.text2')} ?</p>  
            <Grid container>
                <Grid item xs={6}>
                    <button onClick={this.disabledYes} className="btn orange-btn" style={{float : "none", width : "30%"}}>{t('ss.api.yes')}</button>
                </Grid>
                <Grid item xs={6}>
                    <button onClick={this.disabledNo} className="btn orange-btn" style={{float : "none", width : "30%"}}>{t('ss.api.no')}</button>
                </Grid>
            </Grid>                      
            </div>
        </div>

        {/* regenerate API key warning */}
        <div id="regenerate_api" className="modal_parent" style={{ marginLeft: "-100px"}}>
            <div className="modal_body renew_modal_body" style={{ width: "60%", textAlign : "center", lineHeight : "2em" }}>
            <p className="p_header">{t('ss.api.warning.text3')}.<br/><br/>{t('ss.api.warning.text2')} ?</p>  
            <Grid container>
                <Grid item xs={6}>
                    <button onClick={this.generateYes} className="btn orange-btn" style={{float : "none", width : "30%"}}>{t('ss.api.yes')}</button>
                </Grid>
                <Grid item xs={6}>
                    <button onClick={this.generateNo} className="btn orange-btn" style={{float : "none", width : "30%"}}>{t('ss.api.no')}</button>
                </Grid>
            </Grid>                      
            </div>
        </div>

        {/* view api vehicle list */}
        <div id="api_vehicle_list" className="modal_parent" style={{ marginLeft: "-100px"}}>
            <div className="modal_body renew_modal_body" style={{ width: "fit-content", textAlign : "center", lineHeight : "2em" }}>
                <p className="modal_header">{t('ss.api.modal.header')}</p>
                <p className="p_big_grey"><b className="cj_w_header">{t('ss.api.veh.list')} : </b> {CommonFunction.zeroAppend(this.state.vehicleListData.length)} </p>  
                <table className="api_vehicle_list cj_w_header">
                    <thead style={{fontWeight : "bold"}}>
                        <tr>
                            <td>{t('ss.api.vehiclelist.h1')}</td>
                            <td>{t('ct.table.h10')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {vehicleList}
                    </tbody>
                </table>
                <div className="close_modal" onClick={this.modalClose}>
                    <i class="fa fa-close" aria-hidden="true" style={{position : "relative", top : "-4px"}}></i>
                </div>                     
            </div>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1800}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(ApiIntegration);
