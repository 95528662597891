import React, { Component } from 'react';
import $ from 'jquery';
import './journey_overview.scss'
import { Grid } from '@material-ui/core';
import './journey_document.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Slider from '@material-ui/core/Slider';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import { format, isThisSecond } from "date-fns";
import ApiUrlCall from '../API/apiurl';
import Table from 'react-bootstrap/Table';
import Navbar from '../../Config/Navbar';
import CommonFunction from '../common_function'
import Map from '../map/map'
import Tooltip from '@material-ui/core/Tooltip';
import 'moment-timezone';
import moment, { duration } from 'moment';
import Sentry from 'react-activity/lib/Sentry';
import 'react-activity/lib/Sentry/Sentry.css';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';

const COMPLETE = 1
const ONGOING_ONTIME = 2
const ONGOING_DELAY = 3
const PENDING = 4
const PENDING_DV = 5
const ABORTED = 6
const L = window.L
const ALERT_NAME = [{ "alert_id": 14, "name": "Ignition" }, { "alert_id": 6, "name": "Idling" }, { "alert_id": 2, "name": "Boundary" },
{ "alert_id": 1, "name": "Speed" }, { "alert_id": 4, "name": "Late Night Ignition" }];
const STOPDETAILS1 = {}
const STOPDETAILS = {
    "stop_list": [{
        "lat": "19.2342342", "lng": "72.08210382", "name": "Manish", "pic_list":
            [{ "url": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png", "tag": "food" }]
    },
    {
        "lat": "19.158757", "lng": "72.858027", "name": "Rushi", "pic_list":
            [{ "url": "", "tag": "khar" }, { "url": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png", "tag": "bandra" }]
    },
    {
        "lat": "19.122444", "lng": "72.853142", "name": "Virat", "pic_list":
            [{ "url": "", "tag": "vile" }]
    }]
}
class JourneyOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            journeyDetails: '',
            journeyVehicleDetails: '',
            journeyState: '',
            points: '',
            polylineParam: '',
            lastPolylineLatLng: '',
            currentPolylineLatLng: '',
            vehicleStatus: 0,
            // markerLat: '',
            // markerLng: '',
            boundLat: [],
            boundLng: [],
            setIcon: true,
            replayRouteData: '',
            serial_number_is: '',
            slidedValue: 0,
            sliderDuration: 0,
            sliderMax: 0,
            methodCall: true,
            playReplayRoute: true,
            trackingSerialNumber: '',
            trackingStart: '',
            trackingEnd: '',
            live_or_replay: true,
            openAlert: false,
            errorMessage: '',
            movingDuration: 0,
            idlingDuration: 0,
            parkedDuration: 0,
            movingFuelConsumed: 0,
            parkedFuelConsumed: 0,
            idlingFuelConsumed: 0,
            actualStart: '',
            actualEnd: '',
            overallTime: '',
            delayTime: '',
            vehicleNotification: [],
            VehicleDocumentData: '',
            documentType: '',
            vehicleStops: '',
            journeyExpense: '',
            stopObj: [],
            boundFlag: true,
            journeyStopList : [],
            vehicle_type_id : 1,
            replayIntervalSpeed : 1000,
            pudo_doc:[],
            PudoUserList : [],
        }
        this.getJourneyDetails = this.getJourneyDetails.bind(this)
    }

    componentDidMount() {
        analytics.logEvent('screen_view', {
            screen_name: "ViewJourney"
        })
        analytics.logEvent('page_view', {
            page_title: "ViewJourney",
        })
        Amplify.configure(awswebapp);
        Analytics.record({
            name: constant.awsName,
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_JOURNEY_OVERVIEW' }
        });
        var navBarHeight = $('.navBar').outerHeight();
        $('.journey_over_parent').css({
            paddingTop: navBarHeight + 60,
        })
        this.getJourneyDetails()
        this.getDocumentType()
    }

    componentWillUnmount = () => {
        clearInterval(this.interval)
    }


    getLiveTrackData = () => {
        // console.log('!!!!!!!!!!!!!!!!!!!!')
        this.tenSecApiCall()
        this.interval = setInterval(() => {
            this.tenSecApiCall()
        }, 10000)
    }

    tenSecApiCall = () => {
        const serial_number = JSON.stringify({ serial_number: this.state.trackingSerialNumber })
        let polylineLast = this.state.lastPolylineLatLng
        // console.log('old lat long is points', polylineLast)
        let polylineCurrent = this.state.currentPolylineLatLng
        // console.log('new lat long is', polylineCurrent)
        // console.log('live track data is', serial_number)
        // this.interval = setInterval(() => {
        ApiUrlCall.getSingleVehicleLiveTrack(serial_number).then(response => {
            if (response.data.response_code === 200 && response.data.is_success === true) {
                $('#processing').hide()
                if (polylineLast) {
                    this.setState({
                        lastPolylineLatLng: polylineCurrent,
                        currentPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
                        // markerLat: response.data.data.lat,
                        // markerLng: response.data.data.lng,
                        setIcon: false,
                        boundLat: [...this.state.boundLat, response.data.data.lat],
                        boundLng: [...this.state.boundLng, response.data.data.lng],
                        vehicleStatus: response.data.data.vehicle_state,
                    }, () => {
                        // console.log('>>>>>>>>>>>>>>>>>>>>>>1111')
                        this.liveTrack()
                    })
                } else {
                    // console.log(response.data.data.lat, '3333333333333333333', response.data.data.lng)
                    $('#processing').hide()
                    this.setState({
                        vehicleStatus: response.data.data.vehicle_state,
                        currentPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
                        lastPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
                        // markerLat: response.data.data.lat,
                        // markerLng: response.data.data.lng,
                        setIcon: true,
                    }, () => {
                        // console.log('2222222222222')
                        this.liveTrack()
                    })
                }

            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching live track data', error)
        })
        // },10000)
    }



    liveTrack = () => {
        // console.log('live track data')
        if (this.state.lastPolylineLatLng && this.state.currentPolylineLatLng) {
            // console.log('live track if')
            var oldlat = this.state.lastPolylineLatLng.lat
            var oldlng = this.state.lastPolylineLatLng.lng
            var newlat = this.state.currentPolylineLatLng.lat
            var newlng = this.state.currentPolylineLatLng.lng
            var pts = [{ "oldlat": oldlat, "oldlng": oldlng }, { "newlat": newlat, "newlng": newlng }]
            // [19.0760,72.8777]    
            var oldLatLng = new L.LatLng(oldlat, oldlng)
            var newLatLng = new L.LatLng(newlat, newlng);


            // var center = new L.LatLng(this.state.markerLat,this.state.markerLng);
            // var pts = [

            //     new L.LatLng(this.state.markerLat,this.state.markerLng),

            // ];

            var polylineParam =
            {
                weight: 2, // The thickness of the polyline 
                // opacity: 0.5 //The opacity of the polyline colour 
            };
            this.setState({
                points: pts,
                polylineParam: polylineParam,
                vehicleStatus: this.state.vehicleStatus,
            }, () => {
                // console.log('points value is', this.state.points)
            })
        }
    }

    getReplayRouteData = () => {
        // console.log('get replay route data')
        if (this.state.trackingStart && this.state.trackingEnd) {
            var urlData = {
                serial_number: this.state.trackingSerialNumber,
                start_date: CommonFunction.ServerDateTime(this.state.trackingStart),
                end_date: CommonFunction.ServerDateTime(this.state.trackingEnd)
            }
        } else {
            var urlData = {
                serial_number: this.state.trackingSerialNumber,
                start_date: CommonFunction.ServerDateTime(this.state.journeyDetails.journey_vehicle_detail[0].started_at),
                end_date: CommonFunction.ServerDateTime(this.state.journeyDetails.journey_vehicle_detail[0].ended_at)
            }
        }

        $('#processing').show()
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>> selected replay route vehicle', urlData)
        // var url_Json_data = JSON.stringify(url_Json_data)
        ApiUrlCall.getReplayRoute(JSON.stringify(urlData)).then(response => {
            // console.log('response of replay route is', response)
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    replayRouteData: response.data.data,
                    slidedValue: 0,
                    sliderMax: response.data.data.replay_data.length,
                }, () => {
                    $('#processing').hide()
                    // console.log('replay route data from api', this.state.replayRouteData.replay_data)
                    // console.log('replay route data from api lenght>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.state.replayRouteData.replay_data.length)
                })
            }
            else {
                $('#processing').hide()
                this.setState({
                    openAlert: true,
                    errorMessage: response.data.response_message,
                })
            }
        }).catch(error => {
            console.log('error while fetching replay route data is', error)
        })
    }

    slideValue = (value, endReplay) => {
        if (!endReplay) {
            this.setState({
                slidedValue: value
            })
        } else {
            this.setState({
                slidedValue: 0,
            }, () => {
                $('#pause_rr').click()
                this.setState({
                    playReplayRoute: false,
                })
            })
        }
    }

    renderMap = () => {
        const maps = []
        // console.log('points value of live track is', this.state.points)
        if (this.state.journeyDetails) {
            maps.push(
                <Map
                    height={"100%"}
                    zoom={14}
                    // center={[this.state.markerLat, this.state.markerLng]}
                    // markers={[
                    //     {
                    //         position: [this.state.markerLat, this.state.markerLng],
                    //         icon:L.icon({
                    //             iconUrl: 'images/vehicle_icon/Car moving.png',
                    //             iconRetinaUrl: 'images/vehicle_icon/Car moving.png',
                    //             iconSize: [40, 40],
                    //             popupAnchor: [-3, -15]
                    //           }),                            
                    //         draggable: false,
                    //         title: "Marker title",
                    //         onClick: e => {
                    //             console.log("clicked ");
                    //         }
                    //     }
                    // ]}
                    // markerobj={{ line: new L.Polyline({ color: '#f4811f', weight: 4 }) }}
                    panningflag={this.state.boundFlag}
                    replayIntervalSpeed = {this.state.replayIntervalSpeed }
                    onZoom={() => {
                        this.setState({
                            boundFlag: false
                        })
                    }}
                    stops={this.state.stopObj}
                    cluster={false}
                    polyline={
                        {
                            points: this.state.points,
                            polylineParam: this.state.polylineParam,
                            icon: this.state.setIcon,
                            state: this.state.vehicleStatus,
                            speed: 500,
                            show: this.state.live_or_replay,
                            serial_number: this.state.trackingSerialNumber,
                        }
                    }
                    // bounds = {[
                    //     {
                    //     lat : this.state.boundLat,
                    //     lng: this.state.boundLng
                    //     }
                    //   ]}

                    replayroutedata={
                        {
                            replay: this.state.replayRouteData.replay_data,
                            index: this.state.slidedValue,
                            flag: this.state.playReplayRoute,
                            sliderValue: this.slideValue,
                            serial_number: this.state.trackingSerialNumber,
                            show: this.state.live_or_replay
                        }

                    }
                />
            )
            return maps
        }
    }
    showJourney = (e) => {
        $('.journey_over_parent').css({
            backgroundColor: '#f9f9f9'
        })
        $(e.currentTarget).addClass('border-orange')
        $(e.currentTarget).siblings().removeClass('border-orange')
        $('#journey_overview').show()
        $('#journey_document').hide()
        $('#delete_journey').show()
        $('#total_journey_expense').hide()

    }

    getDocumentType = () => {
        ApiUrlCall.documentExpenseType().then(response => {
            // console.log('reposne of type id', response)
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    documentType: response.data.data.expense_type_list
                })
            }
        }).catch(error => {
            console.log("error while fetching document type", error)
        })
    }

    showDocument = (e) => {
        clearInterval(this.interval)
        this.setState({
            playReplayRoute: false,
        })
        var journey_id = this.props.location.state.journey_id
        var data = { journey_id: journey_id }
        ApiUrlCall.getJourneyDocument(JSON.stringify(data)).then(response => {
             //console.log('reponse of document is', response)
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    VehicleDocumentData: response.data.data.vehicle_doc_list,
                    journeyExpense: response.data.data.journey_total_amount,
                    pudo_doc : response.data.data.pudo_doc_list

                }, () => {
                     console.log('document data in state', this.state.pudo_doc)
                })
            }
        }).catch(error => {
            console.log('error while fetching journey document data', error)
        })
        $('.journey_over_parent').css({
            backgroundColor: '#ffffff'
        })
        $(e.currentTarget).addClass('border-orange')
        $(e.currentTarget).siblings().removeClass('border-orange')
        $('#journey_overview').hide()
        $('#total_journey_expense').show()
        $('#journey_document').show()
        $('#delete_journey').hide()
    }
    viewAllStops = () => {
        $('#show_all_stops').slideDown("slow", "swing")
        $('.stop_margin_bottom').last().find('.navigation').hide()
        $('html, body').css({
            overflow: 'hidden',
            height: '100%'
        });
    }
    modalClose = () => {
        $('#show_all_stops').fadeOut("slow")
        $('html, body').css({
            overflow: 'auto',
            height: '100%'
        });
    }

    getJourneyStopDetails = () =>{
        var journey_id = this.props.location.state.journey_id
        ApiUrlCall.JourneyGetStops( JSON.stringify({ journey_id : journey_id , stop_type : 1 }) ).then(
            response=>{
                //console.log(response , " STOPS RESPONSE ")
                if (response.data.response_code==200 && response.data.is_success){
                    this.setState( {
                        journeyStopList: response.data.data.stop_list
                    },()=>{
                        this.createStopsObj()
                    } )

                }
            }
        ).catch(
            error=>{
                console.log("ERROR WHILE GETTING JOURNEY STOPS DETAILS" , error)
            }
        )
        
    }

    createStopsObj = () => {
        // console.log('create stops object', STOPDETAILS)
        const {journeyStopList} = this.state
        var array = []
           

        if (journeyStopList.length > 0) {
            for (let i = 0; i < journeyStopList.length; i++) {
                const element = journeyStopList[i];
                //console.log('STOPS DATA ', element)
                array.push(
                    {
                        position: [element.lat, element.lng],
                        name: element.name,
                        images: element.pic_list
                    }
                )
            }
            // console.log('stop array', array)
            this.setState({
                stopObj: array
            }, () => {
                // console.log('stops', this.state.stopObj)
            })
        }
        // this.forceUpdate()
    }


    getJourneyDetails = () => {
        $('#processing').show()
        this.getJourneyStopDetails()

        var journey_id = this.props.location.state.journey_id
        var previous_serial_number = this.state.trackingSerialNumber
        ApiUrlCall.uniqueJourneyDetail(journey_id).then(response => {
             //console.log('journey details', response)
            if (response.status === 200 ) {
                this.getPudoUserList()
                $('#processing').hide()
                this.setState({
                    journeyDetails: response.data,
                    journeyVehicleDetails: response.data.journey_vehicle_detail,
                    journeyState: response.data.moving_status,
                    // markerLat: response.data.journey_vehicle_detail[0].start_loc_lat,
                    // markerLng: response.data.journey_vehicle_detail[0].end_loc_lon,
                    trackingSerialNumber: response.data.journey_vehicle_detail[0].serial_number,
                    vehicleStops: response.data.journey_vehicle_detail[0].stops
                    // trackingStart: response.data.journey_vehicle_detail[0].started_at,
                    // trackingEnd: response.data.journey_vehicle_detail[0].ended_at,
                }, () => {
                    if (this.state.journeyState === ONGOING_DELAY || this.state.journeyState === ONGOING_ONTIME) {
                        var showDiv = document.getElementById(this.state.trackingSerialNumber)
                        this.vehilceTableData(this.state.trackingSerialNumber)
                        if (previous_serial_number !== this.state.trackingSerialNumber) {
                            $(previous_serial_number).prev().hide()
                            $(previous_serial_number).show()
                            $(showDiv).prev().show()
                            $(showDiv).hide()
                            // console.log('serial number not equal')

                        } else {
                            // console.log('show button div', $(showDiv))
                            $(showDiv).prev().show()
                            $(showDiv).hide()
                        }
                        clearInterval(this.interval)
                        // console.log('in live tracking JD')
                        this.setState({
                            live_or_replay: true,
                        })
                        // this.state.live_or_replay = true
                        // this.forceUpdate()
                        this.getLiveTrackData()
                    } else if (this.state.journeyState === COMPLETE ) {
                        clearInterval(this.interval)
                        this.vehilceTableData(this.state.trackingSerialNumber)
                        this.vehicleNotificationData()
                        var showDiv = document.getElementById(this.state.trackingSerialNumber)
                        $(showDiv).prev().show()
                        $(showDiv).hide()
                        $(showDiv).parent().siblings().children().eq(0).show()
                        $(showDiv).parent().siblings().children().eq(0).show()
                        this.setState({
                            live_or_replay: false,
                        }, () => {
                            // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
                        })
                        // this.state.live_or_replay = false
                        // this.forceUpdate()                                              
                        this.getReplayRouteData()

                    } 
                    
                    // console.log('marker lat', this.state.markerLat, 'marker long', this.state.markerLng)                   
                })
            }

        }).catch(error => {
            console.log('error while getting journey detail', error)
        })
    }

    showLiveTrack = (e) => {
        const { journeyDetails } = this.state
        $('#processing').show()
        var previousSerial = this.state.trackingSerialNumber
        var selectedSerial = $(e.currentTarget).prop('id')
        var previousDiv = document.getElementById(previousSerial)
        var currentDiv = document.getElementById(selectedSerial)
        var indexElement = $(e.currentTarget).attr('name')
        // console.log('index element is', indexElement)
        $(previousDiv).show()
        $(previousDiv).siblings().hide()
        $(currentDiv).hide()
        $(currentDiv).siblings().show()
        if (this.state.journeyState === ONGOING_DELAY || this.state.journeyState === ONGOING_ONTIME) {
            // console.log('calling live track switch')
            this.vehilceTableData(selectedSerial)
            this.setState({
                trackingSerialNumber: selectedSerial,
                lastPolylineLatLng: '',
                currentPolylineLatLng: '',
                points: '',
                vehicleStops: journeyDetails.journey_vehicle_detail[indexElement].stops,
            }, () => {
                this.getLiveTrackData()
            })
        } else if (this.state.journeyState === COMPLETE) {
            // console.log('calling replay track switch')
            this.vehilceTableData(selectedSerial)
            this.setState({
                trackingSerialNumber: selectedSerial,
                trackingStart: journeyDetails.journey_vehicle_detail[indexElement].started_at,
                trackingEnd: journeyDetails.journey_vehicle_detail[indexElement].ended_at,
                vehicleStops: journeyDetails.journey_vehicle_detail[indexElement].stops,
            }, () => {
                this.getReplayRouteData()
            })
        }
        // console.log('serial number is', selectedSerial, 'previous serial is', previousSerial)
    }



    showAlerts = (e) => {
        var serial_number = $(e.currentTarget).attr('name')
        $(e.currentTarget).hide()
        $(e.currentTarget).parent().parent().prev().fadeIn(200, "swing")
        // this.vehicleNotificationData(serial_number)
    }

    closeAlert = (e) => {
        $(e.currentTarget).parent().fadeOut(200, "swing")
        $(e.currentTarget).parent().next().find('.journey_alert_count').fadeIn(1000)
    }
    showDriverDetails = (e) => {
        var table_id = $(e.currentTarget).attr('name')
        // console.log('id of table is', table_id)
        $(e.currentTarget).children().toggle()
        $(e.currentTarget).parent().parent().parent().parent().next().slideToggle(200, "swing")
        setTimeout(CommonFunction.tableCellWidth(table_id), 500)
    }

    showDetails = (e) => {
        $('#journey_trips').slideToggle("slow", "swing")
        CommonFunction.tableCellWidth('trip_table')
        var toggleIcon = $(e.currentTarget).children()
        $(toggleIcon).toggle()
    }

    playRr = (e) => {
        $(e.currentTarget).hide()
        $(e.currentTarget).siblings().show()
        this.setState({
            playReplayRoute: true,
        })
    }

    pauseRr = (e) => {
        $(e.currentTarget).hide()
        $(e.currentTarget).siblings().show()
        this.setState({
            playReplayRoute: false,
        })
    }

    hideScroll = () => {
        $('html, body').css({
            overflow: 'hidden',
            height: '100%',
            pointerEvents: 'none'
        });
        $('.create_journey_parent').scrollTop()
    }

    showScroll = () => {
        $('html, body').css({
            overflow: 'scroll',
            height: '100%',
            pointerEvents: 'auto'
        });
    }

    zoomInMap = (e) => {
        $(e.currentTarget).hide()
        $(e.currentTarget).next().show()
        $('.map_container').addClass('zoom_in_map')
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 500)
        this.hideScroll()
    }

    zoomOutMap = (e) => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 500)
        $(e.currentTarget).hide()
        $(e.currentTarget).prev().show()
        $('.map_container').removeClass('zoom_in_map')
        this.showScroll()
    }

    rrSliderDuration = (start, end) => {
        var start_time = new Date(start)
        var end_time = new Date(end)
        var diffsecond = end_time - start_time
        var seconds = diffsecond / 1000
        var minutes = seconds / 60
        var hours = minutes / 60
        if (minutes > 60) {
            return hours.toFixed(2) + '.' + '00'
        } else {
            return '00' + '.' + minutes.toFixed(2)
        }
        // var replayRouteDuration = diff.toFixed(2)
        // console.log('return duration in min is', diff)
        // return replayRouteDuration        
    }


    startJourney = (e) => {
        var serial_number = $(e.currentTarget).parent().attr('name')
        var journey_id = this.props.location.state.journey_id
        var data = { journey_id: journey_id, operation_type: 1, serial_number: serial_number }
        ApiUrlCall.startJourneyVehicle(JSON.stringify(data)).then(response => {
            //console.log('response on start', response)
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.getJourneyDetails()
            } else if (response.data.response_code === 405 && response.data.inactive === true) {
                $('#nrt_status').slideDown()
            } else if (response.data.response_code === 405 && response.data.fuel_level_low === true) {
                $('#insuf_fuel').slideDown()
            }
        }).catch(error => {
            console.log('error while starting journey', error)
        })
    }

    stopJourney = (e) => {
        var serial_number = $(e.currentTarget).parent().attr('name')
        var journey_id = this.props.location.state.journey_id
        var data = { journey_id: journey_id, operation_type: 2, serial_number: serial_number }
        ApiUrlCall.startJourneyVehicle(JSON.stringify(data)).then(response => {
            if (response.data.response_code === 200 && response.data.is_success === true) {
                clearInterval(this.interval)
                this.getJourneyDetails()
            }
        }).catch(error => {
            console.log('error while starting journey', error)
        })
    }

    vehilceTableData = (serial_number) => {
        const { journeyVehicleDetails } = this.state
        for (let i = 0; i < journeyVehicleDetails.length; i++) {
            const element = journeyVehicleDetails[i];
            if (element.serial_number === serial_number) {
                var tableObj = element.activity_detail
                var delay_time
                if ('delay_time' in element) {
                    delay_time = element.delay_time
                } else {
                    delay_time = ''
                }
                // console.log(delay_time, 'delay time is', element.delay_time)
                this.setState({
                    movingDuration: tableObj.moving_duration,
                    idlingDuration: tableObj.idling_duration,
                    parkedDuration: tableObj.parked_duration,
                    movingFuelConsumed: tableObj.moving_fuel,
                    parkedFuelConsumed: tableObj.parked_fuel,
                    idlingFuelConsumed: tableObj.idling_fuel,
                    actualStart: element.started_at,
                    actualEnd: element.ended_at,
                    overallTime: element.total_duration,
                    delayTime: delay_time,
                })
            } else {
                // console.log(' in else of table')
            }
        }
        // console.log('serial number is', journeyVehicleDetails)
    }


    vehicleNotificationData = () => {
        const { journeyVehicleDetails } = this.state
        // if(journeyVehicleDetails){
        for (let i = 0; i < journeyVehicleDetails.length; i++) {
            const element = journeyVehicleDetails[i];
            var data = { serial_number: element.serial_number, start_date: element.started_at, end_date: element.ended_at }
            // var data = {serial_number : element.serial_number, start_date : "2020-03-11 00:00:00", end_date : "2020-03-11 18:41:37"}
            ApiUrlCall.journeyVehicleNotification(JSON.stringify(data)).then(response => {
                // console.log('response is notification', response)
                if (response.data.is_success === true) {
                    this.setState({
                        vehicleNotification: [...this.state.vehicleNotification, response.data.data]
                    })

                }
            }).catch(error => {
                console.log('error while fetching vehicle notificatio', error)
            })
        }
        // }                
    }

    VehicleNotiCount = (serial_number) => {
        var { vehicleNotification } = this.state
        for (let i = 0; i < vehicleNotification.length; i++) {
            const element = vehicleNotification[i];
            if (serial_number === element.serial_number) {
                if (element.notification_list.length < 10) {
                    return '0' + element.notification_list.length
                } else {
                    return element.notification_list.length
                }
            }
        }
    }


    vehicleAlertContent = (array) => {
        // console.log('array lenght is', array.length)
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            // console.log('22222222222222222222222', element)
            return (
                <Grid container item xs={4}>
                    <Grid item xs={3}>
                        <div className="journey-alert-icon">
                            <img src="/images/dashboard.png" className="alert-icon"></img>
                        </div>
                    </Grid>
                    <Grid item xs className="">
                        <p className="p_date_time">{element.alertType}</p>
                        <p className="cj_w_header">25&nbsp;mins</p>
                    </Grid>
                </Grid>
            )
        }
    }

    AlertName = (alert_id) => {
        for (let i = 0; i < ALERT_NAME.length; i++) {
            const element = ALERT_NAME[i];
            if (alert_id === element.alert_id) {
                return element.name
            }
        }
        return "Alert"
    }


    VehicleNotiAlert = (serial_number) => {
        var { vehicleNotification } = this.state
        var contentArray = []
        for (let i = 0; i < vehicleNotification.length; i++) {
            const element = vehicleNotification[i];
            if (serial_number === element.serial_number) {
                var array = element.notification_list
                for (let i = 0; i < array.length; i++) {
                    const element = array[i];
                    var alert_name = this.AlertName(element.alertType)
                    contentArray.push(
                        <Grid container item xs={4}>
                            <Grid item xs={3}>
                                <div className="journey-alert-icon">
                                    <img src={"/images/ALERT_TYPE_" + element.alertType + ".png"} className="alert-icon"></img>
                                </div>
                            </Grid>
                            <Grid item xs className="">
                                <p className="p_date_time">{alert_name}</p>
                                <p className="noti_date_time"><span>{CommonFunction.timeFormate(element.event_time)}</span>&nbsp;&nbsp;<span>|
                                </span>&nbsp;&nbsp;<span>{CommonFunction.dateFormate(element.event_time)}</span></p>
                            </Grid>
                        </Grid>
                    )
                }
            }
        }
        return contentArray
    }

    documentTypeName = (id) => {
        const { documentType } = this.state
        for (let i = 0; i < documentType.length; i++) {
            const element = documentType[i];
            if (id === element.id) {
                return element.tag
            }
        }
    }

    vehicleName = (serial_number) => {
        const { journeyVehicleDetails } = this.state
        for (let i = 0; i < journeyVehicleDetails.length; i++) {
            const element = journeyVehicleDetails[i];
            if (element.serial_number === serial_number) {
                return element.vehicle_name
            }
        }
    }

    deleteJourney = () => {
        var journey_id = this.props.location.state.journey_id
        ApiUrlCall.deleteJourney(journey_id).then(response => {
            if (response.status === 200) {
                this.props.history.push('/journey')
            }
        }).catch(error => {
            console.log('error while deleting journey', error)
        })
    }

    editJourney = () => {
        var journey_id = this.props.location.state.journey_id
        this.props.history.push({
            //pathname: '/create_new_journey',
            pathname:'/edit_journey_details',
            state: { journey_id: journey_id }
        })
    }

    shareTracking = (e) => {
        const { journeyState } = this.state
        if (journeyState == COMPLETE) {
            var share_type = 2
        } else {
            var share_type = 1
        }
        var serial_number = $(e.currentTarget).attr('name')
        var mobile_number = $(e.currentTarget).parent().prev().find('input').val()
        if (mobile_number.length !== 10 || !mobile_number) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Enter Valid Number"
            })
        } else {
            var sendMessage
            if (journeyState == COMPLETE) {
                sendMessage = "Replay Route Shared Successfully"
                var data = {
                    mobile_number: mobile_number, serial_number: serial_number, sharing_type: share_type,
                    start_date: CommonFunction.ServerDateTime(this.state.journeyDetails.start_time),
                    end_date: CommonFunction.ServerDateTime(this.state.journeyDetails.end_time), expiry_minutes: "30"
                }
            } else {
                sendMessage = "Live Track Shared Successfully"
                var data = { mobile_number: mobile_number, serial_number: serial_number, sharing_type: share_type, expiry_minutes: "30" }
            }
            $(e.currentTarget).parent().prev().find('input').val("")
            ApiUrlCall.shareTracking(JSON.stringify(data)).then(response => {
                if (response.data.response_code == 200 && response.data.is_success) {
                    this.setState({
                        openAlert: true,
                        errorMessage: sendMessage
                    })
                }
            }).catch(error => {
                console.log('error while sharing Live Track or Replay Route', error)
            })
        }
    }
    getPudoUserList = () =>{
        $('#processing').show()
        var data = {role_id : 4}
        ApiUrlCall.ShowPudoUserList(JSON.stringify(data)).then(response => {            
            //this.emptyTable()
            if(response.data.response_code === 200 && response.data.is_success){
              $('#processing').hide()          
              this.setState({
                PudoUserList: response.data.data.vender_list
              },() => {
                //this.createTableData()          
                //console.log(">>>>>>>>>>>>>. vender_list ",this.state.PudoUserList)
              })
            }else{
              $('#processing').hide()
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching getPudoUserList list', error)
        })
    }

    pudo_user = (name) => {
                    
        for (let i = 0; i < this.state.PudoUserList.length; i++){
            var element = this.state.PudoUserList[i];
            //console.log("ankita success",element)
            if (element.user_id === name) {
                return element.first_name
            }
            
          }
         
        }
    render() {
        const { t } = this.props
        // console.log('moving state in render is', this.state.journeyState)
        if (this.state.vehicleStops) {
            var stopAddress = this.state.vehicleStops.map(stopsInfo => {
                // console.log("alfa new",stopsInfo)
                return (
                    <Grid container className="stop_margin_bottom">
                        <Grid item xs={0}>
                            <img src="images/stop.png" width="20px" className="navgation_image"></img>
                            <div className="navigation"></div>
                        </Grid>
                        <Grid item xs={1} style={{ paddingRight: "20px" }}>
                            <p className="cj_w_header">{stopsInfo.name}</p>
                        </Grid>
                        <Grid item xs={3} style={{ paddingRight: "20px" }}>
                            <p className="cj_w_header">{stopsInfo.location}</p>
                        </Grid>
                        <Grid item xs={1}>
                            <p className="cj_w_header"> {stopsInfo.pudo_user}</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p className="cj_w_header">{CommonFunction.convertSecToMin(stopsInfo.defined_dur)}</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p className="cj_w_header">{CommonFunction.convertSecToMin(stopsInfo.duration)}</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p className="cj_w_header">{CommonFunction.convertSecToMin(stopsInfo.delay_duration)}</p>
                        </Grid>
                        
                    </Grid>
                )
            })
        }

        if (this.state.replayRouteData && this.state.slidedValue !== undefined) {
            if (this.state.slidedValue !== this.state.sliderMax) {
                var speed = this.state.replayRouteData.replay_data[this.state.slidedValue].speed
                var distancDriven = this.state.replayRouteData.replay_data[this.state.slidedValue].distance_driven
                var time = this.state.replayRouteData.replay_data[this.state.slidedValue].datetime
            }
            // else {
            //     this.setState({
            //       slidedValue: 0,
            //     }, () => {
            //       $('#pause_rr').click()
            //       this.setState({
            //         playReplayRoute: false,
            //       })
            //     })
            //   }
        }

        if (this.state.journeyVehicleDetails) {
            var counter = 0
            var { vehicleNotification } = this.state
            var showVehicle = this.state.journeyVehicleDetails.map((journeyVehicle, index) => {
                // console.log('journey data is', journeyVehicle.serial_number, ">>>>>>>>>>>>>>")
                var showFlag = (() => {

                    if (this.state.journeyState === COMPLETE) {
                        return true
                    } else if (this.state.journeyState === PENDING) {
                        return false
                    } else if (this.state.journeyState === ONGOING_ONTIME && journeyVehicle.ended_at) {
                        return false
                    } else {
                        return true
                    }
                })()

                this.state.serial_number_is = journeyVehicle.serial_number
                return (
                    <div className="journey_list_item" key={journeyVehicle.id}>
                        <Grid container>
                            <Grid item xs={3}>
                                <img src="images/vehicle-1.png" alt="car icon"></img>
                                <p className="journey_item_vehicle">{journeyVehicle.vehicle_name}</p>
                                {/* <span><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>scheduled</span><br /> */}
                                <div className="live_track_input" style={{ display: showFlag ? '' : 'none' }}>
                                    <div className="live_track_div" style={{ display: "none" }}>
                                        <div style={{ display: "inline-block" }}>
                                            <p className="cj_w_header">{this.state.journeyState === COMPLETE ? t('jos.replaying') : t('jos.tracking')}</p>
                                        </div>
                                        <div style={{ position: "absolute", bottom: "-8px", left: "62px" }}>
                                            <Sentry color="red" size={25} speed={0.5} animating={true} />
                                        </div>
                                    </div>
                                    <div id={journeyVehicle.serial_number} style={{ display: journeyVehicle.started_at ? '' : 'none' }} name={index} onClick={this.showLiveTrack}>
                                        <button className="btn orange-btn" style={{ float: "none" }}>{this.state.journeyState === COMPLETE ? t('jos.view.replay') :
                                            t('jos.view.live')}</button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs container>
                                <div className="jo_noti_container" id="show_noti_div">
                                    <div className="jo_noti_header">
                                        <p className="p_header p_margin0">{t('jos.triggered')}</p>
                                    </div>
                                    <div className="jo_notification">
                                        <Grid spacing={3} container id={"alert_div_" + journeyVehicle.serial_number}>
                                            {
                                                this.VehicleNotiAlert(journeyVehicle.serial_number)
                                            }
                                        </Grid>
                                    </div>
                                    <div className="close_modal" onClick={this.closeAlert}>
                                        <i class="fa fa-close" aria-hidden="true"></i>
                                    </div>
                                </div>

                                <Grid container style={{ borderBottom: "1px solid #eeeeee" }}>
                                    <Grid item xs={4} className="padding_left_20">
                                        <p className="cj_w_header">{t('ct.start.datetime')}</p>
                                        <p className="p_date_time">
                                            {CommonFunction.dateFormate(journeyVehicle.started_at)}&nbsp;|&nbsp;{CommonFunction.timeFormate(journeyVehicle.started_at)}
                                        </p>
                                    </Grid>
                                    <Grid item xs={4} className="padding_left_20">
                                        <p className="cj_w_header">{t('ct.end.datetime')}</p>
                                        <p className="p_date_time">
                                            {CommonFunction.dateFormate(journeyVehicle.ended_at)}&nbsp;|&nbsp;{CommonFunction.timeFormate(journeyVehicle.ended_at)}
                                        </p>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div style={{ display: this.state.journeyState === COMPLETE ? '' : 'none' }} name={journeyVehicle.serial_number} className="journey_alert_count"
                                            onClick={this.showAlerts}>
                                            <label>{this.VehicleNotiCount(journeyVehicle.serial_number)}</label>
                                        </div>
                                        <div style={{ display: journeyVehicle.ended_at ? 'none' : '' }} name={journeyVehicle.serial_number}>
                                            <p className="p_header v_start_journey"
                                                style={{ display: this.state.journeyState === PENDING ?'' : 'none' }}
                                                onClick={this.startJourney}>{t('ct.start.journey')}</p>
                                            <p className="p_header v_stop_journey"
                                                style={{ display: this.state.journeyState === ONGOING_ONTIME && ONGOING_DELAY ? '' : 'none' }}
                                                onClick={this.stopJourney}>{t('ct.end.journey')}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid container item xs={4} className="padding_left_20">
                                        <Grid item xs={2} xl={1} style={{ textAlign: "center" }}>
                                            <img src="images/dashboard.png" alt="car icon" style={{ marginTop: "12px" }}></img>
                                        </Grid>
                                        <Grid item xs={10} xl={11}>
                                            <p className="p_big_grey p_margin_bottom">{journeyVehicle.activity_detail.distance_driven < 10 && journeyVehicle.activity_detail.distance_driven > 0
                                                ? '0' + journeyVehicle.activity_detail.distance_driven.toFixed(2) : journeyVehicle.activity_detail.distance_driven.toFixed(2)} kms</p>
                                            <p className="p_margin_bottom cj_w_header">{t('jos.km')}</p>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={4} className="padding_left_20 border_left">
                                        <div className="journey_icon">
                                            <img src="images/dashboard.png" alt="car icon"></img>
                                        </div>
                                        <div style={{ display: "inline-block" }}>
                                            <p className="p_big_grey p_margin_bottom">N/A kms</p>
                                            <p className="p_margin_bottom cj_w_header">Milage</p>
                                        </div>
                                    </Grid> */}
                                    <Grid container item xs={4} className="padding_left_20 border_left">
                                        <Grid item xs={2} xl={1} style={{ textAlign: "center" }}>
                                            <img src="images/dashboard.png" alt="car icon" style={{ marginTop: "12px" }}></img>
                                        </Grid>
                                        <Grid item xs={10} xl={11}>
                                            <p className="p_big_grey p_margin_bottom">{journeyVehicle.activity_detail.max_speed < 10
                                                ? '0' + journeyVehicle.activity_detail.max_speed : journeyVehicle.activity_detail.max_speed} kms</p>
                                            <p className="p_margin_bottom cj_w_header">{t('jos.max.speed')}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="journey_driver_details">
                            <Grid item xl xs style={{ padding: "7px" }}>
                                <div style={{ display: "table" }}>
                                    <div style={{ display: "table-cell" }} className="journey_driver_image">
                                        <img src={journeyVehicle.assigned_to.driver_photo ? journeyVehicle.assigned_to.driver_photo : 'images/group-8.png'}
                                            className="img-circle" alt=""></img>
                                    </div>
                                    <div style={{ display: "table-cell" }}>
                                        <p className="padding_left_20 p_header p_margin_bottom">{journeyVehicle.assigned_to.driver_name}</p>
                                    </div>
                                    <div style={{ display: "table-cell" }}>
                                        <p className="padding_left_20 p_margin_bottom driver_mo_no">{journeyVehicle.assigned_to.mobile_no}</p>
                                    </div>
                                </div>
                            </Grid>
                            {/* <Grid item xl xs style={{ display: this.state.journeyState == COMPLETE || this.state.journeyState == ONGOING_ONTIME ? "" : "none" }}>
                                <div className="share_live_replay" style={{ position: "inherit", width: "fit-content", float: "right", boxShadow: "none" }}>
                                    <div className="grid-container">
                                        <div className="grid-item">
                                            <input type="number" className="vd_input" style={{ backgroundColor: "#ffffff", borderRadius: "none", margin: "0px", padding: "10px" }}
                                                placeholder="Enter Mobile Number"></input>
                                        </div>
                                        <div className="grid-item" style={{ borderLeft: "1px solid #cccccc", padding: "5px" }}>
                                            <i className="fa fa-share-alt fa-2x v_start_journey" name={journeyVehicle.serial_number} onClick={this.shareTracking} aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </Grid> */}
                            {/* <Grid container item xl={2} xs={3} className="journey_driver_score" style={{ padding: "4px", borderRadius: "0px 30px 30px 0px", position: "relative" }}>
                                <Grid item xs>
                                    <p className="p_header p_margin_bottom" style={{ marginTop: "8px" }}>driver score <span>N/A</span></p>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="jo_vd_open_close">
                                        <div class="jo_small_button" name={'driver_table' + journeyVehicle.serial_number} onClick={this.showDriverDetails}>
                                            <i class="fa fa-angle-up del_info_icon" aria-hidden="true" style={{ display: "none" }}></i>
                                            <i class="fa fa-angle-down del_info_icon" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid> */}
                        </Grid>
                        <Grid style={{ display: "none" }} id="jo_driver_details">
                            <div className="tableOuterDiv" id={'driver_table' + journeyVehicle.serial_number}>
                                <div className="tableParent j_driver_list">
                                    <Table responsive>
                                        <thead className="tableHeader">
                                            <tr>
                                                <th>{t('ct.table.h13')}</th>
                                                <th>{t('ct.table.h14')}</th>
                                                <th>{t('ct.table.h15')}</th>
                                                <th>{t('ct.table.h16')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tableBody">
                                            <tr>
                                                <td>{t('ct.duration')}<br />(hrs:mins)</td>
                                                <td>{this.state.movingDuration}</td>
                                                <td>{this.state.idlingDuration}</td>
                                                <td>{this.state.parkedDuration}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Grid>
                    </div>
                )

            })
            var start_location = <span className="start_journey_loc">{CommonFunction.tableAddressEllipsis(this.state.journeyDetails.planned_start_loc, 15)}</span>
            var end_location = <span className="end_journey_loc">{CommonFunction.tableAddressEllipsis(this.state.journeyDetails.planned_end_loc, 15)}</span>
        }

        let icon = <img src="images/next-copy-2.png" width="35px"></img>
        if (this.state.VehicleDocumentData) {
            var documentContent = this.state.VehicleDocumentData.map(data => {
                var delivery_count = (() => {
                    return (
                        <p className="p_margin_bottom p_header">{t('jos.del.receipt')}&nbsp;-&nbsp;
                    {CommonFunction.zeroAppend(data.receipt_list.length)}</p>
                    )
                })()
                var expense_count = (() => {
                    return (
                        <p className="p_margin_bottom p_header">{t('jos.bills')}&nbsp;-&nbsp;
                     {CommonFunction.zeroAppend(data.expense_list.length)}</p>
                    )
                })()
                var delivery_receipt = data.receipt_list.map(receipt => {
                    return (
                        <div className="document_image_parent">
                            <label>{receipt.tag}</label>
                            <div>
                                <img src={receipt.url} className="document_image"></img>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <div style={{ float: "right" }}>
                                    <a href={receipt.url} target="_blank">
                                        <img src="images/group-6.png"></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })
                var expense_receipt = data.expense_list.map(expense => {
                    return (
                        <div className="document_image_parent">
                            <label>{this.documentTypeName(expense.expense_type)}</label>
                            <div>
                                <img src={expense.url} className="document_image"></img>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <label>&#8377;&nbsp;{expense.amount}</label>
                                <div style={{ float: "right" }}>
                                    <a href={expense.url} target="_blank">
                                        <img src="images/group-6.png"></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })
                
                var pudo_doc_count = []
                var pudo_doc_images =[]
                if (this.state.pudo_doc) {
                    var documentContentnew = this.state.pudo_doc.map(data1 => {
                        pudo_doc_count = (() => {
                            return (
                                <p className="p_margin_bottom p_header">{t('jos.pd_vendor_doc')}&nbsp;-&nbsp;
                            {CommonFunction.zeroAppend(data1.pic_list.length)}</p>
                            )
                        })()
                        pudo_doc_images = data1.pic_list.map(data1 => {
                            return (
                                <div className="document_image_parent">
                                    <label>{this.pudo_user(data1.name)}
                                    </label>
                                    <div>
                                        <img src={data1.url} className="document_image"></img>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <div style={{ float: "right" }}>
                                            <a href={data1.url} target="_blank">
                                                <img src="images/group-6.png"></img>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    })
                }
                    
                return (
                    <div className="document_expand_parent" id={"document_" + data.serial_number} key={data.serial_number}>
                        <div className="document_vehicle_details">
                            <div style={{ display: "inline-block" }}>
                                <i style={{ verticalAlign: "super" }}>
                                    <img src="images/vehicle-1.png"></img>
                                </i>
                                <span className="journey_item_vehicle" style={{ marginLeft: "15px" }}>{data.name}</span>
                            </div>
                            <div style={{ display: "inline-block", float: "right" }}>
                                <p className="cj_w_header" style={{ display: "inline", marginRight: "20px", fontSize: "25px" }}>&#8377;&nbsp;{data.total_amount}</p>
                                {/* <img src="images/group-6.png" style={{verticalAlign : "inherit"}}></img> */}
                            </div>
                        </div>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={icon}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {delivery_count}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="expand_panel">
                                {delivery_receipt}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={icon}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {expense_count}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="expand_panel">
                                {expense_receipt}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel> 
                            <ExpansionPanelSummary
                                expandIcon={icon}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {pudo_doc_count}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="expand_panel">
                                {pudo_doc_images}
                            </ExpansionPanelDetails>
                         </ExpansionPanel>
                    </div>
                )
            })
        }



        return (
            <div>
                <Navbar />
                <div className="journey_over_parent">
                    {/* detail view of journey */}
                    <div className="journey_detail_parent">
                        <div>
                            <Link to="/journey"><span style={{ verticalAlign: "super", marginRight: "15px" }}><img src="images/arrow.png" alt="icon"></img></span></Link>
                            <label>
                                {start_location}
                                <div className="Triangle"></div>
                                {end_location}
                            </label>
                            <span style={{ float: "right" }} id="delete_journey">
                                {/* <button className="btn orange-btn" style={{ float: "none", display: this.state.journeyState === PENDING ? '' : 'none' }}>start journey</button> */}
                                {/* <button className="btn orange-btn" style={{ float: "none", display: this.state.journeyState === ONGOING_ONTIME && ONGOING_DELAY ? '' : 'none' }}>stop journey</button> */}
                                <img onClick={this.deleteJourney} src="images/group-2.png" alt="icon" style={{ marginTop: "10px", marginLeft: "15px", cursor: "pointer", display: this.state.journeyState === PENDING ? '' : 'none' }}></img>
                                <img onClick={this.editJourney} src="images/j_edit.png" alt="icon" style={{ marginTop: "10px", cursor: "pointer", display: this.state.journeyState === PENDING ? '' : 'none' }}></img>
                            </span>
                            <span style={{ float: "right", display: "none" }} id="total_journey_expense">
                                <p className="cj_w_header" style={{ fontSize: "36px", fontWeight: "bold", marginTop: "10px" }}>&#8377;&nbsp;{this.state.journeyExpense}</p>
                            </span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <div className="journey_overview_tab border-orange" onClick={this.showJourney}>
                                <p>{t('jos.overview')}</p>
                            </div>
                            <div className="journey_document_tab" onClick={this.showDocument}>
                                <p>{t('jos.document')}</p>
                            </div>
                        </div>
                        <div id="journey_overview">
                            <div className="detail_journey_div">

                                <div className="map_container">
                                    {this.renderMap()}
                                    <div className="replay_route_slider" style={{ display: this.state.journeyState === 1 ? '' : 'none' }}>
                                        <Grid container>
                                            <Grid item xs={6} className="rr_slider" style={{ borderRight: "1px solid #979797" }}>
                                                <div>
                                                    <div>
                                                        <img src="images/Play.png" alt="icon" height="25px" onClick={this.playRr}
                                                            style={{ display: this.state.playReplayRoute ? 'none' : '' }}></img>
                                                        <img src="images/Pause.png" alt="icon" height="25px" onClick={this.pauseRr}
                                                            style={{ display: this.state.playReplayRoute ? '' : 'none' }} id="pause_rr"></img>
                                                    </div>
                                                    <div className="slider_div">
                                                        <Slider
                                                            min={0}
                                                            max={this.state.sliderMax}
                                                            step={1}
                                                            value={this.state.slidedValue}
                                                            onChangeCommitted={(e, value) => {
                                                                this.setState({
                                                                    slidedValue: value
                                                                }, () => {
                                                                    // if(this.state.slidedValue !== this.state.sliderMax) {
                                                                    // this.rrSliderDuration(this.state.replayRouteData.replay_data[this.state.slidedValue].datetime,
                                                                    //     this.state.replayRouteData.replay_data.splice(-1)[0].datetime)
                                                                    // console.log('slider value is', this.state.slidedValue)
                                                                    // }else{
                                                                    //     this.setState({
                                                                    //         slidedValue : 0,
                                                                    //     })
                                                                    // }
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div>
                                                        <p style={{ color: "#666666" }}> {rrDuration} </p>
                                                    </div> */}
                                                </div>
                                            </Grid>
                                            <Grid item container xs={6} style={{ textAlign: "center" }}>
                                                <Grid container item xs={6}>
                                                    <Grid item xs={3}>
                                                        <p><span className="rr_value" style={{fontSize : "25px", lineHeight : "1.7em"}}>{time ? CommonFunction.timeFormate(time) : ""}</span></p>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <p style={{ marginTop: "15px" }}>{t('ct.speed')}</p>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <p><span className="rr_value">{speed}</span>&nbsp;<b>km/h</b></p>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={6}>
                                                    <Grid item xs={6}>
                                                        <div style={{ borderLeft: "1px solid #979797", marginTop: "15px" }}>
                                                            <p>{t('ct.distance')}</p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <p><span className="rr_value">{distancDriven}</span>&nbsp;<b>kms</b></p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <img src="images/zoom_map.png" className="map_zoom_icon" alt="icon" onClick={this.zoomInMap}></img>
                                    <img src="images/group-9.png" alt="icon" className="map_close_icon" onClick={this.zoomOutMap}></img>
                                </div>

                                <div>
                                    <div>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <p className="p_header">{t('jos.overall')}</p>
                                                <p className="p_big_grey">{CommonFunction.convertMinToHoursText1(this.state.overallTime)}</p>
                                            </Grid>
                                            <Grid container item xs>
                                                <Grid item xs={2}>
                                                    <p className="bigger_p">{t('jos.route')}</p>
                                                </Grid>
                                                <Grid item container xs={8}>
                                                    <Grid container item xs={6} style={{ borderRight: "1px solid #eeeeee" }}>
                                                        <Grid item xs={2}>
                                                            <img src="images/location.png" style={{ display: 'block', margin: 'auto' }}></img>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <p className="cj_w_header">{this.state.journeyDetails.planned_start_loc}</p>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={6}>
                                                        <Grid item xs={2}>
                                                            <img src="images/location.png" style={{ display: 'block', margin: 'auto' }}></img>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <p className="cj_w_header">{this.state.journeyDetails.planned_end_loc}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <button className="btn orange-btn" onClick={this.viewAllStops}>{t('ct.vall.stops')}</button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <hr className="cust_hr" />
                                    <div>
                                        <Grid container>
                                            <Grid item xs={5} container>
                                                <Grid item xs={6}>
                                                    <p className="p_header">{t('jos.est')}</p>
                                                    <p className="cj_w_header">{t('ct.start.datetime')}</p>
                                                    <p className="p_date_time">
                                                        {CommonFunction.dateFormate(this.state.journeyDetails.start_time)}&nbsp;|&nbsp;
                                                    {CommonFunction.timeFormate(this.state.journeyDetails.start_time)}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <p>&nbsp;</p>
                                                    <p className="cj_w_header">{t('ct.end.datetime')}</p>
                                                    <p className="p_date_time">
                                                        {CommonFunction.dateFormate(this.state.journeyDetails.end_time)}&nbsp;|&nbsp;
                                                    {CommonFunction.timeFormate(this.state.journeyDetails.end_time)}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5} container style={{
                                                borderLeft: "1px solid #eeeeee", paddingLeft: "30px",
                                                display: this.state.journeyState === ONGOING_ONTIME || COMPLETE ? '' : 'none'
                                            }}>
                                                <Grid item xs={6}>
                                                    <p className="p_header">{t('jos.actual')}</p>
                                                    <p>{t('ct.start.datetime')}</p>

                                                    <p className="p_date_time">
                                                        {CommonFunction.dateFormate(this.state.actualStart)}&nbsp;|&nbsp;
                                                    {CommonFunction.timeFormate(this.state.actualStart)}
                                                    </p>
                                                    <p className="p_date_time">{}</p>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <p>&nbsp;</p>
                                                    <p>{t('ct.end.datetime')}</p>
                                                    {/* <p className="p_date_time">not receving in data</p> 
                                                      <p className="cj_w_header">End Date & Time</p> */}
                                                    <p className="p_date_time">
                                                        {CommonFunction.dateFormate(this.state.actualEnd)}&nbsp;|&nbsp;
                                                    {CommonFunction.timeFormate(this.state.actualEnd)}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2} style={{
                                                borderLeft: "1px solid #eeeeee", paddingLeft: "30px",
                                                display: this.state.journeyState === COMPLETE && this.state.delayTime !== "" ? '' : 'none'
                                            }}>
                                                <p>&nbsp;</p>
                                                <p>{t('jos.delay')}</p>
                                                <p className="p_date_time">{this.state.delayTime < 10 ? '0' + this.state.delayTime : this.state.delayTime} mins</p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>

                                <div id="journey_trips" style={{ display: "none" }}>
                                    <hr className="cust_hr" style={{ marginBottom: "0px" }} />
                                    {/* <p className="p_header">number of trips</p>
                                    <p className="p_big_grey">{this.state.trackingSerialNumber}</p> */}
                                    <div className="tableOuterDiv" style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                                        <div className="tableParent" id="trip_table">
                                            <Table responsive>
                                                <thead className="tableHeader">
                                                    <tr>
                                                        <th>{t('ct.table.h13')}</th>
                                                        <th className="table_moving">{t('ct.table.h14')}</th>
                                                        <th className="table_idling">{t('ct.table.h15')}</th>
                                                        <th className="table_parked">{t('ct.table.h16')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tableBody">
                                                    <tr>
                                                        <td>{t('ct.duration')}</td>
                                                        <td>{CommonFunction.convertMinToHoursText(this.state.movingDuration)}</td>
                                                        <td>{CommonFunction.convertMinToHoursText(this.state.idlingDuration)}</td>
                                                        <td>{CommonFunction.convertMinToHoursText(this.state.parkedDuration)}</td>
                                                    </tr>
                                                    <tr style={{display : localStorage.getItem('has_fuel_sensor') == "true" ? "" : "none"}}>
                                                        <td>{t('jos.fuel')}</td>
                                                        <td>{CommonFunction.zeroAppend(this.state.movingFuelConsumed)} {t('jos.litres')}</td>
                                                        <td>{CommonFunction.zeroAppend(this.state.idlingFuelConsumed)} {t('jos.litres')}</td>
                                                        <td>{CommonFunction.zeroAppend(this.state.parkedFuelConsumed)} {t('jos.litres')}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                                <div className="vd_open_close" style={{ display: this.state.journeyState === COMPLETE ? '' : 'none' }}>
                                    <div class="small_button" onClick={this.showDetails}>
                                        <i class="fa fa-angle-up del_info_icon" aria-hidden="true" style={{ display: "none" }}></i>
                                        <i class="fa fa-angle-down del_info_icon" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>


                            {/* list view of journey */}
                            <div className="journey_list_parent">
                                {showVehicle}
                            </div>
                        </div>
                    </div>
                    <div id="journey_document" style={{ display: "none" }}>
                        <div>
                            {documentContent}
                        </div>
                    </div>

                    {/* view stops modal*/}
                    <div id="show_all_stops" className="modal_parent">
                        <div className="modal_body">
                            <p className="modal_header">{t('jos.route')}</p>
                            <Grid container className="stop_vehicle_div">
                                <Grid item xs={12}>
                                    <img src="images/vehicle-1.png" alt="car icon"></img>
                                    <p className="journey_item_vehicle">{this.vehicleName(this.state.trackingSerialNumber)}</p>
                                    {/* <span><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>scheduled</span><br /> */}
                                </Grid>
                            </Grid>
                            <Grid container>
                                {/* <Grid  item xs={0}>
                                </Grid>
                                <Grid  item xs={7}>
                                </Grid>
                                <Grid item xs={1}>
                                    <p className="stop_dur">{t('ct.duration')}</p>
                                </Grid> 
                                <Grid item xs={3}>
                                    <p className="pd_user">{t('ct.pduser')}</p>
                                </Grid> */}
                                 <Grid container className="stop_margin_bottom">
                        <Grid item xs={0}>
                            <img src="images/location.png" width="20px" className="navgation_image"></img>
                            <div className="navigation"></div>
                        </Grid>
                        <Grid item xs={1} style={{ paddingRight: "20px" }}>
                            <p className="pd_user">{'Stop Name'}</p>
                        </Grid>
                        <Grid item xs={3} style={{ paddingRight: "20px" }}>
                            <p className="pd_user">{'Stop Address'}</p>
                        </Grid>
                        <Grid item xs={1}>
                            <p className="pd_user">{t('ct.pduser')}</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p className="stop_dur">{t('ct.define.duration')}</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p className="stop_dur">{t('ct.actual.duration')}</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p className="stop_dur">{t('ct.delay.duration')}</p>
                        </Grid>
                        
                    </Grid>

                                                                
                            </Grid>
                            {/*<Grid container>
                                <Grid container item xs={10}>
                                </Grid>
                                <Grid item xs={3}>
                                    <p className="pd_user">{t('ct.pduser')}</p>
                                </Grid>                                
                            </Grid>*/}
                            <Grid container className="stop_margin_bottom">
                                <Grid item xs={0}>
                                    <img src="images/location.png" width="20px" className="navgation_image"></img>
                                    <div className="navigation"></div>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={3} style={{ paddingRight: "20px" }}>
                                    <p className="cj_w_header">{this.state.journeyDetails.planned_start_loc}</p>
                                </Grid>
                                {/* <Grid item xs={1}>
                                    <p className="cj_w_header">{"15"} Mins</p>
                                </Grid> */}
                            </Grid>
                            {stopAddress}
                            <Grid container className="stop_margin_bottom">
                                <Grid item xs={0}>
                                    <img src="images/location.png" width="20px" className="navgation_image"></img>
                                    <div className="navigation"></div>
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={3} style={{ paddingRight: "20px" }}>
                                    <p className="cj_w_header">{this.state.journeyDetails.planned_end_loc}</p>
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <p className="cj_w_header">{stopsInfo.duration} Mins</p>
                                </Grid> */}
                            </Grid>
                            <div className="close_modal" onClick={this.modalClose}>
                                <i class="fa fa-close" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    {/* view stops modal end*/}

                    {/* Insufficient fuel modal*/}
                    <div id="insuf_fuel" className="modal_parent">
                        <div className="modal_body">
                            <p className="cj_w_header" style={{ textAlign: "center" }}><b>Insufficient fuel available for completing the journey. Please refill the fuel for flawless travel.</b></p>
                            <div style={{ textAlign: "center" }}>
                                <button className="btn orange-btn" style={{ float: "none" }} onClick={() => {
                                    $('#insuf_fuel').fadeOut("slow")
                                    this.getJourneyDetails()
                                }}>Okay</button>
                            </div>
                        </div>
                    </div>
                    {/* Insufficient fuel modal end*/}
                    {/* Nrt Status modal*/}
                    <div id="nrt_status" className="modal_parent">
                        <div className="modal_body">
                            <p className="cj_w_header" style={{ textAlign: "center" }}><b>Your vehicle is out of network area.So we are not allowing you to start your journey.Please move your vehicle.</b></p>
                            <div style={{ textAlign: "center" }}>
                                <button className="btn orange-btn" style={{ float: "none" }} onClick={() => {
                                    $('#nrt_status').fadeOut("slow")
                                    this.getJourneyDetails()
                                }}>Okay</button>
                            </div>
                        </div>
                    </div>
                    {/* Nrt modal end*/}

                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1800}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(JourneyOverview);
