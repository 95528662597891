import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import Grid from '@material-ui/core/Grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import Snackbar from '@material-ui/core/Snackbar';
import html2canvas from "html2canvas";
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Calendar from 'react-calendar/dist/entry.nostyle'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

const data1 = [
    {
      label: 'FRD / FIR / IDENTIFICATION NUMBER',
      field: 'frd_fir',
      sort: 'asc',
      width: 200,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: 'VEHICLE NUMBER',
      field: 'vehicle_no',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'TRACKER NUMBER',
      field: 'tracker_no',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'TRIP START',
      field: 'start_date',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'TRIP END',
      field: 'end_date',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'TOTAL TRIP DURATION',
      field: 'frd_fir_duration',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'OVER STAY',
      field: 'over_stay',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'OVER SPEED',
      field: 'over_speed',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'START TRIP BY USER',
      field: 'start_trip_by_user',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'END TRIP BY USER',
      field: 'end_trip_by_user',
      sort: 'asc',
      width: 150,
    }       
  ]   

class JswReport extends Component {
    counter = 0
    _exporter;
    data1;
  constructor(props){
    super(props);
    this.state = {
        reportList : '',
        date : new Date(),
        startDate: new Date(),
        endDate: new Date(),
        datatable : [],          
        nextCount : 0, 
        totalCount : 0, 
        buttonFlag : true, 
        dataCollection : [], 
        chunckValue : '',
        searchText : '',
        totalEntries : '',
        nextCount : 0, 
        sevenDayFlag : true,
        openAlert : false,
        errorMessage : '',
        excelNext : 0,
        reportData : [],        
        downloadReportFlag : false ,
        stopReportExe : false,     
    }
  }

  componentDidMount(){
    // setTimeout(() => {
        var navBarHeight = $('.navBar').outerHeight();                
        $('.jws_report').css({
            paddingTop: navBarHeight,        
        })        
    // }, 1000);
    this.getReportData()   
    this.createReportData() 
  }

  componentWillUnmount(){
    
  }

  emptyTable = () => {
    this.setState({
      datatable : []
    })
  }

  getReportData = () => {
    $('#processing').show()
    const{startDate, endDate} = this.state
    const data = {next_count : this.state.nextCount, status : 0, start_date : CommonFunction.ServerDateTime(startDate.setHours(0,0,0,0)) , 
      end_date : CommonFunction.ServerDateTime(endDate.setHours(23,59,59,0)), search : this.state.searchText}
    // console.log('data is', data)
    ApiUrlCall.getRentalReport(JSON.stringify(data)).then(response => {
      $('#processing').hide()
      // console.log('response of tracker is', response)
      // return
      this.emptyTable()
      if(response.data.response_code === 200 && response.data.is_success){
          this.setState({
            reportList : response.data.data.report_list,
            nextCount : response.data.data.next_count,
            totalCount : response.data.data.total_count,
            chunckValue : response.data.data.size
          },() => {
            this.createTableData()
          })
      }else{
        this.setState({
          openAler : true,
          errorMessage : response.data.response_message
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching report data', error)
    })
  }

  emptyTable = () => {
    this.setState({
      datatable : []
    })
  }

  buttonStatus = () => {
    const {nextCount, totalCount} = this.state
    // console.log(nextCount,'.................',totalCount)
    if(nextCount === totalCount){
      // console.log('iffffffffff')      
      if(this.counter === 0){
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }else{
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    }else{
      // console.log('elseeeeeeee', this.counter)
      if(this.counter === 0){
        // console.log('else 1111111111')
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')        
        $('#dt-next').addClass('enabled-btn')
      }else{
        // console.log('else 22222222')      
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')        
      }
    }    
  }

  entriesData = () => {
    const {nextCount, totalCount, chunckValue} = this.state
    if(nextCount != totalCount){
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start+'-'+end
      this.setState({
        totalEntries : entries_text
      })
    }else{
      if(nextCount <= 10){
        if(nextCount == 0){ 
          this.setState({
            totalEntries : "0"
          })
        }else{
          var entries_text = '1'+'-'+totalCount
          this.setState({
            totalEntries : entries_text
          })
        }
      }else{
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if(module > 0){
          start = nextCount - module + 1
          end = nextCount
          entries_text = start+'-'+end
        }else{
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start+'-'+end
        }
        this.setState({
          totalEntries : entries_text
        })
      }
    }
  }

  createTableData = () => {        
    const {reportList,datatable} = this.state    
    var row = []  
     //console.log('lenght is11111111111', reportList)
    for (let i = 0; i < reportList.length; i++) {
      const element = reportList[i];
      var row_data = {frd_fir : element.frd_number, vehicle_no : element.vehicle_number, tracker_no : element.serial_number,
        start_date : CommonFunction.tableDateFormate(element.start_trip_time)+" "+CommonFunction.timeFormate(element.start_trip_time), 
        end_date : CommonFunction.tableDateFormate(element.end_trip_time)+" "+CommonFunction.timeFormate(element.end_trip_time),
        frd_fir_duration : CommonFunction.convertSecToHoursMin(element.duration), over_stay : this.overStayOverSpeed(element.is_overstay), 
        over_speed : this.overStayOverSpeed(element.is_overspeed),start_trip_by_user : element.start_trip_by_user,end_trip_by_user : element.end_trip_by_user}
      datatable.push(row_data)                 
    }    
    this.buttonStatus() 
    this.entriesData()   
    this.forceUpdate()
    // console.log('data table data is2222222', datatable)
    // this.intervalData()
  }

  pageNextClick = () => {
    const {totalCount,nextCount,dataCollection,datatable} = this.state
    // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
    this.counter = this.counter + 1  
    for (let i = 0; i < datatable.length; i++) {
      const element = datatable[i];
      dataCollection.push(element)
    }      
    this.setState({
      datatable : []
    },() => {
      this.getReportData()
    })
  }

  pagePreviousClick = () => {
    const {dataCollection, chunckValue, nextCount} = this.state
    var pageSize = nextCount % chunckValue
    if(pageSize === 0){
      pageSize = chunckValue
    }
    // console.log('previous clicked', pageSize)    
    var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
    this.setState({
      datatable : last,
      nextCount : nextCount - pageSize
    },() => {      
      this.buttonStatus() 
      this.entriesData()     
    })
    this.counter = this.counter -1
  }

  optimizationSearch = (value) => {  
    
      this.counter = 0
      if(value.length >= 3){
        // console.log('serach.................')
        setTimeout(() => {
          this.setState({
            datatable : [],
            dataCollection : [],
            totalCount : '',
            chunckValue : '',
            nextCount : 0,
            searchText : value
          },() => {
            this.getReportData()          
          })
        }, 1000);     
      }else if(value.length === 0){
        setTimeout(() => {
          this.setState({
            datatable : [],
            dataCollection : [],
            totalCount : '',
            chunckValue : '',
            nextCount : 0,
            searchText : value
          },() => {
            this.getReportData()          
          })
        }, 1000);     
      }          
  }

  StartdateClicked = date => {
    // var isoStartDate = date
    // console.log('date is', date)
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ startDate: date })
    $('#start_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  EnddateClicked = date => {
    var isoStartDate = date
    isoStartDate.setHours(23,59,59,0);
    // var Difference_In_Time = isoStartDate.getTime() - this.state.startDate.getTime();
    // var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    // if(Difference_In_Days > 7){ 
    //   $('#processing').hide()     
    //   this.setState({
    //     sevenDayFlag : false,   
    //     openAlert : true,
    //     errorMessage : "Not allowed to select more than 7 days"     
    //   })     
    //   return 
    // }else{
    //   this.setState({
    //     sevenDayFlag : true
    //   })
    // }
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ endDate: isoStartDate })
    $('#end_date_cal').slideUp(200)
  }
  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('#end_date_cal').slideUp(200)
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('#start_date_cal').slideUp(200)
  }

  create_report = () => {    
    const {sevenDayFlag, startDate, endDate} = this.state
    $('#start_date_cal').slideUp("slow")
    $('#end_date_cal').slideUp("slow")
    var dateValidate = CommonFunction.dateValidation(startDate , endDate)    
      if(!dateValidate){
        // $('#processing').hide()
        this.setState({
          openAlert : true,
          errorMessage : "Kindly select valid date"
        })
        return
      }  

    //   var Difference_In_Time = this.state.endDate.getTime() - this.state.startDate.getTime();
    // var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    // // console.log('seven days', Difference_In_Days)
    // if(Difference_In_Days > 7){ 
    //   $('#processing').hide()           
    //   this.setState({
    //     sevenDayFlag : false,   
    //     openAlert : true,
    //     errorMessage : "Not allowed to select more than 7 days"     
    //   }) 
    //   return    
    // }else{      
    //   this.setState({
    //     sevenDayFlag : true,
    //   })
    // }
    this.setState({
      complete_report :false,
      datatable : [],
      dataCollection : [],
      totalCount : '',
      chunckValue : '',
      nextCount : 0,
      excelNext : 0,
      downloadReportFlag : false,
      reportData : []
    },() => {
      this.getReportData()
      this.createReportData()
    })
    // if(sevenDayFlag){
    // }else{
    //   this.setState({        
    //     openAlert : true,
    //     errorMessage : "Not allowed to select more than 7 days"     
    //   })     
    // }
  }

  overStayOverSpeed = (val) => {
    if(val){
      return "Yes"
    }else{
      return "No"
    }
  }

  createReportData = () => {   
    const {startDate, endDate,reportData, excelNext} = this.state       
    startDate.setHours(0,0,0,0);
    var data = {start_date : CommonFunction.ServerDateTime(startDate) , end_date : CommonFunction.ServerDateTime(endDate), next_count : excelNext}      
    ApiUrlCall.getRentalReport(JSON.stringify(data)).then(response => { 
      // console.log('report data is', response)     
      if(response.data.response_code === 200 && response.data.is_success){        
        var data = response.data.data.report_list        
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          var row_data = {frd_fir : element.frd_number, vehicle_no : element.vehicle_number, tracker_no : element.serial_number,
            start_date : CommonFunction.tableDateFormate(element.start_trip_time)+" "+CommonFunction.timeFormate(element.start_trip_time), 
            end_date : CommonFunction.tableDateFormate(element.end_trip_time)+" "+CommonFunction.timeFormate(element.end_trip_time),
            frd_fir_duration : CommonFunction.convertSecToHoursMin(element.duration), over_stay : this.overStayOverSpeed(element.is_overstay), 
            over_speed : this.overStayOverSpeed(element.is_overspeed),start_trip_by_user : element.start_trip_by_user ,end_trip_by_user : element.end_trip_by_user}       
          reportData.push(row_data)                 
        }    
        this.forceUpdate()         
        if(response.data.data.next_count !== response.data.data.total_count){
          this.setState({
            excelNext : response.data.data.next_count,
          })
          this.createReportData()
        }else{
          $('#export_excel').removeClass("disable-click")
          this.setState({
            excelNext : 0,
            downloadReportFlag : true,
          })                     
        }
      }
    }).catch(error => {      
      console.log('error while getting fuel report',error)
    })     
  }

  export = () => {
    this._exporter.save();
  }

  render(){
    const {t} = this.props
    // var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);  
    var excel_name = "Report_"+CommonFunction.downloadReportDate(this.state.date)+".xlsx"  
    if(this.state.downloadReportFlag){
      var excel_data = process(this.state.reportData, {
        // group: group
       }).data;
    }
    return (
        <div>
            <Navbar/>
            <div className="jws_report">
                <div className="report_filter_div" style={{marginTop : "50px"}}>
                <Grid spacing={2} container>
                    <Grid item xs={3}>

                    <p>{t('ct.startdate')}</p>
                    <div className="input_div" onClick={this.showStartDate}>
                        <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>
                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="start_date_cal">
                        <Calendar
                        selectRange={false}
                        onChange={this.StartdateClicked}
                        value={this.state.startDate}
                        maxDate={this.state.date}
                        // minDate={minDate}
                        />
                    </div>
                    </Grid>
                    <Grid item xs={3}>
                    <p>{t('ct.enddate')}</p>
                    <div className="input_div" onClick={this.showEndDate}>
                        <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input"></input>
                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="end_date_cal">
                        <Calendar
                        selectRange={false}
                        onChange={this.EnddateClicked}
                        value={this.state.endDate}
                        maxDate={this.state.date}
                        // minDate={minDate}
                        />
                    </div>
                    </Grid> 
                    <Grid item xs={2}>
                    </Grid>           
                    <Grid item xs={2}>
                    <p>&nbsp;</p>
                    <button className="btn orange-btn btn-block" style={{ marginTop: "0px" }} onClick={this.create_report}>{t('ars.generate')}</ button>
                    </Grid>
                    <Grid container item xs={2} className="pdf_excel_download">
                    {/* <img src="images/group-6-copy-2.png" className="vd_journey_icon"></img>
                    <label>&nbsp;</label> */}
                    <Grid item xs={12}><label>&nbsp;</label></Grid>
                    <Grid item xs={6} onClick={this.download}>
                        {/* <img src="images/group-6-copy-2.png" style={{marginRight : "5px"}}></img>
                        <label className="BSC_semi_bold18">{t('ars.pdf')}</label> */}
                    </Grid>
                    <Grid id="export_excel" item xs ={6} onClick={this.export} className="disable-click">
                        <img src="images/group-6-copy-2.png" style={{marginRight : "5px"}}></img>
                        <label className="BSC_semi_bold18">{t('ars.excel')}</label>
                        <ExcelExport
                            data={excel_data}
                            fileName={excel_name}
                            ref={(exporter) => { this._exporter = exporter; }}>
                            <ExcelExportColumn field="frd_fir" title="FRD / FIR NUMBER" />
                            <ExcelExportColumn field="vehicle_no" title="VEHICLE NUMBER" />
                            <ExcelExportColumn field="tracker_no" title="TRACKER NUMBER" />
                            <ExcelExportColumn field="start_date" title="TRIP START" />
                            <ExcelExportColumn field="end_date" title="TRIP END" />
                            <ExcelExportColumn field="frd_fir_duration" title="TOTAL TRIP DURATION" />
                            <ExcelExportColumn field="over_stay" title="OVER STAY" />
                            <ExcelExportColumn field="over_speed" title="OVER SPEED" />
                            <ExcelExportColumn field="start_trip_by_user" title="Start Trip By User" />
                            <ExcelExportColumn field="end_trip_by_user" title="End Trip By User" />
                        </ExcelExport>
                    </Grid>
                    {/* <div className="pdf_excel_download">
                        <span style={{ marginRight: "20px" }}>
                        </span>
                        <span>
                        </span>
                    </div> */}
                    </Grid>
                </Grid>                
                  {/* <p className="work_sans_regular16" 
                style={{fontSize : "12px", color : "#dc3545", marginBottom : "0px", marginTop : "5px"}}>
                  {t('ars.sevenday')} *</p> */}
                </div>

                {/* table start code */}
                <div style={{marginTop : "30px"}}>
                    <MDBDataTable            
                        displayEntries={false}
                        paging={false}
                        ref={this.myref}
                        scrollX
                        entries={10}            
                        displayEntries={false}
                        striped
                        bordered
                        data={{columns : data1 , rows : this.state.datatable}}            
                        onSearch = {this.optimizationSearch}            
                    />
                    <Grid container style={{margin : "20px 0px"}}>
                        <Grid item xs>
                            <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                        </Grid>
                        <Grid item xs style={{textAlign : "right"}}>
                            <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>{t('aeds.previous')}</button>
                            <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>{t('aeds.next')}</button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={1400}
                onClose={(e, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    this.setState({ openAlert: false })
                }
                }
                open={this.state.openAlert}
                message={this.state.errorMessage}
            />
        </div>
    );
  }
}

export default withTranslation()(JswReport);
