import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import Grid from '@material-ui/core/Grid';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Snackbar from '@material-ui/core/Snackbar';
import { withTranslation } from 'react-i18next';

const ADMIN = 2
const USER = 3

class SendInvite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            addUser: false,
            name: '',
            mobileNumber: '',
            emailAddress: '',
            userCount: 0,
            openAlert: false,
            errorMessage: '',
            roleID: ''
        }
    }

    componentDidMount() {
        var navBarHeight = $('.navBar').outerHeight()
        $('.people_report_parent').css({
            paddingTop: navBarHeight + 60,
        })
        if (this.props.location.state !== undefined) {
            this.getUserDetails()
        }
    }

    setUserDetails = () => {
        const { addUser } = this.state
        if (addUser) {
            this.setState({
                isAdmin: true
            }, () => {
                $('#add_user').prop("checked", true)
                $('#user_count').val(this.state.userCount)
            })
        }
    }

    getUserDetails = () => {
        $('#processing').show()
        var data = { customer_id: this.props.location.state.user_id }
        ApiUrlCall.ptUserDetails(JSON.stringify(data)).then(response => {
            console.log('response', response)
            $('#processing').hide()
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    name: response.data.data.name,
                    mobileNumber: response.data.data.mobile_number,
                    emailAddress: response.data.data.email,
                    roleID: response.data.data.role_id,
                    userCount: response.data.data.add_user_limit,
                    addUser: response.data.data.add_user
                }, () => {
                    if (this.state.roleID == 2) {
                        this.setUserDetails()
                    }
                })
            } else if (response.data.response_code == 410 && !response.data.is_success) {
                if ($('#pplt_expire_dialog').is(':hidden')) {
                    $('#pplt_expire_dialog').slideDown()
                }
            } else {

            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching user details', error)
        })
    }

    selectUser = (e) => {
        var value = e.currentTarget.value
        if (value == ADMIN) {
            this.setState({
                isAdmin: true,
                roleID: 2
            })
        } else if (value == USER) {
            $('#add_user').prop('checked', false)
            setTimeout(() => {
                this.setState({
                    isAdmin: false,
                    addUser: false,
                    userCount: '',
                    roleID: 3
                })
            }, 10);
        }
    }

    addUserCount = (e) => {
        if (e.currentTarget.checked) {
            this.setState({
                addUser: true,
            })
        } else {
            $('#user_count').val("")
            this.setState({
                addUser: false,
                userCount: ''
            })
        }
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/p_user_management'
        })
    }

    sendInvite = () => {
        const { isAdmin, name, mobileNumber, emailAddress, userCount, addUser } = this.state
        if (!name.trim()) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Enter Name"
            })
            return
        }
        if (mobileNumber.length !== 10) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Enter Valid Mobile Number"
            })
            return
        }
        if (emailAddress) {
            var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!emailAddress.match(reg)) {
                this.setState({
                    openAlert: true,
                    errorMessage: "Kindly Enter Valid Email Address"
                })
                return
            }
        }
        if (addUser) {
            if (userCount == 0) {
                this.setState({
                    openAlert: true,
                    errorMessage: "Kindly Add Number Of User"
                })
            }
        }
        $('#processing').show()
        if (this.props.location.state !== undefined) {
            const data = {
                role_id: this.roleIdReturn(isAdmin), name: name, email: emailAddress, add_user: addUser, add_user_limit: userCount,
                mobile_number: mobileNumber, customer_id: this.props.location.state.user_id
            }
            ApiUrlCall.ptUpdateUserDetails(JSON.stringify(data)).then(response => {
                // console.log('response is', response)
                $('#processing').hide()
                if (response.data.response_code == 200 && response.data.is_success) {
                    this.props.history.push({
                        pathname: '/p_user_management'
                    })
                } else if (response.data.response_code == 410 && !response.data.is_success) {
                    if ($('#pplt_expire_dialog').is(':hidden')) {
                        $('#pplt_expire_dialog').slideDown()
                    }
                } else {

                }
            }).catch(error => {
                $('#processing').hide()
                if (error.response.status == 409) {
                    this.setState({
                        openAlert: true,
                        errorMessage: error.response.data.response_message
                    })
                }
                console.log('error while adding user', error.response)
            })
        } else {
            const data = { role_id: this.roleIdReturn(isAdmin), name: name, email: emailAddress, add_user: addUser, add_user_limit: userCount, mobile_number: mobileNumber }
            ApiUrlCall.ptAddUser(JSON.stringify(data)).then(response => {
                // console.log('response is', response)
                $('#processing').hide()
                if (response.data.response_code == 200 && response.data.is_success) {
                    this.props.history.push({
                        pathname: '/p_user_management'
                    })
                } else if (response.data.response_code == 410 && !response.data.is_success) {
                    if ($('#pplt_expire_dialog').is(':hidden')) {
                        $('#pplt_expire_dialog').slideDown()
                    }
                } else {

                }
            }).catch(error => {
                $('#processing').hide()
                if (error.response.status == 409) {
                    this.setState({
                        openAlert: true,
                        errorMessage: error.response.data.response_message
                    })
                }
                console.log('error while adding user', error.response)
            })
        }
    }

    roleIdReturn = (value) => {
        if (value) {
            return 2
        } else {
            return 3
        }
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Navbar />
                <div className="people_report_parent">
                    <div >
                        <h2 className="page_header"> <img src="images/arrow11.png" alt="icon" onClick={this.goBack}></img>&nbsp;&nbsp;
                        {this.props.location.state !== undefined ? "Edit User details" : "Send New Invite"}</h2>
                    </div>

                    <div className="map_view_div">
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Name *</p>
                                <input type="text" placeholder="Enter Name" className="vd_input" autoComplete="off" style={{ marginBottom: "0px" }} name="fname"
                                    onChange={(e) => {
                                        this.setState({
                                            name: e.currentTarget.value
                                        })
                                    }} defaultValue={this.state.name}></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Mobile Number *</p>
                                <input type="number" placeholder="Enter Mobile Number" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname"
                                    onChange={(e) => {
                                        this.setState({
                                            mobileNumber: e.currentTarget.value
                                        })
                                    }} defaultValue={this.state.mobileNumber}></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Email Address</p>
                                <input type="text" placeholder="Enter Email Address" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname"
                                    onChange={(e) => {
                                        this.setState({
                                            emailAddress: e.currentTarget.value
                                        })
                                    }} defaultValue={this.state.emailAddress}></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">User Type</p>
                                <select className="status-filter" style={{ width: "100%", position: "relative", padding: "15px 8px" }}
                                    onChange={this.selectUser} defaultValue={this.state.roleID}>
                                    <option value="3">User</option>
                                    <option value="2">Admin</option>
                                </select>
                            </Grid>
                            <Grid item xs={4} style={{ display: this.state.isAdmin ? "" : "none" }}>
                                <p className="cj_w_header">Can Add User ?</p>
                                <input type="checkbox" id="add_user" onChange={this.addUserCount}></input>
                            </Grid>
                            <Grid item xs={4} style={{ display: this.state.addUser ? "" : "none" }}>
                                <p className="cj_w_header">Add Max User*</p>
                                <input type="number" placeholder="Enter Number Of User" id="user_count" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname"
                                    onChange={(e) => {
                                        this.setState({
                                            userCount: e.currentTarget.value
                                        })
                                    }} defaultValue={this.state.userCount}></input>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <button className="btn orange-btn" style={{ float: "none" }} onClick={this.sendInvite}>{this.props.location.state !== undefined ? "Update" : "Send Invite"}</button>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1800}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(SendInvite);