import React, { Component } from 'react';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import CommonFunction from '../common_function'
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ApiUrlCall from '../API/apiurl';
import Snackbar from '@material-ui/core/Snackbar';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Calendar from 'react-calendar/dist/entry.nostyle'

const data1 = [
    {
        label: '',
        field: 'checkbox',
        sort: 'asc',
        width: 80,
    },
    {
        label: 'VEHICLE NAME',
        field: 'vehicle_name',
        sort: 'asc',
        width: 200,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'VEHICLE REGISTRATION NUMBER',
        field: 'reg_number',
        sort: 'asc',
        width: 300,
    },
    {
        label: 'TRACKER NUMBER',
        field: 'serial_number',
        sort: 'asc',
        width: 300,
    },
    {
        label: 'EXPIRY DATE',
        field: 'expiry',
        sort: 'asc',
        width: 300,
    }
]


class VendorIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleList: [],
            datatable: [],
            selectedVehicle: [],
            vendorList: [],
            selectedVendor: 2,
            vendorVehicleList1: [],
            vendorVehicleList2: [],
            vendorName: '',
            openAlert: false,
            errorMessage: '',
            expiryModalContent: [],
            selectDate: '',
            selectedSerialNumber: '',
            removeSerialNumber : '',
            removeVehicleName : '',
            vendor_name1:'',
            vendor_name2:''
        }
    }

    componentDidMount() {
        // var navBarHeight = $('.navBar').outerHeight()
        // $('.vehicleRoute').css({
        //   paddingTop : navBarHeight+60,
        // })        
        this.getVendorList()
        this.getVendorMappingList()
    }



    emptyTable = () => {
        this.setState({
            datatable: []
        })
    }

    getVendorList = () => {
        ApiUrlCall.getVendorList().then(response => {
            //console.log('vendor list', response)
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    vendorList: response.data.data.vendor_list
                })
            } else {

            }

        }).catch(error => {
            console.log('error while fetching vendor list', error)
        })
    }

   

    vendorVehicleList1 = () => {
        const { vehicleList } = this.state
        var array = []
        for (let i = 0; i < vehicleList.length; i++) {
            const element = vehicleList[i];
            if (element.mapping_id && element.vendor_id === 3) {
                var data = { serial_number: element.serial_number, name: element.name }
                array.push(data)
            }
        }
        this.setState({
            vendorVehicleList1: array
        })
    }
    
    vendorVehicleList2 = () => {
        const { vehicleList } = this.state
        var array = []
        for (let i = 0; i < vehicleList.length; i++) {
            const element = vehicleList[i];
            if (element.mapping_id && element.vendor_id === 2) {
                var data = { serial_number: element.serial_number, name: element.name }
                array.push(data)
            }
        }
        this.setState({
            vendorVehicleList2: array
        })
    }


    getVendorName = () => {
        const { vehicleList } = this.state
               for (let i = 0; i < vehicleList.length; i++) {
            const element = vehicleList[i];
            if (element.vendor_name === 'NICER GLOBE') {
                this.setState({
                    vendor_name1: 'NICER GLOBE'
                   
                })
            }else if(element.vendor_name === 'REDBUS') {
                this.setState({
                    vendor_name2: 'REDBUS'
                })  
            }
        }

    }

    getVendorMappingList = () => {
        $('#processing').show()
        this.emptyTable()
        ApiUrlCall.getVendorMapping().then(response => {            
            $('#processing').hide()
            //console.log('>>>>>>>>>',response)
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    vehicleList: response.data.data.mapping_list,
                    vendorName: response.data.data.vendor_name
                }, () => {
                    this.createTableData()
                    this.vendorVehicleList1()
                    this.vendorVehicleList2()
                    this.getVendorName()
                })
            } else {
                $('#processing').hide()
            }

        }).catch(error => {
            $('#processing').hide()
            console.log('error whicle fetching vehicle list', error)
        })
    }

    isAssigned = (reg_no, mapping_id, serial_number) => {
        if (mapping_id) {
            return (
                <Tooltip title={
                    <span style={{ fontSize: "15px", textAlign: "center", padding: "20px" }}>
                        <p>Already sending data to Nicer Globe</p>
                    </span>
                }>
                    <span><img src="images/green_tick.png" alt="icon" style={{ width: "15px", marginRight: "5px" }}></img>{this.enterRegistrationNumber(reg_no, serial_number)}</span>
                </Tooltip>
            )
        } else {
            return <span>{this.enterRegistrationNumber(reg_no, serial_number)}</span>
        }
    }


    enterRegistrationNumber = (value, serial_number) => {
        if (value) {
            return value
        } else {
            return (
                <div className="grid-container" style={{ width: "fit-content" }}>
                    <div className="grid-item">
                        <input type="text" className="journey_input" placeholder="Enter Registration Number"></input>
                    </div>
                    <div>
                        <button className="btn orange-btn" style={{ marginTop: "0px" }} id={serial_number} onClick={this.submitRegistrationNumber}>Submit</button>
                    </div>
                </div>
            )
        }
    }

    submitRegistrationNumber = (e) => {
        var serial_number = $(e.currentTarget).prop('id')
        var registration_number = $(e.currentTarget).parent().prev().children().val()
        if (registration_number) {
            // console.log(serial_number,'>>>>>>>>',registration_number) 
            var data = { reg_number: registration_number, serial_number: serial_number }
            ApiUrlCall.updateRegistrationNumber(JSON.stringify(data)).then(response => {
                // console.log('>>>>>>>>>><<<<<<', response)
                if (response.data.response_code == 200 && response.data.is_success) {
                    this.getVendorMappingList()
                }
            }).catch(error => {
                console.log('error while updating registration number', error)
            })

        } else {
            this.setState({
                openAlert: true,
                errorMessage: 'Kindly enter Registration Number'
            })
        }
    }

    createTableData = () => {
        const { vehicleList, datatable } = this.state
        // console.log('lenght is', driverdata.length)
        for (let i = 0; i < vehicleList.length; i++) {
            const element = vehicleList[i];
            var row_data = {
                checkbox: <input type="checkbox" id={element.serial_number} onChange={this.selectVehicle}
                    style={{
                        pointerEvents: element.mapping_id || CommonFunction.returnNa(element.registration_number) == "NA" ? "none" : "",
                        opacity: element.mapping_id || CommonFunction.returnNa(element.registration_number) == "NA" ? "0.3" : "1"
                    }}></input>, vehicle_name: element.name,
                reg_number: this.isAssigned(element.registration_number, element.mapping_id, element.serial_number), serial_number: element.serial_number,
                expiry: CommonFunction.tableDateFormate(element.expiry_date)
            }
            datatable.push(row_data)
        }
        // this.intervalData()
    }

    selectVehicle = (e) => {
        const { selectedVehicle } = this.state
        var serial_number = $(e.currentTarget).prop('id')
        if (e.currentTarget.checked) {
            var data = { serial_number: serial_number, expiry_date: "" }
            selectedVehicle.push(data)
            this.forceUpdate()

        } else {
            var removedArray = selectedVehicle.findIndex(x => x.serial_number == serial_number)
            selectedVehicle.splice(removedArray, 1)
            this.forceUpdate()
        }
    }

    assignTrackerToVendor = () => {
        const { selectedVehicle } = this.state
        var array = []
        if (selectedVehicle.length > 0) {
            for (let i = 0; i < selectedVehicle.length; i++) {
                const element = selectedVehicle[i];
                array.push(
                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid item xs>
                            <p className="cj_w_header">Tracker Number</p>
                            <input type="text" placeholder="Enter Vehicle Name" value={element.serial_number} className="journey_input" disabled></input>
                        </Grid>
                        <Grid item xs>
                            <p className="cj_w_header">Enter Expiry Date</p>
                            <div id={element.serial_number} onClick={this.selectExpiryDate}>
                                <input type="text" placeholder="Enter Vehicle Name" className="journey_input" disabled
                                    value={CommonFunction.dateFormate(element.expiry_date)}></input>
                            </div>
                        </Grid>
                    </Grid>
                )
            }
            this.setState({
                expiryModalContent: array
            }, () => {
                $('#enter_expiry_date').slideDown()
            })
        } else {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Select Atleast One Vehicle"
            })
        }
    }

    selectExpiryDate = (e) => {
        var height = $(e.currentTarget).outerHeight()
        var top = $(e.currentTarget).offset().top
        var left = $(e.currentTarget).offset().left
        var serial_number = $(e.currentTarget).prop('id')
        this.setState({
            selectedSerialNumber: serial_number
        })
        if ($('#modal_date').is(':visible')) {
            $('#modal_date').css({
                display: "none",
            })
        } else {
            $('#modal_date').css({
                display: "block",
                top: top + height,
                left: left
            })
        }
    }

    removeTracker = (e) => {
        $('#processing').show()        
        var data = { serial_number_list: [this.state.removeSerialNumber], vendor_id: 3 }
        ApiUrlCall.removeTrackerFromApi(JSON.stringify(data)).then(response => {
            if (response.data.response_code == 200 && response.data.is_success) {
                $('#confirmation_popup').fadeOut()
                $('#processing').hide()
                this.getVendorList()
                this.getVendorMappingList()
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while removing data', error)
        })
    }

    expiryDateCalClick = date => {
        const { selectedSerialNumber, selectedVehicle } = this.state
        for (let i = 0; i < selectedVehicle.length; i++) {
            const element = selectedVehicle[i];
            if (selectedSerialNumber == element.serial_number) {
                element.expiry_date = date.setHours(23,59,59,59)
                this.forceUpdate()
                this.assignTrackerToVendor()
                $('#modal_date').hide()
            }
        }
    }

    saveVehicleDetails = () => {
        $('#processing').show()
        const { selectedVehicle } = this.state
        $('#enter_expiry_date').fadeOut()
        for (let i = 0; i < selectedVehicle.length; i++) {
            const element = selectedVehicle[i];            
            if(element.expiry_date !== ""){                
                element.expiry_date = CommonFunction.ServerDateTime(element.expiry_date)
            }
        }        
        var data = { mapping_id_list: selectedVehicle, vendor_id: this.state.selectedVendor }
        // console.log('data is', data)                
        ApiUrlCall.addTrackerToVendor(JSON.stringify(data)).then(response => {            
            if (response.data.response_code === 200 && response.data.is_success === true) {
                $('#processing').hide()
                this.setState({
                    selectedSerialNumber: '',
                    selectedVehicle: [],
                    expiryModalContent: [],
                    selectDate: '',                    
                })
                this.getVendorList()
                this.getVendorMappingList()
            }else{
                $('#processing').hide()
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while assinging vehicle', error)
        })
    }

    render() {
        const { t } = this.props
        const { vendorList, vendorVehicleList1,vendorVehicleList2} = this.state
        if (vendorList.length > 0) {
            var vendor = vendorList.map(data => {
                return (
                    <option value={data.id}>{data.name}</option>
                )
            })
        }
        if (vendorVehicleList1.length > 0) {
            var vendorVehList1 = vendorVehicleList1.map(data => {
                return <label className="vendor_vehicle cj_w_header">{data.name}
                    <i class="fa fa-close" id={data.serial_number} aria-hidden="true" onClick={(e) => {
                        $('#confirmation_popup').slideDown()
                        this.setState({
                            removeSerialNumber : $(e.currentTarget).prop('id'),
                            removeVehicleName : $(e.currentTarget).parent().text()
                        })
                    }}
                        style={{ marginLeft: "10px", color: "#F0762B", cursor: "pointer" }}></i></label>
            })
        }
        if (vendorVehicleList2.length > 0) {
            var vendorVehList2 = vendorVehicleList2.map(data => {
                return <label className="vendor_vehicle cj_w_header">{data.name}
                    <i class="fa fa-close" id={data.serial_number} aria-hidden="true" onClick={(e) => {
                        $('#confirmation_popup').slideDown()
                        this.setState({
                            removeSerialNumber : $(e.currentTarget).prop('id'),
                            removeVehicleName : $(e.currentTarget).parent().text()
                        })
                    }}
                        style={{ marginLeft: "10px", color: "#F0762B", cursor: "pointer" }}></i></label>
            })
        }
        return (
            <div className="vendor_parent">
                <Grid container>
                    <Grid item xs>
                        <div className="grid-container" style={{ width: "fit-content" }}>
                            <div className="grid-item">
                                <p className="p_header" style={{ lineHeight: "2em" }}>{t('ss.vendor.name')}</p>
                            </div>
                            <div className="grid-item" style={{ paddingLeft: "15px" }}>
                                <select className="status-filter" style={{ position: "inherit" }}
                                    onChange={(e) => {
                                        this.setState({ selectedVendor: e.currentTarget.value })
                                    }}>
                                    {vendor}
                                </select>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className="user_profile" style={{ display: this.state.vendorVehicleList1.length > 0 && this.state.vendor_name1 === 'NICER GLOBE' ? "" : "none", width: "fit-content", float: "right" }}>
                            <Grid container>
                                <Grid item xs>
                                    <p className="cj_w_header"><b>{t('ss.vendor.name')}</b></p>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs>
                                    <p className="cj_w_header">{this.state.vendor_name1}</p>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs>
                                    <p className="cj_w_header"><b>{t('ss.select.vehicle')}</b></p>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs style={{ maxHeight: "100px", overflow: "auto" }}>
                                    {vendorVehList1}
                                </Grid>
                            </Grid>
                            {/* <Grid container>
                                <Grid item xs>
                                    <p className="cj_w_header"><b>{t('ss.access.grant')}</b></p>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs>
                                    <p className="cj_w_header">Nicer globe</p>
                                </Grid>
                            </Grid> */}
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className="user_profile" style={{ display: this.state.vendorVehicleList2.length > 0 && this.state.vendor_name2 === 'REDBUS' ? "" : "none", width: "fit-content", float: "right" }}>
                            <Grid container>
                                <Grid item xs>
                                    <p className="cj_w_header"><b>{t('ss.vendor.name')}</b></p>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs>
                                    <p className="cj_w_header">{this.state.vendor_name2}</p>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs>
                                    <p className="cj_w_header"><b>{t('ss.select.vehicle')}</b></p>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs style={{ maxHeight: "100px", overflow: "auto" }}>
                                    {vendorVehList2}
                                </Grid>
                            </Grid>
                            {/* <Grid container>
                                <Grid item xs>
                                    <p className="cj_w_header"><b>{t('ss.access.grant')}</b></p>
                                </Grid>
                                <Grid item xs={1}>:</Grid>
                                <Grid item xs>
                                    <p className="cj_w_header">Nicer globe</p>
                                </Grid>
                            </Grid> */}
                        </div>
                    </Grid>
                </Grid>
                <hr />
                <p className="p_header" style={{ position: "absolute" }}>{t('ss.select.vehicle')} : <span>{CommonFunction.zeroAppend(this.state.selectedVehicle.length)}</span></p>
                <button className="btn orange-btn" onClick={this.assignTrackerToVendor}>Submit</button>
                <div style={{ marginBottom: "20px" }}>
                    <MDBDataTable
                        displayEntries={false}
                        searching={false}
                        maxHeight="400px"
                        paging={false}
                        scrollX
                        entries={10}
                        displayEntries={false}
                        striped
                        bordered
                        data={{ columns: data1, rows: this.state.datatable }}
                    />

                </div>
                <Snackbar
                    style={{ zIndex: "999999" }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1800}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
                <div id="enter_expiry_date" className="p_modal_parent" style={{ display: "none", right: "0px" }}>
                    <div className="p_modal_boday">
                        <p className="p_header">Add Expiry Date</p>
                        <hr />
                        {this.state.expiryModalContent}
                        <Grid container spacing={4}>
                            <Grid item xs>
                                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.saveVehicleDetails}>Save</button>
                            </Grid>
                            <Grid item xs>
                                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={() => {
                                    $('#enter_expiry_date').fadeOut()
                                }}>Cancel</button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div id="confirmation_popup" className="p_modal_parent" style={{ display: "none", right: "0px" }}>
                    <div className="p_modal_boday">
                        <p className="cj_w_header">Do Really Wish To Remove The Vehicle (<b>{this.state.removeVehicleName}</b>)</p>
                        <hr />                        
                        <Grid container spacing={4}>
                            <Grid item xs>
                                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.removeTracker}>Yes</button>
                            </Grid>
                            <Grid item xs>
                                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={() => {
                                    $('#confirmation_popup').fadeOut()
                                    this.setState({
                                        removeSerialNumber : '',
                                        removeVehicleName : ''
                                    })
                                }}>No</button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="calendar_parent" id="modal_date" style={{ width: "224px", position: "absolute", zIndex: "999999" }}>
                    <Calendar
                        selectRange={false}
                        onChange={this.expiryDateCalClick}
                        value={this.state.selectDate}
                        minDate={new Date()}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(VendorIntegration);
