import React, { Component } from 'react';
import $ from 'jquery';
import './notification.scss'
import Grid from '@material-ui/core/Grid';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';


class PeopleNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noAlert: false,
      notificationData: '',
      notificationCount : 0,
      category : 0,
      notificationContent : ''
    }
  }

  componentDidMount() {
    this.getNotificationData()
  }

  getNotificationData = () => {
    $('.notification-loading').show()
    var end = new Date()
    var start = new Date()    
    // start.setTime(end.getTime() - (30 * 60 * 1000))  
    start.setHours(0,0,0,0)
    end.setHours(23,59,59,0)  
    let parameter = {start_date:  CommonFunction.ServerDateTime(start) ,end_date: CommonFunction.ServerDateTime(end)}
    // let parameter = {type : 2}
    // console.log('last 30 min para', parameter)
    // let parameter = { start_date: "2020-05-01 00:00:00", end_date: "2020-06-01 00:00:00",category : this.state.category }
    ApiUrlCall.ptAlerts(JSON.stringify(parameter)).then(response => {
      // console.log('notification data is',response)
      // return
      $('.notification-loading').hide()
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          notificationData: response.data.data,
          notificationCount : response.data.data.notifications[0].notification_list.length,
          noAlert : false,
        }, () => {          
          this.renderNotification()
          $('.notification-loading').hide()
        })
      }
      else if (response.data.response_code === 204 && response.data.is_success === true) {
        this.setState({ noAlert: true, },() => {
          $('.notification-loading').hide()
        })
      }else if(response.data.response_code == 410 && !response.data.is_success) {
        if($('#pplt_expire_dialog').is(':hidden')){
          $('#pplt_expire_dialog').slideDown()
        }
      }      
    }).catch(error => {
      $('.notification-loading').hide()
      console.log('error while fetching nottification data', error)
    })
  }

  renderNotification = () => {
    let { notificationData } = this.state
    if(notificationData){
      let notification = notificationData.notifications
      let arrayNoti = []
      
      for (let i = 0; i < notification.length; i++) {
        const dateNotification = notification[i].notification_list;                
          for (let j = 0; j < dateNotification.length; j++) {
            const element = dateNotification[j]; 
            let icon = "images/ppl_notification.png"             
            arrayNoti.push(
              <div>
                <Grid container>
                  <Grid item xs={2}>
                    <img src={icon} className="noti-icon" style={{width : "40px"}}></img>
                  </Grid>
                  <Grid item xs className="noti-content">
                    <label><b>{element.employee_name}</b></label>
                    <p>{element.bodyText}</p>
                    <span>{CommonFunction.timeFormate1(element.event_time)}</span>&nbsp;&nbsp;<span>|
                      </span>&nbsp;&nbsp;<span>{CommonFunction.dateFormate(element.event_time)}</span>
                  </Grid>
                </Grid>
                <hr />
              </div>
            )
          }
      }      
      this.setState({
        notificationContent : arrayNoti
      })
    }
  }


  render() {   
    const {t} = this.props 
    const { noAlert, notificationCount, notificationContent } = this.state    
    if(notificationContent){
      var content = notificationContent.map(data => {
        return data
      })
    }  
    return (
      <div className="cardView noti-card button_parent" style={{height : "48.3em"}}>
        <Grid container>
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('nws.alert.header')}</label>
            <p className="p_header" style={{display : "inline", float : "right"}}>
              <b>{notificationCount < 10 ? '0'+notificationCount : notificationCount}</b></p>
            <Tabs>
              <TabList>
                <Tab>All</Tab>
                {/* <Tab onClick={() => {
                  this.setState({
                    notificationContent : '',
                    category : 0,
                    notificationCount : 0
                  },() => {
                    this.getNotificationData()
                  })
                }}>All</Tab> */}
                {/* <Tab onClick={() => {
                  this.setState({
                    notificationContent : '',
                    category : 1,
                    notificationCount : 0
                  },() => {
                    this.getNotificationData()
                  })
                }}>Critical</Tab>
                <Tab onClick={() => {
                  this.setState({
                    notificationContent : '',
                    category : 2,
                    notificationCount : 0
                  },() => {
                    this.getNotificationData()
                  })
                }}>Violation</Tab>
                <Tab onClick={() => {
                  this.setState({
                    notificationContent : '',
                    category : 3,
                    notificationCount : 0
                  },() => {
                    this.getNotificationData()
                  })
                }}>Regular</Tab> */}
              </TabList>
              <TabPanel>
                <div className="noti-div" style={{ height: this.props.data, display: noAlert ? 'none' : '' }}>
                    {content}
                  </div>
                  <div className="" style={{ display: noAlert ? '' : 'none' }}>
                    <div className="na_blank_div">
                      <img src="images/no_notification.png" alt="no journey"></img>
                      <p>{t('nws.no.alert')}</p>
                    </div>
                  </div>
              </TabPanel>
              {/* <TabPanel >
                <div className="noti-div" style={{ height: this.props.data, display: noAlert ? 'none' : '' }}>
                  {content}
                </div>
                <div className="" style={{ display: noAlert ? '' : 'none' }}>
                  <div className="na_blank_div">
                    <img src="images/no_notification.png" alt="no journey"></img>
                    <p>{t('nws.no.alert')}</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="noti-div" style={{ height: this.props.data, display: noAlert ? 'none' : '' }}>
                    {content}
                  </div>
                  <div className="" style={{ display: noAlert ? '' : 'none' }}>
                    <div className="na_blank_div">
                      <img src="images/no_notification.png" alt="no journey"></img>
                      <p>{t('nws.no.alert')}</p>
                    </div>
                  </div>
              </TabPanel>
              <TabPanel>
              <div className="noti-div" style={{ height: this.props.data, display: noAlert ? 'none' : '' }}>
                  {content}
                </div>
                <div className="" style={{ display: noAlert ? '' : 'none' }}>
                  <div className="na_blank_div">
                    <img src="images/no_notification.png" alt="no journey"></img>
                    <p>{t('nws.no.alert')}</p>
                  </div>
                </div>
              </TabPanel>               */}
            </Tabs>
            <button className="btn orange-btn view_button" onClick={() => {
              this.props.data.history.push('/journey_alert')
              // console.log('props value', this.props)
            }}>{t('ct.view.all')}</button>
          </Grid>
        </Grid>
        <div className="notification-loading">
          <CircularProgress className="map_loading_icon" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PeopleNotifications);
