import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import Table from 'react-bootstrap/Table';
import Slider from '@material-ui/core/Slider';
import Navbar from '../../Config/Navbar';
// import '../../../src/common_style1.scss'
import WebCalendar from '../calendar/Calendar';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Calendar from 'react-calendar/dist/entry.nostyle'
import '../calendar/calendar.scss'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';

class DriverDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            driverIddata: '',
            //iddata :[],
            driver_name: '',
            mobile_no: '',
            driver_address: '',
            prefer_area: '',
            prefer_vehicle: '',
            driver_id: this.props.location.state.driver_id,
            driverJourney: [],
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            firstLoad: true,
            documentData : '',

        }
        this.getdriverDriverId = this.getdriverDriverId.bind(this)
    }

    componentDidMount() {
        analytics.logEvent('page_view',{
            "page_title" : "DriverDetail"
          })
          analytics.logEvent('screen_view',{
            screen_name : "DriverDetail",        
          }) 
            Amplify.configure(awswebapp);
            Analytics.record({
            name: constant.awsName, 
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_DRIVER_DETAIL'}
            });  
        var navBarHeight = $('.navBar').outerHeight();
        $('.driver_detail_parent').css({
            paddingTop: navBarHeight + 60,
            paddingLeft: "100px",
            paddingRight: "100px"
        })
        this.getdriverDriverId()
        this.getDriverJourney()
        this.getVehicleDocuments()
    }

    showDetails = (e) => {
        $('#driver_detail_div').slideToggle("slow", "swing")
        var toggleIcon = $(e.currentTarget).children()
        $(toggleIcon).toggle()
    }
    uniqueDriverId = (id) => {
        // console.log('driver id', id)
        this.props.history.push({
            pathname: '/add_driver',
            state: { driver_id: id }
        })

    }
    getVehicleDocuments = () => {
        var driver_id = this.props.location.state.driver_id
        var data = {owner_type : 2 , driver_id : driver_id}
        ApiUrlCall.getDetailDocuments(JSON.stringify(data)).then(response => {
            if(response.data.response_code === 200 && response.data.is_success === true){
                this.setState({
                    documentData : response.data.data.doc_list
                })
            }
            // console.log('document data is >>>>>>>>>>>>>', response)
        }).catch(error => {
            console.log('error while fetching document data',error)
        })
    }
    getdriverDriverId() {
        var driver_id = this.props.location.state.driver_id
        ApiUrlCall.uniqueidDriverData(driver_id).then(res => {
            // console.log(res, "???????????????")
            // console.log(res.data, "ppppppppppppppp")
            if (res.status === 200) {
                this.setState({
                    driverIddata: res.data,
                    iddata: res.data
                })
                // console.log(this.state.driverIddata, "4444444444444444");
                // console.log(this.state.iddata, "55555555555555")
            }
        }).catch(err => {
            console.log(err, "driver details page api error not found")
        })

    }


    getDriverJourney = () => {
        let { startDate, endDate } = this.state
        let driver_id = this.state.driver_id
        if (this.state.firstLoad) {
            var parameters = { start_time: "", end_time: "" }
        } else {
            var parameters = { start_time: CommonFunction.ServerDateTime(startDate), end_time: CommonFunction.ServerDateTime(endDate) }
        }
        ApiUrlCall.getDriverJourney(parameters,driver_id).then(response => {
            // console.log(response.data, 'driver journey')
            if (response.status === 200) {
                this.setState({
                    driverJourney: response.data,
                    firstLoad : false,
                },() => {
                    CommonFunction.tableCellWidth('driver_journey_table')
                })
            }
        }).catch(error => {
            console.log('error while getting driver journeys', error.response.data)
        })
    }

    goBack = () => {
        this.props.history.push('user_list')
    }
    StartdateClicked = date => {
        var isoStartDate = date
        this.setState({ startDate: isoStartDate })
        $('#start_date_cal').slideUp(200)
    }

    // this method is used to create end date into ISO and send it to server
    EnddateClicked = date => {
        var isoStartDate = date
        isoStartDate.setHours(23,59,59,0);
        this.setState({ endDate: isoStartDate }, () => {
            if (this.state.startDate > this.state.endDate) {
                this.setState({ showDateWarning: true })
                // console.log('select date is greater')
            } else {
                this.setState({ showDateWarning: false })
                // console.log('proper date is seleted.')
            }
        })
        $('#end_date_cal').slideUp(200)
    }

    showStartDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }
    showEndDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }
    driverStatus = (status) => {
        const AVAILABLE = 1;
        const ASSIGNED = 2;
        const {t} = this.props
        if(status === AVAILABLE){
          return(
            <p className="cj_w_header" style={{color : "#28a745", marginTop : "20px"}}>{t('dds.available')}</p>
          )
        }else if(status === ASSIGNED){
          return(
            <p className="cj_w_header" style={{color : "#ed7d28", marginTop : "20px"}}>{t('dds.assigned')}</p>
          )
        }
      }

    render() {
        var minDate = new Date();
        const {t} = this.props
        if(this.state.driverIddata){
            var vehicle_list = this.state.driverIddata.driver_pref_vehicle_list.map(vehicle => {
                return vehicle.name+" , "
            })
    
            var region_list = this.state.driverIddata.preferred_region_list.map(region => {
                return region.name+" , "
            })
        }

        var driver_journey = this.state.driverJourney.map(driver_journey => {
            return (
                <tr key={driver_journey.id} id={driver_journey.serial_number}>
                    <td> {CommonFunction.tableAddressEllipsis(driver_journey.journey_detail.planned_start_loc,7)}&nbsp;to&nbsp; 
                    {CommonFunction.tableAddressEllipsis(driver_journey.journey_detail.planned_end_loc,7)}</td>
                    <td> {CommonFunction.tableDateFormate(driver_journey.started_at)} {CommonFunction.timeFormate(driver_journey.started_at)} </td>
                    <td> {CommonFunction.tableDateFormate(driver_journey.ended_at)} {CommonFunction.timeFormate(driver_journey.ended_at)} </td>
                    {/* <td>NA</td> */}
                    <td>Completed</td>
                </tr>
            )
        })
        if(this.state.documentData){
            if(this.state.documentData.length > 0){
                var documents = this.state.documentData.map(doc => {
                    return(
                        <Grid item xs={6}>
                            <div className="margin_bot_20">
                                <a href={doc.url} target="_blank">
                                    <img src="images/group-6-copy-2.png" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                </a>
                                <label className="cj_w_header" style={{display : "inline", textTransform : "uppercase"}}>{doc.name}</label>
                            </div>
                        </Grid>
                    )
                })                
            }else{
                var documents = (() => {
                    return (
                        <p className="cj_w_header">{t('dds.nodocument')}</p>
                    )
                })()
            }
        }
        return (
            <div>
                <Navbar />
                <div className="driver_detail_parent">
                    <div>
                        <h3>
                            <img src="images/arrow11.png" className="car-icon vd_icon_1" class="fa fa-long-arrow-left" aria-hidden="true" onClick={this.goBack}></img>
                            &nbsp;&nbsp;{t('dds.detail')}
                        </h3>
                    </div>

                    <div className="vehicle_parent">
                        <Grid container>
                            <Grid item xs={10}>
                                <div>
                                    <div className="inline-block" style={{ marginRight: "3em" }}>
                                        <img src={this.state.driverIddata.driver_photo !== '' ? this.state.driverIddata.driver_photo :
                                            'images/group-8.png'} className="driver_profile"></img>
                                        {this.driverStatus(this.state.driverIddata.status)}
                                    </div>
                                    <div className="inline-block">
                                        <h3 className="journey_item_vehicle">{this.state.driverIddata.driver_name}</h3>
                                        <p className="cj_w_header" style={{ marginTop: "20px" }}>{this.state.driverIddata.mobile_no}</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid container item xs={2}>
                                <Grid item container onClick={() => this.uniqueDriverId(this.state.driver_id)}>
                                    <Grid item xs={2}>
                                        <img src="images/shape.png" className="vd_icon pointer"></img>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <label className="cj_w_header pointer">{t('dds.edit')}</label>
                                    </Grid>
                                    {/* <div className="margin_bot_20 inline-block pointer" style={{ marginTop: "25px" }}  
                                    >
                                    </div> */}
                                    {/* <div className="inline-block driver_score">
                                        <p className="p_header p_margin0"> {this.state.driverIddata.driver_score} <br />driver<br />Score</p>
                                    </div> */}
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <button className="btn orange-btn vl_assign">Assign</button>
                                </Grid> */}
                            </Grid>
                        </Grid>


                        <div id="driver_detail_div" style={{ display: "none" }}>
                            <hr className="custom-hr" />
                            <p className="p_header">{t('dds.otherdetail')}</p>
                            <Grid container>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('dds.address')}</p>
                                    <p className="edit_boundary_p">{this.state.driverIddata.driver_address}</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('dds.area')}</p>
                                    <p className="edit_boundary_p">
                                        {region_list}
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">{t('dds.vehicle')}</p>
                                    <p className="edit_boundary_p">
                                        {vehicle_list}
                                    </p>
                                </Grid>
                            </Grid>
                            <hr className="custom-hr" />
                            <p className="p_header">{t('dds.document')}</p>
                            <Grid container>
                                {documents}
                                {/* <Grid item xs={4}>
                                    <div className="margin_bot_20">
                                        <img src="images/group-6-copy-2.png" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                        <label className="cj_w_header" style={{display : "inline"}}>Driver License</label>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="margin_bot_20">
                                        <img src="images/group-6-copy-2.png" className="vd_icon" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                        <label className="cj_w_header" style={{display : "inline"}}>PAN Card</label>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="margin_bot_20">
                                        <img src="images/group-6-copy-2.png" className="vd_icon" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                        <label className="cj_w_header" style={{display : "inline"}}>Aadhar Card</label>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="margin_bot_20">
                                        <img src="images/group-6-copy-2.png" className="vd_icon" className="vd_icon" style={{display : "inline", marginRight : "20px"}}></img>
                                        <label className="cj_w_header" style={{display : "inline"}}>Passport</label>
                                    </div>
                                </Grid> */}
                            </Grid>
                        </div>


                        <div className="vd_open_close">
                            <div class="small_button" onClick={this.showDetails}>
                                <i class="fa fa-angle-up del_info_icon" aria-hidden="true" style={{ display: "none" }}></i>
                                <i class="fa fa-angle-down del_info_icon" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>

                    <div className="vehicle_stats">
                        <p className="p_header">{t('dds.stats')}</p>
                        <hr className="custom-hr" />
                        <Grid container spacing={1}>
                            <Grid container item xs={3}>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/dashboard.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{this.state.driverIddata.over_all_km_driven}</p>
                                    <p className="p_margin0">{t('dds.km')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs={3}>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/group-1127.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{this.state.driverIddata.total_hrs_driven}</p>
                                    <p className="p_margin0">{t('dds.hrs')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs={3}>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/group-1115.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{this.state.driverIddata.no_of_journeys}</p>
                                    <p className="p_margin0">{t('dds.journeys')}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs={3}>
                                <Grid item xl={2} xs={3} style={{ position: "relative" }}>
                                    <img src="images/group-1127.png" className="car-icon vd_icon_1"></img>
                                </Grid>
                                <Grid item xl={10} xs={9} className="stats_data">
                                    <p className="p_big_grey p_margin0">{this.state.driverIddata.no_of_violation}</p>
                                    <p className="p_margin0">{t('dds.violation')}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="vd_journeys">
                        <div style={{ padding: "20px" }}>
                            <Grid spacing={4} container>
                                <Grid item xs={2} style={{ position: "relative" }}>
                                    <p className="cj_w_header">{t('ct.startdate')}</p>
                                    <div className="input_div" onClick={this.showStartDate}>
                                        <input type="text" placeholder="Start Date" id="start_date" value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>
                                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                    </div>
                                    <div className="calendar_parent" id="start_date_cal" style={{width : "100%"}}>
                                        <Calendar
                                            selectRange={false}
                                            onChange={this.StartdateClicked}
                                            minDate={0}
                                            maxDate={minDate}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={2} style={{ position: "relative" }}>
                                    <p className="cj_w_header">{t('ct.enddate')}</p>
                                    <div className="input_div" onClick={this.showEndDate}>
                                        <input type="text" placeholder="End Date" id="end_date" value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input"></input>
                                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                    </div>
                                    <div className="calendar_parent" id="end_date_cal" style={{width : "100%"}}>
                                        <Calendar
                                            selectRange={false}
                                            onChange={this.EnddateClicked}
                                            minDate={0}
                                            maxDate={minDate}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <p>&nbsp;</p>
                                   <button className="btn orange-btn" style={{ marginTop: "0px", float: 'none' }}
                                        onClick={(e) => {
                                            this.getDriverJourney()
                                        }}>{t('ct.generate')}</button>
                                </Grid>
                                <Grid item xs>
                                    <div style={{ float: "right" }}>

                                        {/* <i class="fa fa-sort del_info_icon" aria-hidden="true"></i>  */}
                                        {/* <img src="images/group-1322.png" className="del_info_icon" ></img> */}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <div className="tableOuterDiv" style={{ width: "100%" }}>
                                <div className="tableParent" id="driver_journey_table">
                                    <Table responsive>
                                        <thead className="tableHeader BSC_extra_bold18">
                                            <tr>
                                                <th>{t('ct.table.h1')}</th>
                                                <th>{t('ct.table.h2')}</th>
                                                <th>{t('ct.table.h3')}</th>
                                                {/* <th>{t('ct.table.h4')}</th> */}
                                                <th>{t('ct.table.h5')}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="tableBody">
                                            {driver_journey}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DriverDetails);
