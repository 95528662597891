import React, { Component, Suspense } from 'react';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import './App.css';
import MainRoute from './Component/dashboard/MainRoute';
import Login from './Login';
import CircularProgress from '@material-ui/core/CircularProgress';
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from './aws-exports';
import packageJson from '../../webapp3/package.json';
import ApiUrlCall from './Component/API/apiurl';
import './i18n'
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import { Grid } from '@material-ui/core';
import StarRatings from 'react-star-ratings';
import constant from '../src/Component/constant.json'
import Footer from './Component/footer.js';
import {osName,osVersion,browserName,deviceType} from "react-device-detect";
import { EmojiNatureOutlined } from '@material-ui/icons';
import CommonFunction from '../src/Component/common_function.js'




class App extends Component {
  constructor(props) {
    super(props);
    this.otSession = React.createRef();
    this.switch = React.createRef()
    this.state = {
      toogleShare: false,
      shareContent: '',
      text: "Turn On Screen Sharing",
      checkedA: false,
      apiKey: '',
      tokenID: '',
      sessionID: '',
      message: '',
      rating: 0,
      showfeedback: false,
    }
    this.publisherEventHandlers = {
      streamCreated: event => {
        console.log('event', event)
        var connection_id = event.target.session.connection.id
        this.AcknowledgementSession(1, connection_id)
      },
      streamDestroyed: event => {
        $('#ot_dialog').hide()
        if (this.state.showfeedback) {
          $('#feedback_dialog').slideDown('slow')
        }
        this.setState({
          toogleShare: false,
          shareContent: '',
          text: 'Turn ON Screen Sharing'
        })
        console.log('Publisher stream destroyed!');
      },
      accessAllowed: event => {
        $('#ot_dialog').show()
      },
      accessDenied: event => {
        var connection_id = ""
        this.AcknowledgementSession(0, connection_id)
        this.setState({
          toogleShare: false,
          shareContent: '',
          text: 'Turn ON Screen Sharing'
        })
      }
    };

    this.sessionEventHandlers = {
      signal: (event) => {
        this.setState({
          message: "Product Expert Connected",
          showfeedback: true,
        })
        setTimeout(() => {
          $('#ot_dialog').hide()
        }, 1500);
      },
    }
  }

  componentWillMount() {
    var has_fuel = localStorage.getItem('has_fuel_sensor')
    if (has_fuel == undefined) {
      var data = {}
      const FUELSENSOR = 18
      const DIESELGENERATOR = 99
      const DIESELGENERATOROG = 100
      var has_fuel_sensor = false
      var has_diesel_generator = false
      var has_diesel_generator_og = false
      ApiUrlCall.getVehcile_Name(data).then(response => {
        //console.log('feture list', response)
        if (response.data.response_code == 200 && response.data.is_success) {
          var list = response.data.data.vehicle_list
          localStorage.setItem("tracker_list",JSON.stringify(list))
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            if (element.features_list.includes(FUELSENSOR)) {
              has_fuel_sensor = true
            }
            else if (element.features_list.includes(DIESELGENERATOR)) {
              has_diesel_generator = true
            }
            else if (element.features_list.includes(DIESELGENERATOROG)) {
              has_diesel_generator_og = true
            }
          }
          if (has_fuel_sensor) {
            localStorage.setItem('has_fuel_sensor', true)
          } else {
            localStorage.setItem('has_fuel_sensor', false)
          }
          if (has_diesel_generator) {
            localStorage.setItem('has_diesel_generator', true)
          } else {
            localStorage.setItem('has_diesel_generator', false)
          }
          if (has_diesel_generator_og) {
            localStorage.setItem('has_diesel_generator_og', true)
          } else {
            localStorage.setItem('has_diesel_generator_og', false)
          }
        }
      })
    }
  }

  componentDidMount() {
    Amplify.configure(awswebapp);
    setTimeout(() => {
      var endpointId = Analytics.getPluggable('AWSPinpoint')._config.endpointId
      localStorage.setItem('endpoint_id', endpointId)
    }, 200);
    this.getIpAddress()
   
  }

  getIpAddress = () => {
    ApiUrlCall.getIpAddress().then(response => {
      // console.log('ip address response', response)
    }).catch(error => {
      console.log('error getting ip is', error)
    })
  }

  openTokConnect = () => {
    const { apiKey, sessionID, tokenID } = this.state
    if (apiKey && sessionID && tokenID) {
      var content = (() => {
        return (
          <OTSession
            ref={this.otSession}
            apiKey={apiKey}
            sessionId={sessionID}
            token={tokenID}
            option={{ connectionEventsSuppressed: true }}
            onConnnect={() => { console.log('connected successfully') }}
            eventHandlers={this.sessionEventHandlers}
            signal={
              {
                data: 'SESSION',
                type: 'CSA_JOINED_SESSION'

              }
            }
          >
            <OTPublisher
              properties={{
                videoSource: 'screen',
                width: 0, height: 0,
              }}
              eventHandlers={this.publisherEventHandlers}
            />
            <OTStreams>
              <OTSubscriber />
            </OTStreams>
          </OTSession>
        )
      })()
      this.setState({
        shareContent: content,
      }, () => {
        this.setState({
          toogleShare: true,
          message: 'Waiting for the Product Expert to connect',
          text: 'Turn OFF Screen Sharing'

        }, () => {
          this.startHide()
        })
      })
    }
  }

  openTokDisconnect = () => {
    console.log('session is', this.otSession.current.sessionHelper.session.currentState)
    this.otSession.current.sessionHelper.session.disconnect()
    this.setState({
      toogleShare: false,
      shareContent: '',
      text: 'Turn ON Screen Sharing'
    })
  }

  AcknowledgementSession = (choice, id) => {
    var data = { session_id: this.state.sessionID, choice: choice, connection_id: id }
    console.log('data is', data)
    ApiUrlCall.openTokAck(JSON.stringify(data)).then(response => {
      console.log('response of ack is', response)
    }).catch(error => {
      console.log('error while sending acknowledgment to server', error)
    })
  }

  screenShare = (e) => {

    if (e.target.checked) {
      ApiUrlCall.openTokSession().then(response => {
        if (response.data.response_code && response.data.is_success) {
          this.setState({
            apiKey: response.data.data.api_key,
            sessionID: response.data.data.session_id,
            tokenID: response.data.data.token_id
          }, () => {
            this.openTokConnect()
          })
        }
      }).catch(error => {
        console.log('error while fetching opentok session', error)
      })
    } else {
      this.openTokDisconnect()
    }
  }


  slideUp = (e) => {
    $('.fixed-toggle').slideUp()
    $(e.currentTarget).next().show(200)
    $(e.currentTarget).hide()
    $('.icon-div').css({
      top: "0"
    })
  }

  slideDown = (e) => {
    $('.fixed-toggle').slideDown(200)
    $(e.currentTarget).prev().show()
    $(e.currentTarget).hide()
    $('.icon-div').css({
      top: "45px"
    })
  }

  startHide = (e) => {
    $('.fixed-toggle').slideUp(200)
    $('.fa-chevron-down').show()
    $(".fa-chevron-up").hide()
    $('.icon-div').css({
      top: "0"
    })
  }

  changeRating = (newRating) => {
    this.setState({
      rating: newRating
    })
  }

  sendRating = () => {
    const { rating } = this.state
    if (rating <= 0) {
      alert('Kindly give Rating and then Submit')
      return
    }
    var data = { exp_rating: this.state.rating, session_id: this.state.sessionID }
    ApiUrlCall.sendRating(JSON.stringify(data)).then(response => {
      console.log('response', response)
      if (response.data.response_code === 200 && response.data.is_success) {
        $('#feedback_dialog').fadeOut('slow')
        this.setState({
          rating: 0
        })
      }
    }).catch(error => {
      $('#feedback_dialog').fadeOut('slow')
      console.log('error while sending rating', error)
    })

    
  
  }

  render() {
   
    $(window).on("blur focus", function(e) {
      var prevType = $(this).data("prevType");  
      if (prevType != e.type && window.location.pathname!= "/login" ) {   //  reduce double fire issues
          switch (e.type) {
              case "blur":
                  localStorage.setItem('cache blur date',new Date())
                  var user_id = localStorage.getItem("user_id")
                  var sha256 = require('js-sha256');
                  var userid_enc = sha256(user_id);
                  var active_session = {
                    start_time : CommonFunction.ServerDateTime(localStorage.getItem("cache focus date")),
                    end_time: CommonFunction.ServerDateTime(localStorage.getItem("cache blur date"))
                  }

                  var device_detail = [{
                    event_id : 1,
                    unique_id : userid_enc,
                    model_name : osName,
                    value : active_session
                  }]

                  const analytics_details = {
                    data : device_detail , source : 2
                  };

                  
                  JSON.stringify(analytics_details)

                  //console.log(analytics_details)
                  ApiUrlCall.send_analytics(analytics_details).then(response => {
                    if (response.status === 200) {
                      return
                    }
                  }
                  )
                  break;
                  
              case "focus":
                  //console.log("focus",new Date())
                  localStorage.setItem('cache focus date',new Date())

                  break;
          }
      }
      $(this).data("prevType", e.type);
    })
    const { shareContent } = this.state
    const { t } = this.props
    return (
      <div className="App">
        <Suspense fallback={null}>
          <MainRoute />
          {shareContent}
        </Suspense>
        <div id="processing" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday">
            {/* <p className="modal_header">Uploading Document</p> */}
            <CircularProgress />
          </div>
        </div>

        <div id="ot_dialog" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday" style={{ textAlign: "center" }}>
            <p className="p_header">{this.state.message}</p>
            <CircularProgress />
          </div>
        </div>
        <div id="feedback_dialog" className="p_modal_parent" style={{ background: "#00000099", display: "none" }}>
          <div className="p_modal_boday" style={{ textAlign: "center" }}>
            <img src="images/screen_share.png" alt="icon"></img>
            <p className="cj_w_header"><b>SCREEN SHARING</b>&nbsp;FEEDBACK</p>
            <p>How was your experience</p>
            <StarRatings
              rating={this.state.rating}
              starRatedColor="rgb(255,215,0)"
              starHoverColor="rgb(255,215,0)"
              changeRating={this.changeRating}
              numberOfStars={5}
              starDimension="20px"
            />
            <p className="submit_rating" onClick={this.sendRating}>Submit</p>
            <div className="close_modal" onClick={() => {
              $('#feedback_dialog').fadeOut('slow')
            }}>
              <i class="fa fa-close" aria-hidden="true" style={{ position: "relative" }}></i>
            </div>
          </div>
        </div>
        {/* <Tooltip title={<span style={{fontSize : "13px"}}>{this.state.text}</span>} placement="left">
          <div className="float-btn" onClick={this.screenShare}>
              <p>{this.state.toogleShare ? "OFF" : "ON"}</p>
          </div>
       </Tooltip> */}

        {/* People tracking Trial expired dialog */}
        <div id="pplt_expire_dialog" className="p_modal_parent" style={{ display: "none", right: "0px" }}>
          <div className="p_modal_boday call_status_modal" style={{width : "35%"}}>
            <p className="p_header" style={{ textAlign: "center" }}>This feature is disabled due to non payment / non renewal. Please make the payment for further access.</p>
            <hr />
            {/* <Grid container spacing={4}>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={() => {
                  $('#sku_success').fadeOut()                  
                }}>OK</button>
              </Grid>
            </Grid> */}
          </div>
        </div>


        <div className="fixed-div">
          <div className="fixed-toggle">
            <div class="grid-container">
              <div class="grid-item">
                <Switch
                  ref={this.switch}
                  checked={this.state.toogleShare}
                  onChange={this.screenShare}
                  name="toogleShare"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
              <div class="grid-item">
                <p className="fixed-text">{this.state.text}</p>
              </div>
            </div>
          </div>
          <div className="icon-div" style={{ display: parseInt(localStorage.getItem("group")) !== constant.rent_user ? "" : "none" }}>
            <i className="fa fa-chevron-up fixed-icon" onClick={this.slideUp} style={{ display: "none" }} aria-hidden="true"></i>
            <i className="fa fa-chevron-down fixed-icon" onClick={this.slideDown} aria-hidden="true"></i>
          </div>
        </div>
      </div> 
    );
  }
}

export default App;
