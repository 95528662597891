import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import '../../src/App.css';

function Footer(/*props*/) {
    return (
      <div>
        <Container>
          <div className="footer container">
            <div>
                <span className="privacy" >Privacy Policy </span>
                <span className="terms" >Terms & Conditions</span>
                <span className="cls_version" > Version: 210107</span>
            </div>              
            <div>
              © matchpointGPS 2021
            </div>
          </div>
        </Container>
      </div>
    );
  }
  
export default Footer;
