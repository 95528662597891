import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Snackbar from '@material-ui/core/Snackbar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import VehicleTrackingSolution from './vehicle_tracking_solution'
import PeopleTrackingSolution from './people_tracking_solution'
import { withTranslation } from 'react-i18next';

const ENGINE_IMMOBILIZATION = 1
const FUELSENSOR = 18

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: '',
      subscriptionData: '',
      openAlert: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    analytics.logEvent('screen_view',{
      screen_name : "ProfileSetting"
    })
    analytics.logEvent('page_view',{
      page_title : "ProfileSetting",        
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName, 
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_PROFILE_SETTING'}
    });
    this.getUserData()
    // this.getVehicleSubsriptionData()
  }



  getUserData = () => {
    ApiUrlCall.getUserProfile().then(response => {
      if (response.data.response_code && response.data.is_success === true) {
        // console.log('user profile data', response.data.data.profile_basics)
        this.setState({
          userProfile: response.data.data.profile_basics
        })
      }
    }).catch(error => {
      console.log('error while fetching user profile data', error)
    })
  }

  getVehicleSubsriptionData = () => {
    $('#processing').show()
    ApiUrlCall.getUserSubscription().then(response => {
      // console.log('response is', response)
      if (response.data.response_code && response.data.is_success === true) {
        $('#processing').hide()
        this.setState({
          subscriptionData: response.data.data.subscriptions
        })
      }
      // console.log('sub data', response)
    }).catch(error => {
      console.log('error while fetching subsription', error)
    })
  }

  UpdateUserData = () => {
    var emailProceed = this.validateEmail('user_email')
    var noProceed = this.validatePhoneNumber('alt_number')
    var updateData = JSON.stringify(this.state.userProfile)
    // console.log('update data is', emailProceed)
    // console.log('email status is', noProceed)
    if (emailProceed && noProceed) {
      ApiUrlCall.UpdateUserProfile(updateData).then(response => {
        if (response.data.response_code === 200 && response.data.is_success === true) {
          this.modalClose()
          this.getUserData()
        }
        // console.log('reposne is update', response)
      }).catch(error => {
        console.log('error while updating user profile data', error)
      })
    } else {
      this.setState({
        openAlert: true,        
      })
    }
  }

  validateEmail = (emailField) => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var inputValue = document.getElementById(emailField).value
    if(inputValue.match(reg)){
      return true;
    }else{
      this.setState({
        errorMessage: "Invalid Email Address"
      })
      return false;
    }
  }

 validatePhoneNumber = (inputtxt) => {
  var phoneno = /^\d{10}$/;
  var inputValue = document.getElementById(inputtxt).value
  // console.log('alt number value ', inputValue)
  if(inputValue.match(phoneno)){
    return true
  }else{
    this.setState({
      errorMessage: "Invalid Contact number"
    })
    return false
  }
 }

hideScroll = () => {
  $('html, body').css({
    overflow: 'hidden',
    height: '100%'
  });
  $('.create_journey_parent').scrollTop()
}

showScroll = () => {
  $('html, body').css({
    overflow: 'scroll',
    height: '100%'
  });
}

showProfileEdit = () => {
  $('#user_p_modal').slideDown("slow", "swing")
  this.hideScroll()
}
modalClose = () => {
  $('#user_p_modal').fadeOut("slow")
  this.showScroll()
}

renewSubOpen = () => {
  $('#renew_sub').slideDown("slow", "swing")
  this.hideScroll()
}

modalCloseSub = () => {
  $('#renew_sub').fadeOut("slow")
  this.showScroll()
}

render() {

  let { userProfile } = this.state
  const {t} = this.props  

  return (
    <div className="profile_parent">
      <div className="user_profile">
        <Grid container>
          <Grid item xl={1} xs={2}>
            <img className="profile_image" src="images/user_profile_img.png" alt="icon"></img>
          </Grid>
          <Grid item xs={9}>
            <p className="journey_item_vehicle">{userProfile.name}</p>
            <p className="cj_w_header">{userProfile.primary_rmn} | {userProfile.email}</p>
            <p className="cj_w_header">{userProfile.address}</p>
          </Grid>
          <Grid item xl={2} xs={1}>
            <img src="images/edit_boundary.png" style={{ float: "right" }} alt="icon" onClick={this.showProfileEdit}></img>
          </Grid>
        </Grid>
      </div>
      <p className="p_header">My purchase</p>
      <Tabs>
          <TabList style={{ textAlign: 'left' }}>
            <Tab>Vehicle Tracking Solution</Tab>
            {/* <Tab>Personal Tracking Solution</Tab>                    */}
            {/* <Tab>Special Report</Tab> */}
          </TabList>
          <TabPanel>
            <VehicleTrackingSolution/>
          </TabPanel>
          {/* <TabPanel>
            <PeopleTrackingSolution />
          </TabPanel>           */}
        </Tabs>
      
      {/* user profile data modal*/}
      <div id="user_p_modal" className="modal_parent" style={{ marginLeft: "-100px" }}>
        <div className="modal_body" style={{ width: "60%" }}>
          <p className="modal_header renew_modal_header">{t('ss.p.edit')}</p>
          <p className="edit_p_head">{t('ss.p.detail')}</p>
          {/* user data div start */}
          <div>
            <div className="p_img_name">
              <div>
                <img src="images/user_profile_img.png" alt="icon"></img>
              </div>
              <div>
                <p className="journey_item_vehicle">{userProfile.name}</p>
                <p className="cj_w_header">{userProfile.primary_rmn}</p>
              </div>
            </div>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <p className="cj_w_header">{t('ss.p.alternate')}.</p>
                  <input type="number" className="vd_input" id="alt_number" defaultValue={userProfile.secondary_rmn} placeholder={t('ss.p.alternate')}
                    onChange={(e) => {
                      this.state.userProfile.secondary_rmn = e.currentTarget.value
                    }}></input>
                </Grid>
                <Grid item xs={6}>
                  <p className="cj_w_header">{t('ss.p.email')}</p>
                  <input type="text" defaultValue={userProfile.email} className="vd_input" id="user_email" placeholder={t('ss.p.email')}
                    onChange={(e) => {
                      this.state.userProfile.email = e.currentTarget.value
                    }}></input>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <p className="cj_w_header">{t('aeds.address')}</p>
                  <input type="text" defaultValue={userProfile.address} className="vd_input" placeholder={t('aeds.address')}
                    onChange={(e) => {
                      this.state.userProfile.address = e.currentTarget.value
                    }}></input>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <p className="cj_w_header">{t('ss.p.city')}</p>
                  <input type="text" defaultValue={userProfile.city} className="vd_input" placeholder={t('ss.p.city')}
                    onChange={(e) => {
                      this.state.userProfile.city = e.currentTarget.value
                    }}></input>
                </Grid>
                <Grid item xs={6}>
                  <p className="cj_w_header">{t('ss.p.pincode')}</p>
                  <input type="text" defaultValue={userProfile.pincode} className="vd_input" placeholder={t('ss.p.pincode')}
                    onChange={(e) => {
                      this.state.userProfile.pincode = e.currentTarget.value
                    }}></input>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <p className="cj_w_header">{t('ss.p.state')}</p>
                  <input type="text" defaultValue={userProfile.state} className="vd_input" placeholder={t('ss.p.state')}
                    onChange={(e) => {
                      this.state.userProfile.state = e.currentTarget.value
                    }}></input>
                </Grid>
              </Grid>
            </div>
            <div>
              <p className="edit_p_head">{t('ss.p.info')}<br /> {t('ss.p.help')}. <span style={{ color: "#f4811f" }}>9029070083</span> (24X7)</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <button className="btn orange-btn sub_btn" style={{ float: "none", paddingLeft: "40px", paddingRight: "40px" }}
                onClick={this.UpdateUserData}>{t('ct.update')}</button>
            </div>
          </div>
          {/* user data div end */}
          <div className="close_modal" onClick={this.modalClose}>
            <i class="fa fa-close" aria-hidden="true"></i>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
      {/* user profile data modal end*/}

      {/* renew subscription modal dailog start */}
      <div id="renew_sub" className="modal_parent" style={{ marginLeft: "-100px" }}>
        <div className="modal_body renew_modal_body" style={{ width: "60%" }}>
          <p className="modal_header renew_modal_header">{t('ss.p.renew')} : <span className="renew_modal_header_car">FIGO</span></p>
          <p className="p_header">{t('ss.p.package')}</p>
          <div className="renew_header">
            <Grid container>
              <Grid item xs={5}>
                <p className="cj_w_header">{t('ss.p.sub.period')}</p>
              </Grid>
              <Grid item xs={5}>
                <p className="cj_w_header">{t('ss.p.price')}</p>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item xs={5}>
                <p className="renew_price_year">1 Year</p>
              </Grid>
              <Grid item xs={5}>
                <p className="renew_price_year">3600/-</p>
              </Grid>
              <Grid item xs={2}>
                <button className="btn orange-btn">{t('ss.p.purchase')}</button>
              </Grid>
            </Grid>
          </div>
          <div className="close_modal" onClick={this.modalCloseSub}>
            <i class="fa fa-close" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      {/* renew subscription modal dailog end*/}
    </div>
  );
}
}

export default withTranslation()(Profile);