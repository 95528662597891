import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import Navbar from '../../Config/Navbar';
import CommonFunction from '../common_function';
import Snackbar from '@material-ui/core/Snackbar';
import ApiUrlCall from '../API/apiurl'
import { withTranslation } from 'react-i18next';

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleContent: [],
            contentCounter: 0,
            delectContent: [],
            username: '',
            userRole: 0,
            mobileNumber: '',
            emailAddress: '',
            SearchVehicleList: [],
            vehicleInputSelected: '',
            openAlert: false,
            errorMessage: '',
            htmlData : [],
            vehicleData : [],
            is_permanent : true,
        }
    }

    componentDidMount() {
        var navBarHeight = $('.navBar').outerHeight()
        $('.createUser').css({
            paddingTop: navBarHeight + 60,
        })
        if(this.props.location.state !== undefined){
            this.getUserDetails(this.props.location.state.user_id)
        }else{
            this.selectTimeType()
        }
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/user_list'
        })
    }

    getUserDetails = (user_id) => {
        $('#processing').show()
        var data = {invited_id : user_id}
        ApiUrlCall.inviteUserDetails(JSON.stringify(data)).then(response => {
            //console.log('datt response is', response)
            $('#processing').hide()
            if(response.data.response_code === 200 && response.data.is_success){
                this.setState({
                    username : response.data.data.user_details.name,
                    userRole : response.data.data.user_details.role_id,
                    emailAddress : response.data.data.user_details.email,
                    is_permanent : this.valueToBoolean(response.data.data.user_details.vehicle_assignment_type),
                    mobileNumber : response.data.data.user_details.mobile_number,
                    vehicleData : response.data.data.user_details.vehicle_list,
                    contentCounter : response.data.data.user_details.vehicle_list.length,
                },() => {
                    this.selectTimeType()
                    this.editCreateContent()
                })
            }else{
                $('#processing').hide()
            }
        }).catch(error => {  
            $('#processing').hide()
            console.log('error while getting user details', error)
        })
    }

    selectDeleteContent = (e) => {
        const { delectContent } = this.state
        var check = e.currentTarget.checked
        var index = $(e.currentTarget).attr('name')        
        if (check) {
            delectContent.push(index)
            this.forceUpdate()
        } else {
            delectContent.splice(index, 1)
            this.forceUpdate()
        }
    }

    searchVehicle = (e) => {
        var searchText = e.currentTarget.value
        var height = $(e.currentTarget).outerHeight()
        var width = $(e.currentTarget).width()
        var top = $(e.currentTarget).offset().top + height
        var left = $(e.currentTarget).offset().left
        var id = $(e.currentTarget).prop('id')
        var index = $(e.currentTarget).attr('name')
        this.setState({
            vehicleInputSelected: id
        })
        if (searchText.length > 1) {
            $('#vehicle_list').attr('name',index)
            $('#vehicle_list').css({
                top: top,
                left: left,
                width: width
            })
            var data = { next_count: 0, search: e.currentTarget.value, search_column : "vehicle_name" }
            ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
                if (response.data.response_code === 200 && response.data.is_success) {
                    $(e.currentTarget).next().slideDown()
                    this.setState({
                        SearchVehicleList: response.data.data.vehicle_summary_list
                    }, () => {
                        $('#vehicle_list').slideDown()
                    })
                }
            }).catch(error => {
                console.log('error while fetching vehicle list', error)
            })
        } else {
            this.setState({
                SearchVehicleList: []
            }, () => {
                $('#vehicle_list').slideUp()
            })
        }
    }

    appendValue = () => {
        const {htmlData} = this.state
        var child = $('.div_driver_vehicle')        
        for (let i = 0; i < child.length; i++) {
            if(htmlData[i] !== undefined ){
                const element = child[i];                
                $(element).find('.vehicle').val(htmlData[i].name)
                $(element).find('.serial_number').text(htmlData[i].serial_number)
                $(element).find('.duration').attr('value',htmlData[i].duration)
            }
        }
        this.forceUpdate()
    }

    vehicleListItem = (e) => {
        const { htmlData } = this.state
        var serial_number = $(e.currentTarget).prop('id')
        var vehicle_name = $(e.currentTarget).text()   
        var index = $(e.currentTarget).parent().attr('name')        
        if(htmlData[index] == undefined){
            var data = {serial_number : serial_number , name : vehicle_name, duration : "1"}
            htmlData.push(data)
        }else{
            htmlData[index].serial_number = serial_number
            htmlData[index].name = vehicle_name            
        }
        this.appendValue()
        $('#vehicle_list').slideUp()        
    }

    addDuration = (e) => {
        const {htmlData} = this.state
        var value = e.currentTarget.value
        var index = $(e.currentTarget).attr('name')
        htmlData[index].duration = value
        this.appendValue()
    }

    editCreateContent = () => {
        const { t } = this.props
        const { contentCounter, vehicleData} = this.state
        var array = [] 
        var createData = []       
        for (let i = 0; i < contentCounter; i++) {
            var data = {serial_number : vehicleData[i].serial_number , name : vehicleData[i].name, duration : vehicleData[i].duration}
            array.push(
                <Grid container className="div_driver_vehicle" id={'vehicle_content_'+i} spacing={2} item xs={6}>
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                        <p>&nbsp;</p>
                        <input type="checkbox" name={i} className="select_vehicle" onChange={this.selectDeleteContent}></input>
                    </Grid>
                    <Grid item xs style={{ position: "relative" }}>
                        <p>{t('os.vehicles')}</p>
                        <input type="text" placeholder="Search Vehicle" name={i} id={"vehicle_"+i} className="journey_input vehicle" autoComplete="off"
                            onChange={this.searchVehicle} defaultValue={vehicleData[i].name}></input>
                        <span className="serial_number" style={{ display: "none" }}>{vehicleData[i].serial_number}</span>
                    </Grid>
                    <Grid item xs style={{ position: "relative", display : this.state.is_permanent ? "none" : ""}}>
                        <p>{t('iv.duration')}</p>
                        <input type="text" placeholder="Enter Days" name={i} className="journey_input duration" 
                        onChange={this.addDuration} autoComplete="off" defaultValue={vehicleData[i].duration}></input>
                    </Grid>
                </Grid>
            )
            createData.push(data)
        }        
        this.setState({
            vehicleContent: array,
            htmlData : createData        
        })
    }

    selectTimeType = () => {
        const {is_permanent} = this.state
        // console.log('is_permanend', is_permanent)
        if(is_permanent){
            $('#preselect').attr('checked',true)
        }else{
            $('#postselect').attr('checked',true)
        }
    }

    createContent = () => {
        const { t } = this.props
        const { contentCounter , vehicleData} = this.state
        var array = []                
        for (let i = 0; i < contentCounter; i++) {
            array.push(
                <Grid container className="div_driver_vehicle" id={'vehicle_content_'+i} spacing={2} item xs={6}>
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                        <p>&nbsp;</p>
                        <input type="checkbox" name={i} className="select_vehicle" onChange={this.selectDeleteContent}></input>
                    </Grid>
                    <Grid item xs style={{ position: "relative" }}>
                        <p>{t('os.vehicles')}</p>
                        <input type="text" placeholder="Search Vehicle" name={i} id={"vehicle_"+i} className="journey_input vehicle" autoComplete="off"
                            onChange={this.searchVehicle}></input>
                        <span className="serial_number" style={{ display: "none" }}></span>    
                    </Grid>
                    <Grid item xs style={{ position: "relative", display : this.state.is_permanent ? "none" : ""}}>
                        <p>{t('iv.duration')}</p>
                        <input type="text" placeholder="Enter Days" name={i} className="journey_input duration" onChange={this.addDuration} autoComplete="off"></input>
                    </Grid>
                </Grid>
            )
        }        
        this.setState({
            vehicleContent: array        
        },() => {
            setTimeout(() => {
                this.setVehicleNumber()                
            }, 1000);
        })
    }

    setVehicleNumber = () => {
        const {vehicleData} = this.state               
        if(vehicleData.length > 0){            
            for (let i = 0; i < vehicleData.length; i++) {
                const element = vehicleData[i];                
                $('#vehicle_content_'+i).find('.serial_number').text(element.serial_number)
            }
        }
    }

    assignVehicle = () => {
        const { contentCounter } = this.state        
        this.setState({
            contentCounter: contentCounter + 1
        }, () => { this.createContent() })
    }

    uncheckCheckbox = () => {
        var contentLenght = $('.select_vehicle')
        for (let i = 0; i < contentLenght.length; i++) {
            const element = contentLenght[i];
            $(element).prop('checked', false)
        }        
    }        

    deleteSelection = () => {
        const { delectContent, vehicleContent, contentCounter, htmlData, vehicleData } = this.state
        var newArray
        for (let i = 0; i < delectContent.length; i++) {
            var element = delectContent[i]    
            // newArray = vehicleContent.filter(item => item.props.id !== 'vehicle_content_'+element)  
            vehicleContent.splice(element,1)       
            htmlData.splice(element,1)
            vehicleData.splice(element,1)
        }
        // return        
        this.forceUpdate() 
        // console.log('vehicle data is', vehicleData)       
        this.setState({
            contentCounter: contentCounter - delectContent.length,                
            delectContent : []         
        }, () => {
            // this.createContent()            
            this.appendValue()
            this.uncheckCheckbox()
        })
    }

    createVehicleDuration = () => {
        var driverVehicleDiv = $('.div_driver_vehicle')
        var array = []
        if (driverVehicleDiv.length > 0) {
            for (let i = 0; i < driverVehicleDiv.length; i++) {
                const element = driverVehicleDiv[i];
                var serial_number = $(element).find('.serial_number').text()
                var duration = $(element).find('.duration').val()
                if(this.state.is_permanent){
                    var data = { serial_number: serial_number, duration: "" }
                }else{
                    var data = { serial_number: serial_number, duration: duration }
                }
                array.push(data)
            }
            return array
        } else {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Select Vehicle and Driver"
            })
            return
        }
    }

    validateVehicleDuration = () => {
        var child_div = $('.div_driver_vehicle')
        var flag = true        
        for (let i = 0; i < child_div.length; i++) {
            const element = child_div[i];
            var vehicleContent = $(element).find('.vehicle').val()
            var durationContent = $(element).find('.duration').val()
            if(!vehicleContent){
                $(element).find('.vehicle').css({
                    border : "1px solid red"
                })   
                flag = false
                return
            }else{
                $(element).find('.vehicle').css({
                    border : "none"
                })
            }
            if(!this.state.is_permanent){                
                if(!durationContent){
                    $(element).find('.duration').css({
                        border : "1px solid red"
                    })                
                    flag = false
                    return
                }else{
                    if(parseInt(durationContent) === 0){
                        $(element).find('.duration').css({
                            border : "1px solid red"
                        })                
                        flag = false
                        return
                    }else{
                        $(element).find('.duration').css({
                            border : "none"
                        })
                    }
                }                
            }else{
                flag = true
            }
        }        
        return flag

    }

    booleanToValue = (value) => {
        if(value){
            return 1
        }else{
            return 2
        }
    }

    valueToBoolean = (value) => {
        if(value === 1){
            return true
        }else{
            return false
        }
    }

    sendInvite = () => {
        const { userRole, username, emailAddress, mobileNumber } = this.state
        var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var vehicleData = this.createVehicleDuration()
        var vehicleDurationFlag = this.validateVehicleDuration()
        if (!username) {
            $('#username_input').css({
                border: "1px solid red"
            })
            return
        } else {
            $('#username_input').css({
                border: "none"
            })
        }
        if (mobileNumber.length !== 10) {
            $('#number_input').css({
                border: "1px solid red"
            })
            return
        } else {
            $('#number_input').css({
                border: "none"
            })
        }
        if (emailAddress) {
            if (!emailAddress.match(reg)) {
                $('#email_input').css({
                    border: "1px solid red"
                })
                return
            } else {
                $('#email_input').css({
                    border: "none"
                })
            }
        } 
        // console.log('vehicle dutation flg', vehicleDurationFlag)               
        if(vehicleDurationFlag){
            $('#processing').show()            
            // check whether user is trying to update or create new user details
            if(this.props.location.state == undefined){
                var data = { name: username, role_id: userRole, email: emailAddress, mobile_number: mobileNumber, 
                    vehicle_list: vehicleData, vehicle_assignment_type : this.booleanToValue(this.state.is_permanent) }
                    // console.log('data is', data)
                ApiUrlCall.createInvite(JSON.stringify(data)).then(response => {                
                    $('#processing').hide()
                    if(response.data.response_code === 200 && response.data.is_success){
                        this.props.history.push({
                            pathname : "/user_list"
                        })
                    }else{      
                        $('#processing').hide()              
                        this.setState({
                            openAlert : true,
                            errorMessage : response.data.response_message
                        })
                    }
                }).catch(error => {
                    $('#processing').hide()

                    if(error.response !== undefined && error.response.status == 409){
                        this.setState({
                            openAlert : true,
                            errorMessage : "Mobile Number Already Exists"
                        })
                    }
                    console.log('error while creating new user',error.response)
                })
            }else{
                var data = {invited_id : this.props.location.state.user_id, name: username, role_id: userRole, email: emailAddress, mobile_number: mobileNumber, 
                    vehicle_list: vehicleData, vehicle_assignment_type : this.booleanToValue(this.state.is_permanent) }
                // console.log('edit details data is', data)                
                ApiUrlCall.inviteUserUpdateDetails(JSON.stringify(data)).then(response => {
                    $('#processing').hide()
                    // console.log('response edit is', response)
                    if(response.data.response_code === 200 && response.data.is_success){
                        this.props.history.push({
                            pathname : "/user_list"
                        })
                    }else{  
                        $('#processing').hide()                  
                        this.setState({
                            openAlert : true,
                            errorMessage : response.data.response_message
                        })
                    }
                }).catch(error => {
                    $('#processing').hide()
                    console.log('error while update user details', error)
                })
            }
        }
    }

    addDuration = () => {
        const {htmlData} = this.state
        for (let i = 0; i < htmlData.length; i++) {
            const element = htmlData[i];
            element.duration = 1
        }
        this.forceUpdate()        
        this.appendValue()
    }

    removeDuration = () => {
        const {htmlData} = this.state
        for (let i = 0; i < htmlData.length; i++) {
            const element = htmlData[i];
            element.duration = ""
        }
        this.appendValue()
    }

    selectTimebased = () => {
        var child_div = $('.div_driver_vehicle')
        this.setState({
            is_permanent : false,
        },() => {
            $(child_div).find('.duration').parent().show()
            $(child_div).find('.duration').val("1")
            this.addDuration()
        })
    }

    selectPermanent = () => {
        var child_div = $('.div_driver_vehicle')
        this.setState({
            is_permanent : true,
        },() => {
            $(child_div).find('.duration').val("")
            $(child_div).find('.duration').parent().hide()
            this.removeDuration()
        })
    }

    render() {
        const { t } = this.props
        // console.log('role id is', this.state.userRole)
        const { vehicleContent, SearchVehicleList } = this.state
        if (vehicleContent.length > 0) {
            var vehicle_content = vehicleContent.map(content => {
                return content
            })
        }
        if (SearchVehicleList.length > 0) {
            var vehicle_list = SearchVehicleList.map(list => {
                return <p id={list.serial_number} onClick={this.vehicleListItem}>{list.vehicle_name}</p>
            })
        }
        return (
            <div>
                <Navbar />
                <div className="createUser">
                    <div>
                        <h3 style={{ fontWeight: "bold" }}>
                            <img src="images/arrow11.png" className="car-icon vd_icon_1" class="fa fa-long-arrow-left" aria-hidden="true" onClick={this.goBack}></img>
                            &nbsp;&nbsp;{t('iv.invite.user')}
                        </h3>
                    </div>
                    <div className="vehicle_stats">
                        <Grid container spacing={4}>
                            <Grid container spacing={2} item xs={6}>
                                <Grid item xs={4}>
                                    <p className="p_header"><b>{t('iv.username')}*</b></p>
                                </Grid>
                                <Grid item xs={8}>
                                    <input type="text" className="journey_input" id="username_input" defaultValue={this.state.username} autoComplete="off" placeholder={t('iv.username')}
                                        onChange={(e) => {
                                            this.setState({
                                                username: e.currentTarget.value
                                            })
                                        }}></input>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} item xs={6}>
                                <Grid item xs={4}>
                                    <p className="p_header"><b>{t('iv.role')}*</b></p>
                                </Grid>
                                <Grid item xs={8} style={{ position: "relative" }}>
                                    <select className="status-filter" value={this.state.userRole} style={{ width: "96%",display : localStorage.getItem('group') != 28 ? "" : "none" }}
                                        onChange={(e) => {
                                            if (e.currentTarget.value!="111")
                                            {
                                                this.setState({
                                                    userRole: e.currentTarget.value
                                                })
                                            }
                                            else{
                                                window.location="/add_driver"
                                            }
                                        }}>
                                        <option value="0">Select User</option>
                                        <option value="4">{t('iv.pd')}</option>
                                        <option value="2">{t('iv.third')}</option>
                                        {/* <option value="3">{t('iv.temp')}</option> */}
                                        <option value="3">{t('iv.demo')}</option>
                                        {/* <option value="5">{t('iv.management')}</option> */}
                                        <option value="111">Add Driver</option>
                                    </select>
                                    <select className="status-filter" value={this.state.userRole} style={{ width: "96%",display : localStorage.getItem('group') == 28 ? "" : "none"}}
                                        onChange={(e) => {
                                            if (e.currentTarget.value!="111")
                                            {
                                                this.setState({
                                                    userRole: e.currentTarget.value
                                                })
                                            }
                                            else{
                                                window.location="/add_driver"
                                            }
                                        }}>
                                        <option value="0">Select User</option>
                                        <option value="2">{t('iv.third')}</option>
                                        <option value="111">Add Driver</option>
                                    </select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} item xs={6}>
                                <Grid item xs={4}>
                                    <p className="p_header"><b>{t('iv.number')}*</b></p>
                                </Grid>
                                <Grid item xs={8}>
                                    <input type="number" className="journey_input" id="number_input" defaultValue={this.state.mobileNumber} placeholder={t('iv.number')}
                                        onChange={(e) => {
                                            this.setState({
                                                mobileNumber: e.currentTarget.value
                                            })
                                        }}></input>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} item xs={6}>
                                <Grid item xs={4}>
                                    <p className="p_header"><b>{t('iv.email')}</b></p>
                                </Grid>
                                <Grid item xs={8}>
                                    <input type="text" className="journey_input" id="email_input" defaultValue={this.state.emailAddress} placeholder={t('iv.email')}
                                        onChange={(e) => {
                                            this.setState({
                                                emailAddress: e.currentTarget.value
                                            })
                                        }}></input>
                                </Grid>
                            </Grid>
                        </Grid>
                        <p className="p_header v_start_journey" id="asign_click" onClick={this.assignVehicle} style={{ marginTop: "15px", width: "fit-content" }}>{t('iv.assign')}</p>
                        <Grid container style={{ marginBottom: "20px" }}>
                            <Grid item xs>
                                <label class="radio-inline cj_w_header" style={{ fontSize: "18px" }}>
                                    <input type="radio" id="preselect" name="optradio" onClick={this.selectPermanent}></input>{t('iv.permanent')}
                                </label>
                                <label class="radio-inline cj_w_header" style={{ fontSize: "18px" }}>
                                    <input type="radio" id="postselect" name="optradio" onClick={this.selectTimebased}></input>{t('iv.timebased')}
                                </label>
                            </Grid>
                            <Grid item xs>
                                <button style={{ display: vehicleContent.length > 0 ? "" : "none" }} className="btn orange-btn"
                                    onClick={this.deleteSelection}>Delete</button>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            {vehicle_content}
                            <div id="vehicle_list" className="dropdown_div">
                                {vehicle_list}
                            </div>
                        </Grid>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <button className="btn orange-btn" style={{ float: "none" }} onClick={this.sendInvite}>{t('iv.send')}</button>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={2000}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(CreateUser);
