import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import Map from '../map/map'
import './jsw.scss'
import { Grid } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';


const L = window.L
const MOVING_ICON = 'images/vehicle_icon/Car moving.png'
const IDLING_ICON = 'images/vehicle_icon/Car idling.png'
const PARKED_ICON = 'images/vehicle_icon/Car parked.png'
const CONNECTION_LOST_ICON = 'images/vehicle_icon/Car lost connection.png'

class JwsComponent extends Component {
  constructor(props){
    super(props);
    this.mapRef = React.createRef()
    this.state = {
        startDate : new Date(),
        endDate : new Date(),
        portArea : [],
        loadingData : '',
        loadingLocation : [],
        // mapContent : '',
        markers : [],
        notificationCount : 0,
        searchList : '',
        vehicleLiveTrack : '',
        singleTrackingNumber : '',        
        searchSelectedVehicle : '',
        openAlert : false,
        errorMessage : '',
    }
  }

  componentDidMount(){
      setTimeout(() => {
        var navBarHeight = $('.navBar').outerHeight();        
        var mapHeight = $(window).outerHeight()
        $('.jws_parent').css({
        paddingTop: navBarHeight,
        height : mapHeight
        })
        window.dispatchEvent(new Event('resize'));           
    }, 1000);      
    this.getPortData()
    this.setIntervalLoadingPointData()
    this.setIntervalLiveData()
    this.setIntervalNotificationCount()
    // this.observerFunction()
    
  }
  

  componentWillUnmount(){
      clearInterval(this.interval)
      clearInterval(this.singleInterval)
      clearInterval(this.notificationInterval)
      clearInterval(this.loadingDataInterval)
  }

  setIntervalLiveData = () => {
    $('#processing').show()    
    this.getVehicleMarker()
    this.interval = setInterval(this.getVehicleMarker, 10000)
  }

  setIntervalNotificationCount = () => {
    this.getNotificationListData()
    this.notificationInterval = setInterval(this.getNotificationListData, 120000)
  }

  setIntervalLoadingPointData = () => {
    this.getLoadPointData()
    this.loadingDataInterval = setInterval(this.getLoadPointData, 30000)
  }

  getNotificationListData = () => {     
    var data = {filter_type: 1 , frd_number: "" , registration_number : "", start_date : CommonFunction.ServerDateTime(this.state.startDate.setHours(0,0,0,0)),
    end_date : CommonFunction.ServerDateTime(this.state.endDate.setHours(23,59,59,0))}    
    // console.log('data is', data)
    ApiUrlCall.jswNotificationList(JSON.stringify(data)).then(response => {
        // console.log('notification repsonse is', response)
        if(response.data.response_code === 200 && response.data.is_success){            
            this.setState({
                notificationCount: response.data.data.notification_count,
            })
        }
    }).catch(error => {        
        console.log('error while fetching notification data', error)
    })
}

  getVehicleMarker = () => { 
    this.mounted = true   
    // recuringData = setInterval(() => {  
        ApiUrlCall.jswActiveLiveTrack().then(response => {      
        // console.log('getting vehicle detail in vehicle list', response) 
        if(response.data.response_code === 200 && response.data.is_success){        
          $('#processing').hide()   
          this.setState({
            vehicleLiveTrack : response.data.data.live_track                    
          }, () => {                    
            this.drawMarker()    
          })
        }else{
          this.setState({
            openAlert : true,
            errorMessage : response.data.response_message
          })
          $('#processing').hide()          
        }
      }).catch(error => {
        $('#processing').hide()
        clearInterval(this.interval)
        console.log('error while fetching data is', error.response)
      })
    // },10000)        
  }

  drawMarker = () => {
    const array = []
    const icon = [MOVING_ICON,IDLING_ICON,PARKED_ICON,CONNECTION_LOST_ICON]
    if(this.state.vehicleLiveTrack){              
      for(let i = 0; i< this.state.vehicleLiveTrack.length;i++){
        var j = (() => {            
          if(this.state.vehicleLiveTrack[i].vehicle_state === 2 || this.state.vehicleLiveTrack[i].vehicle_state === 7){
            return 0
          }else if(this.state.vehicleLiveTrack[i].vehicle_state === 3){
            return 1
          }else if(this.state.vehicleLiveTrack[i].vehicle_state === 4 || this.state.vehicleLiveTrack[i].vehicle_state === 10){
            return 2
          }else{
            return 3
          }
        })()  
        array.push(
          {
            position : [this.state.vehicleLiveTrack[i].lat , this.state.vehicleLiveTrack[i].lng],
            rotateMarker: true,
            icon : L.icon({
              iconUrl: icon[j],
              iconRetinaUrl: icon[j],
              iconSize: [40, 40],
              popupAnchor: [-3, -15]
            }),
            draggable : false,
            // title : this.state.vehicleLiveTrack[i].name,
            title : this.state.vehicleLiveTrack[i].name,
            onClick: () => {},
            onDragend: ()=> {},            
          }
        )
      }
    } 
    this.setState({
      markers : array,                                                              
    }) 
  }

  showWindow = () => {
    $('.slide_win').toggleClass('close');
    $('.open_win_icon').children().toggle()
    $('#search_legend_container').fadeToggle()
  }

  openNotification = () => {
    this.props.history.push({
        pathname : '/notification'        
      })
  }

  

  searchVehicle = (e) => {
    var searchText = e.currentTarget.value
    if(searchText.length > 3){
      var data = {search_str : searchText.trim(), is_dashboard : 1}
      ApiUrlCall.searchVehicleNumber(JSON.stringify(data)).then(response => {
        // console.log('search dahboard hit', response)
        if(response.data.response_code === 200 && response.data.is_success){
          this.setState({
            searchList : response.data.data
          })
        }

      }).catch(error => {
        console.log("error while searching vehicle details", error)
      })  
      $(e.currentTarget).next().slideDown("slow")

    }else{
        $(e.currentTarget).next().slideUp()
    }
  }
  

  getPortData = () => {
      ApiUrlCall.getFullArea().then(response => {
        // console.log('port area',response)
        if(response.data.response_code === 200 && response.data.is_success){
            this.setState({
                portArea : response.data.data
            },() => {
                this.getLoadPointLocation()
            })
        }
      }).catch(error => {
          console.log('error while fetching port area',error)
      })
  }

  getLoadPointLocation = () => {
    ApiUrlCall.getLoadingPointsLocation().then(response => {
      // console.log('point location',response)
      if(response.data.response_code === 200 && response.data.is_success){
            this.setState({
                loadingLocation : response.data.data
            // },() => {
            //     this.loadMap()                
            })
      }
    }).catch(error => {
        console.log('error while fetching loading points location',error)
    })
  }

  getLoadPointData = () => {
    ApiUrlCall.getLoadingPointsData().then(response => {
      // console.log('point data',response)
      if(response.data.response_code === 200 && response.data.is_success){
            this.setState({
                loadingData : response.data.data
            })
      }
    }).catch(error => {
        console.log('error while fetching loading point data',error)
    })
  }

  zoomToPort = (e) => {    
    $('#processing').show()  
    var location_lat = $(e.currentTarget).find('.lat').text()
    var location_lng = $(e.currentTarget).find('.lng').text()
    var zoom_location = [location_lat, location_lng]    
    this.setState({
        portArea : [zoom_location],            
    },() => {
        $('#reset_filter').fadeIn()
        $('#processing').hide()                 
    })    
  }

  showAllPanel = () => {
    $('#search_port').val("")
    $('.slide_win').find('.port_panel').css({display : ""})    
  }

  // loadMap = () => {
  //   const {portArea, loadingLocation, loadingData, markers} = this.state
  //   var content = []
  //   if(portArea && loadingLocation && markers){
  //       // console.log(portArea,'--------',loadingLocation)
  //       content.push(
            
  //       )        
  //   }
  //   if(content.length > 0){
  //       this.setState({
  //           mapContent : content
  //       },() => {
  //           $('#processing').hide()  
  //       })
  //   }
  // }

  allPortData = () => {
      this.setState({
          portArea : '',
          mapContent : ''
      },() => {
        this.showAllPanel()
      })
      this.getPortData()
      $('#reset_filter').fadeOut()
  }

  removeSearchVehicle = () => {
    this.setState({
      portArea : '',
      mapContent : '',
      searchSelectedVehicle : '',
      singleTrackingNumber : ''
    },() => {   
      $('#search_vehicle').fadeOut()   
      clearInterval(this.singleInterval)
      this.setIntervalLiveData()
      this.getPortData()
    })
  }

  searchContent = (input, table, column) => {
    var search_input, search_filter, search_table, search_tbody, search_tr, search_td, i, txtValue;
    search_input = document.getElementById(input);
    search_filter = search_input.value.toUpperCase();
    search_table = document.getElementsByClassName(table);   
    if(search_filter.length > 0){      
      $('#reset_filter').show()
    }else{      
      $('#reset_filter').fadeOut()
    }
    for (i = 0; i < search_table.length; i++) {
        search_td = $(search_table[i]).find('.port_name')
        if (search_td) {
            txtValue = $(search_td).text()            
        if (txtValue.toUpperCase().indexOf(search_filter) > -1) {
            search_table[i].style.display = "";
        } else {
            search_table[i].style.display = "none";
        }
      }
    }
  }

  getSingleTrackerLiveTracking = () => {
    // console.log('time interval', this.singleInterval)
    var data = {serial_number : this.state.singleTrackingNumber}
    ApiUrlCall.getSingleVehicleLiveTrack(JSON.stringify(data)).then(response => {
      if(response.data.response_code === 200 && response.data.is_success){
        $('#processing').hide()
        // console.log('single live track data', response)
        this.setState({
          vehicleLiveTrack : [response.data.data],          
          portArea : [[response.data.data.lat, response.data.data.lng]]
        },() => {
          clearInterval(this.interval)
          this.drawMarker()
        })
      }
    }).catch(error => {
      $('#processing').hide()
      clearInterval(this.singleInterval)
      console.log("error while fetching single tracking data", error)
    })
  }
  
  searchDataClick = (e) => {
    $('#processing').show()
    var serial_number = $(e.currentTarget).prop('id')
    var vehicle_details = $(e.currentTarget).text()
    // console.log('serial_number', serial_number)
    this.setState({
      singleTrackingNumber : serial_number,
      searchSelectedVehicle : vehicle_details
    },() => {
      this.getSingleTrackerLiveTracking()    
      this.singleInterval = setInterval(this.getSingleTrackerLiveTracking, 12000)
    })

  }

  render(){
    const {portArea, loadingLocation, loadingData, mapContent, searchList, searchSelectedVehicle} = this.state    
    const {t} = this.props
    if(mapContent){
        var portMap = mapContent.map(data => {
            return data
        })
    }
    if(loadingData){
        var loadPoints = loadingData.location_list.map(data => {
            return(
                <Grid container className="port_panel" onClick={this.zoomToPort}>
                    <span className="lat" style={{display : "none"}}>{data.location[0]}</span>
                    <span className="lng" style={{display : "none"}}>{data.location[1]}</span>
                    <Grid item xs={2} style={{backgroundColor : data.color_code}}>                            
                    </Grid>
                    <Grid item xs style={{borderRight : "1px solid #cccccc"}}>
                        <p className="cj_w_header port_name">{data.location_name}</p>
                    </Grid>
                    <Grid item xs={2}>
                        <p className="p_big_grey" style={{margin : "0px", color : data.limit_level == 1 ? "green" : 
                      data.limit_level == 2 ? "yellow" : data.limit_level == 3 ? "red" : "#828282"}}>{CommonFunction.zeroAppend(data.location_count)}</p>    
                    </Grid>
                </Grid>
            )
        })
    }
    if(searchList){
      if(searchList.length > 0){
        var search_data_list = searchList.map(data => {
          return(
            <div>
              <p id={data.serial_number} onClick={this.searchDataClick}>{data.vehicle_name} / {data.frd_fir_number}</p>
              <hr/>
            </div>
          )
        })
      }else{
        var search_data_list = (() => {
          return(
            <div>
              <p>No Data Available</p>
            </div>
          )
        })()
      }   
    }

    if(searchSelectedVehicle){
      var split_details = searchSelectedVehicle.split("/")
      var unique_number = split_details[1]
      var vehicle_name = split_details[0]      
    }

    return (
    <div>
        <Navbar/>  
        <div className="jws_parent" style={{position : "relative"}}>
            {/* {portMap} */}
            <Map      
                ref = {this.mapRef}          
                height={"100%"}
                circle={loadingLocation} 
                jws={true}                     
                fitBound={portArea} 
                cluster={true}
                markers={this.state.markers}                                                 
            />
            <div className="search_area">
                <input type="text" className="form-control" placeholder="Search" onKeyUp={this.searchVehicle}
                onBlur={(e) => {
                    $(e.currentTarget).next().slideUp()
                    $(e.currentTarget).val("")
                    setTimeout(() => {
                      this.setState({
                        searchList : ''
                      })
                    }, 1000);                    
                }}></input>
                <div className="search_list">
                    {search_data_list}
                </div>
            </div>
            <div id="search_vehicle" className="search_vehicle_name" style={{display : this.state.searchSelectedVehicle ? "" : "none"}}>
                <p className="cj_w_header"><b>Tracking</b></p>
                <hr style={{marginTop : "5px", marginBottom : "5px"}} />
                <Grid container>
                  <Grid item xs>
                      <p className="cj_w_header"><b>{t('jsw.dashboard.unique')}</b></p>
                  </Grid>
                  <Grid item xs style={{textAlign : "center"}}>
                      <p className="cj_w_header">{unique_number}</p>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                      <p className="cj_w_header"><b>{t('jsw.dashboard.name')}</b></p>
                  </Grid>
                  <Grid item xs style={{textAlign : "center"}}>                      
                      <p className="cj_w_header">{vehicle_name}</p>
                  </Grid>
                </Grid>

                <div className="close_modal" onClick={this.removeSearchVehicle}>
                  <i class="fa fa-close" aria-hidden="true"></i>
                </div>
            </div>
            <div className="current_notification" onClick={this.openNotification}>
                <img src="images/bell_icon.png" className="notification-icon"></img>
                <div className="notification_count">{CommonFunction.zeroAppend(this.state.notificationCount)}</div>
            </div>
            <div className="statistic_container">
                <div className="open_win_icon" onClick={this.showWindow}>
                    <i class="fa fa-chevron-up fixed-icon" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down fixed-icon" style={{display : "none"}} aria-hidden="true"></i>
                </div>
                <div id="search_legend_container" style={{marginBottom : "10px", display : "none"}}>
                  <p className="cj_w_header" style={{textAlign : "left"}}>{t('jsw.dashboard.cluster')}</p>
                  <Grid container style={{marginBottom : "10px"}}>
                    <Grid item xs container className="legend_parent">
                      <Grid item xs={1}></Grid>
                      <Grid item xs={2}>
                        <div className="legend_1"></div>
                      </Grid>
                      <Grid item xs={9}>
                        <p className="legend_text cj_w_header"><b>1-6</b></p>
                      </Grid>
                    </Grid>
                    <Grid item xs container className="legend_parent">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2}>
                          <div className="legend_2"></div>
                        </Grid>
                        <Grid item xs={9}>
                          <p className="legend_text cj_w_header"><b>7-10</b></p>
                        </Grid>
                    </Grid>
                    <Grid item xs container className="legend_parent">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2}>
                          <div className="legend_3"></div>
                        </Grid>
                        <Grid item xs={9}>
                          <p className="legend_text cj_w_header"><b>10+</b></p>
                        </Grid>
                    </Grid>
                  </Grid>
                  <input id="search_port" type="text" className="form-control" style={{marginBottom : "15px"}} placeholder="Search"
                    onKeyUp={(e) => {
                        this.searchContent("search_port","port_panel")
                    }}></input>
                </div>                
                <div className="slide_win close">   
                    {/* <img id="reset_filter" src="images/reset_filter.png" width="25px" alt="icon" style={{float : "left", display : "none", cursor : "pointer"}} 
                    onClick={this.allPortData}></img>  */}                      
                    <p id="reset_filter" class="p_header v_start_journey" style={{float : "left", display : "none"}} onClick={this.allPortData}>{t('jsw.dashboard.reset')}</p>                 
                    <Grid container style={{border : "1px solid #cccccc"}}>                      
                      <Grid item xs style={{borderRight : "1px solid #cccccc", paddingTop : "10px"}}><p className="cj_w_header" style={{fontSize : "14px"}}><b>{t('jsw.dashboard.loadingpoints')}</b></p></Grid>
                      <Grid item xs={5} style={{paddingTop : "10px"}}><p className="cj_w_header" style={{fontSize : "14px"}}><b>{t('jsw.dashboard.count')}</b></p></Grid>
                    </Grid>
                    {loadPoints}                    
                </div>
                <Grid container spacing={2}>
                    <Grid item xs style={{borderRight : "1px solid #cccccc"}}>
                        <p className="cj_w_header" style={{margin : "0px"}}><b>{t('jsw.dashboard.port')}</b></p>
                        <p className="p_big_grey" style={{margin : "0px"}}>{CommonFunction.zeroAppend(loadingData.total_vehicle_on_map)}</p>
                    </Grid>
                    <Grid item xs>
                        <p className="cj_w_header" style={{margin : "0px"}}><b>{t('jsw.dashboard.overstay')}</b></p>
                        <p className="p_big_grey" style={{margin : "0px", color : "red"}}>{CommonFunction.zeroAppend(loadingData.overstay_count)}</p>
                    </Grid>
                </Grid>
            </div>
        </div>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={1000}
            onClose={(e, reason) => {
                if (reason === 'clickaway') {
                    return;
                }
                this.setState({ openAlert: false })
            }
            }
            open={this.state.openAlert}
            message={this.state.errorMessage}
        />
    </div>
    );
  }
}

export default withTranslation()(JwsComponent);
