import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import Snackbar from '@material-ui/core/Snackbar';
import html2canvas from "html2canvas";
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Calendar from 'react-calendar/dist/entry.nostyle'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Timekeeper from 'react-timekeeper';
const pdfConverter = require("jspdf");

const data1 = [
    {
      label: 'Sr. No.',
      field: 'sr_no',
      sort: 'asc',
      width: 80,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: 'VEHICLE NAME',
      field: 'name',
      sort: 'asc',
      width: 150,
    },
    // {
    //   label: 'JOURNEY START',
    //   field: 'j_start',
    //   sort: 'asc',
    //   width: 300,
    // },
    // {
    //   label: 'JOURNEY END',
    //   field: 'j_end',
    //   sort: 'asc',
    //   width: 300,
    // },
    {
      label: 'FUEL TYPE',
      field: 'fuel_type',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'TOTAL FUEL CONSUMED (LITRES)',
      field: 'fuel_consumed',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'DISTANCE TRAVELED (kms)',
      field: 'distance',
      sort: 'asc',
      width: 150,
    },
    // {
    //   label: 'START DATE',
    //   field: 'start_date',
    //   sort: 'asc',
    //   width: 150,
    // },
    // {
    //   label: 'END DATE',
    //   field: 'end_date',
    //   sort: 'asc',
    //   width: 150,
    // },
    {
      label: 'TOTAL FUEL EXPENSE (INR)',
      field: 'fuel_exp',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'FUEL REFILL EVENT (COUNT)',
      field: 'fuel_refill',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'FUEL REFILL IN (LITRE)',
      field: 'refill_value',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'FUEL THEFT EVENT (COUNT)',
      field: 'fuel_theft',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'FUEL THEFT IN (LITRE)',
      field: 'theft_value',
      sort: 'asc',
      width: 150,
    },
    // {
    //   label: 'AVGERAGE FUEL CONSUMED (Km/L)',
    //   field: 'avg_fuel',
    //   sort: 'asc',
    //   width: 150,
    // } 
  ]

class StopReport extends Component {
  counter = 0
  _exporter;  
  constructor(props){
    super(props);
    this.state = {
        date: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        datatable : [],          
        nextCount : 0, 
        totalCount : '',          
        dataCollection : [], 
        chunckValue : '',
        searchText : '',
        totalEntries : '',
        stopData : '',
        openAlert : false,
        errorMessage : '',
        sevenDayFlag : true,
        reportData : [] ,         
        reportNext : 0,
        downloadReportFlag : false,
        startTime : "12:00 am",
        sendStartTime : "00:00",
        showStartTime : false,
        endTime : "11:59 pm",
        sendEndTime : "23:59",
        showEndTime : false      
    }
  }

  componentDidMount(){
         
  }

  export = () => {
    this._exporter.save();
  }
  

  createReportData = () => {    
    const {startDate, endDate,reportData,reportNext,sendStartTime,sendEndTime} = this.state       
    startDate.setHours(0,0,0,0);
    var data = {start_date : CommonFunction.createServerDateTime(startDate,sendStartTime) , end_date : CommonFunction.createServerDateTime(endDate,sendEndTime), next_count : reportNext}      
    ApiUrlCall.getFuelReport(JSON.stringify(data)).then(response => {      
      if(response.data.response_code === 200 && response.data.is_success){        
        var data = response.data.data.fuel_data
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          var row_data = {name : element.vehicle_name, 
            // j_start : element.journey_start_address, j_end : element.journey_end_address, 
          fuel_type : element.fuel_type, 
          fuel_consumed : element.fuel_consumed, 
          distance : element.distance, 
          // start_date : CommonFunction.dateFormate(element.start_date), end_date : CommonFunction.dateFormate(element.end_date), 
          fuel_exp : element.total_expense, fuel_refill : element.refuel_count, refill_value : this.retrunNaValue(element.refuel_level),
          fuel_theft : element.theft_count, theft_value : this.retrunNaValue(element.theft_level), avg_fuel : element.avg_consumed}
          // avg_fuel : element.avg_consumed} 
          reportData.push(row_data)                 
        }    
        this.forceUpdate()          
        if(response.data.data.next_count !== response.data.data.total_count){
          this.setState({
            reportNext : response.data.data.next_count
          },() => {
            this.createReportData()
          })          
        }else{            
          $('#export_excel').removeClass("disable-click")
          this.setState({
            reportNext : 0,
            downloadReportFlag : true,
          })                            
        }
      }
    }).catch(error => {      
      console.log('error while getting fuel report',error)
    })
    // const {fuelData} = this.state    
    // var row = []  
    // console.log('lenght is', vehicleData.length)
  }

  emptyTable = () => {
    this.setState({
      datatable : []
    })
  }

  FuelReport = () => {
    $('#processing').show()
    const {startDate, endDate, nextCount,sendStartTime,sendEndTime} = this.state    
    startDate.setHours(0,0,0,0);
    var data = {start_date : CommonFunction.createServerDateTime(startDate,sendStartTime) , end_date : CommonFunction.createServerDateTime(endDate,sendEndTime),
      next_count : nextCount, search : this.state.searchText}    
    ApiUrlCall.getFuelReport(JSON.stringify(data)).then(response => {
      // console.log('response is report', response)
      this.emptyTable()
      if(response.data.response_code === 200 && response.data.is_success){
        $('#processing').hide()
        this.setState({
          fuelData : response.data.data.fuel_data,
          nextCount : response.data.data.next_count,
          totalCount : response.data.data.total_count,
          chunckValue : response.data.data.size
        },() => {
          this.createTableData()          
        })
      }else{
        $('#processing').hide()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while getting fuel report',error)
    })
  }

  StartdateClicked = date => {
    // var isoStartDate = date
    // console.log('date is', date)
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ startDate: date })
    $('#start_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  EnddateClicked = date => {
    var isoStartDate = date
    isoStartDate.setHours(23,59,59,0);
    var Difference_In_Time = isoStartDate.getTime() - this.state.startDate.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(Difference_In_Days > 90){ 
      $('#processing').hide()     
      this.setState({
        sevenDayFlag : false,   
        openAlert : true,
        errorMessage : "Not allowed to select more than 90 days"     
      })     
    }else{      
      this.setState({
        sevenDayFlag : true,
      })
    }
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ endDate: isoStartDate })
    $('#end_date_cal').slideUp(200)
  }
  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('#end_date_cal').slideUp()
    this.setState({
      showStartTime : false,
      showEndTime : false,
    })
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
    $('#start_date_cal').slideUp()
    this.setState({
      showStartTime : false,
      showEndTime : false,
    })
  }

  buttonStatus = () => {
    const {nextCount, totalCount} = this.state
    // console.log(nextCount,'.................',totalCount)
    if(nextCount === totalCount){
      // console.log('iffffffffff')      
      if(this.counter === 0){
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }else{
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    }else{
      // console.log('elseeeeeeee', this.counter)
      if(this.counter === 0){
        // console.log('else 1111111111')
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')        
        $('#dt-next').addClass('enabled-btn')
      }else{
        // console.log('else 22222222')
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')        
      }
    }    
  }

  entriesData = () => {
    const {nextCount, totalCount, chunckValue} = this.state
    if(nextCount != totalCount){
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start+'-'+end
      this.setState({
        totalEntries : entries_text
      })
    }else{
      if(nextCount <= 10){
        if(nextCount == 0){ 
          this.setState({
            totalEntries : "0"
          })
        }else{
          var entries_text = '1'+'-'+totalCount
          this.setState({
            totalEntries : entries_text
          })
        }
      }else{
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if(module > 0){
          start = nextCount - module + 1
          end = nextCount
          entries_text = start+'-'+end
        }else{
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start+'-'+end
        }
        this.setState({
          totalEntries : entries_text
        })
      }
    }
  } 

  createTableData = () => {
    const {fuelData,datatable} = this.state    
    var row = []  
    // console.log('lenght is', vehicleData.length)
    for (let i = 0; i < fuelData.length; i++) {
      const element = fuelData[i];
      var row_data = {sr_no : i+1, name : element.vehicle_name, 
        // j_start : element.journey_start_address, j_end : element.journey_end_address, 
        fuel_type : element.fuel_type, 
      fuel_consumed : element.fuel_consumed,
      distance : element.distance, 
      // start_date : CommonFunction.dateFormate(element.start_date), end_date : CommonFunction.dateFormate(element.end_date), 
      fuel_exp : element.total_expense,  fuel_refill : element.refuel_count, refill_value : this.retrunNaValue(element.refuel_level),
       fuel_theft : element.theft_count, theft_value : this.retrunNaValue(element.theft_level), avg_fuel : element.avg_consumed} 
      datatable.push(row_data)                 
    }    
    this.buttonStatus() 
    this.entriesData()   
    this.forceUpdate()
    // this.intervalData()
  }

  // getVehicleList = () => {
  //   $('#processing').show()  
  //   // return
  //   var data = {next_count : this.state.nextCount , search : this.state.searchText}    
  //   ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {             
  //     console.log('vehicle list is', response)      
  //     if(response.data.response_code === 200 && response.data.is_success ===  true){        
  //       this.setState({
  //           fuelData : response.data.data.vehicle_summary_list,
  //           nextCount : response.data.data.next_count,
  //           totalCount : response.data.data.total_count,
  //           chunckValue : response.data.data.size
  //       },() => {          
  //         this.createTableData()
  //       })
  //       $('#processing').hide()
  //     }else{
  //       $('#processing').hide()
  //     }
  //   }).catch(error => {
  //     $('#processing').hide()
  //       console.log(error, "error while fetching vehicle list");
        
  //   })
  // }

  pageNextClick = () => {
    const {totalCount,nextCount,dataCollection,datatable} = this.state
    // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
    this.counter = this.counter + 1  
    for (let i = 0; i < datatable.length; i++) {
      const element = datatable[i];
      dataCollection.push(element)
    }      
    this.setState({
      datatable : []
    },() => {
      this.FuelReport()
    })
  }

  pagePreviousClick = () => {
    const {dataCollection, chunckValue, nextCount} = this.state
    var pageSize = nextCount % chunckValue
    if(pageSize === 0){
      pageSize = chunckValue
    }
    // console.log('previous clicked', pageSize)    
    var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
    this.setState({
      datatable : last,
      nextCount : nextCount - pageSize
    },() => {      
      this.buttonStatus() 
      this.entriesData()     
    })
    this.counter = this.counter -1
  }

  optimizationSearch = (value) => {  
    setTimeout(() => {
      this.counter = 0
      if(value.length >= 3){
        this.setState({
          datatable : [],
          dataCollection : [],
          totalCount : '',
          chunckValue : '',
          nextCount : 0,
          searchText : value
        },() => {
          this.FuelReport()          
        })
      }else if(value.length === 0){
        this.setState({
          datatable : [],
          dataCollection : [],
          totalCount : '',
          chunckValue : '',
          nextCount : 0,
          searchText : value
        },() => {
          this.FuelReport()          
        })
      }      
    }, 1000);     
  }

  hideAllDropDown = () => {   
    $('#end_date_cal').hide()
    $('#start_date_cal').hide()
    this.setState({
      showStartTime : false,
      showEndTime : false,
    })
  }

  create_fuel_report = () => {    
    const {sevenDayFlag, startDate, endDate} = this.state
    // console.log('seven days flag', sevenDayFlag)
    this.hideAllDropDown()
    var dateValidate = CommonFunction.dateValidation(startDate , endDate)    
      if(!dateValidate){
        // $('#processing').hide()
        this.setState({
          openAlert : true,
          errorMessage : "Kindly select valid date"
        })
        return
      }  

    var Difference_In_Time = this.state.endDate.getTime() - this.state.startDate.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    // console.log('seven days', Difference_In_Days)
    if(Difference_In_Days > 90){ 
      $('#processing').hide()              
      this.setState({
        sevenDayFlag : false,   
        openAlert : true,
        errorMessage : "Not allowed to select more than 90 days"     
      }) 
      return    
    }else{                
      this.setState({
        sevenDayFlag : true,
      },() => {
        this.sevendayCheckCreateReport()
      })
    }
    $('#start_date_cal').slideUp("slow")
    $('#end_date_cal').slideUp("slow")    
  }
  sevendayCheckCreateReport = () => {
    if(this.state.sevenDayFlag){
      this.setState({
        complete_report :false,
        datatable : [],
        dataCollection : [],
        totalCount : '',
        chunckValue : '',
        nextCount : 0,
        reportData : [],
        reportNext : 0,
        downloadReportFlag : false,
      },() => {
        $('#export_excel').addClass("disable-click")
        this.FuelReport()
        this.createReportData()
      })
    }else{
      this.setState({        
        openAlert : true,
        errorMessage : "Not allowed to select more than 90 days"     
      })     
    }
  }
  selectStartTime = (newTime) => {    
    this.setState({
      startTime : newTime.formatted12,
      sendStartTime : newTime.formatted24
    })
  }

  showStartTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if(this.state.showStartTime){
      this.setState({
        showStartTime : false,
        showEndTime : false
      })
    }else{      
      this.setState({
        showStartTime : true,
        showEndTime : false
      })
    }
  }

  selectEndTime = (newTime) => {    
    this.setState({
      endTime : newTime.formatted12,
      sendEndTime : newTime.formatted24
    })
  }

  showEndTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if(this.state.showEndTime){
      this.setState({
        showEndTime : false,
        showStartTime : false
      })
    }else{
      this.setState({
        showEndTime : true,
        showStartTime : false
      })
    }
  }

  render(){
    const {t} = this.props
    var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);  
    var excel_name = "Stop_Report_"+CommonFunction.downloadReportDate(this.state.date)+".xlsx"  
    if(this.state.downloadReportFlag){
      var excel_data = process(this.state.reportData, {
        // group: group
       }).data;
    }
    return (
      <div className="fuel_report_parent">
        <div className="report_filter_div">
        <Grid spacing={2} container>
            <Grid item xs>

              <p>{t('ct.startdate')}</p>
              <div className="input_div" onClick={this.showStartDate}>
                <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="start_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.StartdateClicked}
                  value={this.state.startDate}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
            </Grid>
            <Grid item xs>
              <p>Start Time</p>
              <div onClick={this.showStartTimeContent} className="time_div">
                <p>{this.state.startTime}</p>
              </div>
              {this.state.showStartTime &&
                <div style={{position : "absolute", zIndex : "9"}}>
                  <Timekeeper 
                  time={this.state.startTime}
                  switchToMinuteOnHourSelect
                  onDoneClick={() => {
                    this.setState({
                      showStartTime : false
                    })
                  }}
                  onChange={this.selectStartTime}
                  />
                </div>
              }
            </Grid>
            <Grid item xs>
              <p>{t('ct.enddate')}</p>
              <div className="input_div" onClick={this.showEndDate}>
                <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="end_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.EnddateClicked}
                  value={this.state.endDate}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
            </Grid> 
            <Grid item xs>
              <p>End Time</p>
              <div onClick={this.showEndTimeContent} className="time_div">
                <p>{this.state.endTime}</p>
              </div>
              {this.state.showEndTime &&
                <div style={{position : "absolute", zIndex : "9"}}>
                  <Timekeeper 
                  time={this.state.endTime}
                  switchToMinuteOnHourSelect
                  onDoneClick={() => {
                    this.setState({
                      showEndTime : false
                    })
                  }}
                  onChange={this.selectEndTime}
                  />
                </div>
              }
            </Grid>            
            <Grid item xs={2}>
              <p>&nbsp;</p>
              <button className="btn orange-btn btn-block" style={{ marginTop: "0px" }} onClick={this.create_fuel_report}>{t('ars.generate')}</ button>
            </Grid>
            <Grid container item xs={2} className="pdf_excel_download">
              {/* <img src="images/group-6-copy-2.png" className="vd_journey_icon"></img>
              <label>&nbsp;</label> */}
              <Grid item xs={12}><label>&nbsp;</label></Grid>
              <Grid item xs={6} onClick={this.download}>
                  {/* <img src="images/group-6-copy-2.png" style={{marginRight : "5px"}}></img>
                  <label className="BSC_semi_bold18">{t('ars.pdf')}</label> */}
              </Grid>
              <Grid id="export_excel" item xs ={6} onClick={this.export} className="disable-click">
                  <div>
                    <img src="images/group-6-copy-2.png" style={{marginRight : "5px"}}></img>
                    <label className="BSC_semi_bold18">{t('ars.excel')}</label>
                  </div>
                  <ExcelExport
                    data={excel_data}
                    fileName={excel_name}
                    ref={(exporter) => { this._exporter = exporter; }}>                    
                    <ExcelExportColumn field="name" title="Vehicle Name" />
                    {/* <ExcelExportColumn field="j_start" title="Journey Start" />
                    <ExcelExportColumn field="j_end" title="Journey End" /> */}
                    <ExcelExportColumn field="fuel_type" title="Fuel Type" />
                    <ExcelExportColumn field="fuel_consumed" title="TOTAL FUEL CONSUMED (LITRES)" />
                    {/* <ExcelExportColumn field="start_date" title="Start Date" />                    
                    <ExcelExportColumn field="end_date" title="End Date" /> */}
                    <ExcelExportColumn field="fuel_exp" title="TOTAL FUEL EXPANSE (INR)" />
                    <ExcelExportColumn field="fuel_refill" title="FUEL REFILL EVENT (COUNT)" />
                    <ExcelExportColumn field="fuel_theft" title="FUEL THEFT EVENT (COUNT)" />
                    <ExcelExportColumn field="avg_fuel" title="AVGERAGE FUEL CONSUMED (Km/L)" />
                  </ExcelExport>
              </Grid>
              {/* <div className="pdf_excel_download">
                <span style={{ marginRight: "20px" }}>
                </span>
                <span>
                </span>
              </div> */}
            </Grid>
          </Grid>
          <p className="work_sans_regular16"
            style={{ fontSize: "12px", color: "#dc3545", marginBottom: "0px", marginTop: "5px" }}>
            {t('ars.sevenday')} *</p>
          </div>

          <div style={{marginTop : "60px"}}>
            <MDBDataTable
                displayEntries={false}
                paging={false}                
                scrollX
                entries={10}            
                displayEntries={false}
                striped
                bordered
                data={{columns : data1 , rows : this.state.datatable}}            
                onSearch = {this.optimizationSearch}
            />
            <Grid container style={{margin : "20px 0px"}}>
                <Grid item xs>
                    <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                </Grid>
                <Grid item xs style={{textAlign : "right"}}>
                    <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                    <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                </Grid>
            </Grid>
          </div>
          <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1200}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(StopReport);
